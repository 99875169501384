<template>
  <Card title="Asset Dissipation">
    <DataTable :headers="headers" :data="tableData" />
  </Card>
</template>
<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { formatNumberWithCommas } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AssetDissipation',
    components: {
      Card,
      DataTable,
    },
    props: {
      financialId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loanId: this.$route.params.id,
        discountRates: {
          Checking: 0,
          Savings: 0,
          'Certificate of Deposit': 0,
          'Roth IRA': 0,
          'Assets Subject to Federal Taxes': 15,
        },
        headers: [
          { key: 'assetType', label: 'Type' },
          { key: 'description', label: 'Description' },
          { key: 'amountWithCommas', label: 'Amount' },
          { key: 'rate', label: 'Rate' },
          { key: 'discountedValueWithCommas', label: 'Discounted Value' },
        ],
        tableData: [],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchAssets() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/assets/', {
            loan: this.loanId,
          });
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            rate: `${this.discountRates[item.assetType]}%`,
            amountWithCommas: `$${formatNumberWithCommas(item.amount)}`,
            discountedValueWithCommas: `$${formatNumberWithCommas(item.discountedValue)}`,
            ...item,
          }));
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      financialId: {
        handler() {
          this.fetchAssets();
        },
        immediate: true,
      },
    },
  };
</script>
