<template>
  <Card title="Reporting">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <Card v-for="(tab, index) in tabOptions" :key="index" :isFlex="true">
        <template v-slot:title>
          <div class="flex items-center gap-2">
            <component :is="tab.iconComponent" />
            <h1 class="text-lg font-semibold leading-none">{{ tab.title }}</h1>
          </div>
        </template>
        <template v-slot:action>
          <button class="p-2 rounded bg-primary" @click="navigate(tab.page)">
            <img src="../../assets/image/settings/up-right-arrow.svg" />
          </button>
        </template>
        <p class="text-sm mt-2">
          {{ tab.description }}
        </p>
      </Card>
    </div>
  </Card>
</template>

<script>
  import Card from '@/components/Card/index.vue';
  import LoanIcon from './components/Icons/LoanIcon.vue';
  import UserAccessIcon from './components/Icons/UserAccessIcon.vue';
  import DocumentHistoryIcon from './components/Icons/DocumentHistoryIcon.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Reporting',
    components: {
      Card,
    },
    data() {
      const tabOptions = [
        {
          title: 'Loan Export',
          iconComponent: LoanIcon,
          page: 'LoanExport',
          description: 'Export loan data to JSON format.',
        },
        {
          title: 'User Access',
          iconComponent: UserAccessIcon,
          page: 'UserAccess',
          description: 'Analyze user access to the system.',
        },
        {
          title: 'Document History',
          iconComponent: DocumentHistoryIcon,
          page: 'DocumentHistory',
          description: 'Analyze document download history.',
        },
        {
          title: 'Report Engine',
          iconComponent: DocumentHistoryIcon,
          page: 'ReportEngine',
          description: 'Generate and manage custom reports.',
        },
        {
          title: 'Mortgage Call Report',
          iconComponent: DocumentHistoryIcon,
          page: 'MortgageCallReport',
          description: 'Generate mortgage call reports.',
        },
      ];
      return {
        tabOptions,
      };
    },
    methods: {
      navigate(newPage) {
        if (newPage) {
          this.$router.push({ name: `${newPage}` });
        }
      },
    },
  };
</script>
