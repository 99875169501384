<template>
  <Card title=" Appraisal Analysis: Appraisal 1 @ 100000 by John Smith">
    <div class="overflow-x-auto border-b pb-15 mb-15">
      <table class="min-w-full bg-white border border-gray-200">
        <tbody>
          <tr class="border-b border-gray-200">
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Appraiser:</th>
            <td class="text-left px-6 py-4">John Smith</td>
            <th class="text-left px-6 py-4 bg-gray-100 font-semibold">Credit Report Opened On :</th>
            <td class="text-left px-6 py-4">100000</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <RadioInput
        label="Does the appraisal report represent a consistent and far analysis of the property?"
        id="floodInsuranceRequired"
        :value="formData.floodInsuranceRequired"
        :options="hecmOptions"
        v-model="formData.floodInsuranceRequired"
      />
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
        <div>
          <TextareaInput
            label="Explain"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
        <div>
          <TextareaInput
            label="Explain"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
        <div>
          <RadioInput
            label="Are the comparables acceptable?"
            id="floodInsuranceRequired"
            :value="formData.floodInsuranceRequired"
            :options="hecmOptions"
            v-model="formData.floodInsuranceRequired"
          />
        </div>
        <div>
          <TextareaInput
            label="Explain"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <RadioInput
        label="Are the adjustments acceptable both as to the items adjusted and the amount collected to each item adjusted?"
        id="floodInsuranceRequired"
        :value="formData.floodInsuranceRequired"
        :options="hecmOptions"
        v-model="formData.floodInsuranceRequired"
      />
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
        <div>
          <TextareaInput
            label="Explain"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
        <div>
          <RadioInput
            label="Is the value acceptable for FHA/HUD purposes?"
            id="floodInsuranceRequired"
            :value="formData.floodInsuranceRequired"
            :options="hecmOptions"
            v-model="formData.floodInsuranceRequired"
          />
        </div>
        <div>
          <RadioInput
            label="If not, should it be corrected?"
            id="floodInsuranceRequired"
            :value="formData.floodInsuranceRequired"
            :options="hecmOptions"
            v-model="formData.floodInsuranceRequired"
          />
        </div>
        <div>
          <TextareaInput
            label="Explain"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
        <div>
          <InputField
            label="Value for FHA/HUD purposes"
            id="floodCertificateNumber"
            ref="floodCertificateNumber"
            :value="formData.floodCertificateNumber"
            v-model.trim="formData.floodCertificateNumber"
            :validation="{ required: true }"
          />
        </div>

        <div>
          <TextareaInput
            label="Explain"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
        <div>
          <TextareaInput
            label="Repair Conditions"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
        <div>
          <TextareaInput
            label="Other Comments"
            id="legalVesting"
            ref="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :validation="{ required: true }"
          />
        </div>
      </div>
    </div>

    <div class="mt-4 flex justify-end">
      <Button :variant="primary" :disabled="!writePermission"> Order Flood Certificate</Button>
    </div>
  </Card>
</template>

<script>
  import Card from './../../../../../../components/Card/index.vue';
  import InputField from './../../../../../../components/FormInput/InputField/index.vue';
  import RadioInput from './../../../../../../components/FormInput/RadioInput/index.vue';
  import Button from './../../../../../../components/Button/index.vue';
  import TextareaInput from './../../../../../../components/FormInput/TextareaInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AppraisalAnalysis',
    props: {
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      Card,
      InputField,
      RadioInput,
      Button,
      TextareaInput,
    },
    data() {
      return {
        formData: {
          floodCertificateNumber: '1234567890',
          floodDeterminationDate: '01/01/2020',
          floodInsuranceRequired: 'Yes',
          livingTrust: '',
        },
        floodZoneOptions: [
          { label: 'Sample legal vesting', value: 'Sample legal vesting' },
          { label: 'HECM for Purchase', value: 'HECM for Purchase' },
        ],
        hecmOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    methods: {
      formatDate(date) {
        // Format the date to "YYYY-MM-DD"
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;
      },
    },
    computed: {
      isFormValid() {
        return (
          this.$refs.street.isValid &&
          this.$refs.suffix.isValid &&
          this.$refs.preDirection.isValid &&
          this.$refs.streetType.isValid &&
          this.$refs.postDirection.isValid &&
          this.$refs.addressSuffix.isValid &&
          this.$refs.zipCode.isValid &&
          this.$refs.zip4.isValid
        );
      },
    },
  };
</script>
