<template>
  <Card title="Income">
    <DataTable :headers="headers" :data="tableData">
      <template #no-data>
        <div>You can add income data at Borrower's Income tab.</div>
      </template>
    </DataTable>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { formatNumberWithCommas } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Income',
    components: {
      Card,
      DataTable,
    },
    props: {
      financialId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loanId: this.$route.params.id,
        headers: [
          { key: 'borrowerName', label: 'Borrower Name' },
          { key: 'incomeType', label: 'Type' },
          { key: 'annualIncomeWithCommas', label: 'Annual' },
          { key: 'monthlyIncomeWithCommas', label: 'Monthly' },
        ],
        tableData: [],
      };
    },
    setup() {
      return {
        formatNumberWithCommas,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchIncomes() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/incomes/', {
            loan: this.loanId,
          });
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            borrowerName: item.borrower?.firstName + ' ' + item.borrower?.lastName,
            annualIncomeWithCommas: `$${formatNumberWithCommas(item.annualIncome)}`,
            monthlyIncomeWithCommas: `$${formatNumberWithCommas(item.monthlyIncome)}`,
            ...item,
          }));
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      financialId: {
        handler() {
          this.fetchIncomes();
        },
        immediate: true,
      },
    },
  };
</script>
