<template>
  <Card title="Third Party Integrations" class="third-party-integrations">
    <form @submit.prevent="saveData">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Password</th>
            <th>Scope</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MeridianLink</td>
            <td><input type="text" id="username" v-model="settings.meridianlink.individual_credentials.meridianlink_username" /></td>
            <td>
              <input type="text" id="password-meridianLink" v-model="settings.meridianlink.individual_credentials.meridianlink_password" />
            </td>
            <td>
              <select id="scope" v-model="settings.meridianlink.individual_credentials.meridianlink_scope">
                <option value="individual">Individual</option>
                <option value="company" v-if="!settings.meridianlink.has_company_meridianlink_credentials || !settings.meridianlink.is_company_meridianlink">Company</option>
              </select>
            </td>
          </tr>
          <tr
            v-if="
              isCompanyAdmin() &&
              settings.meridianlink.has_company_meridianlink_credentials &&
              settings.meridianlink.company_credentials &&
              settings.meridianlink.individual_credentials.meridianlink_scope == 'individual'
            "
          >
            <td>Company MeridianLink</td>
            <td><input type="text" id="username" v-model="settings.meridianlink.company_credentials.meridianlink_username" /></td>
            <td>
              <input type="text" id="password-meridianLink" v-model="settings.meridianlink.company_credentials.meridianlink_password" />
            </td>
            <td>
              <div class="mx-auto">Company</div>
            </td>
          </tr>

          <tr>
            <td>Flood Certificate Api Key</td>
            <td colspan="2"><input type="text" id="username" v-model="settings.flood_cert_credentials.individual_credentials.flood_cert_api_key" /></td>
            <td>
              <select id="scope" v-model="settings.flood_cert_credentials.individual_credentials.flood_cert_scope">
                <option value="individual">Individual</option>
                <option value="company" v-if="!settings.flood_cert_credentials.has_company_flood_cert_credentials || !settings.flood_cert_credentials.is_company_flood_cert_key">
                  Company
                </option>
              </select>
            </td>
          </tr>
          <tr
            v-if="
              isCompanyAdmin() &&
              settings.flood_cert_credentials.has_company_flood_cert_credentials &&
              settings.flood_cert_credentials.company_credentials &&
              settings.flood_cert_credentials.individual_credentials.flood_cert_scope == 'individual'
            "
          >
            <td>Company Flood Certificate Api Key</td>
            <td colspan="2"><input type="text" id="username" v-model="settings.flood_cert_credentials.company_credentials.flood_cert_api_key" required /></td>
            <td>
              <div class="mx-auto">Company</div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <Button variant="success" type="submit">Save Data</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </Card>
</template>

<script>
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import { isCompanyAdmin } from '@/utils';
  import Button from '@/components/Button/index.vue';
  export default {
    name: 'ThirdPartyIntegrations',
    components: {
      Card,
      Button,
    },
    setup() {
      return {
        isCompanyAdmin,
      };
    },
    created() {
      this.fetcData();
    },
    data() {
      return {
        settings: {
          meridianlink: {
            has_company_meridianlink_credentials: false,
            individual_credentials: {
              meridianlink_username: '',
              meridianlink_password: '',
              meridianlink_scope: 'individual',
              is_company_meridianlink: false,
            },
            company_credentials: {
              meridianlink_username: '',
              meridianlink_password: '',
            },
          },
          flood_cert_credentials: {
            has_company_flood_cert_credentials: false,
            individual_credentials: {
              flood_cert_api_key: '',
              flood_cert_scope: 'individual',
              is_company_flood_cert_key: true,
            },
            company_credentials: {
              flood_cert_api_key: '',
            },
          },
        },
      };
    },
    methods: {
      async fetcData() {
        try {
          const response = await apiService.get('/company/companies/api-credentials/');
          if (!response.data) return;
          this.settings = response.data;
        } catch (error) {
          console.error(error);
        }
      },
      async saveData() {
        try {
          if (this.settings.meridianLink?.individual_credentials.meridianlink_scope === 'company') {
            this.settings.meridianLink.company_credentials = null;
          }
          if (this.settings.flood_cert_credentials?.flood_cert_scope === 'company') {
            this.settings.flood_cert_credentials.individual_credentials = null;
          }
          const response = await apiService.post('/company/companies/api-credentials/', this.settings);
          if (response.status === 200) {
            this.$root.showSnackbar(response.data.detail, 'success');
            this.fetcData();
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.detail) {
            const detail = error.response.data.detail;

            const errorMessage = this.findErrorMessage(detail);
            if (errorMessage) {
              this.$root.showSnackbar(errorMessage, 'error');
            } else {
              this.$root.showSnackbar(detail, 'error');
            }
          } else {
            console.error(error);
          }
        }
      },
      findErrorMessage(obj) {
        for (const key in obj) {
          if (Array.isArray(obj[key]) && typeof obj[key][0] === 'string') {
            return obj[key][0]; // Return the first string in the array
          } else if (typeof obj[key] === 'object') {
            const message = this.findErrorMessage(obj[key]); // Recursively search
            if (message) return message;
          }
        }
        return null;
      },
    },
  };
</script>

<style scoped>
  .table {
    width: 100%;
    min-width: 700px;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 11px 15px;
    font-size: 14px;
    text-align: left;
    border: 1px solid #f3f3f3;
  }

  .word-break {
    word-break: break-word;
  }
</style>
