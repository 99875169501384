<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="backToPackageList" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">{{ `${isUpdate ? 'Update' : 'Create'} Package` }}</h1>
      </div>
    </template>
    <div class="border border-gray bg-white rounded-lg p-5 grow overflow-y-auto">
      <div class="flex flex-col md:flex-row gap-3 items-center border-b border-gray-200 mb-4 pb-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <InputField
              label="Package Title *"
              id="name"
              ref="name"
              placeholder="Enter package title"
              v-model.trim="formData.name"
              :value="formData.name"
              :disable-validation="true"
            />
          </div>
        </div>
        <div class="flex mt-2 md:mt-0 md:ml-auto gap-4">
          <Button variant="primary" @click="handleSave" style="width: fit-content" :disabled="disabledSave"> {{ isUpdate ? 'Save Changes' : 'Create' }} </Button>
        </div>
      </div>

      <div class="hard-coded-templates mb-4">
        <label class="text-md text-black font-semibold mb-2">Hard-coded Templates</label>
        <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          <div class="relative" v-for="(hardCodedDocumentType, index) in HARD_CODED_DOCUMENT_TYPES" :key="index">
            <div class="absolute right-1 top-1 z-10 cursor-pointer" @click="toggleHardCodedInclusion(hardCodedDocumentType.value)">
              <font-awesome-icon v-if="isHardCodedIncluded(hardCodedDocumentType.value)" class="text-white bg-cyan-700 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-check" />
              <font-awesome-icon v-else class="text-white bg-gray-300 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-circle" />
            </div>

            <!-- Edit Icon -->
            <button
              v-if="isHardCodedIncluded(hardCodedDocumentType.value)"
              class="absolute right-8 top-1 z-10 cursor-pointer"
              @click="handleOpenActivationDialog('hardcoded', hardCodedDocumentType.value)"
            >
              <font-awesome-icon class="text-white bg-cyan-700 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-edit" />
            </button>

            <DocumentCard :action="false" :title="hardCodedDocumentType.name" :pdf-link="hardCodedDocumentType.fileLink" />
          </div>
        </div>
      </div>

      <hr class="border-t border-gray-200 my-4 w-full" />

      <div class="user-defined-templates">
        <label class="text-md text-black font-semibold mb-2">User-defined Templates</label>
        <div v-if="noTemplatesLoaded" class="flex justify-center items-center">
          <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
            <img src="../../../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
            <h2 class="text-2xl font-semibold text-gray-800 mb-2">No Templates</h2>
            <p class="text-gray-600 mb-4">There is no created templates yet.</p>
            <div class="flex justify-center gap-4">
              <Button @click="navigateToCreateNew"><font-awesome-icon icon="fa-solid fa-plus" /> Create New Template</Button>
            </div>
          </div>
        </div>

        <div v-else class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          <div class="relative" v-for="(template, index) in templates" :key="index">
            <!-- Toggle Inclusion -->
            <button class="absolute right-1 top-1 z-10 cursor-pointer" @click="toggleInclusion(template.id)">
              <font-awesome-icon v-if="isIncluded(template.id)" class="text-white bg-cyan-700 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-check" />
              <font-awesome-icon v-else class="text-white bg-cyan-700 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-circle" />
            </button>

            <!-- Edit Icon -->
            <button v-if="isIncluded(template.id)" class="absolute right-8 top-1 z-10 cursor-pointer" @click="handleOpenActivationDialog('normal', template.id)">
              <font-awesome-icon class="text-white bg-cyan-700 p-1 w-4 h-4 rounded-full" icon="fa-solid fa-edit" />
            </button>

            <DocumentCard :action="false" :title="template.name" :pdf-link="template.templateFileUrl" />
          </div>
        </div>
      </div>
    </div>
    <ActivationDialog :isOpen="isActivationDialogOpen" :info="selectedTemplate" @save="handleSaveActivationDialog" @close="handleCloseActivationDialog" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import DocumentCard from '../../../../components/DocumentCard/index.vue';
  import ActivationDialog from '../../components/ActivationDialog/index.vue';
  import { HARD_CODED_DOCUMENT_TYPES } from '@/constants';

  export default {
    name: 'PackageForm',
    components: {
      Button,
      Card,
      InputField,
      DocumentCard,
      ActivationDialog,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
        HARD_CODED_DOCUMENT_TYPES,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchInitialData();
    },
    data() {
      return {
        isUpdate: typeof this.$route.params.id !== 'undefined',
        templates: undefined,
        formData: {
          name: '',
          normal_templates: [],
          hardcoded_templates: [],
        },
        selectedTemplate: null,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      // Fetch package with its id
      async fetchInitialData() {
        this.setLoading(true);
        try {
          // Fetch Templates
          const response = await apiService.get('/documents/templates/');
          this.templates = response.data;
        } catch (error) {
          this.templates = undefined;
          this.$root.showSnackbar(`Failed to fetch document templates: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }

        // Fetch package data when edit mode
        if (this.isUpdate) {
          try {
            const response = await apiService.get(`/documents/packages/${this.$route.params.id}/`);
            this.formData = {
              name: response.data.name,
              normal_templates: response.data.normal_templates,
              hardcoded_templates: response.data.hardcoded_templates,
            };
          } catch (error) {
            this.$root.showSnackbar(`Failed to fetch package data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          }
        }

        this.setLoading(false);
      },
      // handle action to create or update package data
      async handleSave() {
        this.setLoading(true);
        try {
          if (this.isUpdate) {
            await apiService.patch(`/documents/packages/${this.$route.params.id}/`, this.formData);
          } else {
            await apiService.post('/documents/packages/', this.formData);
          }
          this.$root.showSnackbar(`Document package ${this.isUpdate ? 'update' : 'create'} successfully`, 'success');
          this.backToPackageList();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            const firstErrorKey = Object.keys(error.response.data)[0];
            const firstErrorMessage = error.response.data[firstErrorKey][0];
            this.$root.showSnackbar(`Failed to ${this.isUpdate ? 'update' : 'create'} document package: ${firstErrorMessage}`, 'error');
          } else {
            this.$root.showSnackbar(
              `Failed to ${this.isUpdate ? 'update' : 'create'} document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`,
              'error'
            );
          }
        } finally {
          this.setLoading(false);
        }
      },
      // Check if normal template is included
      isIncluded(templateId) {
        return this.formData.normal_templates.some((template) => template.id === templateId);
      },
      // Toggle inclusion of normal template
      toggleInclusion(templateId) {
        const index = this.formData.normal_templates.findIndex((template) => template.id === templateId);
        if (index === -1) {
          this.formData.normal_templates.push({ id: templateId, activation_logic: {} });
        } else {
          this.formData.normal_templates.splice(index, 1);
        }
      },
      // Check if hardcoded template is included
      isHardCodedIncluded(templateName) {
        return this.formData.hardcoded_templates.some((template) => template.name === templateName);
      },
      // Toggle inclusion of hardcoded template
      toggleHardCodedInclusion(templateName) {
        const index = this.formData.hardcoded_templates.findIndex((template) => template.name === templateName);
        if (index === -1) {
          this.formData.hardcoded_templates.push({ name: templateName, activation_logic: {} });
        } else {
          this.formData.hardcoded_templates.splice(index, 1);
        }
      },
      // Open activation dialog
      handleOpenActivationDialog(type, templateIdOrName) {
        const template =
          type === 'normal'
            ? this.formData.normal_templates.find((template) => template.id === templateIdOrName)
            : this.formData.hardcoded_templates.find((template) => template.name === templateIdOrName);
        this.selectedTemplate = {
          ...template,
          type,
        };
      },
      // Handle save activation dialog
      handleSaveActivationDialog(data) {
        for (const key in data) {
          if (data[key] === null) {
            delete data[key];
          }
        }
        if (this.selectedTemplate.type === 'normal') {
          this.formData.normal_templates.find((template) => template.id === this.selectedTemplate.id).activation_logic = { ...data };
        } else {
          this.formData.hardcoded_templates.find((template) => template.name === this.selectedTemplate.name).activation_logic = { ...data };
        }
        this.handleCloseActivationDialog();
      },
      // Close activation dialog
      handleCloseActivationDialog() {
        this.selectedTemplate = null;
      },
      backToPackageList() {
        this.$router.push({ name: 'DocumentPackage' });
      },
      navigateToCreateNew() {
        this.$router.push({ name: 'PDFTemplateCreate' });
      },
    },
    computed: {
      noTemplatesLoaded() {
        return this.templates?.length == 0;
      },
      disabledSave() {
        return !this.formData.name.length || (!this.formData.normal_templates.length && !this.formData.hardcoded_templates.length);
      },
      isActivationDialogOpen() {
        return this.selectedTemplate !== null;
      },
    },
    watch: {},
  };
</script>
