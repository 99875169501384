<template>
  <Card title="Roles Permission">
    <div class="flex justify-between items-center border-b-2 pb-3 mb-3">
      <h3 class="font-bold text-lg">Loan-Related Permissions</h3>
      <div class="flex justify-end space-x-4">
        <button
          @click="resetLoanPermissions(false)"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Reset
        </button>
        <button
          @click="saveLoanPermissions(false)"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </div>
    <div class="roles-permission flex space-x-6">
      <div class="form-group flex-1">
        <label for="role" class="block text-sm font-medium text-gray-700">Role:</label>
        <select
          v-model="selectedRole"
          id="role"
          @change="getPermissions(false)"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option disabled value="">Please select a role</option>
          <option v-for="role in companyRoleOptions" :key="role" :value="role">{{ role }}</option>
        </select>
      </div>

      <div class="form-group flex-1">
        <label for="status" class="block text-sm font-medium text-gray-700">Status:</label>
        <select
          v-model="selectedStatus"
          id="status"
          @change="getPermissions(false)"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option disabled value="">Please select a status</option>
          <option v-for="(status, key) in loanStatuses" :key="key" :value="key">{{ status }}</option>
        </select>
      </div>
    </div>

    <table class="permissions-grid w-full border-collapse mt-6">
      <thead class="bg-gray-50">
        <tr>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Read</th>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Write</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(item, key) in loanPermissions" :key="key">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ key }}</td>
          <td class="px-6 py-4 whitespace-nowrap">
            <input type="checkbox" v-model="item.read" class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <input type="checkbox" v-model="item.write" @click="item.read = true" class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex justify-between items-center border-b-2 pb-3 mb-3 mt-6">
      <h3 class="font-bold text-lg">Other Permissions</h3>

      <div class="flex justify-end space-x-4">
        <button
          @click="resetLoanPermissions(true)"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Reset
        </button>
        <button
          @click="saveLoanPermissions(true)"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </div>
    <div class="roles-permission flex space-x-6">
      <div class="form-group flex-1">
        <label for="role" class="block text-sm font-medium text-gray-700">Role:</label>
        <select
          v-model="otherPermissionRole"
          id="otherrole"
          @change="getPermissions(true)"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option disabled value="">Please select a role</option>
          <option v-for="role in companyRoleOptions" :key="role" :value="role">{{ role }}</option>
        </select>
      </div>
    </div>
    <table class="permissions-grid w-full border-collapse mt-6">
      <thead class="bg-gray-50">
        <tr>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Read</th>
          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Write</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(item, key) in otherPermission" :key="key">
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ key }}</td>
          <td class="px-6 py-4 whitespace-nowrap">
            <input type="checkbox" v-model="item.read" class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <input type="checkbox" v-model="item.write" @click="item.read = true" class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
          </td>
        </tr>
      </tbody>
    </table>
  </Card>
</template>

<script>
  import { COMPANY_USER_ROLE_OPTIONS, LOAN_STATUSES } from '@/constants';
  import Card from './../../../../components/Card/index.vue';
  import apiService from '@/api/apiService';

  export default {
    name: 'RolesPermission',
    components: {
      Card,
    },
    data() {
      return {
        selectedRole: '',
        selectedStatus: '',
        loanPermissions: {
          Borrowers: { read: false, write: false },
          Property: { read: false, write: false },
          Fees: { read: false, write: false },
          'Case Number': { read: false, write: false },
          'Financial Assessment': { read: false, write: false },
          Closing: { read: false, write: false },
          Comparison: { read: false, write: false },
          Condition: { read: false, write: false },
          Compliance: { read: false, write: false },
        },
        otherPermissionRole: '',
        otherPermission: {
          'Manage own licenses': { read: false, write: false },
          "Manage other users' licenses": { read: false, write: false },
        },
        companyRoleOptions: COMPANY_USER_ROLE_OPTIONS,
        loanStatuses: LOAN_STATUSES,
      };
    },
    methods: {
      resetLoanPermissions(isOtherPermission = false) {
        if (isOtherPermission) {
          this.otherPermission = {
            'Manage own licenses': { read: false, write: false },
            "Manage other users' licenses": { read: false, write: false },
          };
          return;
        }
        this.loanPermissions = {
          Borrowers: { read: false, write: false },
          Property: { read: false, write: false },
          Fees: { read: false, write: false },
          'Case Number': { read: false, write: false },
          'Financial Assessment': { read: false, write: false },
          Closing: { read: false, write: false },
          Comparison: { read: false, write: false },
          Condition: { read: false, write: false },
          Compliance: { read: false, write: false },
        };
      },
      async saveLoanPermissions(isOtherPermission = false) {
        try {
          if ((!this.selectedRole || !this.selectedStatus) && !isOtherPermission) {
            this.$root.showSnackbar('Please select role and status', 'error');
            return;
          } else if (isOtherPermission && !this.otherPermissionRole) {
            this.$root.showSnackbar('Please select role', 'error');
            return;
          }
          const response = await apiService.post('/company/companies/role-permissions/', {
            role: isOtherPermission ? this.otherPermissionRole : this.selectedRole,
            status: isOtherPermission ? null : this.selectedStatus,
            permissions: isOtherPermission ? this.otherPermission : this.loanPermissions,
            is_other_permissions: isOtherPermission,
          });
          if (response.status === 200) {
            this.$root.showSnackbar('Permissions saved successfully', 'success');
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getPermissions(isOtherPermission = false) {
        try {
          if ((!this.selectedRole || !this.selectedStatus) && !isOtherPermission) {
            return;
          }
          this.resetLoanPermissions(isOtherPermission);
          const response = await apiService.get('/company/companies/filter-role-permissions/', {
            role: isOtherPermission ? this.otherPermissionRole : this.selectedRole,
            status: !isOtherPermission ? this.selectedStatus : null,
            is_other_permissions: isOtherPermission,
          });
          if (response.status === 200) {
            const data = response.data?.permissions;
            if (data) {
              if (isOtherPermission) {
                this.otherPermission = {
                  ...this.otherPermission,
                  ...data,
                };
              } else {
                this.loanPermissions = {
                  ...this.loanPermissions,
                  ...data,
                };
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
</script>

<style scoped>
  .permissions-grid th,
  .permissions-grid td {
    text-align: left;
    padding: 0.75rem;
  }
</style>
