<template>
  <Card>
    <div class="overflow-x-auto border-b pb-15 mb-15">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div>
          <SelectInput label="Service Provider" :options="serviceProviderOptions" :value="serviceProvider" v-model="serviceProvider" :disable-validation="true" />
          <span v-if="!serviceProvider && validationOrderCreditReport.serviceProvider" class="text-sm text-red">{{ validationOrderCreditReport.serviceProvider }}</span>
        </div>
        <div>
          <SelectInput label="Joint or Individual Report" :options="reportOptions" :value="reportOption" v-model="reportOption" :disable-validation="true" />
          <span v-if="!reportOption && validationOrderCreditReport.reportOption" class="text-sm text-red">{{ validationOrderCreditReport.reportOption }}</span>
        </div>
      </div>

      <div class="flex justify-end mt-2 gap-2">
        <Button variant="secondary" :disabled="!writePermission" @click="handleOrderCreditReport"> Order Credit Report </Button>
        <Button variant="primary" :disabled="!writePermission" @click="handleCreditReportPdfDownload"> Credit Report PDF </Button>
      </div>
    </div>
    <PageHeader title="Credit Report" />
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 border-b pb-15 my-15">
      <div class="flex items-center">
        <span class="mr-2 font-semibold">Opened By:</span><span>{{ formData.openedBy }}</span>
      </div>
      <div class="flex items-center">
        <span class="mr-2 font-semibold">Opened On:</span><span>{{ formData.openedOn }}</span>
      </div>
      <div class="flex items-center">
        <span class="mr-2 font-semibold">Provider:</span><span>{{ formData.provider }}</span>
      </div>
      <div class="flex items-center">
        <span class="mr-2 font-semibold">Experian:</span><span>{{ formData.experian }}</span>
      </div>
      <div class="flex items-center">
        <span class="mr-2 font-semibold">Equifax:</span><span>{{ formData.equifax }}</span>
      </div>
      <div class="flex items-center">
        <span class="mr-2 font-semibold">Transunion:</span><span>{{ formData.transunion }}</span>
      </div>
    </div>

    <PageHeader title="Account Information" />
    <EditableDataTable class="mt-15" :headers="headers" :data="tableData" @onDataUpdate="handleDataUpdate" />
    <div class="flex flex-col items-end p-4">
      <div class="text-sm font-bold">Monthly Payment: {{ totalMonthlyPayment }}</div>
      <div class="text-sm font-bold">Monthly Payment (actual): {{ totalActualMonthlyPayment }}</div>
      <div class="text-sm font-bold">Unpaid Balance: {{ totalUnpaidBalance }}</div>
    </div>
  </Card>
  <Dialog title="Choose Borrowers" :isOpen="isOpenChooseBorrowerDialog" @confirm="handleConfirmChooseBorrowersDialog" @close="handleCloseChooseBorrowerDialog">
    <div v-if="borrowers.length === 0">
      <p class="text-center text-gray-500">No borrowers found.</p>
    </div>
    <div v-else class="">
      <div v-for="borrower in borrowers" :key="borrower.id" class="mb-2 cursor-pointer" @click.prevent="handleChangeSelectedBorrower(borrower.id)">
        <label class="flex items-center p-3 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors">
          <font-awesome-icon
            :icon="selectedBorrowers.includes(borrower.id) ? ['fas', 'fa-check-square'] : ['fas', 'fa-square']"
            class="h-5 w-5 transition duration-150 ease-in-out"
          />
          <span class="ml-3 text-gray-700"> {{ borrower.firstName }} {{ borrower.lastName }} </span>
        </label>
      </div>
      <div class="flex justify-end gap-2">
        <Button variant="primary" :disabled="selectedBorrowers.length < 2 || !writePermission" @click="handleConfirmChooseBorrowersDialog"> Confirm </Button>
        <Button variant="secondary" @click="handleCloseChooseBorrowerDialog"> Cancel </Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import EditableDataTable from '@/components/EditableDataTable/index.vue';
  import { BORROWER_TYPES } from '@/constants';
  import { formatDate, formatMoney, formatNumberWithCommas } from '@/utils';

  const initialData = {
    openedBy: null,
    openedOn: null,
    provider: null,
    equifax: null,
    experian: null,
    transunion: null,
  };

  const accountTypeOptions = [
    { label: 'Mortgage', value: 'Mortgage' },
    { label: 'Installment', value: 'Installment' },
    { label: 'Revolving', value: 'Revolving' },
    { label: 'Collection', value: 'Collection' },
    { label: 'Charge Off', value: 'Charge Off' },
  ];

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Credit',
    components: {
      Card,
      SelectInput,
      Button,
      PageHeader,
      Dialog,
      EditableDataTable,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      borrowers: {
        type: Array,
        default: () => [],
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {
        formatDate,
        formatNumberWithCommas,
        BORROWER_TYPES,
      };
    },
    data() {
      return {
        isDraft: false,
        formData: { ...initialData },
        serviceProviderOptions: [{ label: 'CIC Credit', value: 'CIC Credit' }],
        validationOrderCreditReport: {
          serviceProvider: '',
          reportOption: '',
        },
        serviceProvider: 'CIC Credit',
        reportOption: 'individual',
        headers: [
          { key: 'creditorName', label: 'Account Name' },
          { key: 'accountType', label: 'Account Type', type: 'select', options: accountTypeOptions },
          { key: 'statusType', label: 'Account Status' },
          { key: 'monthlyPaymentWithMoneyFormat', label: 'Monthly Payment' },
          { key: 'actualMonthlyPayment', label: 'Actual Monthly Payment', type: 'money' },
          { key: 'unpaidBalanceWithMoneyFormat', label: 'Unpaid Balance' },
          // { key: 'inclusionStatus', label: 'Include ?', type: 'boolean' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        isOpenChooseBorrowerDialog: false,
        selectedBorrowers: [],
        jointPDF: false,
      };
    },
    async created() {
      await this.fetchProperty();
      await this.fetchCreditReportDetail();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCreditReportDetail() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/credit-report/credit-reports/report_detail/`, {
            borrower_id: this.borrowerId,
          });
          this.handleReportData(response.data);
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.error ?? error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      validateOrderCreditReport() {
        this.validationOrderCreditReport = {
          serviceProvider: !this.serviceProvider ? 'This field is required.' : '',
          reportOption: !this.reportOption ? 'This field is required.' : '',
        };
        return !this.validationOrderCreditReport.serviceProvider && !this.validationOrderCreditReport.reportOption;
      },
      async fetchProperty() {
        this.setLoading(true);
        const loanId = this.$route.params.id;
        try {
          const response = await apiService.get(`/loan/properties/`, {
            loan: loanId,
          });
          if (response.data?.length > 0) this.propertyData = response.data[0];
          else this.propertyData = {};
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.error ?? error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      resetOrderCreditReportValidation() {
        this.validationOrderCreditReport = {
          serviceProvider: '',
          reportOption: '',
        };
      },
      getPayload(borrower) {
        const dob = borrower.dob.toString();
        const addressLineText =
          borrower.isNBS && !borrower.isEligible
            ? `${borrower.nbsAddressLine1}`
            : `${this.propertyData.streetNumber} ${this.propertyData.streetName} ${this.propertyData.addressSuffix}`;
        const cityName = borrower.isNBS && !borrower.isEligible ? borrower.nbsCity : this.propertyData.city;
        const stateCode = borrower.isNBS && !borrower.isEligible ? borrower.nbsState : this.propertyData.state;
        const postalCode = borrower.isNBS && !borrower.isEligible ? borrower.nbsZip : this.propertyData.zipCode;
        const ssn = borrower.ssn.replace(/\D/g, '');
        const payload = {
          DataVersionIdentifier: '201703',
          FirstName: borrower.firstName,
          LastName: borrower.lastName,
          MiddleName: borrower.middleName.length ? borrower.middleName : '',
          SuffixName: 'null',
          AddressLineText: addressLineText,
          CityName: cityName,
          CountryCode: 'US',
          PostalCode: postalCode,
          StateCode: stateCode,
          BorrowerResidencyType: 'Current',
          TaxpayerIdentifierType: 'SocialSecurityNumber',
          TaxpayerIdentifierValue: ssn,
          borrower_id: borrower.id,
          DOB: dob,
        };

        return payload;
      },
      handleReportData(reportData) {
        this.formData = {
          ...reportData.credit_report,
          openedOn: formatDate(reportData.credit_report.openedOn),
        };
        this.tableData = Object.keys(reportData.liabilities).map((key) => {
          const liability = reportData.liabilities[key];
          return {
            ...liability,
            monthlyPaymentWithMoneyFormat: formatMoney(liability.monthlyPaymentAmount),
            actualMonthlyPayment: liability.userMonthlyPaymentAmount,
            unpaidBalanceWithMoneyFormat: formatMoney(liability.unpaidBalanceAmount),
            actions: ['edit'],
          };
        });
      },
      async handleOrderCreditReport() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }

        // Check validation and reset validation message
        if (!this.validateOrderCreditReport()) return;
        this.resetOrderCreditReportValidation();

        // Reset table data
        this.tableData = [];

        if (this.reportOption === 'individual') {
          const payload = this.getPayload(this.borrowers.find((borrower) => borrower.id === this.borrowerId));

          this.setLoading(true);
          try {
            const response = await this.retryAPITrigger('post', '/credit-report/credit-reports/', payload);
            if (response.success) this.handleReportData(response.response.data);
            else
              this.$root.showSnackbar(
                `${response.error.response?.data?.error ?? response.error.response?.data?.detail ?? response.error.message ?? 'Something went wrong.'}`,
                'error'
              );
          } catch (error) {
            this.$root.showSnackbar(`${error.response?.data?.error ?? error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        } else {
          this.isOpenChooseBorrowerDialog = true;
        }
      },
      async handleCreditReportPdfDownload() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.validateOrderCreditReport()) return;
        this.resetOrderCreditReportValidation();

        if (this.reportOption === 'individual') {
          const payload = this.getPayload(this.borrowers.find((borrower) => borrower.id === this.borrowerId));

          this.setLoading(true);
          try {
            const response = await this.retryAPITrigger('post', '/credit-report/credit-reports/individual_pdf/', payload);
            if (response.success) await this.handleDownloadPDF(response.response.data.file);
            else
              this.$root.showSnackbar(
                `${response.error.response?.data?.error ?? response.error.response?.data?.detail ?? response.error.message ?? 'Something went wrong.'}`,
                'error'
              );
          } catch (error) {
            this.$root.showSnackbar(`${error.response?.data?.error ?? error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        } else {
          this.jointPDF = true;
          this.isOpenChooseBorrowerDialog = true;
        }
      },
      handleChangeSelectedBorrower(borrowerId) {
        if (this.selectedBorrowers.includes(borrowerId)) {
          this.selectedBorrowers = this.selectedBorrowers.filter((id) => id !== borrowerId);
        } else if (this.selectedBorrowers.length < 2) {
          this.selectedBorrowers.push(borrowerId);
        }
      },
      async handleConfirmChooseBorrowersDialog() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }

        // Get payload for each selected borrower
        const payload = this.selectedBorrowers.map((borrowerId) => {
          const borrower = this.borrowers.find((borrower) => borrower.id === borrowerId);
          return this.getPayload(borrower);
        });

        this.setLoading(true);
        try {
          if (!this.jointPDF) {
            const response = await this.retryAPITrigger('post', '/credit-report/credit-reports/joint_report/', payload);
            if (response.success) {
              this.formData = {
                ...response.response.data.credit_report,
                openedOn: formatDate(response.response.data.credit_report.openedOn),
              };
              this.handleReportData(response.response.data);
            } else
              this.$root.showSnackbar(
                `${response.error.response?.data?.error ?? response.error.response?.data?.detail ?? response.error.message ?? 'Something went wrong.'}`,
                'error'
              );
          } else {
            const response = await this.retryAPITrigger('post', '/credit-report/credit-reports/joint_pdf/', payload);
            if (response.success) await this.handleDownloadPDF(response.response.data.file);
            else
              this.$root.showSnackbar(
                `${response.error.response?.data?.error ?? response.error.response?.data?.detail ?? response.error.message ?? 'Something went wrong.'}`,
                'error'
              );
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.error ?? error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
          this.handleCloseChooseBorrowerDialog();
        }
      },
      handleCloseChooseBorrowerDialog() {
        this.selectedBorrowers = [];
        this.jointPDF = false;
        this.isOpenChooseBorrowerDialog = false;
      },
      async handleDataUpdate(data) {
        this.setLoading(true);
        try {
          await apiService.patch(`/credit-report/liabilities/${data.id}/`, {
            userMonthlyPaymentAmount: Number(data.actualMonthlyPayment),
          });

          this.$root.showSnackbar('Account information has been updated successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.error ?? error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleDownloadPDF(link) {
        try {
          const url = link;
          const responseFile = await fetch(url);
          const blob = await responseFile.blob();
          const objectUrl = window.URL.createObjectURL(blob);
          const linkElement = document.createElement('a');
          linkElement.href = objectUrl;
          linkElement.download = url.split('/').pop();
          linkElement.click();
          window.URL.revokeObjectURL(objectUrl);
        } catch (error) {
          this.$root.showSnackbar('Failed to download credit report PDF', 'error');
        }
      },
      // Retry API trigger for 5 seconds if the first attempt fails
      async retryAPITrigger(method, endpoint, payload) {
        let attempts = 0;
        const maxAttempts = 4;
        while (attempts < maxAttempts) {
          try {
            const response = await apiService[method](endpoint, payload);
            return { success: true, response };
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              return { success: false, error };
            }
            await new Promise((resolve) => setTimeout(resolve, 5000));
          }
        }
      },
    },
    watch: {
      borrowerId() {
        // Remove the saved data from local storage when the borrower ID changes
        this.formData = { ...initialData };
        this.tableData = [];
        this.fetchCreditReportDetail();
      },
    },
    computed: {
      reportOptions() {
        return [
          { label: 'Individual', value: 'individual' },
          ...(this.borrowers.length > 1 ? [{ label: 'Joint', value: 'joint' }] : []), // Only show joint option if there are multiple borrowers
        ];
      },
      totalMonthlyPayment() {
        return formatMoney(this.tableData.reduce((total, account) => (account.inclusionStatus ? total + Number(account.monthlyPayment) : total), 0));
      },
      totalActualMonthlyPayment() {
        return formatMoney(this.tableData.reduce((total, account) => (account.inclusionStatus ? total + Number(account.actualMonthlyPayment) : total), 0));
      },
      totalUnpaidBalance() {
        return formatMoney(this.tableData.reduce((total, account) => (account.inclusionStatus ? total + Number(account.unpaidBalance) : total), 0));
      },
    },
  };
</script>
