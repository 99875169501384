<template>
  <div class="relative inline-block text-left" ref="userDropdown">
    <!-- Avatar -->
    <button @click="toggleDropdown" type="button" class="flex items-center">
      <p class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-primary text-white focus:outline-none">
        {{ avatarLetter }}
      </p>
      <div class="flex items-center px-3.5 py-1.5 text-sm bg-[rgba(16,72,98,0.1)] rounded-full ml-[-12px]">
        <div class="flex items-center">
          <div class="pr-2">
            <div>{{ userInfo?.email || '' }}</div>
            <div v-if="isImpersonated()" class="text-xs text-amber-500">(Impersonation Mode)</div>
          </div>
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0326 1.15749C10.9629 1.08719 10.8799 1.03139 10.7885 0.993318C10.6971 0.955242 10.5991 0.935638 10.5001 0.935638C10.4011 0.935638 10.3031 0.955242 10.2117 0.993318C10.1203 1.03139 10.0373 1.08719 9.96761 1.15749L6.53261 4.59249C6.46288 4.66278 6.37993 4.71858 6.28854 4.75666C6.19714 4.79473 6.09912 4.81434 6.00011 4.81434C5.9011 4.81434 5.80307 4.79473 5.71167 4.75666C5.62028 4.71858 5.53733 4.66278 5.46761 4.59249L2.03261 1.15749C1.96288 1.08719 1.87993 1.03139 1.78854 0.993318C1.69714 0.955242 1.59912 0.935638 1.50011 0.935638C1.4011 0.935638 1.30307 0.955242 1.21167 0.993318C1.12028 1.03139 1.03733 1.08719 0.967606 1.15749C0.827918 1.29801 0.749512 1.4881 0.749512 1.68624C0.749512 1.88438 0.827918 2.07447 0.967606 2.21499L4.41011 5.65749C4.83198 6.07884 5.40385 6.31551 6.00011 6.31551C6.59636 6.31551 7.16823 6.07884 7.59011 5.65749L11.0326 2.21499C11.1723 2.07447 11.2507 1.88438 11.2507 1.68624C11.2507 1.4881 11.1723 1.29801 11.0326 1.15749V1.15749Z"
              fill="#282828"
            />
          </svg>
        </div>
      </div>
    </button>

    <!-- Dropdown -->
    <div
      v-if="isOpen"
      class="origin-top-right py-2 absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-avatar-dropdown"
    >
      <a
        v-if="isImpersonated()"
        @click.prevent="deactivateImpersonation"
        class="px-4 py-2 flex item-center justify-between cursor-pointer hover:bg-slate-100"
        active-class="active"
      >
        <p class="text-sm text-gray-700 leading-none">De-Impersonation</p>
        <font-awesome-icon icon="fa fa-ban" />
      </a>

      <a @click.prevent="goProfile" class="px-4 py-2 flex item-center justify-between cursor-pointer hover:bg-slate-100" active-class="active">
        <p class="text-sm text-gray-700 leading-none">My Profile</p>
        <font-awesome-icon icon="user" />
      </a>

      <a v-if="isSuperUser()" @click.prevent="goAdmin" class="px-4 py-2 flex item-center justify-between cursor-pointer hover:bg-slate-100" active-class="active">
        <p class="text-sm text-gray-700 leading-none">Admin</p>
        <font-awesome-icon :icon="['fas', 'user-shield']" />
      </a>

      <a @click.prevent="logout" class="px-4 py-2 flex item-center justify-between cursor-pointer hover:bg-slate-100">
        <p class="text-sm text-gray-700 leading-none">Logout</p>
        <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" />
      </a>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import apiService from '@/api/apiService';
  import { isSuperUser } from '@/utils';

  export default {
    name: 'UserDropDown',
    setup() {
      return {
        isSuperUser,
      };
    },
    data() {
      return {
        isOpen: false,
      };
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {
      ...mapGetters(['getUserInfo']),
      userInfo() {
        return this.getUserInfo; // Use the Vuex getter to get the user email
      },
      avatarLetter() {
        return this.userInfo ? this.userInfo.email?.charAt(0).toUpperCase() : 'U';
      },
    },
    methods: {
      ...mapActions(['setLoading', 'setUserInfo', 'clearUserInfo']),
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      isImpersonated() {
        const secret = localStorage.getItem('secret');
        return secret?.length > 0;
      },
      handleClickOutside(event) {
        if (this.$refs.userDropdown && !this.$refs.userDropdown.contains(event.target)) {
          this.isOpen = false;
        }
      },
      async deactivateImpersonation() {
        const secret = localStorage.getItem('secret');
        this.setLoading(true);
        try {
          const response = await apiService.post(`/company/users/deactivate-impersonation/`, {
            secret: secret,
          });
          const userInfo = response.data.user;
          localStorage.setItem('auth_token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          localStorage.removeItem('secret');
          this.setUserInfo({
            name: userInfo.firstName + ' ' + userInfo.lastName,
            email: userInfo.email,
            hierarchy: userInfo.hierarchy,
            roles: userInfo.roles,
          });
          this.$root.showSnackbar(`${response.data.message}`, 'success');
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.error : error.message}`, 'error');
        } finally {
          this.$router.push({ name: 'Login' });
          this.setLoading(false);
          this.toggleDropdown();
        }
      },
      goProfile() {
        this.$router.push({ name: 'Profile' });
        this.toggleDropdown();
      },
      goAdmin() {
        this.$router.push({ name: 'AdminRedirect' });
        this.toggleDropdown();
      },
      async logout() {
        const token = localStorage.getItem('auth_token');
        this.setLoading(true);
        try {
          await apiService.revokeToken(token);

          // Remove all local storage items
          localStorage.removeItem('auth_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('secret');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('creditReportData');

          this.clearUserInfo(); // Clear the user email from Vuex store
          this.$router.push({ name: 'Login' });
        } catch (error) {
          // Handle the error as needed, e.g., show a notification to the user
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
  };
</script>
