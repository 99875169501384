<template>
  <Dialog :title="'Add New Appraisal'" :isOpen="isOpen" @confirm="handleConfirm" @close="handleClose">
    <div>
      <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
          <RadioInput
            v-if="existingAppraisalsCount > 0"
            label="Will be used for calculation?"
            id="useAppraisalForCalculationsForModal"
            :options="radioInputOptions"
            v-model="formData.useAppraisalForCalculations"
            :value="formData.useAppraisalForCalculations"
          />
          <IntegerInput
            label="Number of Units"
            id="numberOfUnits"
            ref="numberOfUnits"
            :disable-validation="true"
            v-model="formData.numberOfUnits"
            :value="formData.numberOfUnits"
          />
        </div>
      </div>
      <!-- Value -->
      <div class="mt-4">
        <h3 class="pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Value</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <MoneyInput
              label="Appraised Value *"
              id="appraisedValue"
              ref="appraisedValue"
              :disable-validation="true"
              v-model="formData.appraisedValue"
              :value="formData.appraisedValue"
            />
            <span v-if="!formData.appraisedValue && validationFormErrors.appraisedValue" class="text-sm text-red">
              {{ validationFormErrors.appraisedValue }}
            </span>
          </div>
          <div>
            <FloatInput
              label="Appraisal Less Land"
              id="appraisalLessLand"
              ref="appraisalLessLand"
              :disable-validation="true"
              v-model="formData.appraisalLessLand"
              :value="formData.appraisalLessLand"
            />
          </div>
          <div>
            <MoneyInput
              label="Appraisal Cost Now"
              id="appraisalCostNow"
              ref="appraisalCostNow"
              :disable-validation="true"
              v-model="formData.appraisalCostNew"
              :value="formData.appraisalCostNew"
            />
          </div>
        </div>
      </div>
      <!-- Land -->
      <div>
        <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Land</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <FloatInput
              label="Property (sqft)"
              id="propertySquareFootage"
              ref="propertySquareFootage"
              :disable-validation="true"
              v-model="formData.propertySquareFootage"
              :value="formData.propertySquareFootage"
            />
          </div>
          <div>
            <FloatInput
              label="Property Acreage"
              id="propertyAcreage"
              ref="propertyAcreage"
              :disable-validation="true"
              v-model="formData.propertyAcreage"
              :value="formData.propertyAcreage"
            />
          </div>
        </div>
      </div>
      <!-- Appraiser Details -->
      <div>
        <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Appraiser Details</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <InputField label="First Name" id="firstName" ref="firstName" :disable-validation="true" v-model="formData.firstName" :value="formData.firstName" />
          </div>
          <div>
            <InputField label="Middle Name" id="middleName" ref="middleName" :disable-validation="true" v-model="formData.middleName" :value="formData.middleName" />
          </div>
          <div>
            <InputField label="Family Name" id="familyName" ref="familyName" :disable-validation="true" v-model="formData.familyName" :value="formData.familyName" />
          </div>
          <div>
            <InputField label="Address Line 1" id="addressLine1" ref="addressLine1" :disable-validation="true" v-model="formData.addressLine1" :value="formData.addressLine1" />
          </div>
          <div>
            <InputField label="Address Line 2" id="addressLine2" ref="addressLine2" :disable-validation="true" v-model="formData.addressLine2" :value="formData.addressLine2" />
          </div>
          <div>
            <InputField label="City" id="city" ref="city" :disable-validation="true" v-model="formData.city" :value="formData.city" />
          </div>
          <div>
            <InputField label="State" id="state" ref="state" :disable-validation="true" v-model="formData.state" :value="formData.state" />
          </div>
          <div>
            <InputField label="Zip" id="zip" ref="zip" :disable-validation="true" v-model="formData.zip" :value="formData.zip" />
          </div>
          <div>
            <PhoneNumberInputField
              label="Phone Number"
              id="phoneNumber"
              ref="phoneNumber"
              :disable-validation="true"
              v-model="formData.phoneNumber"
              :value="formData.phoneNumber"
            />
          </div>
          <div>
            <InputField label="Email Address" id="emailAddress" ref="emailAddress" :disable-validation="true" v-model="formData.email" :value="formData.email" />
          </div>
          <div>
            <InputField label="License Number" id="licenseNumber" ref="licenseNumber" :disable-validation="true" v-model="formData.license" :value="formData.license" />
          </div>
        </div>
      </div>
      <!-- Appraisal Analysis -->
      <div>
        <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Appraisal Analysis</h3>
        <div>
          <div class="mt-4">
            <RadioInput
              label="Does the appraisal report represent a consistent and fair analysis of the property?"
              id="representsFairAnalysis"
              v-model="formData.representsFairAnalysis"
              :value="formData.representsFairAnalysis"
              :options="radioInputOptions"
            />
            <div class="mt-4">
              <TextareaInput
                label="Explain"
                id="representsFairAnalysisExplanation"
                ref="representsFairAnalysisExplanation"
                v-model="formData.representsFairAnalysisExplanation"
                :value="formData.representsFairAnalysisExplanation"
              />
            </div>
          </div>
          <div class="mt-4">
            <div>
              <RadioInput
                label="Are the comparables acceptable?"
                id="comparablesAcceptable"
                v-model="formData.comparablesAcceptable"
                :value="formData.comparablesAcceptable"
                :options="radioInputOptions"
              />
            </div>
            <div class="mt-4">
              <TextareaInput
                label="Explain"
                id="comparablesAcceptableExplanation"
                ref="comparablesAcceptableExplanation"
                v-model="formData.comparablesAcceptableExplanation"
                :value="formData.comparablesAcceptableExplanation"
              />
            </div>
          </div>
          <div class="mt-4">
            <RadioInput
              label="Are the adjustments acceptable both as to the items adjusted and the amount collected to each item adjusted?"
              id="adjustmentsAcceptable"
              v-model="formData.adjustmentsAcceptable"
              :value="formData.adjustmentsAcceptable"
              :options="radioInputOptions"
            />
            <div class="mt-4">
              <TextareaInput
                label="Explain"
                id="adjustmentsAcceptableExplanation"
                ref="adjustmentsAcceptableExplanation"
                v-model="formData.adjustmentsAcceptableExplanation"
                :value="formData.adjustmentsAcceptableExplanation"
              />
            </div>
          </div>
          <div class="mt-4">
            <div>
              <RadioInput
                label="Is the value acceptable for FHA/HUD purposes?"
                id="valueAcceptableForFhaHudPurposes"
                v-model="formData.valueAcceptableForFhaHudPurposes"
                :value="formData.valueAcceptableForFhaHudPurposes"
                :options="radioInputOptions"
              />
            </div>
            <div class="mt-4">
              <TextareaInput
                label="Explain"
                id="valueAcceptableForFhaHudPurposesExplanation"
                ref="valueAcceptableForFhaHudPurposesExplanation"
                v-model="formData.valueAcceptableForFhaHudPurposesExplanation"
                :value="formData.valueAcceptableForFhaHudPurposesExplanation"
              />
            </div>
          </div>
          <div class="mt-4">
            <div>
              <RadioInput
                label="If not, should it be corrected?"
                id="ifNotShouldItBeCorrected"
                v-model="formData.ifNotShouldItBeCorrected"
                :value="formData.ifNotShouldItBeCorrected"
                :options="radioInputOptions"
              />
            </div>
            <div class="mt-4">
              <TextareaInput
                label="Explain"
                id="ifNotShouldItBeCorrectedExplanation"
                ref="ifNotShouldItBeCorrectedExplanation"
                v-model="formData.ifNotShouldItBeCorrectedExplanation"
                :value="formData.ifNotShouldItBeCorrectedExplanation"
              />
            </div>
          </div>
          <div class="mt-4">
            <div>
              <IntegerInput
                label="Value for FHA/HUD purposes"
                id="valueForFhaHudPurposes"
                ref="valueForFhaHudPurposes"
                v-model="formData.valueForFhaHudPurposes"
                :value="formData.valueForFhaHudPurposes"
              />
            </div>
            <div class="mt-4">
              <TextareaInput
                label="Explain"
                id="valueForFhaHudPurposesExplanation"
                v-model="formData.valueForFhaHudPurposesExplanation"
                :value="formData.valueForFhaHudPurposesExplanation"
              />
            </div>
            <div class="mt-4">
              <TextareaInput label="Repair Conditions" id="repairConditions" ref="repairConditions" v-model="formData.repairConditions" :value="formData.repairConditions" />
              <TextareaInput label="Other Comments" id="otherComments" ref="otherComments" v-model="formData.otherComments" :value="formData.otherComments" />
            </div>
          </div>
        </div>
      </div>
      <!-- Improvements -->
      <div>
        <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Improvements</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <IntegerInput label="Month Built" id="monthBuilt" ref="monthBuilt" :disable-validation="true" v-model="formData.monthBuilt" :value="formData.monthBuilt" />
          </div>
          <div>
            <IntegerInput label="Year Built" id="yearBuilt" ref="yearBuilt" :disable-validation="true" v-model="formData.yearBuilt" :value="formData.yearBuilt" />
          </div>
          <div>
            <IntegerInput label="Structure Age" id="structureAge" ref="structureAge" :disable-validation="true" v-model="formData.structureAge" :value="formData.structureAge" />
          </div>
          <div>
            <IntegerInput
              label="Remaining Economic Life"
              id="remainingEconomicLife"
              ref="remainingEconomicLife"
              :disable-validation="true"
              v-model="formData.remainingEconomicLife"
              :value="formData.remainingEconomicLife"
            />
          </div>
          <div>
            <InputField label="Dwelling Type" id="dwellingType" ref="dwellingType" :disable-validation="true" v-model="formData.dwellingType" :value="formData.dwellingType" />
          </div>
          <div>
            <IntegerInput label="Living Units" id="livingUnits" ref="livingUnits" :disable-validation="true" v-model="formData.livingUnits" :value="formData.livingUnits" />
          </div>
          <div>
            <IntegerInput label="Bedrooms" id="bedrooms" ref="bedrooms" :disable-validation="true" v-model="formData.bedrooms" :value="formData.bedrooms" />
          </div>
          <div>
            <IntegerInput label="Bathrooms" id="bathrooms" ref="bathrooms" :disable-validation="true" v-model="formData.bathrooms" :value="formData.bathrooms" />
          </div>
          <div>
            <IntegerInput label="Total Rooms" id="totalRooms" ref="totalRooms" :disable-validation="true" v-model="formData.totalRooms" :value="formData.totalRooms" />
          </div>
          <div>
            <FloatInput
              label="Living Area (sqft) *"
              id="livingAreaSquareFootage"
              ref="livingAreaSquareFootage"
              :disable-validation="true"
              v-model="formData.livingAreaSquareFootage"
              :value="formData.livingAreaSquareFootage"
            />
            <span v-if="!formData.livingAreaSquareFootage && validationFormErrors.livingAreaSquareFootage" class="text-sm text-red">
              {{ validationFormErrors.livingAreaSquareFootage }}
            </span>
          </div>
          <div>
            <InputField
              label="Parking Facility"
              id="parkingFacility"
              ref="parkingFacility"
              :disable-validation="true"
              v-model="formData.parkingFacility"
              :value="formData.parkingFacility"
            />
          </div>
          <div>
            <InputField
              label="Foundation Type"
              id="foundationType"
              ref="foundationType"
              :disable-validation="true"
              v-model="formData.foundationType"
              :value="formData.foundationType"
            />
          </div>
          <div>
            <InputField
              label="Building Structure"
              id="buildingStructure"
              ref="buildingStructure"
              :disable-validation="true"
              v-model="formData.buildingStructure"
              :value="formData.buildingStructure"
            />
          </div>
          <div>
            <RadioInput label="Air Conditioning" id="airConditioning" :options="radioInputOptions" v-model="formData.airConditioning" :value="formData.airConditioning" />
          </div>
        </div>
      </div>
      <!-- FHA Appraisal Logging -->
      <div>
        <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">FHA Appraisal Logging</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <InputField
              label="Flood Zone Code"
              id="floodZoneCode"
              ref="floodZoneCode"
              :disable-validation="true"
              v-model="formData.floodZoneCode"
              :value="formData.floodZoneCode"
            />
          </div>
          <div>
            <InputField
              label="Neighborhood Type"
              id="neighborhoodType"
              ref="neighborhoodType"
              :disable-validation="true"
              v-model="formData.neighborhoodType"
              :value="formData.neighborhoodType"
            />
          </div>
          <div>
            <FloatInput
              label="Average Dwelling Price"
              id="averageDwellingPrice"
              ref="averageDwellingPrice"
              :disable-validation="true"
              v-model="formData.averageDwellingPrice"
              :value="formData.averageDwellingPrice"
            />
          </div>
          <div>
            <FloatInput
              label="Percent Single Family"
              id="percentSingleFamily"
              ref="percentSingleFamily"
              :disable-validation="true"
              v-model="formData.percentSingleFamily"
              :value="formData.percentSingleFamily"
            />
          </div>
          <div>
            <FloatInput
              label="Percent 2-4 Unit"
              id="percent2To4Unit"
              ref="percent2To4Unit"
              :disable-validation="true"
              v-model="formData.percent2To4Unit"
              :value="formData.percent2To4Unit"
            />
          </div>
          <div>
            <FloatInput
              label="Percent Multifamily"
              id="percentMultiFamily"
              ref="percentMultiFamily"
              :disable-validation="true"
              v-model="formData.percentMultiFamily"
              :value="formData.percentMultiFamily"
            />
          </div>
          <div>
            <FloatInput
              label="Percent Commercial"
              id="percentCommercial"
              ref="percentCommercial"
              :disable-validation="true"
              v-model="formData.percentCommercial"
              :value="formData.percentCommercial"
            />
          </div>
          <div></div>
          <div>
            <RadioInput
              label="Presidential declared area?"
              id="presidentialDeclaredArea"
              :options="radioInputOptions"
              v-model="formData.presidentialDeclaredArea"
              :value="formData.presidentialDeclaredArea"
            />
          </div>
          <div>
            <RadioInput label="Damaged?" id="damaged" :options="radioInputOptions" v-model="formData.damaged" :value="formData.damaged" />
          </div>
          <div>
            <RadioInput
              label="Danger Threshold Meet?"
              id="dangerThresholdMet"
              :options="radioInputOptions"
              v-model="formData.dangerThresholdMet"
              :value="formData.dangerThresholdMet"
            />
          </div>
          <div>
            <RadioInput
              label="Declining Value Market?"
              id="decliningMarketValue"
              :options="radioInputOptions"
              v-model="formData.decliningMarketValue"
              :value="formData.decliningMarketValue"
            />
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div class="flex justify-end gap-4 p-5 border-t">
        <Button variant="primary" style="width: fit-content" @click="handleConfirm"> Add Appraisal </Button>
        <Button variant="secondary" style="width: fit-content" @click="handleClose"> Close </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
  // import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  // import Card from '@/components/Card/index.vue';
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import FloatInput from '@/components/FormInput/FloatInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import { initialFormData } from '../../const';
  import { RADIO_INPUT_OPTIONS } from '@/constants';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AddAppraisalDialog',
    components: {
      TextareaInput,
      InputField,
      MoneyInput,
      FloatInput,
      IntegerInput,
      RadioInput,
      PhoneNumberInputField,
      Dialog,
      Button,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      onAdded: {
        type: Function,
        required: true,
      },
      onClose: {
        type: Function,
        required: true,
      },
      propertyId: {
        type: String,
        required: true,
      },
      existingAppraisalsCount: {
        type: Number,
        required: true,
      },
      data: {
        type: Object,
        default: () => ({ ...initialFormData }),
      },
    },
    data() {
      return {
        formData: { ...this.data },
        radioInputOptions: RADIO_INPUT_OPTIONS,
        validationFormErrors: {
          livingAreaSquareFootage: '',
          appraisedValue: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      // Validate form data: Living Area Square Footage is required.
      validateForm() {
        this.validationFormErrors = {
          livingAreaSquareFootage: !this.formData.livingAreaSquareFootage ? 'This field is required.' : '',
          appraisedValue: !this.formData.appraisedValue ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.livingAreaSquareFootage.length && !this.validationFormErrors.appraisedValue.length;
      },
      async handleConfirm() {
        if (!this.propertyId) return;
        if (!this.validateForm()) return;
        this.setLoading(true);
        if (!this.existingAppraisalsCount) this.formData.useAppraisalForCalculations = true;
        try {
          await apiService.post(`/loan/appraisals/`, {
            property: this.propertyId,
            ...this.formData,
          });
          this.$root.showSnackbar(`Appraisal information has been updated successfully!`, 'success');
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.onAdded();
          this.setLoading(false);
        }
      },
      handleClose() {
        this.onClose();
      },
      resetForm() {
        this.formData = { ...initialFormData };
        this.validationFormErrors = {
          livingAreaSquareFootage: '',
          appraisedValue: '',
        };
      },
    },
    watch: {
      isOpen: {
        handler() {
          this.resetForm();
        },
        deep: true,
        immediate: true,
      },
      data: {
        handler(newData) {
          this.formData = { ...newData };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
