<template>
  <img src="../../../../assets/image/reporting/loan.svg" class="w-9 h-9 p-1 rounded bg-[#EBF5FF]" />
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LoanIcon',
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
</script>

<style scoped></style>
