<template>
  <div class="autocomplete-field input-box">
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">
        {{ label }}
      </label>

      <!-- Tooltip Icon with tooltip text on hover -->
      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>
    <div class="input-wrapper input-container">
      <input
        :id="id"
        :type="type"
        :value="mode == 'select' ? textValue : internalValue"
        :maxlength="maxLength"
        :class="{ invalid: !isValid && !disableValidation }"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="handleInput"
        @focus="showSuggestions = true"
        @blur="handleBlur"
      />
      <button v-if="internalValue || textValue != ''" @click="clearInput" type="button" class="clear-button">x</button>
    </div>
    <ul v-if="showSuggestions && filteredItems.length > 0" class="suggestions-list">
      <li v-for="(item, index) in filteredItems" :key="index" @mousedown.prevent="selectItem(item)" class="suggestion-item">
        {{ mode === 'select' ? item.label : item }}
      </li>
    </ul>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  import Tooltip from '@/components/Tooltip';

  export default {
    name: 'AutoCompleteField',
    components: {
      Tooltip,
    },
    props: {
      label: String,
      tooltip: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      id: String,
      value: [String, Number],
      disableValidation: Boolean,
      disabled: {
        type: Boolean,
        default: false,
      },
      maxLength: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
      mode: {
        type: String,
        default: 'input', // 'input' or 'select'
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        textValue: typeof this.value === 'string' ? this.value : '',
        internalValue: this.value || '',
        isValid: true,
        isDirty: false,
        showSuggestions: false,
      };
    },
    computed: {
      filteredItems() {
        if (!this.textValue) return this.items;
        return this.items.filter((item) =>
          this.mode === 'select' ? item.label.toLowerCase().includes(this.textValue.toLowerCase()) : item.toLowerCase().includes(this.textValue.toLowerCase())
        );
      },
      // eslint-disable-next-line vue/return-in-computed-property
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
      },
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Validation rules
        this.isValid = this.internalValue !== '' || this.textValue !== '';
      },
      handleInput(event) {
        this.textValue = event.target.value;
        if (this.mode === 'select') {
          if (this.textValue === '') this.$emit('update:modelValue', '');
        } else {
          this.internalValue = event.target.value;
          this.$emit('update:modelValue', this.internalValue);
        }
        this.validateInput();
        this.showSuggestions = true; // Show suggestions as user types
      },
      handleBlur() {
        // Delay to allow selection of an item before hiding suggestions
        setTimeout(() => {
          this.showSuggestions = false;
        }, 100);
      },
      clearInput() {
        this.internalValue = '';
        this.textValue = '';
        this.$emit('update:modelValue', '');
      },
      selectItem(item) {
        this.internalValue = this.mode === 'select' ? item.value : item;
        this.textValue = this.mode === 'select' ? item.label : item;
        this.$emit('update:modelValue', this.internalValue);
        this.showSuggestions = false;
        this.validateInput();
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
        if (this.textValue === '' && this.mode == 'select') {
          this.textValue = this.items.find((item) => item.value === newValue)?.label || '';
        }
      },
    },
  };
</script>

<style>
  .autocomplete-field {
    position: relative;
  }

  .suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: absolute;
    background-color: white;
    width: 100%;
    max-height: 100px;
    overflow-y: auto;
    z-index: 1000;
  }

  .suggestion-item {
    padding: 2px 4px;
    cursor: pointer;
  }

  .suggestion-item:hover {
    background-color: #f0f0f0;
  }

  .invalid {
    border-color: red;
  }

  .input-container {
    position: relative;
  }

  .clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #999;
  }

  .clear-button:hover {
    color: #333;
  }
</style>
