<template>
  <div class="block lg:flex items-center lg:justify-between mb-3 lg:mb-7 lg:mt-3">
    <div>
      <PageHeader :title="`Welcome Back,  ${userInfo?.name} !`" />
      <p class="text-[#8C9097] mt-[2px] text-sm">Track your sales activity, leads and deals here.</p>
    </div>
    <SearchInput @submit-search="handleSearch" />
  </div>
  <div class="my-15 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
    <WelcomeCard />
    <ReportsCard />
  </div>
  <div class="my-15 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
    <LoansClosedAnalytics />
    <DealStatus />
    <RecentTransactions />
  </div>

  <Card title="My Loans">
    <template v-slot:action>
      <div class="flex items-center">
        <!-- <input v-model="searchQuery" class="search-input flex-grow" placeholder="Search ..." /> -->
      </div>
    </template>
    <ApiDataTable :headers="headers" apiUrl="/loan/dashboard/my-loans/" :sortable="true" @action="handleAction" :columnFilter="true" />
  </Card>
</template>

<script>
  import PageHeader from './../../components/PageHeader/index.vue';
  import ApiDataTable from '@/components/ApiDataTable/index.vue';
  import SearchInput from './../../components/FormInput/SearchInput/input.vue';
  import Card from './../../components/Card/index.vue';
  import WelcomeCard from './components/WelcomeCard/index.vue';
  import ReportsCard from './components/ReportsCard/index.vue';
  import RecentTransactions from './components/RecentTransactions/index.vue';
  import DealStatus from './components/DealStatus/index.vue';
  import LoansClosedAnalytics from './components/LoansClosedAnalytics/index.vue';
  import { mapGetters } from 'vuex';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Dashboard',
    components: {
      PageHeader,
      ApiDataTable,
      Card,
      SearchInput,
      WelcomeCard,
      ReportsCard,
      DealStatus,
      RecentTransactions,
      LoansClosedAnalytics,
    },
    data() {
      return {
        headers: [
          { key: 'name', label: 'Name', type: 'link', filterType: 'text' },
          { key: 'LoanNumber', label: 'Loan Number', type: 'link', filterType: 'text' },
          { key: 'loanProductName', label: 'Loan Product Name', filterType: 'text' },
          { key: 'dateLastAccessed', label: 'Date Last Accessed', filterType: 'text' },
          {
            key: 'status',
            label: 'Status',
            filterType: 'multiselect',
            options: [
              'Adversed',
              'Withdrawn',
              'Prospect',
              'Origination',
              'Processing',
              'Underwriting',
              'Submitted to Underwriting',
              'Clear to Close',
              'Closing',
              'Funding',
              'Post Closing',
              'PrePurchaseQC',
              'PrePurchase',
              'Sold',
              'Insured',
            ],
          },
        ],
        searchQuery: '',
        tableData: [],
        isHistoricDialogOpen: false,
      };
    },
    computed: {
      ...mapGetters(['getUserInfo']),
      userInfo() {
        return this.getUserInfo;
      },
    },
    methods: {
      closeDialog() {
        this.isHistoricDialogOpen = false;
      },
      async handleAction(action, item) {
        if (action === 'view') {
          this.$router.push({ name: 'Loan', params: { id: item.LoanNumber } });
        }
      },
    },
  };
</script>
<style scoped>
  @import 'vue-multiselect/dist/vue-multiselect.min.css';
</style>
