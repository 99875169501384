<template>
  <Card>
    <div class="border rounded-lg mb-2">
      <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
    </div>
    <div class="border rounded-lg">
      <div class="box">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-2 border-b border-gray p-15">
          <div>
            <SelectInput
              label="Asset Type *"
              :options="typeOptions"
              :value="formData.assetType"
              v-model.trim="formData.assetType"
              :disable-validation="true"
              tooltip="Specifies the type of asset, such as savings, investment accounts, or real estate, owned by the borrower."
            />
          </div>
          <div>
            <InputField
              label="Description *"
              :value="formData.description"
              :max-length="160"
              v-model.trim="formData.description"
              :disable-validation="true"
              tooltip="A brief description of the asset, helping clarify its nature and purpose."
            />
          </div>
          <div>
            <MoneyInput
              label="Amount (> 0)"
              :value="formData.amount"
              v-model.trim="formData.amount"
              :disable-validation="true"
              tooltip="The total value of the asset, which contributes to the borrower’s financial standing."
            />
          </div>
          <div>
            <InputField label="Account #" :value="formData.accountNumber" v-model.trim="formData.accountNumber" :disable-validation="true" />
          </div>
          <div>
            <InputField label="Institution Address" :value="formData.institutionAddress" v-model.trim="formData.institutionAddress" :disable-validation="true" />
          </div>
          <div class="text-center flex flex-col">
            <label class="font-semibold text-[#212121]">Discount Rate (%)</label>
            <p class="text-[#212121] flex justify-center items-center h-full">
              {{ discountRates[formData.assetType] }}
            </p>
          </div>
          <div>
            <MoneyInput
              label="Discounted Value (> 0)"
              :value="formData.discountedValue"
              v-model.trim="formData.discountedValue"
              :disable-validation="true"
              tooltip="The reduced value of the asset after applying any necessary discounts, used in financial calculations. Per the Financial Assessment guidelines, assets that may be subject to taxation upon withdrawal are discounted."
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-end">
      <Button variant="primary" class="mr-15" @click="handleAddAssetType" :disabled="!isValidForm || !writePermission"> Add Assets for Dissipation </Button>
    </div>
    <!-- Edit Dialog Component -->
    <AssetDialog :is-open="isDialogOpen" :edit-item="editItem" :type-options="typeOptions" :discount-rates="discountRates" @close="closeDialog" @confirm="handleEditAssetType" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import AssetDialog from './components/AssetDialog/index.vue';
  import { formatMoney } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Assets',
    components: {
      Card,
      DataTable,
      InputField,
      SelectInput,
      MoneyInput,
      Button,
      AssetDialog,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {
        formatMoney,
      };
    },
    data() {
      return {
        isValidForm: false,
        tableHeaders: [
          { key: 'assetType', label: 'Asset Type' },
          { key: 'description', label: 'Description' },
          { key: 'amountWithCommas', label: 'Amount' },
          { key: 'discountedValueWithCommas', label: 'Discounted Value' },
          { key: 'accountNumber', label: 'Account #' },
          { key: 'institutionAddress', label: 'Institution Address' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        typeOptions: [
          { label: 'Checking', value: 'Checking' },
          { label: 'Savings', value: 'Savings' },
          { label: 'Certificate of Deposit', value: 'Certificate of Deposit' },
          { label: 'Roth IRA', value: 'Roth IRA' },
          {
            label: 'Assets Subject to Federal Taxes',
            value: 'Assets Subject to Federal Taxes',
          },
        ],
        formData: {
          assetType: 'Checking',
          amount: 0,
          description: '',
          discountedValue: 0,
          accountNumber: '',
          institutionAddress: '',
        },
        discountRates: {
          Checking: 0,
          Savings: 0,
          'Certificate of Deposit': 0,
          'Roth IRA': 0,
          'Assets Subject to Federal Taxes': 15,
        },
        isDialogOpen: false,
        editItem: null,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfValidForm() {
        this.isValidForm = this.formData.description.length > 0 && this.formData.discountedValue > 0;
      },
      resetFormData() {
        this.formData = {
          assetType: 'Checking',
          amount: 0,
          description: '',
          discountedValue: 0,
          accountNumber: '',
          institutionAddress: '',
        };
      },
      async fetchAssetTypes() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/assets/by-borrower/${this.borrowerId}/`);
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            amountWithCommas: formatMoney(item.amount),
            discountedValueWithCommas: formatMoney(item.discountedValue),
            accountNumber: item.accountNumber,
            institutionAddress: item.institutionAddress,
            actions: ['edit', 'delete'],
            ...item,
          }));
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openDialog(item = null) {
        this.editItem = item;
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.editItem = null;
        this.isDialogOpen = false;
      },
      async handleAddAssetType() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/assets/`, {
            borrower: this.borrowerId,
            ...this.formData,
          });
          this.$root.showSnackbar(`Asset Type has been added for the borrower successfully!`, 'success');
          this.resetFormData();
          await this.fetchAssetTypes();
        } catch (error) {
          this.$root.showSnackbar(`Failed to create asset type: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleEditAssetType(data) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId || !this.editItem) return;
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/assets/${this.editItem.id}/`, {
            borrower: this.borrowerId,
            ...data,
          });
          this.$root.showSnackbar(`Asset Type has been updated for the borrower successfully!`, 'success');
          this.closeDialog();
          await this.fetchAssetTypes();
        } catch (error) {
          this.$root.showSnackbar(`Failed to update asset type: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (action === 'edit') {
          this.openDialog(item);
        } else if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/assets/${item.id}/`);
            this.$root.showSnackbar('Asset type has been deleted successfully', 'success');
            await this.fetchAssetTypes();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete asset type: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchAssetTypes();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfValidForm();
        },
        deep: true,
        immediate: true,
      },
      'formData.assetType': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[newValue]) / 100;
          if (this.formData.amount !== this.formData.discountedValue / discountFactor) {
            this.formData.discountedValue = discountFactor * this.formData.amount;
          }
        },
        immediate: true,
      },
      'formData.amount': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[this.formData.assetType]) / 100;
          if (newValue !== this.formData.discountedValue / discountFactor) {
            this.formData.discountedValue = discountFactor * newValue;
          }
        },
        immediate: true,
      },
      'formData.discountedValue': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[this.formData.assetType]) / 100;
          if (newValue !== this.formData.amount * discountFactor) {
            this.formData.amount = newValue / discountFactor;
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
