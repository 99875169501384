<template>
  <Card title="Property Charge Details">
    <div>
      <h3 class="pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Real Estate Taxes</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-4">
        <div>
          <MoneyInput label="Annual Amount" v-model="formData.realEstateTaxAmount" :value="formData.realEstateTaxAmount" :disable-validation="true" />
          <span v-if="!formData.realEstateTaxAmount && validationFormErrors.realEstateTaxAmount" class="text-sm text-red">
            {{ validationFormErrors.realEstateTaxAmount }}
          </span>
        </div>
        <div>
          <RadioInput
            label="Account is current?"
            id="realEstateTaxCurrent"
            :options="radioInputOptions"
            :value="formData.realEstateTaxCurrent"
            v-model="formData.realEstateTaxCurrent"
          />
        </div>
        <div>
          <RadioInput
            label="Delinquency in last 24 months?"
            id="realEstateTaxDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.realEstateTaxDelinquencyLast24Months"
            v-model="formData.realEstateTaxDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput
            label="Tax Exempt or Waived"
            id="realEstateTaxExemptOrWaived"
            :options="radioInputOptions"
            :value="formData.realEstateTaxExemptOrWaived"
            v-model="formData.realEstateTaxExemptOrWaived"
          />
        </div>
        <div>
          <RadioInput
            label="Tax Deferred"
            id="realEstateTaxDeferred"
            :options="radioInputOptions"
            :value="formData.realEstateTaxDeferred"
            v-model="formData.realEstateTaxDeferred"
          />
        </div>
        <div>
          <RadioInput
            label="Tax paid directly by borrower"
            id="realEstateTaxPaidDirectlyByBorrower"
            :options="radioInputOptions"
            :value="formData.realEstateTaxPaidDirectlyByBorrower"
            v-model="formData.realEstateTaxPaidDirectlyByBorrower"
          />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Hazard Insurance</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
        <div>
          <MoneyInput label="Annual Amount" v-model="formData.hazardInsuranceAmount" :value="formData.hazardInsuranceAmount" :disable-validation="true" />
          <span v-if="!formData.hazardInsuranceAmount && validationFormErrors.hazardInsuranceAmount" class="text-sm text-red">
            {{ validationFormErrors.hazardInsuranceAmount }}
          </span>
        </div>
        <div>
          <RadioInput
            label="Account is current?"
            id="hazardInsuranceCurrent"
            :options="radioInputOptions"
            :value="formData.hazardInsuranceCurrent"
            v-model="formData.hazardInsuranceCurrent"
          />
        </div>
        <div>
          <RadioInput
            label="Delinquency in last 24 months?"
            id="hazardInsuranceDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.hazardInsuranceDelinquencyLast24Months"
            v-model="formData.hazardInsuranceDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput
            label="In place for last 12 months?"
            id="hazardInsuranceInPlaceLast12Months"
            :options="radioInputOptions"
            :value="formData.hazardInsuranceInPlaceLast12Months"
            v-model="formData.hazardInsuranceInPlaceLast12Months"
          />
        </div>
        <div>
          <RadioInput
            label="Insurance paid directly by borrower?"
            id="hazardInsurancePaidDirectlyByBorrower"
            :options="radioInputOptions"
            :value="formData.hazardInsurancePaidDirectlyByBorrower"
            v-model="formData.hazardInsurancePaidDirectlyByBorrower"
          />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Flood Insurance</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div v-if="formData.floodInsuranceApplicable">
          <MoneyInput label="Annual Amount" v-model="formData.floodInsuranceAmount" :value="formData.floodInsuranceAmount" :disable-validation="true" />
          <span v-if="!formData.floodInsuranceAmount && validationFormErrors.floodInsuranceAmount" class="text-sm text-red">
            {{ validationFormErrors.floodInsuranceAmount }}
          </span>
        </div>
        <div v-if="formData.floodInsuranceApplicable">
          <RadioInput
            label="Account is current?"
            id="floodInsuranceCurrent"
            :options="radioInputOptions"
            :value="formData.floodInsuranceCurrent"
            v-model="formData.floodInsuranceCurrent"
          />
        </div>
        <div v-if="formData.floodInsuranceApplicable">
          <RadioInput
            label="Delinquency in last 24 months?"
            id="floodInsuranceDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.floodInsuranceDelinquencyLast24Months"
            v-model="formData.floodInsuranceDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput
            label="Applicable"
            id="floodInsuranceApplicable"
            :options="radioInputOptions"
            :value="formData.floodInsuranceApplicable"
            v-model="formData.floodInsuranceApplicable"
          />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Homeowner's Association Due</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div v-if="formData.hoaDueApplicable">
          <MoneyInput label="Annual Amount" v-model="formData.hoaDueAmount" :value="formData.hoaDueAmount" :disable-validation="true" />
          <span v-if="!formData.hoaDueAmount && validationFormErrors.hoaDueAmount" class="text-sm text-red">
            {{ validationFormErrors.hoaDueAmount }}
          </span>
        </div>
        <div v-if="formData.hoaDueApplicable">
          <RadioInput label="Account is current?" id="hoaDueCurrent" :options="radioInputOptions" :value="formData.hoaDueCurrent" v-model="formData.hoaDueCurrent" />
        </div>
        <div v-if="formData.hoaDueApplicable">
          <RadioInput
            label="Delinquency in last 24 months?"
            id="hoaDueDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.hoaDueDelinquencyLast24Months"
            v-model="formData.hoaDueDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput label="Applicable" id="hoaDueApplicable" :options="radioInputOptions" :value="formData.hoaDueApplicable" v-model="formData.hoaDueApplicable" />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Condo Dues</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div v-if="formData.condoDuesApplicable">
          <MoneyInput label="Annual Amount" v-model="formData.condoDuesAmount" :value="formData.condoDuesAmount" :disable-validation="true" />
          <span v-if="!formData.condoDuesAmount && validationFormErrors.condoDuesAmount" class="text-sm text-red">
            {{ validationFormErrors.condoDuesAmount }}
          </span>
        </div>
        <div v-if="formData.condoDuesApplicable">
          <RadioInput label="Account is current?" id="condoDuesCurrent" :options="radioInputOptions" :value="formData.condoDuesCurrent" v-model="formData.condoDuesCurrent" />
        </div>
        <div v-if="formData.condoDuesApplicable">
          <RadioInput
            label="Delinquency in last 24 months?"
            id="condoDuesDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.condoDuesDelinquencyLast24Months"
            v-model="formData.condoDuesDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput label="Applicable" id="condoDuesApplicable" :options="radioInputOptions" :value="formData.condoDuesApplicable" v-model="formData.condoDuesApplicable" />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">Ground Rents</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div v-if="formData.groundRentsApplicable">
          <MoneyInput label="Annual Amount" v-model="formData.groundRentsAmount" :value="formData.groundRentsAmount" :disable-validation="true" />
          <span v-if="!formData.groundRentsAmount && validationFormErrors.groundRentsAmount" class="text-sm text-red">
            {{ validationFormErrors.groundRentsAmount }}
          </span>
        </div>
        <div v-if="formData.groundRentsApplicable">
          <RadioInput label="Account is current?" id="groundRentsCurrent" :options="radioInputOptions" :value="formData.groundRentsCurrent" v-model="formData.groundRentsCurrent" />
        </div>
        <div v-if="formData.groundRentsApplicable">
          <RadioInput
            label="Delinquency in last 24 months?"
            id="groundRentsDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.groundRentsDelinquencyLast24Months"
            v-model="formData.groundRentsDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput label="Applicable" id="groundRentsApplicable" :options="radioInputOptions" :value="formData.groundRentsApplicable" v-model="formData.groundRentsApplicable" />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-5 pb-3 mb-4 text-lg font-semibold border-gray border-b leading-none">PUD Dues</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div v-if="formData.pudDuesApplicable">
          <MoneyInput label="Annual Amount" v-model="formData.pudDuesAmount" :value="formData.pudDuesAmount" :disable-validation="true" />
          <span v-if="!formData.pudDuesAmount && validationFormErrors.pudDuesAmount" class="text-sm text-red">
            {{ validationFormErrors.pudDuesAmount }}
          </span>
        </div>
        <div v-if="formData.pudDuesApplicable">
          <RadioInput label="Account is current?" id="pudDuesCurrent" :options="radioInputOptions" :value="formData.pudDuesCurrent" v-model="formData.pudDuesCurrent" />
        </div>
        <div v-if="formData.pudDuesApplicable">
          <RadioInput
            label="Delinquency in last 24 months?"
            id="pudDuesDelinquencyLast24Months"
            :options="radioInputOptions"
            :value="formData.pudDuesDelinquencyLast24Months"
            v-model="formData.pudDuesDelinquencyLast24Months"
          />
        </div>
        <div>
          <RadioInput label="Applicable" id="pudDuesApplicable" :options="radioInputOptions" :value="formData.pudDuesApplicable" v-model="formData.pudDuesApplicable" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PropertyCharge',
    components: {
      Card,
      RadioInput,
      MoneyInput,
      Button,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        localData: {
          realEstateTaxAmount: null,
          realEstateTaxCurrent: null,
          realEstateTaxDelinquencyLast24Months: null,
          realEstateTaxExemptOrWaived: null,
          realEstateTaxDeferred: null,
          realEstateTaxPaidDirectlyByBorrower: null,
          hazardInsuranceAmount: null,
          hazardInsuranceCurrent: null,
          hazardInsuranceDelinquencyLast24Months: null,
          hazardInsuranceInPlaceLast12Months: null,
          hazardInsurancePaidDirectlyByBorrower: null,
          floodInsuranceApplicable: null,
          floodInsuranceAmount: null,
          floodInsuranceCurrent: null,
          floodInsuranceDelinquencyLast24Months: null,
          hoaDueApplicable: null,
          hoaDueAmount: null,
          hoaDueCurrent: null,
          hoaDueDelinquencyLast24Months: null,
          condoDuesApplicable: null,
          condoDuesAmount: null,
          condoDuesCurrent: null,
          condoDuesDelinquencyLast24Months: null,
          groundRentsApplicable: null,
          groundRentsAmount: null,
          groundRentsCurrent: null,
          groundRentsDelinquencyLast24Months: null,
          pudDuesApplicable: null,
          pudDuesAmount: null,
          pudDuesCurrent: null,
          pudDuesDelinquencyLast24Months: null,
        },
        formData: {
          realEstateTaxAmount: null,
          realEstateTaxCurrent: null,
          realEstateTaxDelinquencyLast24Months: null,
          realEstateTaxExemptOrWaived: null,
          realEstateTaxDeferred: null,
          realEstateTaxPaidDirectlyByBorrower: null,
          hazardInsuranceAmount: null,
          hazardInsuranceCurrent: null,
          hazardInsuranceDelinquencyLast24Months: null,
          hazardInsuranceInPlaceLast12Months: null,
          hazardInsurancePaidDirectlyByBorrower: null,
          floodInsuranceApplicable: null,
          floodInsuranceAmount: null,
          floodInsuranceCurrent: null,
          floodInsuranceDelinquencyLast24Months: null,
          hoaDueApplicable: null,
          hoaDueAmount: null,
          hoaDueCurrent: null,
          hoaDueDelinquencyLast24Months: null,
          condoDuesApplicable: null,
          condoDuesAmount: null,
          condoDuesCurrent: null,
          condoDuesDelinquencyLast24Months: null,
          groundRentsApplicable: null,
          groundRentsAmount: null,
          groundRentsCurrent: null,
          groundRentsDelinquencyLast24Months: null,
          pudDuesApplicable: null,
          pudDuesAmount: null,
          pudDuesCurrent: null,
          pudDuesDelinquencyLast24Months: null,
        },
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
        validationFormErrors: {
          realEstateTaxAmount: '',
          hazardInsuranceAmount: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchPropertyCharge() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/property_charges/by-property/${this.propertyId}/`);
          this.localData = response.data;
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Validate form data: Real Estate Tax Amount and Hazard Insurance Amount are required fields for Calculation.
      validateForm() {
        this.validationFormErrors = {
          realEstateTaxAmount: !this.formData.realEstateTaxAmount ? 'This field is required.' : '',
          hazardInsuranceAmount: !this.formData.hazardInsuranceAmount ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.realEstateTaxAmount && !this.validationFormErrors.hazardInsuranceAmount;
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.propertyId) return;
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/property_charges/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/property_charges/`, {
              property: this.propertyId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Property charge information has been updated successfully!`, 'success');
          await this.fetchPropertyCharge();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
        this.validationFormErrors = {
          realEstateTaxAmount: '',
          hazardInsuranceAmount: '',
        };
      },
      resetLocalData() {
        this.localData = {
          realEstateTaxAmount: null,
          realEstateTaxCurrent: null,
          realEstateTaxDelinquencyLast24Months: null,
          realEstateTaxExemptOrWaived: null,
          realEstateTaxDeferred: null,
          realEstateTaxPaidDirectlyByBorrower: null,
          hazardInsuranceAmount: null,
          hazardInsuranceCurrent: null,
          hazardInsuranceDelinquencyLast24Months: null,
          hazardInsuranceInPlaceLast12Months: null,
          hazardInsurancePaidDirectlyByBorrower: null,
          floodInsuranceApplicable: null,
          floodInsuranceAmount: null,
          floodInsuranceCurrent: null,
          floodInsuranceDelinquencyLast24Months: null,
          hoaDueApplicable: null,
          hoaDueAmount: null,
          hoaDueCurrent: null,
          hoaDueDelinquencyLast24Months: null,
          condoDuesApplicable: null,
          condoDuesAmount: null,
          condoDuesCurrent: null,
          condoDuesDelinquencyLast24Months: null,
          groundRentsApplicable: null,
          groundRentsAmount: null,
          groundRentsCurrent: null,
          groundRentsDelinquencyLast24Months: null,
          pudDuesApplicable: null,
          pudDuesAmount: null,
          pudDuesCurrent: null,
          pudDuesDelinquencyLast24Months: null,
        };
      },
    },
    watch: {
      propertyId: {
        handler() {
          this.fetchPropertyCharge();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
