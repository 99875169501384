<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
    <div>
      <InputField label="Property Address" id="address" ref="address" :value="formData.address" v-model.trim="formData.address" :disable-validation="true" />
    </div>
    <div>
      <SelectInput label="Status" id="status" ref="status" :options="statusOptions" :value="formData.status" v-model.trim="formData.status" :disable-validation="true" />
    </div>
    <div>
      <SelectInput label="Property Type" id="type" ref="type" :options="typeOptions" :value="formData.type" v-model.trim="formData.type" :disable-validation="true" />
    </div>
    <div>
      <InputField label="Account Number" id="accountNumber" ref="accountNumber" :value="formData.accountNumber" v-model.trim="formData.accountNumber" :disable-validation="true" />
    </div>
    <div>
      <InputField label="Creditor" id="creditor" ref="creditor" :value="formData.creditor" v-model.trim="formData.creditor" :disable-validation="true" />
    </div>
    <div>
      <MoneyInput label="Market Value" id="marketValue" ref="marketValue" :value="formData.marketValue" v-model="formData.marketValue" :disable-validation="true" />
    </div>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
    <div>
      <MoneyInput
        label="Outstanding Mortgage Amount"
        id="outStandingMortgageAmount"
        ref="outStandingMortgageAmount"
        :value="formData.outStandingMortgageAmount"
        v-model="formData.outStandingMortgageAmount"
        :disable-validation="true"
      />
    </div>
    <div>
      <MoneyInput
        label="Gross Rental Income"
        id="grossRentalIncome"
        ref="grossRentalIncome"
        :value="formData.grossRentalIncome"
        v-model="formData.grossRentalIncome"
        :disable-validation="true"
      />
    </div>
    <div>
      <MoneyInput
        label="Net Rental Income"
        id="netRentalIncome"
        ref="netRentalIncome"
        :value="formData.netRentalIncome"
        v-model="formData.netRentalIncome"
        :disable-validation="true"
      />
    </div>
    <div>
      <MoneyInput
        label="Insurance, Maintenance, Taxes & Misc"
        id="insuranceMaintenanceTaxesMisc"
        ref="insuranceMaintenanceTaxesMisc"
        :value="formData.insuranceMaintenanceTaxesMisc"
        v-model="formData.insuranceMaintenanceTaxesMisc"
        :disable-validation="true"
      />
    </div>
    <div>
      <MoneyInput
        label="Mortgage Payment"
        id="mortgagePayments"
        ref="mortgagePayments"
        :value="formData.mortgagePayments"
        v-model="formData.mortgagePayments"
        :disable-validation="true"
      />
    </div>
  </div>
  <div class="flex justify-end gap-4 mt-5">
    <Button variant="primary" @click="emitConfirm" style="width: fit-content" :disabled="!isDraft || !isValidForm"> Save REO Property </Button>
    <Button variant="secondary" @click="emitClose" style="width: fit-content"> Cancel </Button>
  </div>
</template>

<script>
  import { isEqual } from 'lodash';
  import Button from '@/components/Button/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ReoPropertyForm',
    components: {
      Button,
      InputField,
      MoneyInput,
      SelectInput,
    },
    props: {
      itemData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        isValidForm: false,
        formData: {
          address: null,
          status: null,
          type: null,
          accountNumber: null,
          creditor: null,
          marketValue: null,
          outStandingMortgageAmount: null,
          grossRentalIncome: null,
          netRentalIncome: null,
          insuranceMaintenanceTaxesMisc: null,
          mortgagePayments: null,
        },
        localData: {
          address: null,
          status: null,
          type: null,
          accountNumber: null,
          creditor: null,
          marketValue: null,
          outStandingMortgageAmount: null,
          grossRentalIncome: null,
          netRentalIncome: null,
          insuranceMaintenanceTaxesMisc: null,
          mortgagePayments: null,
        },
        statusOptions: [
          { label: 'Sold', value: 'Sold' },
          { label: 'Rental', value: 'Rental' },
          { label: 'Pending', value: 'Pending' },
        ],
        typeOptions: [
          { label: '2 to 4 Units', value: '2 to 4 Units' },
          { label: 'Commercial', value: 'Commercial' },
          { label: 'Condominium', value: 'Condominium' },
          { label: 'Cooperative', value: 'Cooperative' },
          { label: 'Farm', value: 'Farm' },
          { label: 'Land', value: 'Land' },
          { label: 'Manufactured Condo', value: 'Manufactured Condo' },
          { label: 'Manufactured Home', value: 'Manufactured Home' },
          { label: 'Multi-Family 4+', value: 'Multi-Family 4+' },
          { label: 'Single Family', value: 'Single Family' },
          { label: 'Site Condominium', value: 'Site Condominium' },
          { label: 'Townhouse', value: 'Townhouse' },
        ],
      };
    },
    methods: {
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      checkIfValidForm() {
        const requiredFields = ['address', 'status', 'type', 'accountNumber', 'creditor', 'marketValue'];

        this.isValidForm = requiredFields.every((field) => this.formData[field]?.length > 0);
      },
      emitClose() {
        this.$emit('close');
      },
      emitConfirm() {
        this.$emit('confirm', this.formData, this.formData.id);
      },
    },
    watch: {
      formData: {
        handler() {
          this.checkIfDraft();
          this.checkIfValidForm();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
      itemData: {
        handler(newVal) {
          if (newVal) this.localData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
