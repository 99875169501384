<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goToUsers" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">User Details</h1>
      </div>
    </template>
    <form @submit.prevent="handleSaveUser">
      <div v-if="formData !== null">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5">
          <div v-if="formData.hierarchy !== USER_HIERARCHIES.SUPER_USER && isSuperUser()">
            <SelectInput label="Company" id="company" :options="companyOptions" v-model="formData.company" :value="formData.company" :disable-validation="true" />
          </div>
          <div>
            <InputField label="Email" id="email" v-model="formData.email" :value="formData.email" :disabled="true" />
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pb-5">
          <div>
            <InputField label="First Name *" id="firstName" v-model="formData.firstName" :value="formData.firstName" />
          </div>
          <div>
            <InputField label="Last Name *" id="lastName" v-model="formData.lastName" :value="formData.lastName" />
          </div>
          <div>
            <PhoneNumberInputField label="Phone *" id="phone" v-model="formData.phone" :value="formData.phone" />
          </div>
          <div>
            <InputField
              label="CHUMS ID *"
              id="chumsId"
              v-model="formData.chumsId"
              :value="formData.chumsId"
              v-if="formData.hierarchy == 'Company User' && formData.roles.includes('Underwriter')"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pb-5">
          <div>
            <InputField label="Address *" id="address" v-model="formData.address" :value="formData.address" />
          </div>
          <div>
            <InputField label="City *" id="city" v-model="formData.city" :value="formData.city" />
          </div>
          <div>
            <SelectInput label="State *" id="state" :options="stateOptions" v-model="formData.state" :value="formData.state" :disable-validation="true" />
          </div>
          <div>
            <IntegerInput label="Zip Code *" id="zipCode" v-model="formData.zipCode" :value="formData.zipCode" />
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-6 gap-4 pb-5">
          <div>
            <label for="exemptFromNmls" class="font-semibold text-[#212121]">Exempt from NMLS licensing</label>
            <CheckboxInput v-model="formData.exemptFromNmls" :value="formData.exemptFromNmls" />
          </div>
          <div>
            <InputField
              label="Reason for exemption *"
              id="reasonForExemption"
              v-model="formData.reasonForNmlsExemption"
              v-if="formData.exemptFromNmls"
              :value="formData.reasonForNmlsExemption"
            />
            <IntegerInput label="NMLS ID *" id="nmlsId" v-model="formData.nmlsId" v-if="!formData.exemptFromNmls" :value="formData.nmlsId" />
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-4 pb-5">
          <div>
            <label for="is_active" class="font-semibold text-[#212121]">Active</label>
            <CheckboxInput v-model="formData.is_active" :value="formData.is_active" />
          </div>
        </div>
        <div class="grid-cols-1 sm:grid-cols-6 md:grid-cols-6 gap-4 pb-5">
          <div class="form-group">
            <RadioInput label="User Hierarchy" id="hierarchy" :options="userHierarchiesOptions" v-model="formData.hierarchy" :value="formData.hierarchy" />
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-6 md:grid-cols-6 gap-4 pb-5" v-if="formData.hierarchy === 'Company User'">
          <div v-for="role in companyRoleOptions" :key="role">
            <label for="role" class="font-semibold text-[#212121]">{{ role }}</label>
            <CheckboxInput @change="onChangeCompanyRole($event, role)" :value="formData.roles.includes(role)" />
          </div>
        </div>
      </div>
      <div v-else>
        <Loading />
      </div>
      <div class="form-actions flex justify-end">
        <Button type="submit" :disabled="!isFormValid">Save</Button>
      </div>
    </form>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import Loading from '@/components/Loading/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import { COMPANY_USER_ROLE_OPTIONS, US_STATES, USER_HIERARCHIES, USER_HIERARCHY_OPTIONS } from '@/constants';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';

  export default {
    name: 'UserDetail',
    components: {
      Card,
      Button,
      InputField,
      SelectInput,
      CheckboxInput,
      Loading,
      RadioInput,
      IntegerInput,
      PhoneNumberInputField,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
        USER_HIERARCHIES,
        USER_HIERARCHY_OPTIONS,
      };
    },
    data() {
      return {
        formData: {
          id: null,
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          company: null,
          is_delete: false,
          is_active: false,
          is_staff: false,
          is_superuser: false,
          address: '',
          city: '',
          state: '',
          zipCode: '',
          chumsId: '',
          exemptFromNmls: false,
          reasonForNmlsExemption: '',
          nmlsId: 0,
        },
        isLoading: false,
        companyOptions: [],
        stateOptions: US_STATES,
        selectedCompanyRoles: [],
        userHierarchies: USER_HIERARCHIES,
        companyRoleOptions: COMPANY_USER_ROLE_OPTIONS,
      };
    },
    computed: {
      userHierarchiesOptions() {
        const updatedHierarchyOptions = USER_HIERARCHY_OPTIONS.filter((option) => option.value !== 'Super User');
        return isCompanyAdmin() ? updatedHierarchyOptions : USER_HIERARCHY_OPTIONS;
      },
      isFormValid() {
        if (!isSuperUser() && !isCompanyAdmin()) return false;
        const { email, firstName, lastName, address, city, state, zipCode, exemptFromNmls, reasonForNmlsExemption, nmlsId, chumsId, phone } = this.formData;
        if (this.selectedCompanyRoles.includes('Underwriter') && !chumsId) return false;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const areFieldsFilled =
          email &&
          firstName &&
          lastName &&
          emailRegex.test(email) &&
          address &&
          city &&
          state &&
          zipCode &&
          phone &&
          ((exemptFromNmls && reasonForNmlsExemption) || (!exemptFromNmls && nmlsId));
        return areFieldsFilled;
      },
    },
    async created() {
      if (this.isCompanyUser()) {
        this.redirectToDashboard();
        return;
      }
      const userId = this.$route.params.id;
      if (userId) {
        this.setLoading(true);
        await this.fetchCompanies();
        await this.fetchUserData(userId);
        this.setLoading(false);
      } else {
        if (!this.formData) {
          this.formData = {
            company: '',
            email: '',
            firstName: '',
            lastName: '',
            is_active: false,
            is_staff: false,
            is_superuser: false,
            is_delete: false,
            address: '',
            city: '',
            state: '',
            zipCode: '',
            chumsId: '',
            exemptFromNmls: false,
            reasonForNmlsExemption: '',
            nmlsId: 0,
          };
        }
      }
    },
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      async fetchCompanies() {
        try {
          const response = await apiService.get('/company/companies/');
          this.companyOptions = [
            { label: 'None', value: null },
            ...response.data.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ];
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        }
      },
      async fetchUserData(userId) {
        try {
          const response = await apiService.get(`/company/users/${userId}/`);
          this.formData = { ...response.data };
        } catch (error) {
          this.$root.showSnackbar(`${error.response ? error.response.data.message : error.message}`, 'error');
        }
      },
      async handleSaveUser() {
        let payload = this.formData;

        // Handle SUPER_USER hierarchy
        if (payload.hierarchy === USER_HIERARCHIES.SUPER_USER) {
          payload.company = null;
        }

        // Handle the case when the creator is company admin
        if (this.isCompanyAdmin()) {
          // Current logged-in company admin's company id
          payload.company = this.companyOptions[1]?.value ?? null;
        }

        // Handle COMPANY_USER hierarchy and role-based condition
        if (!payload.hierarchy === USER_HIERARCHIES.COMPANY_USER || !this.selectedCompanyRoles.includes('Underwriter')) {
          delete payload.chumsId;
        }

        // Handle NMLS exemption
        if (this.formData.exemptFromNmls) {
          delete payload.nmlsId;
        } else {
          delete payload.reasonForNmlsExemption;
        }

        this.setLoading(true);
        try {
          await apiService.patch(`/company/users/${this.formData.id}/`, this.formData);
          this.$root.showSnackbar('User updated successfully!', 'success');
          this.$router.push({ name: 'Users' });
        } catch (error) {
          let errorMessage = '';
          if (error.response && error.response.data) {
            const errorData = error.response.data;

            const firstKey = Object.keys(errorData)[0];
            errorMessage = Array.isArray(errorData[firstKey]) ? errorData[firstKey][0] : errorData[firstKey];
          } else {
            errorMessage = error.message;
          }

          this.$root.showSnackbar(`Error: ${errorMessage}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      onChangeCompanyRole(event, role) {
        if (event.target.checked) {
          if (!this.formData.roles.includes(role)) this.formData.roles.push(role);
        } else {
          this.formData.roles = this.formData.roles.filter((item) => item !== role);
        }
      },
      goToUsers() {
        this.$router.push({ name: 'Users' });
      },
    },
  };
</script>
