<template>
  <div class="w-full">
    <div class="w-100" v-if="!dataLoading">
      <div class="flex justify-end mb-2 gap-2">
        <button
          v-if="totalPages > 0 && csvDownload"
          @click="downloadCSV"
          class="px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Download CSV
        </button>
      </div>
      <table class="table">
        <tr>
          <th v-for="header in headers" :key="header.key" :class="sortable ? 'cursor-pointer' : ''">
            <span @click="sortTable(header.key)">
              {{ header.label }}
              <span v-if="sortKey === header.key && sortable" class="text-gray">
                {{ sortOrder === 'asc' ? '▲' : '▼' }}
              </span>
            </span>
            <div v-if="columnFilter">
              <input v-if="header.filterType === 'text'" type="text" v-model="filters[header.key]" @input="addFilter(header.key, $event.target.value)" placeholder="Filter" />
              <select v-else-if="header.filterType === 'select'" @change="addFilter(header.key, $event.target.value)">
                <option value="">All</option>
                <option v-for="option in header.options" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
              <VueMultiselect
                v-else-if="header.filterType === 'multiselect'"
                v-model="filters[header.key]"
                :options="header.options ?? [...new Set(tableData.map((row) => row.status))]"
                :searchable="true"
                :allow-empty="true"
                :hideSelected="true"
                :showLabels="false"
                :placeholder="'Filter'"
                :close-on-select="true"
                :multiple="true"
                class="max-w-96 text-sm"
                :max-height="200"
                @update:modelValue="addFilter(header.key, $event)"
              ></VueMultiselect>
            </div>
          </th>
        </tr>
        <tr>
          <td :colspan="headers.length" v-if="searching" class="item">Searching...</td>
        </tr>
        <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
          <td v-for="header in headers" :key="header.key">
            <span v-if="header.key === 'status'">
              <TagComponent :title="row[header.key]" />
            </span>
            <span v-else-if="header.type === 'link'">
              <a href="#" @click="$emit('action', 'view', row)">{{ row[header.key] }}</a>
            </span>
            <span v-else-if="header.type === 'date'">{{ new Date(row[header.key]).toLocaleDateString('en-GB') }}</span>
            <span v-else-if="header.type === 'datetime'">{{ new Date(row[header.key]).toLocaleString() }}</span>
            <span v-else-if="typeof row[header.key] === 'number'">{{ row[header.key].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            <span v-else-if="typeof row[header.key] === 'string'">{{ row[header.key] }}</span>
            <span v-else-if="typeof row[header.key] === 'boolean'">{{ row[header.key] ? 'Yes' : 'No' }}</span>
            <!-- Action Buttons -->
            <div class="flex justify-center gap-2" v-else-if="header.type === 'action'">
              <button class="icon-button bg-cyan-500" v-if="header.actions?.includes('eye')" @click="$emit('action', 'eye', row)">
                <font-awesome-icon icon="fa fa-eye" />
              </button>
              <button class="icon-button bg-primary" v-if="header.actions?.includes('edit')" @click="$emit('action', 'edit', row)">
                <font-awesome-icon icon="fas fa-pen-to-square" />
              </button>
              <button class="icon-button bg-secondary" v-if="header.actions?.includes('print')" @click="$emit('action', 'print', row)">
                <font-awesome-icon icon="print" />
              </button>
              <button class="icon-button bg-primary" v-if="header.actions?.includes('view')" @click="$emit('action', 'view', row)">
                <font-awesome-icon icon="arrow-up-right-from-square" />
              </button>
              <button class="icon-button bg-red" v-if="header.actions?.includes('delete')" @click="$emit('action', 'delete', row)">
                <font-awesome-icon icon="trash-can" />
              </button>
              <slot name="custom" v-bind:row="header"></slot>
            </div>
          </td>
        </tr>
        <tr>
          <td :colspan="headers.length" v-if="tableData.length == 0 && !dataLoading && !searching" class="item">No Data Found</td>
        </tr>
      </table>
    </div>
    <Loading :isLoading="dataLoading" />
    <div class="flex justify-center items-center gap-1 px-2 py-4 border-t">
      <button
        class="px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        v-if="page != 1 && totalPages != 0"
        style="width: fit-content"
        @click="pageChange(this.page - 1)"
      >
        Prev
      </button>
      <template
        v-for="pageNumber in [
          ...new Set([
            ...Array.from({ length: Math.min(2, totalPages) }, (_, i) => i + 1),
            ...Array.from({ length: 5 }, (_, i) => page - 2 + i).filter((p) => p > 2 && p < totalPages - 1),
            ...Array.from({ length: 2 }, (_, i) => totalPages - 1 + i).filter((p) => p > 2),
          ]),
        ]"
        :key="pageNumber"
      >
        <span v-if="totalPages > 6 && pageNumber === totalPages - 1 && page < totalPages - 4">...</span>
        <button
          :class="page == pageNumber ? 'border-blue-400' : ''"
          class="px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
          v-if="totalPages > 6"
          @click="pageChange(pageNumber)"
          :variant="pageNumber === page ? 'primary' : 'secondary'"
        >
          {{ pageNumber }}
        </button>
        <span v-if="totalPages > 6 && pageNumber === 2 && page > 5 && pageNumber + 1 < page - 1">...</span>
      </template>
      <button
        class="px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
        v-if="totalPages > page"
        @click="pageChange(this.page + 1)"
      >
        Next
      </button>
    </div>
    <div class="flex items-center justify-end mb-2 gap-2">
      <span class="text-[#8C9097]">Page Limit</span>
      <select
        v-if="totalPages > 0"
        v-model="limit"
        @change="loadTableData"
        class="w-[100px] cursor-pointer px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option v-for="limit in [25, 50, 75, 100, 250, 500]" :key="limit" :value="limit">
          {{ limit }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
  import apiService from '@/api/apiService';
  import Loading from '@/components/Loading/index.vue';
  import TagComponent from '@/components/TagComponent/index.vue';
  import VueMultiselect from 'vue-multiselect';

  export default {
    name: 'ApiDataTable',
    components: {
      TagComponent,
      Loading,
      VueMultiselect,
    },
    props: {
      headers: {
        type: Array,
        required: true,
      },
      apiUrl: {
        type: String,
        required: true,
      },
      apiUrlParams: {
        type: Object,
        default: () => ({}),
      },
      csvDownload: {
        type: Boolean,
        default: false,
      },
      sortable: {
        type: Boolean,
        required: false,
        default: false,
      },
      columnFilter: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    mounted() {
      this.loadTableData();
    },
    data() {
      return {
        filters: {},
        totalPages: 0,
        dataLoading: false,
        page: 1,
        limit: 25,
        sortOrder: 'asc',
        sortKey: '',
        searching: false,
        tableData: [],
      };
    },
    methods: {
      pageChange(page) {
        this.page = page;
        this.dataLoading = true;
        this.loadTableData();
      },
      addFilter(key, value) {
        if (value.length < 3 && key !== 'status' && value.length != 0) {
          return;
        }
        this.filters = {
          ...this.filters,
          [key]: value,
        };
        this.searching = true;
        this.page = 1;
        this.loadTableData();
      },
      sortTable(key) {
        if (this.sortKey === key) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortOrder = 'asc';
          this.sortKey = key;
        }
        this.loadTableData();
      },
      async loadTableData() {
        try {
          if (this.headers.length === 0) {
            return;
          }
          const response = await apiService.get(this.apiUrl, {
            page: this.page,
            limit: this.limit,
            ...this.apiUrlParams,
            ...Object.keys(this.filters).reduce((acc, key) => {
              acc[key] = Array.isArray(this.filters[key]) ? this.filters[key].join(',') : this.filters[key];
              return acc;
            }, {}),
            sortField: this.sortKey,
            sortOrder: this.sortOrder,
          });

          if (response && response.data) {
            this.tableData = [];
            this.totalPages = 0;
            if (response.data) {
              const data = response.data;
              if (data?.data) {
                this.totalPages = data.total_pages;
                this.tableData = data.data.map((item) => {
                  const row = {};
                  row['id'] = item['id'];
                  this.headers.forEach((header) => {
                    if (Object.prototype.hasOwnProperty.call(item, header.key)) {
                      row[header.key] = item[header.key] !== null ? item[header.key] + (header.lastSymbol || '') : item[header.key];
                    }
                  });
                  return row;
                });
              }
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.dataLoading = false;
          this.searching = false;
        }
      },
      downloadCSV() {
        const csvContent = [
          this.headers.map((header) => header.label).join(','), // Header row
          ...this.tableData.map((row) => this.headers.map((header) => row[header.key]).join(',')),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    },
  };
</script>

<style scoped>
  .item {
    padding: 20px;
    text-align: center;
  }
</style>
