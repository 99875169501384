<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goBack" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Fees</h1>
      </div>
    </template>
    <template v-slot:action>
      <Button @click="openAddDialog">Add Fee</Button>
    </template>
    <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
  </Card>
  <!-- add or edit company fee -->
  <Dialog :title="`${isOpenDialog} Fee`" :isOpen="isOpenDialog != ''" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div v-if="isSuperUser()" class="form-group">
      <SelectInput label="Company" :value="formData.company" v-model="formData.company" :options="companyOptions" />
      <span v-if="validationFormErrors.company?.length" class="text-sm text-red">
        {{ validationFormErrors.company }}
      </span>
      <div class="w-full h-px my-3 bg-gray-300"></div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div class="form-group">
        <InputField label="Name *" :maxLength="50" :value="formData.name" v-model="formData.name" :disable-validation="true" />
        <span v-if="validationFormErrors?.name?.length" class="text-sm text-red">
          {{ validationFormErrors.name }}
        </span>
      </div>
      <div class="form-group">
        <MoneyInput label="Amount" :value="formData.amount" v-model="formData.amount" :disable-validation="true" />
      </div>
      <div class="form-group">
        <InputField label="Hud Line *" :value="formData.hudLine" v-model="formData.hudLine" :disable-validation="true" />
        <span v-if="validationFormErrors?.hudLine?.length" class="text-sm text-red">
          {{ validationFormErrors.hudLine }}
        </span>
      </div>
      <div class="form-group">
        <AutoCompleteField label="Payee *" id="payee" ref="payee" :items="payees" :value="formData.payee" v-model.trim="formData.payee" :disable-validation="true" />
        <span v-if="validationFormErrors?.payee?.length" class="text-sm text-red">
          {{ validationFormErrors.payee }}
        </span>
      </div>
      <div class="form-group">
        <SelectInput label="Tolerance (%)" :value="formData.tolerance" v-model="formData.tolerance" :options="toleranceOptions" />
      </div>
      <div class="form-group">
        <SelectInput label="State *" id="state" :options="US_STATES" v-model="formData.state" :value="formData.state" />
        <span v-if="validationFormErrors?.state?.length" class="text-sm text-red">
          {{ validationFormErrors.state }}
        </span>
      </div>
      <div class="form-group">
        <CheckboxInput label="Finance Charge" :value="formData.isFinanceCharge" v-model="formData.isFinanceCharge" />
      </div>
      <div class="form-group">
        <CheckboxInput label="Exclude From Wire" :value="formData.excludeFromWire" v-model="formData.excludeFromWire" />
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>

    <div class="text-gray">
      <p class="mb-2">
        Fees defined as templates have a <strong>basic template language</strong> that is available.<br />
        For instance, if you wanted to show a fee as <code>{homeValue} * 0.02</code> to calculate 2% of the home value.
      </p>

      <p class="mb-2"><strong>More advanced options</strong> are available, like setting fees based on the state:</p>

      <div class="bg-gray-100 text-center rounded py-2 mb-2">200 if {state} == 'TX' else 100</div>

      <p class="mb-2">This sets the fee amount as <strong>$100</strong>, unless the state is <strong>TX</strong>, in which case the fee adjusts to <strong>$200</strong>.</p>

      <p>
        <a href="#" class="text-blue-500 underline"> Learn more about the miniature template language in our help center. </a>
      </p>
    </div>

    <div class="w-full h-px my-3 bg-gray-300"></div>
    <div class="flex justify-end gap-2 mt-2">
      <Button variant="primary" @click="handleConfirmDialog">Save Fee</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
  <!-- Delete Fee Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this fee?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import AutoCompleteField from '@/components/FormInput/AutoCompleteField/index.vue';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import { US_STATES } from '@/constants';

  const initialData = {
    name: '',
    amount: 0,
    hudLine: '',
    payee: '',
    tolerance: '0%',
    state: '',
    isFinanceCharge: false,
    excludeFromWire: false,
    company: null,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Fees',
    components: {
      AutoCompleteField,
      Button,
      Card,
      CheckboxInput,
      DataTable,
      Dialog,
      InputField,
      SelectInput,
      Confirmation,
      MoneyInput,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
        US_STATES,
      };
    },
    data() {
      return {
        tableHeaders: [
          { key: 'name', label: 'Name' },
          { key: 'hudLine', label: 'Hud Line' },
          { key: 'feeAmount', label: 'Amount' },
          { key: 'tolerance', label: 'Tolerance' },
          { key: 'payee', label: 'Payee' },
          { key: 'isFinanceCharge', label: 'Finance Charge' },
          { key: 'excludeFromWire', label: 'Exclude From Wire' },
          { key: 'state', label: 'State' },
          { key: 'company', label: 'Company' },
          { key: 'action', label: 'Action' },
        ],
        fees: [],
        payees: [],
        companyOptions: [],
        tableData: [],
        isOpenDialog: '',
        formData: {},
        toleranceOptions: [
          { value: '0%', label: '0%' },
          { value: '10%', label: '10%' },
          { value: 'Unlimited', label: 'Unlimited' },
        ],
        validationFormErrors: {
          name: '',
          hudLine: '',
          payee: '',
          state: '',
          company: '',
        },
        selectedFee: null,
        isDeleteConfirmationOpen: false,
      };
    },
    async created() {
      // Company User cannnot access here
      if (isCompanyUser()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchCompanies();
      await this.fetchPayees();
      await this.fetchFees();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCompanies() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/company/companies/');
          this.companyOptions = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        } catch (error) {
          this.companyOptions = [];
          this.$root.showSnackbar(`Failed to fetch company data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchPayees() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/payees/`);
          this.payees = response.data.map((el) => el.name);
        } catch (error) {
          this.payees = []; // reset table data
          // if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      async fetchFees() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/fee_templates/`);
          this.fees = response.data;
          this.updateTableData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddDialog() {
        this.formData = { ...initialData };
        this.isOpenDialog = 'Add';
      },
      openEditDialog(id) {
        const item = this.fees.find((el) => el.id == id);
        this.formData = { ...initialData, ...item };
        this.isOpenDialog = 'Edit';
      },
      validateForm() {
        this.validationFormErrors = {
          name: !this.formData.name?.length ? 'This field is required.' : '',
          hudLine: !this.formData.hudLine?.length ? 'This field is required.' : '',
          payee: !this.formData.payee?.length ? 'This field is required.' : '',
          state: !this.formData.state?.length ? 'This field is required.' : '',
          company: !this.formData?.company && isSuperUser() ? 'This field is required.' : '',
        };
        return (
          !this.validationFormErrors.name.length &&
          !this.validationFormErrors.hudLine.length &&
          !this.validationFormErrors.payee.length &&
          !this.validationFormErrors.state.length &&
          !this.validationFormErrors.company.length
        );
      },
      async handleConfirmDialog() {
        if (!this.validateForm()) return;
        if (isCompanyAdmin()) {
          delete this.formData.company;
        }
        this.setLoading(true);
        try {
          if (this.formData.id) {
            await apiService.patch(`/loan/fee_templates/${this.formData.id}/`, { ...this.formData });
          } else {
            await apiService.post(`/loan/fee_templates/`, {
              ...this.formData,
            });
          }
          this.$root.showSnackbar(`Company fee template information has been updated successfully!`, 'success');
          await this.fetchFees();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
        this.validationFormErrors = {
          name: '',
          hudLine: '',
          payee: '',
          state: '',
          company: '',
        };
      },
      updateTableData() {
        this.tableData = this.fees.map((fee) => ({
          ...fee,
          feeAmount: `$${fee.amount}`,
          company: this.companyOptions.find((company) => company.value === fee.company)?.label,
          actions: ['edit', 'delete'],
        }));
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedFee = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/fee_templates/${this.selectedFee.id}/`);
          this.$root.showSnackbar('Company fee template information has been deleted successfully', 'success');
          await this.fetchFees();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete company fee template information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      goBack() {
        this.$router.push({ name: 'Settings' });
      },
    },
    computed: {},
    watch: {
      fees: {
        handler() {
          this.updateTableData();
        },
        deep: true,
        immediate: true,
      },
      'formData.state': {
        handler(newValue) {
          if (newValue === 'TX') this.formData.amount = 200;
          else this.formData.amount = 100;
        },
      },
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }
</style>
