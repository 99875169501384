<template>
  <Card>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <InputField
          label="First Name"
          id="firstName"
          ref="firstName"
          :value="formData.firstName"
          v-model.trim="formData.firstName"
          :disable-validation="true"
          tooltip="The first name of an alternate contact for the borrower, who may be reached if the borrower is unavailable."
        />
      </div>
      <div>
        <InputField
          label="Middle Name"
          id="middleName"
          ref="middleName"
          :value="formData.middleName"
          v-model.trim="formData.middleName"
          :disable-validation="true"
          tooltip="The middle name or initial of the alternate contact, if applicable."
        />
      </div>
      <div>
        <InputField
          label="Last Name"
          id="lastName"
          ref="lastName"
          :value="formData.lastName"
          v-model.trim="formData.lastName"
          :disable-validation="true"
          tooltip="The last name of the alternate contact."
        />
      </div>
      <div>
        <PhoneNumberInputField
          label="Home Phone"
          id="homePhone"
          ref="homePhone"
          :value="formData.homePhone"
          v-model.trim="formData.homePhone"
          :disable-validation="true"
          tooltip="The primary phone number of the alternate contact."
        />
      </div>
      <div>
        <PhoneNumberInputField
          label="Mobile Phone"
          id="mobilePhone"
          ref="mobilePhone"
          :value="formData.mobilePhone"
          v-model.trim="formData.mobilePhone"
          :disable-validation="true"
          tooltip="The mobile number of the alternate contact."
        />
      </div>
      <div>
        <InputField
          label="Email Address"
          id="email"
          ref="email"
          :value="formData.email"
          v-model.trim="formData.email"
          :disable-validation="true"
          tooltip="Contact email for the alternate contact, for sending relevant loan information if needed."
        />
      </div>
      <div>
        <InputField
          label="Address Line 1"
          id="addressLine1"
          ref="addressLine1"
          :value="formData.addressLine1"
          v-model.trim="formData.addressLine1"
          :disable-validation="true"
          tooltip="The first line of the alternate contact’s address."
        />
      </div>
      <div>
        <InputField
          label="Address Line 2"
          id="addressLine2"
          ref="addressLine2"
          :value="formData.addressLine2"
          v-model.trim="formData.addressLine2"
          :disable-validation="true"
          tooltip="Additional address information for the alternate contact, if necessary."
        />
      </div>
      <div>
        <InputField
          label="Zip Code"
          id="zipCode"
          ref="zipCode"
          :value="formData.zip"
          v-model.trim="formData.zip"
          :disable-validation="true"
          tooltip="The ZIP code for the alternate contact’s address."
        />
      </div>
      <div>
        <InputField
          label="City"
          id="city"
          ref="city"
          :value="formData.city"
          v-model.trim="formData.city"
          :disable-validation="true"
          tooltip="The city of the alternate contact’s address."
        />
      </div>
      <div>
        <SelectInput
          label="State"
          id="state"
          :options="stateOptions"
          v-model="formData.state"
          :value="formData.state"
          :disable-validation="true"
          tooltip="The state of the alternate contact’s address."
        />
      </div>
      <div>
        <InputField
          label="Relationship"
          id="relationship"
          ref="relationship"
          :value="formData.relationship"
          v-model.trim="formData.relationship"
          :disable-validation="true"
          tooltip="The relationship between the borrower and the alternate contact, such as family member or legal guardian."
        />
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Relationship Details</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput
            label="Information Release Authorization"
            id="notApplicable"
            :value="formData.informationReleaseAuthorization"
            @change="updateField('informationReleaseAuthorization')"
            tooltip="Clarifies if the alternate contact is authorized to receive loan information on behalf of the borrower."
          />
        </div>
        <div>
          <CheckboxInput
            label="Alternate Contact"
            id="notApplicable"
            :value="formData.alternateContact"
            @change="updateField('alternateContact')"
            tooltip="Specifies the alternate contact’s role in relation to the borrower."
          />
        </div>
        <div>
          <CheckboxInput
            label="Power of Attorney"
            id="notApplicable"
            :value="formData.powerOfAttorney"
            @change="updateField('powerOfAttorney')"
            tooltip="Indicates if the alternate contact holds a power of attorney for the borrower."
          />
        </div>
        <div>
          <CheckboxInput
            label="Guardian"
            id="notApplicable"
            :value="formData.guardian"
            @change="updateField('guardian')"
            tooltip="Specifies if the alternate contact is a legal guardian for the borrower."
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button v-if="!isPrimaryBorrower" variant="primary" @click="handleCopyFromPrimaryBorrower" style="width: fit-content" :disabled="!writePermission">
        Copy from Primary Borrower
      </Button>
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { US_STATES } from '@/constants/index.js';
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import Button from '@/components/Button/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';

  const initialFormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    relationship: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    city: '',
    state: '',
    homePhone: '',
    mobilePhone: '',
    email: '',
    informationReleaseAuthorization: false,
    alternateContact: false,
    powerOfAttorney: false,
    guardian: false,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AlternateContact',
    components: {
      Card,
      Button,
      InputField,
      CheckboxInput,
      PhoneNumberInputField,
      SelectInput,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
      borrowers: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        formData: { ...initialFormData },
        localData: { ...initialFormData },
        stateOptions: US_STATES,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchAlternateContact() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/alternate_contacts/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleCopyFromPrimaryBorrower() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/alternate_contacts/by-borrower/${this.primaryBorrowerId}/`);
          this.formData = { ...response.data };
          delete this.formData.id;
          delete this.formData.borrower;
        } catch (error) {
          if (error.response.status === 404) {
            this.$root.showSnackbar(`Primary borrower doesn't have alternate contact information.`, 'error');
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          if (this.localData.id) await apiService.patch(`/loan/alternate_contacts/${this.localData.id}/`, { ...this.formData });
          else
            await apiService.post(`/loan/alternate_contacts/`, {
              borrower: this.borrowerId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Alternate Contact information has been updated successfully!`, 'success');
          await this.fetchAlternateContact();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      updateField(field) {
        this.formData[field] = !this.formData[field];
      },
      resetLocalData() {
        this.localData = {
          firstName: '',
          middleName: '',
          lastName: '',
          relationship: '',
          addressLine1: '',
          addressLine2: '',
          zip: '',
          city: '',
          state: '',
          homePhone: '',
          mobilePhone: '',
          email: '',
          informationReleaseAuthorization: false,
          alternateContact: false,
          powerOfAttorney: false,
          guardian: false,
        };
      },
    },
    computed: {
      isPrimaryBorrower() {
        let youngestBorrowerId = null;
        this.borrowers.forEach((borrower) => {
          if (borrower.dob && !borrower.isNBS) {
            const dob = new Date(borrower.dob);
            if (youngestBorrowerId == null || dob > new Date(this.borrowers.find((borrower) => borrower.id == youngestBorrowerId).dob)) {
              youngestBorrowerId = borrower.id;
            }
          }
        });
        return youngestBorrowerId == this.borrowerId;
      },
      primaryBorrowerId() {
        let youngestBorrowerId = null;
        this.borrowers.forEach((borrower) => {
          if (borrower.dob && !borrower.isNBS) {
            const dob = new Date(borrower.dob);
            if (youngestBorrowerId == null || dob > new Date(this.borrowers.find((borrower) => borrower.id == youngestBorrowerId).dob)) {
              youngestBorrowerId = borrower.id;
            }
          }
        });
        return youngestBorrowerId;
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchAlternateContact();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
