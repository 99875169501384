import ActiveBorrower from './components/ActiveBorrower/index.vue';
import Property from './components/Property/index.vue';
import Fees from './components/Fees/index.vue';
import CaseNumber from './components/CaseNumber/index.vue';
import FinancialAssessment from './components/FinancialAssessment/index.vue';
import Closing from './components/Closing/index.vue';
import Comparison from './components/Comparison/index.vue';
import Condition from './components/Condition/index.vue';
import Compliance from './components/Compliance/index.vue';
const initialTabOptions = [
  {
    title: 'Borrowers',
    component: ActiveBorrower,
    subOptions: [
      { title: 'Demographic Info' },
      { title: 'Income' },
      { title: 'Credit Report' },
      { title: 'Assets' },
      { title: 'Declaration' },
      { title: 'Alternate Contact' },
      { title: 'AKAs' },
    ],
    view: false,
    write: false,
  },
  {
    title: 'Property',
    component: Property,
    subOptions: [{ title: 'Title' }, { title: 'Repairs' }, { title: 'Property Charge' }, { title: 'Flood Zone' }, { title: 'Payoffs' }, { title: 'Real Estate Owned' }],
    view: false,
    write: false,
  },
  { title: 'Fees', component: Fees, view: false, write: false },
  {
    title: 'Case Number',
    component: CaseNumber,
    subOptions: [{ title: 'Schedule of Real Estate Owned' }, { title: 'Existing FHA Loans' }],
    view: false,
    write: false,
  },
  {
    title: 'Financial Assessment',
    component: FinancialAssessment,
    subOptions: [{ title: 'Income' }, { title: 'Asset Dissipation' }, { title: 'Data Collection' }, { title: 'Expenses' }, { title: 'Cash Flow' }],
    view: false,
    write: false,
  },
  { title: 'Closing', component: Closing, view: false, write: false },
  { title: 'Comparison', component: Comparison, view: false, write: false },
  { title: 'Condition', component: Condition, view: false, write: false },
  { title: 'Compliance', component: Compliance, view: false, write: false },
];

const keyData = {
  monthlyIncome: {
    tab: 'Borrowers',
    subTab: 'Income',
  },
  expenses: {
    tab: 'Borrowers',
    subTab: 'Credit Report',
  },
  residualIncome: {
    tab: 'Borrowers',
    subTab: 'Income',
  },
  familySize: {
    tab: 'Financial Assessment',
    subTab: 'Data Collection',
  },
  residualIncomeRequired: {
    tab: 'Financial Assessment',
    subTab: 'Cash Flow',
  },
  residualIncomeFullFilled: {
    tab: 'Financial Assessment',
    subTab: 'Cash Flow',
  },
  comparison: {
    tab: 'Comparison',
    subTab: '',
  },
  homeValue: {
    tab: 'Comparison',
    subTab: '',
  },
  expectedRate: {
    tab: 'Comparison',
    subTab: '',
  },
  margin: {
    tab: 'Comparison',
    subTab: '',
  },
  principalLimit: {
    tab: 'Comparison',
    subTab: '',
  },
  orginationFee: {
    tab: 'Fees',
    subTab: '',
  },
  imip: {
    tab: 'Fees',
    subTab: '',
  },
  otherClosingCost: {
    tab: 'Fees',
    subTab: '',
  },
  payOffs: {
    tab: 'Property',
    subTab: 'Payoffs',
  },
  lessa: {
    tab: 'Comparison',
    subTab: '',
  },
  cashToBorrower: {
    tab: 'Comparison',
    subTab: '',
  },
  lineOfCredit: {
    tab: 'Comparison',
    subTab: '',
  },
};

const allStatusOptions = [
  { label: 'Adversed', value: 'Adversed' },
  { label: 'Withdrawn', value: 'Withdrawn' },
  { label: 'Prospect', value: 'Prospect' },
  { label: 'Origination', value: 'Origination' },
  { label: 'Processing', value: 'Processing' },
  { label: 'Submitted to Underwriting', value: 'Submitted to Underwriting' },
  { label: 'Underwriting', value: 'Underwriting' },
  { label: 'Clear to Close', value: 'Clear to Close' },
  { label: 'Closing', value: 'Closing' },
  { label: 'Funding', value: 'Funding' },
  { label: 'Post Closing', value: 'Post Closing' },
  { label: 'PrePurchaseQC', value: 'PrePurchaseQC' },
  { label: 'PrePurchase', value: 'PrePurchase' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Insured', value: 'Insured' },
];

const statusSequence = [
  'Prospect',
  'Origination',
  'Processing',
  'Submitted to Underwriting',
  'Underwriting',
  'Clear to Close',
  'Closing',
  'Funding',
  'Post Closing',
  'PrePurchaseQC',
  'PrePurchase',
  'Sold',
  'Insured',
];

export { initialTabOptions, keyData, allStatusOptions, statusSequence };
