<template>
  <Card title="Mortgatge Call Report">
    <template v-slot:action>
      <div class="flex space-x-4 items-center">
        <select v-model="formData.quarter" class="border rounded p-2">
          <option value="">Select Period</option>
          <option value="1">Q1 (Jan 1 to March 31)</option>
          <option value="2">Q2 (April 1 to June 30)</option>
          <option value="3">Q3 (July 1 to September 30)</option>
          <option value="4">Q4 (October 1 to December 31)</option>
        </select>
        <select v-model="formData.state" class="border rounded p-2">
          <option value="">Select State</option>
          <option :key="state.value" v-for="state in stateOptions" :value="state.value">{{ state.label }}</option>
        </select>
        <select v-model="formData.year" class="border rounded p-2" placeholder="Select Year">
          <option value="">Select Year</option>
          <option v-for="year in yearOptions" :key="year" :value="year">{{ year }}</option>
        </select>
        <Button variety="primary" :disabled="!isValid" @click="getReport">View</Button>
        <Button variety="primary" :disabled="!isReportAvailable" @click="downloadReport">Download</Button>
      </div>
    </template>
    <div v-if="Object.keys(reportData).length">
      <div class="mb-7" v-for="(data, key) in reportData" :key="key">
        <h2 class="text-xl font-semibold my-4 color-[#104862]">{{ key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()) }}</h2>
        <table v-if="data.length" class="table-auto w-full">
          <thead>
            <tr>
              <th v-for="(value, header) in data[0]" :key="header">{{ header.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data" :key="row[Object.keys(row)[0]]">
              <td v-for="(value, header) in row" :key="header">{{ value }}</td>
            </tr>
          </tbody>
        </table>
        <p class="text-lg font-regular text-gray-500 py-6 text-center border" v-else>No {{ key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()) }} Data</p>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-center items-center h-full">
        <p class="text-lg font-regular text-gray-500 py-6">No Data</p>
      </div>
    </div>
  </Card>
</template>

<script>
  import Card from '@/components/Card/index.vue';
  import Button from '@/components/Button/index.vue';
  import { US_STATES } from '@/constants';
  import apiService from '@/api/apiService';
  import { mapActions } from 'vuex';

  export default {
    name: 'MortgageCallReport',
    components: {
      Card,
      Button,
    },
    data() {
      return {
        stateOptions: US_STATES,
        formData: {
          quarter: '',
          state: '',
          year: '',
        },
        isReportAvailable: false,
        reportData: {},
        yearOptions: Array.from({ length: 150 }, (_, i) => new Date().getFullYear() - i),
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async downloadReport() {
        try {
          this.setLoading(true);
          const response = await apiService.get(
            '/loan/mcr/excel/',
            {
              quarter: this.formData.quarter,
              year: this.formData.year,
              state: this.formData.state,
            },
            {
              responseType: 'blob',
            }
          );
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'mortgage-call-report.xlsx');
            document.body.appendChild(link);
            link.click();
          }
        } catch (error) {
          console.error(error);
          this.isReportAvailable = false;
          this.$root.showSnackbar('Something went wrong', 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async getReport() {
        try {
          this.setLoading(true);
          const response = await apiService.get('/loan/mcr/json/', {
            quarter: this.formData.quarter,
            year: this.formData.year,
            state: this.formData.state,
          });
          if (response.data) {
            this.isReportAvailable = true;
            this.reportData = response.data;
          }
        } catch (error) {
          this.isReportAvailable = false;
          this.$root.showSnackbar('Something went wrong', 'error');

          console.error(error);
        } finally {
          this.setLoading(false);
        }
      },
    },
    computed: {
      isValid() {
        return this.formData.quarter && this.formData.year && this.formData.state;
      },
    },
  };
</script>

<style scoped>
  .mortgage-call-report {
    padding: 20px;
  }

  .mortgage-call-report h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .standard-btn {
    overflow: visible;
  }
  .table-auto {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }

  .table-auto th,
  .table-auto td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .table-auto th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .table-auto tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .table-auto tr:hover {
    background-color: #f1f1f1;
  }

  .table-auto th,
  .table-auto td {
    padding: 11px 15px;
    font-size: 14px;
    text-align: left;
    border: 1px solid #f3f3f3;
  }

  .table-auto th {
    background-color: #fff;
    color: black;
  }
</style>
