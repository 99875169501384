<template>
  <Card title="Existing FHA Loans">
    <template v-slot:action>
      <div class="flex items-center">
        <input class="search-input mr-[15px]" v-model="searchQuery" placeholder="Search ..." />
        <div>
          <button class="standard-btn" :disabled="!writePermission" @click="() => openDialog(null)">Add Existing FHA Loan</button>
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="filteredTableData" @action="handleAction" />
  </Card>
  <Dialog :title="editItem ? 'Edit Existing FHA Loan' : 'Add Existing FHA Loan'" :isOpen="isDialogOpen" @close="closeDialog" @confirm="handleConfirm">
    <ExistingLoanForm :item-data="editItem" @confirm="handleConfirm" @close="closeDialog" />
  </Dialog>
</template>
<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import ExistingLoanForm from './components/ExistingLoanForm/index.vue';
  import { formatNumberWithCommas } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ExistingFHALoans',
    components: {
      Card,
      DataTable,
      Dialog,
      ExistingLoanForm,
    },
    props: {
      caseNumberId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    async created() {
      await this.fetchExistingFhaLoans();
    },
    setup() {
      return {
        formatNumberWithCommas,
      };
    },
    data() {
      return {
        editItem: null,
        isDialogOpen: false,
        headers: [
          { key: 'address', label: 'Full Property Address' },
          { key: 'status', label: 'Status' },
          { key: 'account', label: 'Account' },
          { key: 'creditor', label: 'Creditor' },
          { key: 'balanceWithCommas', label: 'Balance' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        searchQuery: '',
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchExistingFhaLoans() {
        if (!this.caseNumberId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/existing_fha_loans/by-case-number/${this.caseNumberId}/`);
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            balanceWithCommas: `$${formatNumberWithCommas(item.balance)}`,
            actions: ['edit', 'delete'],
            ...item,
          }));
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      openDialog(item) {
        this.editItem = item;
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.editItem = null;
        this.isDialogOpen = false;
      },
      async handleConfirm(data, id) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.caseNumberId) return;
        this.setLoading(true);
        try {
          if (id) {
            await apiService.patch(`/loan/existing_fha_loans/${id}/`, {
              ...data,
            });
          } else {
            await apiService.post(`/loan/existing_fha_loans/`, {
              caseNumber: this.caseNumberId,
              ...data,
            });
          }
          this.$root.showSnackbar(`FHA Loan information has been ${id ? 'updated' : 'created'} for the property successfully!`, 'success');
          await this.fetchExistingFhaLoans();
          this.closeDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (action === 'edit') {
          this.openDialog(item);
        } else if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/existing_fha_loans/${item.id}/`);
            this.$root.showSnackbar('FHA loan information has been deleted successfully', 'success');
            await this.fetchExistingFhaLoans();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete FHA loan information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) {
          return this.tableData;
        }
        const query = this.searchQuery.toLowerCase();
        return this.tableData.filter((item) => {
          return (
            item.address.toLowerCase().includes(query) ||
            item.status.toLowerCase().includes(query) ||
            item.account.toLowerCase().includes(query) ||
            item.creditor.toLowerCase().includes(query)
          );
        });
      },
    },
    watch: {
      caseNumberId: {
        handler(newValue, oldValue) {
          if (newValue && oldValue) {
            this.fetchExistingFhaLoans();
          }
        },
        immediate: true,
      },
    },
  };
</script>
