<template>
  <Card title="Report Engine">
    <template v-slot:action>
      <div class="flex items-center">
        <div class="flex items-center space-x-4">
          <button v-if="isSuperUser()" class="standard-btn whitespace-nowrap" @click="isOpenDialog = true">Manage Reportable Models</button>
          <button
            class="standard-btn whitespace-nowrap"
            @click="
              createScreen = !createScreen;
              isEdit = false;
              clearFields();
            "
          >
            {{ createScreen ? 'View Reports' : 'Create Report' }}
          </button>
        </div>
      </div>
    </template>

    <!-- Create Screen -->
    <div v-if="createScreen">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="flex flex-col">
          <label for="formName" class="font-semibold">Name</label>
          <input id="formName" v-model="createReports.name" class="input" />
        </div>

        <div class="flex flex-col">
          <label for="modelSelect" class="font-semibold">Model</label>
          <SelectInput
            id="modelSelect"
            :options="models"
            v-model="createReports.model_name"
            :value="createReports.model_name"
            @change="fetchFields(createReports.model_name)"
            :disable-validation="true"
          />
        </div>

        <div class="flex flex-col col-span-full" v-if="createReports.model_name">
          <label for="fieldsSelect" class="font-semibold">Fields</label>
          <VueMultiselect
            v-model="createReports.fields"
            :options="fields"
            :searchable="true"
            :allow-empty="true"
            :hide-selected="true"
            :show-labels="false"
            placeholder="Select Fields"
            :close-on-select="true"
            :multiple="true"
            class="w-full text-sm"
            :max-height="200"
          />
        </div>
        <div class="flex flex-col space-y-4" v-if="createReports.model_name">
          <label class="font-semibold">Filters</label>
          <div class="border p-4 rounded">
            <filter-group :group="createReports.filters" :fields="fields" :operands="operands" @update="updateFilters" />
          </div>
        </div>
        <div class="flex justify-end space-x-4 col-span-full" v-if="createReports.model_name">
          <Button @click="clearFields" class="bg-blue-500 text-white px-4 py-2 rounded"> Clear </Button>
          <Button :variant="isEdit ? 'secondary' : 'primary'" @click="handleCreateReport" class="text-white px-4 py-2 rounded">
            {{ isEdit ? 'Update Report' : 'Create Report' }}
          </Button>
        </div>
      </div>
    </div>

    <!-- View Screen -->
    <div v-else>
      <ApiDataTable v-if="!tableLoad" :headers="headers" apiUrl="/company/reports/paginated-reports/" :sortable="true" @action="handleAction" :column-filter="true" />
    </div>

    <Dialog title="Select Reportable Models" :isOpen="isOpenDialog != ''" @confirm="saveReportableModels" @close="isOpenDialog = false">
      <div class="flex flex-col gap-2 min-h-80">
        <label class="font-semibold">Select Models</label>
        <VueMultiselect
          v-model="selectedModels"
          :options="allModels"
          :searchable="true"
          :allow-empty="true"
          :hide-selected="true"
          :show-labels="false"
          placeholder="Select Reportable Models"
          :close-on-select="true"
          :multiple="true"
          class="w-full text-sm"
          :max-height="200"
        />
      </div>
      <!-- Divider -->
      <div class="w-full h-px my-3 bg-gray-300"></div>
      <div class="flex justify-end gap-2 mt-2">
        <Button variant="primary" @click="saveReportableModels">Save Models</Button>
        <Button variant="secondary" @click="isOpenDialog = false">Close</Button>
      </div>
    </Dialog>
  </Card>
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this report?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import ApiDataTable from '@/components/ApiDataTable/index.vue';
  import FilterGroup from './FilterGroup.vue';
  import VueMultiselect from 'vue-multiselect';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import { isSuperUser } from '@/utils';
  import Dialog from '@/components/Dialog/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';

  export default {
    name: 'ReportEngine',
    components: {
      FilterGroup,
      VueMultiselect,
      SelectInput,
      ApiDataTable,
      Card,
      Dialog,
      Button,
      Confirmation,
    },
    setup() {
      return {
        isSuperUser,
      };
    },
    data() {
      return {
        createScreen: false,
        headers: [
          { key: 'name', label: 'Name', type: 'link', filterType: 'text' },
          { key: 'model_name', label: 'Model', filterType: 'text' },
          { key: 'fields', label: 'Fields' },
          {
            key: 'created_at',
            label: 'Created At',
            filterType: 'text',
            type: 'date',
          },
          { key: 'action', label: 'Actions', actions: ['eye', 'edit', 'delete'], type: 'action' },
        ],
        createReports: {
          name: '',
          model_name: '',
          fields: [],
          filters: { operator: 'AND', conditions: [] },
        },
        models: [],
        fields: [],
        operands: ['=', '!=', '>', '<', '>=', '<='], // Replace with actual operands
        searchQuery: '',
        loading: false,
        error: null,
        tableLoad: false,
        isEdit: false,
        editId: '',
        isOpenDialog: false,
        allModels: [],
        selectedModels: [],
        deleteItemId: '',
        isDeleteConfirmationOpen: false,
      };
    },
    created() {
      this.fetchModels();
      this.fetchAllModels();
    },
    methods: {
      ...mapActions(['setLoading']),
      async handleCreateReport() {
        this.setLoading(true);
        try {
          if (!this.isEdit) await apiService.post('/company/reports/', this.createReports);
          else await apiService.put(`/company/reports/${this.editId}/`, this.createReports);
          this.createScreen = false;
          this.$root.showSnackbar(`Report ${this.isEdit ? 'updated' : 'created'} successfully`, 'success');
        } catch (error) {
          if (error.response && error.response.data) {
            const detail = error.response.data;

            const errorMessage = this.findErrorMessage(detail);
            if (errorMessage) {
              this.$root.showSnackbar(errorMessage, 'error');
            } else {
              this.$root.showSnackbar(detail, 'error');
            }
          } else {
            console.error(error);
          }
        } finally {
          this.setLoading(false);
        }
      },
      findErrorMessage(obj) {
        for (const key in obj) {
          if (Array.isArray(obj[key]) && typeof obj[key][0] === 'string') {
            return `${key}: ${obj[key][0]}`;
          } else if (typeof obj[key] === 'object') {
            const message = this.findErrorMessage(obj[key]); // Recursively search
            if (message) return `${key}: ${message}`;
          }
        }
        return null;
      },
      updateFilters(newFilters) {
        this.createReports.filters = newFilters;
      },
      clearFields() {
        this.createReports = {
          name: '',
          model_name: '',
          fields: [],
          filters: { operator: 'AND', conditions: [] },
        };
        this.fields = [];
      },
      async fetchModels() {
        try {
          this.setLoading(true);
          const response = await apiService.get('/company/reports/models/');
          this.models = response.data.models.map((model) => ({
            value: model,
            label: model,
          }));
        } catch (error) {
          this.error = 'Failed to load models. Please try again later.';
          console.error(error);
        } finally {
          this.setLoading(false);
        }
      },
      async fetchFields(model) {
        try {
          this.createReports = {
            ...this.createReports,
            fields: [],
            filters: { operator: 'AND', conditions: [] },
          };
          this.setLoading(true);
          const response = await apiService.get(`/company/reports/models/${model}/fields/`);
          this.fields = response.data;
        } catch (error) {
          this.error = 'Failed to load fields. Please try again later.';
          console.error(error);
        } finally {
          this.setLoading(false);
        }
      },
      async fetchAllModels() {
        if (!this.isSuperUser()) return;
        try {
          this.setLoading(true);
          const response = await apiService.get('/company/reports/all-models/');
          this.allModels = response.data.models;
          this.selectedReportableModels();
        } catch (error) {
          this.$root.showSnackbar('Failed to load models. Please try again later.', 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async selectedReportableModels() {
        try {
          this.setLoading(true);
          const response = await apiService.get('/company/reports/selected-reportable-models/');
          this.selectedModels = response.data.models;
        } catch (error) {
          this.$root.showSnackbar('Failed to load models. Please try again later.', 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async saveReportableModels() {
        try {
          if (!this.isSuperUser()) this.setLoading(true);
          await apiService.post('/company/reports/save-reportable-models/', { models: this.selectedModels });
          this.isOpenDialog = false;
          this.fetchModels();
          this.$root.showSnackbar('Models saved successfully', 'success');
        } catch (error) {
          if (error.response && error.response.data) {
            const detail = error.response.data;

            const errorMessage = this.findErrorMessage(detail);
            if (errorMessage) {
              this.$root.showSnackbar(errorMessage, 'error');
            } else {
              this.$root.showSnackbar(detail, 'error');
            }
          } else {
            console.error(error);
          }
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          try {
            this.setLoading(true);
            this.editId = item.id;
            const response = await apiService.get(`/company/reports/${item.id}/show/`);
            if (response.data) {
              this.isEdit = true;
              await this.fetchFields(response.data.model_name);
              this.createReports = response.data;
              this.createScreen = true;
            }
          } catch (error) {
            this.error = 'Failed to load fields. Please try again later.';
            this.$root.showSnackbar(`Error: ${error.response.data.message || error.message}`, 'error');
          } finally {
            this.setLoading(false);
          }
        } else if (action === 'eye') {
          this.$router.push(`/reporting/report/${item.id}/${item.name}/`);
        } else if (action === 'delete') {
          this.deleteItemId = item.id;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        try {
          this.tableLoad = true;
          this.setLoading(true);
          const response = await apiService.delete(`/company/reports/${this.deleteItemId}/`);
          if (response.status == 204) {
            this.$root.showSnackbar('Report deleted successfully', 'success');
          }
        } catch (error) {
          if (error.response && error.response.data) {
            const detail = error.response.data;

            const errorMessage = this.findErrorMessage(detail);
            if (errorMessage) {
              this.$root.showSnackbar(errorMessage, 'error');
            } else {
              this.$root.showSnackbar(detail, 'error');
            }
          } else {
            console.error(error);
          }
        } finally {
          this.setLoading(false);
          this.tableLoad = false;
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      goBack() {
        this.$router.back();
      },
    },
  };
</script>

<style scoped>
  .search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
</style>
