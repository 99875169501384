<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-2">
    <Card class="w-full mr-1">
      <template v-slot:title>
        <div class="flex items-center">
          <h1 class="text-lg font-semibold leading-none">Personal Information</h1>
        </div>
      </template>
      <form @submit.prevent="handleSavePersonalInfo">
        <div v-if="user !== null">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2">
            <div>
              <InputField label="Email" id="email" v-model="user.email" :value="user.email" :disabled="true" />
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2">
            <div>
              <InputField label="First Name" id="firstName" v-model="user.firstName" :value="user.firstName" />
            </div>
            <div>
              <InputField label="Last Name" id="lastName" v-model="user.lastName" :value="user.lastName" />
            </div>
            <div>
              <PhoneNumberInputField label="Phone" id="phone" v-model="user.phone" :value="user.phone" />
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2">
            <div>
              <InputField label="Address" id="address" v-model="user.address" :value="user.address" />
            </div>
            <div>
              <InputField label="City" id="city" v-model="user.city" :value="user.city" />
            </div>
            <div>
              <SelectInput label="State" id="state" :options="stateOptions" v-model="user.state" :value="user.state" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="Zip Code" id="zipCode" v-model="user.zipCode" :value="user.zipCode" />
            </div>
          </div>

          <!-- Divider -->
          <div class="w-full h-px my-3 bg-gray-300"></div>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mb-2">
            <div>
              <InputField label="Current Password" id="password" v-model="password" :value="password" :disable-validation="true" type="password" />
            </div>
            <div></div>
            <div>
              <InputField label="New Password" id="newPassword" v-model="newPassword" :value="newPassword" type="password" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Password Confirmation" id="confirmPassword" v-model="confirmPassword" :value="confirmPassword" type="password" :disable-validation="true" />
            </div>
          </div>

          <div class="flex justify-end mb-2">
            <span v-if="isNewPwdMismatch" class="text-sm text-red"> New passwords do not match. </span>
          </div>
        </div>
        <div class="form-actions flex justify-end">
          <Button type="submit" :disabled="!isInfoFormValid">Save</Button>
        </div>
      </form>
    </Card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import { US_STATES } from '@/constants';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Profile',
    components: {
      Card,
      InputField,
      SelectInput,
      IntegerInput,
      Button,
      PhoneNumberInputField,
    },
    data() {
      return {
        user: {},
        stateOptions: US_STATES,
        password: '',
        newPassword: '',
        confirmPassword: '',
      };
    },
    computed: {
      ...mapGetters(['getUserInfo']),
      userInfo() {
        return this.getUserInfo; // Use the Vuex getter to get the user email
      },
      isInfoFormValid() {
        const { firstName, lastName, address, city, state, zipCode, phone } = this.user;
        const areFieldsFilled = firstName && lastName && address && city && state && zipCode && phone;

        if (this.newPassword || this.confirmPassword) {
          if (!this.password || this.newPassword != this.confirmPassword) return false;
        }
        if (this.password && (!this.newPassword || !this.confirmPassword)) return false;

        return areFieldsFilled;
      },
      isNewPwdMismatch() {
        return this.newPassword !== this.confirmPassword;
      },
    },
    async created() {
      this.user = this.userInfo;
      await this.fetchProfile();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchProfile() {
        try {
          const response = await apiService.get('/company/users/me/');
          this.user = { ...response.data };
        } catch (error) {
          this.$root.showSnackbar(`Error: ${error.response ? error.response.data.message : error.message}`, 'error');
        }
      },
      async handleSavePersonalInfo() {
        this.setLoading(true);
        try {
          const payload = {
            ...this.user,
            password: this.password,
            newPassword: this.newPassword,
          };
          await apiService.patch('/company/users/me/', payload);
          this.$root.showSnackbar('User updated successfully!', 'success');
          this.$router.push({ name: 'Profile' });
        } catch (error) {
          console.log(error);
          let errorMessage = '';
          if (error.response && error.response.data) {
            const errorData = error.response.data;

            const firstKey = Object.keys(errorData)[0];
            errorMessage = Array.isArray(errorData[firstKey]) ? errorData[firstKey][0] : errorData[firstKey];
          } else {
            errorMessage = error.message;
          }

          this.$root.showSnackbar(`Error: ${errorMessage}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
  };
</script>

<style></style>
