<template>
  <Dialog :title="title" :isOpen="isOpen">
    <div v-if="activatedProductType?.length > 0" class="content-container">
      <div class="grid grid-cols-2 gap-4">
        <div class="form-group">
          <MoneyInput
            label="Requested Cash At Closing"
            id="requested-cash-at-closing"
            v-model="requestedCashAtClosing"
            :value="requestedCashAtClosing"
            :disabled="maximizeAtClosing"
          />
        </div>
        <div class="form-group">
          <label for="maximize-requested-cash-at-closing" class="font-semibold text-[#212121] mb-1">Maximize</label>
          <input type="checkbox" id="maximize-requested-cash-at-closing" v-model="maximizeAtClosing" :value="maximizeAtClosing" class="custom-checkbox" />
        </div>
      </div>
      <div v-if="activatedProductType === 'HECM'" class="grid grid-cols-2 gap-4">
        <div class="form-group">
          <MoneyInput label="Line of Credit" id="line-of-credit" v-model="lineOfCredit" :value="lineOfCredit" :disabled="maximizeLineOfCredit" />
        </div>
        <div class="form-group">
          <label for="maximize-line-of-credit" class="font-semibold text-[#212121] mb-1">Maximize</label>
          <input type="checkbox" id="maximize-line-of-credit" v-model="maximizeLineOfCredit" :value="maximizeLineOfCredit" class="custom-checkbox" />
        </div>
        <div class="form-group">
          <MoneyInput label="Monthly Payment" id="monthly-payment" v-model="monthlyPayment" :value="monthlyPayment" />
        </div>
        <div class="form-group">
          <div class="flex items-center gap-2">
            <SelectInput :options="termOrTenureOptions" label="Term/Tenure" id="term-or-tenure" v-model="termOrTenure" :value="termOrTenure" />
            <div v-if="termOrTenure === 'term'">
              <IntegerInput label="# of Months Term/Tenure" id="term" v-model="term" :value="term" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center text-red font-semibold">No activated product found.</p>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>
    <!-- Dialog Buttons -->
    <div class="flex justify-end gap-4">
      <Button variant="primary" v-if="activatedProductType?.length > 0" @click="handleConfirmPaymentPlanDialog"> Confirm </Button>
      <Button variant="danger" @click="handleClosePaymentPlanDialog"> Close </Button>
    </div>
  </Dialog>
</template>

<script>
  // import { mapActions } from 'vuex';
  // import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PaymentPlansDialog',
    components: {
      Button,
      Dialog,
      MoneyInput,
      IntegerInput,
      SelectInput,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      isOpen: {
        type: Boolean,
        required: true,
      },
      activatedProductType: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        requestedCashAtClosing: 0,
        maximizeAtClosing: false,
        lineOfCredit: 0,
        maximizeLineOfCredit: false,
        monthlyPayment: 0,
        term: 0,
        termOrTenure: 'term',
        termOrTenureOptions: [
          { value: 'term', label: 'Term' },
          { value: 'tenure', label: 'Tenure' },
        ],
      };
    },
    methods: {
      handleConfirmPaymentPlanDialog() {
        this.$emit('confirm', this.requestedCashAtClosing, this.lineOfCredit, this.termOrTenure, this.monthlyPayment, this.term);
      },
      handleClosePaymentPlanDialog() {
        this.$emit('close');
      },
    },
    watch: {
      maximizeAtClosing(newVal) {
        this.requestedCashAtClosing = newVal && !this.maximizeLineOfCredit ? 'Max Value Requested' : 0;
      },
      maximizeLineOfCredit(newVal) {
        this.lineOfCredit = newVal ? 'Max Value Requested' : 0;
        if (newVal) {
          this.requestedCashAtClosing = 0;
        }
        if (!newVal && this.maximizeAtClosing) {
          this.requestedCashAtClosing = 'Max Value Requested';
        }
      },
    },
  };
</script>

<style scoped>
  .custom-checkbox {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid #757575;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
  }

  .custom-checkbox:checked {
    background-color: #e78724;
    border-color: #e78724;
  }

  .custom-checkbox:checked::before {
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='10.5' viewBox='0 0 448 512'%3E%3Cpath fill='%23ffffff' d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 10.5px 12px; /* Set size based on SVG dimensions */
    background-position: center; /* Center the icon */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
