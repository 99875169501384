<template>
  <Card title="Loan Standard Condition Packages">
    <template v-slot:action>
      <Button @click="openAddDialog">Add Package</Button>
    </template>
    <div class="standard-conditions w-full overflow-x-auto">
      <div class="w-full overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Cleared</th>
              <th>Condition Type</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="standardConditionPacks?.length === 0">
              <td :colspan="5" class="!text-center">
                <div class="icon">
                  <img src="../../../../assets/image/info.svg" alt="icon" class="mx-auto mb-1" />
                  <span>No data available</span>
                </div>
              </td>
            </tr>
            <tr v-for="(standardConditionPack, index) in standardConditionPacks" :key="index">
              <td>{{ standardConditionPack.name }}</td>
              <td>
                <div class="h-[30px] flex items-center" v-for="(condition, index) in standardConditionPack.conditions" :key="index">
                  <span class="py-1 px-2 rounded-full text-white" :class="condition.cleared ? 'bg-[#32CD32]' : 'bg-red'">{{ condition.cleared ? 'Yes' : 'No' }}</span>
                </div>
              </td>
              <td>
                <div class="h-[30px] flex items-center" v-for="(condition, index) in standardConditionPack.conditions" :key="index">
                  {{ condition.conditionType }}
                </div>
              </td>
              <td>
                <div class="h-[30px] flex items-center text-nowrap" v-for="(condition, index) in standardConditionPack.conditions" :key="index">
                  {{ condition.description }}
                </div>
              </td>
              <td>
                <div class="flex items-center gap-2">
                  <button class="icon-button bg-primary" @click="handleAction('edit', standardConditionPack)" v-if="isCompanyAdmin()">
                    <font-awesome-icon icon="fas fa-pen-to-square" />
                  </button>
                  <button class="icon-button bg-red" @click="handleAction('delete', standardConditionPack)" v-if="isCompanyAdmin()">
                    <font-awesome-icon icon="trash-can" />
                  </button>
                  <button class="icon-button bg-primary" @click="handleAction('add', standardConditionPack)" v-if="enableAdd">
                    <font-awesome-icon :icon="['fas', 'clone']" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Card>
  <!-- add or edit loan standard conditions -->
  <Dialog :title="`${isOpenDialog} Loan Standard Conditions Package`" :isOpen="isOpenDialog != ''" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div class="flex flex-col gap-2">
      <div>
        <InputField label="Title *" :maxLength="150" :value="formData.name" v-model="formData.name" :disable-validation="true" />
      </div>
      <div>
        <DataTable :headers="conditionTableHeaders" :data="formData.conditions" @action="handleConditionAction" />
      </div>
      <div class="flex justify-end">
        <Button variant="primary" @click="handleAddCondition">Add Condition</Button>
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>
    <div class="flex justify-end gap-2 mt-2">
      <Button variant="primary" @click="handleConfirmDialog" :disabled="!isFormValid">Save Package</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
  <!-- Delete Conditions Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this conditions?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import _ from 'lodash';
  import { v4 as uuidv4 } from 'uuid';
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import { CONDITION_TYPE_OPTIONS } from '@/constants';
  import { isCompanyAdmin } from '@/utils';

  const initialData = {
    name: '',
    conditions: [],
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'StandardConditions',
    components: {
      Button,
      Card,
      Dialog,
      DataTable,
      InputField,
      Confirmation,
    },
    setup() {
      return {
        isCompanyAdmin,
        CONDITION_TYPE_OPTIONS,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchStandardConditionPacks();
    },
    data() {
      return {
        conditionTableHeaders: [
          { key: 'cleared', label: 'Cleared', type: 'checkbox' },
          { key: 'conditionType', label: 'Condition Type', type: 'select', options: CONDITION_TYPE_OPTIONS },
          { key: 'description', label: 'Description', type: 'input' },
          { key: 'action', label: 'Action' },
        ],
        formData: { ..._.cloneDeep(initialData) },
        standardConditionPacks: [],
        isOpenDialog: '',
        isDeleteConfirmationOpen: false,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchStandardConditionPacks() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/standard_condition_pack/`);
          this.standardConditionPacks = response.data;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddDialog() {
        this.formData = { ..._.cloneDeep(initialData) };
        this.isOpenDialog = 'Add';
      },
      async handleConfirmDialog() {
        const data = { ...this.formData };
        this.setLoading(true);
        try {
          if (data.id) await apiService.patch(`/loan/standard_condition_pack/${data.id}/`, { ...data });
          else
            await apiService.post(`/loan/standard_condition_pack/`, {
              ...data,
            });
          this.$root.showSnackbar(`Standard Condition Package has been updated successfully!`, 'success');
          await this.fetchStandardConditionPacks();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
      },
      removeProductFromList(key) {
        this.formData.loanProducts = this.formData.loanProducts.filter((el) => el !== key);
      },
      openEditDialog(id) {
        const item = this.standardConditionPacks.find((el) => el.id == id);
        this.formData = { ..._.cloneDeep(initialData), ..._.cloneDeep(item) };
        this.isOpenDialog = 'Edit';
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedPackage = item;
          this.isDeleteConfirmationOpen = true;
        } else if (action == 'add') {
          this.onAddConditionPack(item);
        }
      },
      handleAddCondition() {
        this.formData.conditions = [
          ...this.formData.conditions,
          {
            id: uuidv4(),
            conditionType: 'General',
            description: '',
            cleared: false,
            actions: ['delete'],
          },
        ];
      },
      handleConditionAction(action, item) {
        if (action === 'delete') {
          this.formData.conditions = this.formData.conditions.filter((condition) => condition.id !== item.id).map((condition, index) => ({ ...condition, id: index }));
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/standard_condition_pack/${this.selectedPackage.id}/`);
          this.$root.showSnackbar('Loan Standard Conditions Pack has been deleted successfully', 'success');
          await this.fetchStandardConditionPacks();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete Standard Conditions Pack: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
    },
    computed: {
      isFormValid() {
        return this.formData.name.length && this.formData.conditions.length;
      },
    },
  };
</script>
