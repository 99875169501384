<template>
  <Dialog :title="`${isOpen} Income Source`" :isOpen="isOpen.length > 0" @close="closeDialog" @confirm="emitConfirm" size="lg">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      <div>
        <SelectInput label="Income Type" :options="INCOME_TYPE_OPTIONS" :value="formData.incomeType" v-model.trim="formData.incomeType" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Monthly Income" :value="formData.monthlyIncome" v-model.trim="formData.monthlyIncome" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Annual Income" :value="formData.annualIncome" v-model.trim="formData.annualIncome" :disable-validation="true" />
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300" v-if="formData.incomeType === 'Employment'"></div>
    <!-- Employment History -->
    <div v-if="formData.incomeType === 'Employment'">
      <div class="flex items-center justify-between">
        <label class="text-md text-dark font-bold">Employment History</label>
        <Button variant="primary" size="small" @click="handleOpenEmploymentHistoryDialog">Add Employment History</Button>
      </div>
      <DataTable :headers="employmentHistoryHeaders" :data="getEmploymentHistoryTableData(this.formData.employmentHistory || [])" @action="handleAction" />
      <Dialog :title="`Add Employment History`" :isOpen="isOpenEmploymentHistoryDialog" @close="handleCloseEmploymentHistoryDialog" size="md">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div>
            <InputField label="Employer Name" :value="historyItem.employerName" v-model.trim="historyItem.employerName" :disable-validation="true" />
            <span v-if="historyItemErrors.employerName.length > 0" class="text-red text-sm">{{ historyItemErrors.employerName }}</span>
          </div>
          <div>
            <InputField label="Employer Address" :value="historyItem.employerAddress" v-model.trim="historyItem.employerAddress" :disable-validation="true" />
            <span v-if="historyItemErrors.employerAddress.length > 0" class="text-red text-sm">{{ historyItemErrors.employerAddress }}</span>
          </div>
          <div>
            <RadioInput label="Self Employed" :value="historyItem.selfEmployed" v-model.trim="historyItem.selfEmployed" :options="RADIO_INPUT_OPTIONS" :disable-validation="true" />
          </div>
          <div>
            <PhoneNumberInput label="Business Phone" :value="historyItem.businessPhone" v-model.trim="historyItem.businessPhone" :disable-validation="true" />
            <span v-if="historyItemErrors.businessPhone.length > 0" class="text-red text-sm">{{ historyItemErrors.businessPhone }}</span>
          </div>
          <div>
            <DateInput label="Start Date" :value="historyItem.startDate" v-model.trim="historyItem.startDate" :disable-validation="true" />
            <span v-if="historyItemErrors.startDate.length > 0" class="text-red text-sm">{{ historyItemErrors.startDate }}</span>
          </div>
          <div>
            <DateInput label="End Date" :value="historyItem.endDate" v-model.trim="historyItem.endDate" :disable-validation="true" />
            <span v-if="historyItemErrors.endDate.length > 0" class="text-red text-sm">{{ historyItemErrors.endDate }}</span>
          </div>
          <div>
            <RadioInput label="Part Time" :value="historyItem.partTime" v-model.trim="historyItem.partTime" :options="RADIO_INPUT_OPTIONS" :disable-validation="true" />
          </div>
          <div>
            <RadioInput label="Current Job" :value="historyItem.currentJob" v-model.trim="historyItem.currentJob" :options="RADIO_INPUT_OPTIONS" :disable-validation="true" />
          </div>
          <div />
        </div>
        <div class="flex justify-end gap-2 mt-2">
          <Button variant="primary" size="small" @click="handleAddEmploymentHistory"> Add </Button>
          <Button variant="secondary" size="small" @click="handleCloseEmploymentHistoryDialog"> Cancel </Button>
        </div>
      </Dialog>
    </div>
    <div class="flex justify-end gap-2 mt-5">
      <Button variant="primary" @click="emitConfirm" :disabled="!isValidForm"> Save Changes </Button>
      <Button variant="secondary" @click="closeDialog"> Cancel </Button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import PhoneNumberInput from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { INCOME_TYPE_OPTIONS, RADIO_INPUT_OPTIONS } from '@/constants';
  import { formatDate } from '@/utils';

  export default {
    name: 'IncomeDialog',
    components: {
      Dialog,
      DateInput,
      InputField,
      PhoneNumberInput,
      SelectInput,
      MoneyInput,
      Button,
      RadioInput,
      DataTable,
    },
    props: {
      isOpen: {
        type: String,
        required: true,
      },
      editItem: {
        type: Object,
        required: true,
      },
    },
    setup() {
      return { INCOME_TYPE_OPTIONS, RADIO_INPUT_OPTIONS, formatDate };
    },
    data() {
      return {
        formData: {
          incomeType: 'Employment',
          monthlyIncome: 0,
          annualIncome: 0,
          employmentHistory: null,
        },
        employmentHistoryHeaders: [
          { label: 'Employer Name', key: 'employerName' },
          { label: 'Employer Address', key: 'employerAddress' },
          { label: 'Self Employed', key: 'selfEmployed' },
          { label: 'Business Phone', key: 'businessPhone' },
          { label: 'Start Date', key: 'startDate' },
          { label: 'End Date', key: 'endDate' },
          { label: 'Part Time', key: 'partTime' },
          { label: 'Current Job', key: 'currentJob' },
          { key: 'action', label: 'Action' },
        ],
        isOpenEmploymentHistoryDialog: false,
        historyItem: {
          employerName: '',
          employerAddress: '',
          selfEmployed: false,
          businessPhone: '',
          startDate: null,
          partTime: false,
          endDate: null,
          currentJob: false,
        },
        historyItemErrors: {
          employerName: '',
          employerAddress: '',
          businessPhone: '',
          startDate: '',
          endDate: '',
        },
      };
    },
    computed: {
      isValidForm() {
        return this.formData.monthlyIncome > 0 && this.formData.annualIncome > 0;
      },
    },
    methods: {
      getEmploymentHistoryTableData(data) {
        return data.map((item, key) => ({
          ...item,
          id: key,
          startDate: formatDate(item.startDate, 'MM/DD/YYYY'),
          endDate: formatDate(item.endDate, 'MM/DD/YYYY'),
          actions: ['delete'],
        }));
      },
      handleAction(action, row) {
        if (action === 'delete') {
          this.formData.employmentHistory.splice(row.id, 1);
        }
      },
      historyFormValid() {
        this.historyItemErrors = {
          employerName: this.historyItem.employerName.length > 0 ? '' : 'Employer Name is required',
          employerAddress: this.historyItem.employerAddress.length > 0 ? '' : 'Employer Address is required',
          businessPhone: this.historyItem.businessPhone.length > 0 ? '' : 'Business Phone is required',
          startDate: this.historyItem.startDate ? '' : 'Start Date is required',
          endDate: !this.historyItem.currentJob && !this.historyItem.endDate ? 'End Date is required' : '',
        };
        if (this.historyItem.startDate && this.historyItem.endDate && this.historyItem.startDate > this.historyItem.endDate) {
          this.historyItemErrors.endDate = 'End Date must be after Start Date';
        }
        return Object.values(this.historyItemErrors).every((error) => error === '');
      },
      handleAddEmploymentHistory() {
        if (!this.historyFormValid()) return;
        (this.formData.employmentHistory = this.formData.employmentHistory || []).push({ ...this.historyItem });
        this.handleCloseEmploymentHistoryDialog();
      },
      handleCloseEmploymentHistoryDialog() {
        this.isOpenEmploymentHistoryDialog = false;
        this.resetHistoryItem();
      },
      handleOpenEmploymentHistoryDialog() {
        this.isOpenEmploymentHistoryDialog = true;
        this.resetHistoryItem();
      },
      resetHistoryItem() {
        this.historyItem = {
          employerName: '',
          employerAddress: '',
          selfEmployed: false,
          businessPhone: '',
          startDate: null,
          partTime: false,
          endDate: null,
          currentJob: false,
        };
        this.historyItemErrors = {
          employerName: '',
          employerAddress: '',
          businessPhone: '',
          startDate: '',
          endDate: '',
        };
      },
      closeDialog() {
        this.$emit('close');
      },
      emitConfirm() {
        this.$emit('confirm', this.formData, this.isOpen);
      },
    },
    watch: {
      editItem: {
        handler(newValue) {
          this.formData = { ...newValue };
        },
        immediate: true,
      },
      'formData.monthlyIncome': {
        handler(newValue) {
          const annual = (newValue * 12).toFixed(2);
          if (parseFloat(this.formData.annualIncome).toFixed(2) !== annual) {
            this.formData.annualIncome = parseFloat(annual);
          }
        },
        immediate: true,
      },
      'formData.annualIncome': {
        handler(newValue) {
          const monthly = (newValue / 12).toFixed(2);
          if (parseFloat(this.formData.monthlyIncome).toFixed(2) !== monthly) {
            this.formData.monthlyIncome = parseFloat(monthly);
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
