<template>
  <Card>
    <div>
      <h3 class="mb-3 text-lg font-semibold">Legal Information</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        <div>
          <RadioInput
            label="Borrower is incapacitated and cannot sign?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.isIncapacitated"
            :focused="focusedInput === 'isIncapacitated'"
            @update:focused="focusedInput = 'isIncapacitated'"
            v-model="formData.isIncapacitated"
            tooltip="Indicates if the borrower is physically unable to sign documents, which may require a power of attorney."
          />
        </div>
        <div>
          <RadioInput
            label="Borrower is incompetent?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.isIncompetent"
            :focused="focusedInput === 'isIncompetent'"
            @update:focused="focusedInput = 'isIncompetent'"
            v-model="formData.isIncompetent"
            tooltip="Marks if the borrower is legally deemed unable to make financial decisions, which may require a power of attorney."
          />
        </div>
        <div>
          <RadioInput
            label="Are there outstanding judgements?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.outstandingJudgments"
            :focused="focusedInput === 'outstandingJudgments'"
            @update:focused="focusedInput = 'outstandingJudgments'"
            v-model="formData.outstandingJudgments"
            tooltip="Shows if there are unresolved court judgments against the borrower, which could impact loan eligibility due to Financial Assessment."
          />
        </div>
        <div>
          <RadioInput
            label="Unresolved Bankruptcy?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.unresolvedBankruptcy"
            :focused="focusedInput === 'unresolvedBankruptcy'"
            @update:focused="focusedInput = 'unresolvedBankruptcy'"
            v-model="formData.unresolvedBankruptcy"
            tooltip="Indicates if the borrower is currently undergoing a bankruptcy process, affecting loan approval."
          />
        </div>
        <div>
          <RadioInput
            label="Default on Federal Debt?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.defaultOnFederalDebt"
            :focused="focusedInput === 'defaultOnFederalDebt'"
            @update:focused="focusedInput = 'defaultOnFederalDebt'"
            v-model="formData.defaultOnFederalDebt"
            tooltip="Marks if the borrower has a history of defaulting on federally-backed debt. This may affect loan eligibility due to Financial Assessment."
          />
        </div>
        <div>
          <RadioInput
            label="Co - Maker or Endorser on Note?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.endorserOnNote"
            :focused="focusedInput === 'endorserOnNote'"
            @update:focused="focusedInput = 'endorserOnNote'"
            v-model="formData.endorserOnNote"
            tooltip="Indicates if the borrower is listed as a co-maker or endorser on another loan, affecting debt obligations."
          />
        </div>
        <div>
          <RadioInput
            label="Party to Lawsuit?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.lawSuit"
            :focused="focusedInput === 'lawSuit'"
            @update:focused="focusedInput = 'lawSuit'"
            v-model="formData.lawSuit"
          />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Financial Information</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        <div>
          <RadioInput
            label="Required to bring funds to closing?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.requiredToBringMoney"
            :focused="focusedInput === 'requiredToBringMoney'"
            @update:focused="focusedInput = 'requiredToBringMoney'"
            v-model="formData.requiredToBringMoney"
            tooltip="States if the borrower must contribute funds at loan closing, common for purchase loans or short-to-close scenarios."
          />
        </div>
        <div>
          <RadioInput
            label="Did you borrow the money?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.borrowedTheMoney"
            :focused="focusedInput === 'borrowedTheMoney'"
            @update:focused="focusedInput = 'borrowedTheMoney'"
            v-model="formData.borrowedTheMoney"
            tooltip="Asks if the borrower used borrowed funds to meet any required payment amounts, affecting their financial position."
          />
        </div>
        <div>
          <RadioInput
            label="Intend to buy financial product?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.purchaseFinancialProduct"
            :focused="focusedInput === 'purchaseFinancialProduct'"
            @update:focused="focusedInput = 'purchaseFinancialProduct'"
            v-model="formData.purchaseFinancialProduct"
            tooltip="Indicates if the borrower plans to use the loan funds to purchase additional financial products."
          />
        </div>
        <div>
          <RadioInput
            label="Is the property the primary residence?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.primaryResidence"
            :focused="focusedInput === 'primaryResidence'"
            @update:focused="focusedInput = 'primaryResidence'"
            v-model="formData.primaryResidence"
            tooltip="Confirms if the property is the borrower’s primary residence, a common requirement for reverse mortgages."
          />
        </div>
        <div>
          <RadioInput
            label="Existing FHA Loan?"
            :options="RADIO_INPUT_OPTIONS"
            :value="formData.fHAInsuranceClaimFound"
            :focused="focusedInput === 'fHAInsuranceClaimFound'"
            @update:focused="focusedInput = 'fHAInsuranceClaimFound'"
            v-model="formData.fHAInsuranceClaimFound"
            tooltip="Indicates if there is an existing FHA loan on the property, relevant for determining loan terms."
          />
        </div>
      </div>
      <div v-if="formData.fHAInsuranceClaimFound">
        <br />
        <Card title="FHA Loans">
          <div class="flex justify-end items-center">
            <Button variant="primary" class="my-2" @click="openAddFHALoanDialog">Add FHA Loan</Button>
          </div>
          <DataTable :headers="fhaLoanTableheaders" :data="formData.existingFHALoans" @action="handleAction" />
        </Card>
      </div>
    </div>
    <div class="flex justify-end gap-2 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
    </div>
  </Card>
  <Dialog :title="isEditFHALoanMode ? 'Edit FHA Loan' : 'Add New FHA Loan'" :isOpen="isFHADialogOpen" @confirm="handleConfirmFHALoaneDialog" @close="handleCloseFHALoanDialog">
    <form @submit.prevent="handleConfirmFHALoaneDialog">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div class="form-group">
          <InputField label="Fully Property Address" v-model="fhaLoanForm.address" :value="fhaLoanForm.address" :disable-validation="true" />
        </div>
        <div class="form-group">
          <SelectInput label="Status" :options="fhaLoanStatusOptions" v-model="fhaLoanForm.status" :value="fhaLoanForm.status" :validation="{ required: true }" />
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        <div class="form-group">
          <IntegerInput label="Account number" :value="fhaLoanForm.accountNumber" v-model.trim="fhaLoanForm.accountNumber" :disable-validation="true" />
        </div>
        <div class="form-group">
          <InputField label="Creditor Name" v-model="fhaLoanForm.creditorName" :value="fhaLoanForm.creditorName" :disable-validation="true" />
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2">
        <div class="form-group">
          <MoneyInput label="Amount" ref="fha-loan-amount" :value="fhaLoanForm.amount" v-model.trim="fhaLoanForm.amount" :disable-validation="true" />
        </div>
        <div class="form-group">
          <MoneyInput label="Balance" ref="fha-loan-balance" :value="fhaLoanForm.balance" v-model.trim="fhaLoanForm.balance" :disable-validation="true" />
        </div>
      </div>
      <div class="form-actions flex justify-end mt-2">
        <Button type="submit" :disabled="!isFHAFormValid || !writePermission">{{ isEditFHALoanMode ? 'Save Changes' : 'Add FHA Loan' }}</Button>
      </div>
    </form>
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import { RADIO_INPUT_OPTIONS } from '@/constants/index';

  const initialData = {
    isIncapacitated: null,
    isIncompetent: null,
    outstandingJudgments: null,
    unresolvedBankruptcy: null,
    defaultOnFederalDebt: null,
    endorserOnNote: null,
    lawSuit: null,
    requiredToBringMoney: null,
    borrowedTheMoney: null,
    purchaseFinancialProduct: null,
    fHAInsuranceClaimFound: null,
    primaryResidence: null,
    existingFHALoans: [],
  };

  const tabElements = [
    'isIncapacitated',
    'isIncompetent',
    'outstandingJudgments',
    'unresolvedBankruptcy',
    'defaultOnFederalDebt',
    'endorserOnNote',
    'lawSuit',
    'requiredToBringMoney',
    'borrowedTheMoney',
    'purchaseFinancialProduct',
    'primaryResidence',
    'fHAInsuranceClaimFound',
  ];

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Declaration',
    components: {
      Button,
      Card,
      RadioInput,
      DataTable,
      IntegerInput,
      Dialog,
      MoneyInput,
      InputField,
      SelectInput,
    },
    inject: ['addWarningToList', 'removeWarning'],
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {
        RADIO_INPUT_OPTIONS,
      };
    },
    data() {
      return {
        isDraft: false,
        isEditFHALoanMode: false,
        isFHADialogOpen: false,
        selectedFHALoan: null,
        fhaLoanStatusOptions: [
          { label: 'Sold', value: 'Sold' },
          { label: 'Pending Sale', value: 'Pending' },
          { label: 'Rental', value: 'Rental' },
        ],
        fhaLoanTableheaders: [
          { key: 'address', label: 'Address' },
          { key: 'status', label: 'Status' },
          { key: 'accountNumber', label: 'Account Number' },
          { key: 'creditorName', label: 'Creditor Name' },
          { key: 'amount', label: 'Amount' },
          { key: 'balance', label: 'Balance' },
          { key: 'action', label: 'Action' },
        ],
        fhaLoanForm: {
          address: '',
          status: '',
          accountNumber: 0,
          creditorName: '',
          amount: 0,
          balance: 0,
        },
        localData: { ...initialData },
        formData: { ...initialData },
        caseNumberExistingLoan: null,
        focusedInput: null,
      };
    },
    computed: {
      isFHAFormValid() {
        const { address, status, accountNumber, amount, balance, creditorName } = this.fhaLoanForm;
        return address && status && accountNumber && amount && balance && creditorName;
      },
    },
    mounted() {
      this.fetchExistingLoan();
      window.addEventListener('keydown', this.onKeyPress);
    },
    unmounted() {
      window.removeEventListener('keydown', this.onKeyPress);
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async fetchDeclaration() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/declarations/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
          this.localData.existingFHALoans = response.data.existingFHALoans.map((loan, key) => ({
            ...loan,
            id: key,
            dataId: loan.id,
            actions: ['edit', 'delete'],
          }));
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchExistingLoan() {
        if (this.caseNumberExistingLoan != null) return;
        try {
          const caseNumerResp = await apiService.get('/loan/case_numbers/', {
            loan: this.$route.params.id,
          });
          if (caseNumerResp.data?.length > 0) {
            const response = await apiService.get(`/loan/existing_fha_loans/by-case-number/${caseNumerResp.data[0].id}/`);
            if (response.data) {
              this.caseNumberExistingLoan = response.data;
              return;
            }
          }
        } catch (error) {
          if (error.response.status != 200) {
            this.caseNumberExistingLoan = [];
          }
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      resetFMALoanForm() {
        this.fhaLoanForm = {
          address: '',
          status: '',
          accountNumber: 0,
          creditorName: '',
          amount: 0,
          balance: 0,
        };
      },
      openAddFHALoanDialog() {
        this.resetFMALoanForm();
        this.isEditFHALoanMode = false;
        this.isFHADialogOpen = true;
      },
      openEditFHALoanDialog(fhaLoan) {
        this.selectedFHALoan = fhaLoan;
        this.fhaLoanForm = {
          ...fhaLoan,
        };
        this.isEditFHALoanMode = true;
        this.isFHADialogOpen = true;
      },
      handleCloseFHALoanDialog() {
        this.isFHADialogOpen = false;
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditFHALoanDialog(item);
        } else if (action === 'delete') {
          this.selectedFHALoan = item;
          this.handleFHALoanDelete();
        }
      },
      async handleConfirmFHALoaneDialog() {
        if (this.isEditFHALoanMode) {
          this.formData.existingFHALoans = this.formData.existingFHALoans.map((fhaLoan) => {
            if (this.selectedFHALoan.id == fhaLoan.id) {
              return this.fhaLoanForm;
            }
            return fhaLoan;
          });
        } else {
          const newFHALoan = {
            ...this.fhaLoanForm,
            id: this.formData.existingFHALoans.length,
            dataId: -1,
            actions: ['edit', 'delete'],
          };
          let data = [...this.formData.existingFHALoans];
          data.push(newFHALoan);
          this.formData.existingFHALoans = data;
        }

        this.isFHADialogOpen = false;
        this.resetFMALoanForm();
      },
      handleFHALoanDelete() {
        const filteredData = this.formData.existingFHALoans.filter((fhaLoan) => fhaLoan.id !== this.selectedFHALoan.id);
        let id = 0;
        this.formData.existingFHALoans = filteredData.map((fhaLoan) => {
          return {
            ...fhaLoan,
            id: id++,
          };
        });
      },
      handleCloseFHALoanDeleteConfirmation() {
        this.isDeleteFHALoanConfirmationOpen = false;
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          let payload = { ...this.formData };
          payload.existingFHALoans = this.formData.fHAInsuranceClaimFound ? this.formData.existingFHALoans : [];
          if (this.localData.id) {
            await apiService.patch(`/loan/declarations/${this.localData.id}/`, payload);
          } else {
            payload.borrower = this.borrowerId;
            await apiService.post(`/loan/declarations/`, payload);
          }
          this.$root.showSnackbar(`Declaration information has been updated successfully!`, 'success');
          await this.fetchDeclaration();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      updateField(field, value) {
        if (this.localFormData[field] == value) {
          this.localFormData[field] = null;
        } else {
          this.localFormData[field] = value;
        }
      },
      resetLocalData() {
        this.localData = {
          isIncapacitated: null,
          isIncompetent: null,
          outstandingJudgments: null,
          unresolvedBankruptcy: null,
          defaultOnFederalDebt: null,
          endorserOnNote: null,
          requiredToBringMoney: null,
          borrowedTheMoney: null,
          purchaseFinancialProduct: null,
          fHAInsuranceClaimFound: null,
          primaryResidence: null,
          existingFHALoans: [],
        };
      },
      showFormWarning(form, existingLoan) {
        if (form.primaryResidence != null && !form.primaryResidence) {
          this.addWarningToList({ name: 'primaryResidence', message: 'Borrower indicates property is not the primary residence' });
        } else {
          this.removeWarning('primaryResidence');
        }
        if (form.fHAInsuranceClaimFound && existingLoan?.length == 0) {
          this.addWarningToList({
            name: 'fHAInsuranceClaimFoundFHALOANS',
            message:
              'Declarations section indicates additional FHA loans but no additional FHA loans are shown on loan application. Either correct the declarations section to say “No”, or add the FHA loan under Case Number > Existing FHA Loans.',
          });
          this.removeWarning('fHAInsuranceClaimFoundNoFHALoans');
        } else if (!form.fHAInsuranceClaimFound && existingLoan?.length > 0) {
          this.addWarningToList({
            name: 'fHAInsuranceClaimFoundNoFHALoans',
            message:
              'Declarations section indicates NO ADDITIONAL FHA loans, but additional FHA loans are shown on the loan application. Either correct the declarations section to say “Yes”, or remove the FHA loan under Case Number > Existing FHA Loans.',
          });
          this.removeWarning('fHAInsuranceClaimFoundFHALOANS');
        } else {
          this.removeWarning('fHAInsuranceClaimFoundNoFHALoans');
          this.removeWarning('fHAInsuranceClaimFoundFHALOANS');
        }
      },
      onKeyPress(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          const index = tabElements.findIndex((tab) => tab === this.focusedInput);
          if (index === -1) {
            this.focusedInput = tabElements[0];
          } else {
            this.focusedInput = tabElements[(index + 1) % tabElements.length];
          }
        }
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchDeclaration();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        async handler(value) {
          this.checkIfDraft();
          this.showFormWarning(value, this.caseNumberExistingLoan);
        },
        deep: true,
        immediate: true,
      },
      caseNumberExistingLoan: {
        async handler(value) {
          this.showFormWarning(this.formData, value);
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
