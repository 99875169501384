<template>
  <Card title="Compliance">
    <div class="grid grid-cols-1 md:grid-cols-10 gap-2">
      <div class="md:col-span-7">
        <Card title="GFE Tracking">
          <SelectInput label="Choose a GFE" :options="disclosureOptions" v-model="gfeDisclosure" class="mb-2" />
          <DataTable :headers="headers" :data="tableData" />
        </Card>
      </div>
      <div class="md:col-span-3">
        <Card title="TIL Tracking">
          <SelectInput label="Choose a TIL" :options="aprOptions" v-model="tilDisclosure" class="mb-2" />
          <Card title="APR">
            <div>Disclosure: {{ formatPercentage(tilTrackingData.prev_apr, 4) }}</div>
            <div>Current: {{ formatPercentage(tilTrackingData.current_apr, 4) }}</div>

            <template v-slot:footer>
              <div>Difference: {{ Number(tilTrackingData.diff_percentage) > 0 ? '+' : '' }}{{ formatPercentage(tilTrackingData.diff_percentage) }}</div>
            </template>
          </Card>
        </Card>
      </div>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import { formatMoney, formatDateTime, formatPercentage } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Compliance',
    components: {
      Card,
      DataTable,
      SelectInput,
    },
    data() {
      return {
        loanId: this.$route.params.id,
        fees: [],
        headers: [
          { key: 'tolerance', label: 'Tolerance' },
          { key: 'name', label: 'Fee' },
          { key: 'gfe', label: 'GFE' },
          { key: 'current', label: 'Current' },
          { key: 'diff', label: 'Diff' },
          { key: 'percent', label: '%' },
        ],
        tableData: [],
        tilTrackingData: {},
        disclosureOptions: [],
        aprOptions: [],
        gfeDisclosure: null,
        tilDisclosure: null,
      };
    },
    setup() {
      return {
        formatMoney,
        formatDateTime,
        formatPercentage,
      };
    },
    async created() {
      this.setLoading(true);
      await this.fetchDisclosures();
      await this.fetchAprs();
      this.setLoading(false);
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchDisclosures() {
        try {
          const response = await apiService.get(`/loan/disclosures/`, {
            loan: this.$route.params.id,
          });
          this.disclosureOptions = response.data.map((disclosure) => ({
            label: disclosure.user_info + ' - ' + formatDateTime(disclosure.created_at),
            value: disclosure.id,
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch disclosures: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchAprs() {
        try {
          const response = await apiService.get(`/loan/aprs/`, {
            loan: this.$route.params.id,
          });
          this.aprOptions = response.data.map((apr) => ({
            label: apr.user_info + ' - ' + formatDateTime(apr.created_at),
            value: apr.id,
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch APRs: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchGFETrackingTableData() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/disclosures/get-gfe-track-data/`, {
            id: this.gfeDisclosure,
          });
          this.handleGFETrackingTableData(response.data);
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch GFE tracking data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleGFETrackingTableData(data) {
        this.tableData = data.map((el) => ({
          tolerance: el.tolerance,
          name: el.name,
          gfe: formatMoney(el.gfe),
          current: formatMoney(el.current),
          diff: formatMoney(el.diff),
          percent: formatPercentage(el.diff_percentage),
        }));
      },
      async fetchTILTrackingData() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/aprs/get-til-track-data/`, {
            id: this.tilDisclosure,
          });
          this.tilTrackingData = { ...response.data };
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch TIL tracking data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      gfeDisclosure(newVal) {
        if (newVal) {
          this.fetchGFETrackingTableData();
        }
      },
      tilDisclosure(newVal) {
        if (newVal) {
          this.fetchTILTrackingData();
        }
      },
    },
  };
</script>
