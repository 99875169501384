<template>
  <div class="flex">
    <div class="lg:w-1/2 hidden lg:block h-screen">
      <Slider />
    </div>
    <div class="w-full lg:w-1/2 h-screen">
      <div class="p-3 lg:p-10 h-screen relative flex justify-center items-center flex-col">
        <div class="lg:absolute lg:top-[40px] lg:right-[40px] relative mx-auto mb-5">
          <img src="../../../assets/image/brand-logo.svg" alt="login image" />
        </div>
        <div class="lg:w-[480px] mx-auto">
          <div class="text-center mb-8">
            <font-awesome-icon v-if="passwordChanged" icon="fa-solid fa-check-circle" class="text-green-500 text-6xl mb-4" />
            <h3 class="text-dark md:text-4xl text-3xl font-bold mb-2">Reset Password</h3>
            <p class="text-dark-gray text-1xl" v-if="passwordChanged">Your password has been successfully reset. You can now log in with your new password.</p>
            <p class="text-dark-gray text-1xl" v-else>Enter your new password below to reset your password.</p>
            <span v-if="errors.general" class="error mt-2 text-red-500">{{ errors.general }}</span>
            <router-link to="/login" v-if="passwordChanged" class="standard-btn mt-3">Login</router-link>
          </div>
          <form @submit.prevent="submitForm" v-if="!passwordChanged">
            <div class="form-group mb-3">
              <label for="password">New Password:</label>
              <input type="password" placeholder="New Password" id="password" v-model="formData.password" required />
              <span v-if="errors.password" class="error mt-2 text-red-500">{{ errors.password }}</span>
            </div>
            <div class="form-group mb-6">
              <label for="confirm-password">Confirm Password:</label>
              <input type="password" placeholder="Confirm Password" id="confirm-password" v-model="formData.confirmPassword" required />
              <span v-if="errors.confirmPassword" class="error mt-2 text-red-500">{{ errors.confirmPassword }}</span>
            </div>
            <button class="standard-btn w-full" type="submit">
              <span>Reset Password </span>
              <font-awesome-icon icon=" fa-solid fa-arrow-right" class="icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Slider from './../Slider/index.vue';
  import apiService from '@/api/apiService';
  import { mapActions } from 'vuex';

  export default {
    name: 'ResetPassword',
    components: {
      Slider,
    },
    data() {
      return {
        formData: {
          password: '',
          confirmPassword: '',
        },
        passwordChanged: false,
        errors: {},
        uidb64: '',
        token: '',
      };
    },
    created() {
      this.uidb64 = this.$route.params.uidb64;
      this.token = this.$route.params.token;
    },
    methods: {
      ...mapActions(['setLoading']),
      async submitForm() {
        console.log('submitting form');

        this.errors = {};

        if (!this.formData.password) {
          this.errors.password = 'Password is required';
        }
        if (this.formData.password.length < 8) {
          this.errors.password = 'Password must be at least 8 characters long';
        }
        if (this.formData.password !== this.formData.confirmPassword) {
          this.errors.confirmPassword = 'Passwords do not match';
        }
        this.setLoading(true);

        try {
          if (Object.keys(this.errors).length === 0) {
            const response = await apiService.post(`/reset/${this.uidb64}/${this.token}/`, {
              password: this.formData.password,
            });
            if (response.status === 200) {
              this.$root.showSnackbar('Password reset successfully!', 'success');
              this.passwordChanged = true;
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.errors.general = error.response.data.message;
          } else {
            this.errors.general = 'An unexpected error occurred. Please try again later.';
          }
        } finally {
          this.setLoading(false);
        }
      },
    },
  };
</script>

<style scoped>
  .standard-btn {
    background: #224760;
  }

  .error {
    color: red;
  }
</style>
