<template>
  <Card title="Recent Transactions">
    <ul class="relative pl-5">
      <li class="mb-4 font-medium relative" v-bind:key="index" v-for="(transaction, index) in recentTransactions">
        <div class="w-5 h-5 rounded-full flex items-center justify-center" :style="`background-color: rgba(${(index + 1) * 12}, ${(index + 1) * 12}, ${(index + 1) * 12}, 0.1)`">
          <div :class="`w-2 h-2 rounded-full ${colors[index]}`"></div>
        </div>
        <p>
          Loan Number {{ transaction.loan_id }} status was changed to <span class="text-primary">{{ transaction.new_status }}</span> by {{ transaction.changed_by }}
        </p>
      </li>
    </ul>
  </Card>
</template>

<script>
  import apiService from '@/api/apiService';
  import Card from './../../../../components/Card/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'RecentTransactions',
    components: { Card },
    data() {
      return {
        recentTransactions: [],
        colors: ['bg-[#E78724]', 'bg-[#845ADF]', 'bg-[#34D399]', 'bg-[#FBBF24]', 'bg-[#3B82F6]', 'bg-[#EF4444]', 'bg-[#10B981]', 'bg-[#6366F1]', 'bg-[#F59E0B]', 'bg-[#8B5CF6]'],
      };
    },
    mounted() {
      this.fetchRecentTransactions();
    },
    methods: {
      async fetchRecentTransactions() {
        try {
          const response = await apiService.get('/loan/dashboard/recent-transaction/');
          if (response.status === 200) {
            this.recentTransactions = response.data;
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
</script>
<style scoped>
  ul:before {
    content: '';
    width: 1px;
    border: 1px dashed #e5e5e5;
    height: 75%;
    position: absolute;
    left: 0;
    top: 10px;
  }
  li > div {
    position: absolute;
    left: -29px;
    top: 2px;
  }
</style>
