<template>
  <Card :title="`Report View - ${$route.params.name || ''}`">
    <template v-slot:action>
      <div class="flex items-center">
        <div class="flex items-center space-x-4">
          <select v-model="selectedFormat" class="w-max border p-1 rounded">
            <option value="csv">CSV</option>
            <option value="json">JSON</option>
            <option value="pdf">PDF</option>
          </select>
          <Button class="standard-btn whitespace-nowrap" @click="downloadReport"> Download Report </Button>
          <Button @click="goBack" class="mr-3">
            <font-awesome-icon icon="arrow-left" />
          </Button>
        </div>
      </div>
    </template>

    <ApiDataTable v-if="!loading" :headers="headers" :apiUrl="`/company/reports/run-report/${$route.params.id}/`" :sortable="true" @action="handleAction" :column-filter="true" />
  </Card>
</template>

<script>
  import ApiDataTable from '@/components/ApiDataTable/index.vue';
  import Card from '@/components/Card/index.vue';
  import Button from '@/components/Button/index.vue';
  import apiService from '@/api/apiService';

  export default {
    name: 'ReportView',
    components: {
      ApiDataTable,
      Card,
      Button,
    },
    data() {
      return {
        reportId: null,
        loading: false,
        headers: [],
        selectedFormat: 'csv',
      };
    },
    async created() {
      this.reportId = this.$route.params.id;
      await this.createReportHeader();
    },
    methods: {
      handleAction(action) {
        console.log(action);
      },
      async createReportHeader() {
        try {
          this.loading = true;
          const response = await apiService.get(`/company/reports/report-fields/${this.reportId}/`);
          if (response.data) {
            this.headers = response.data;
          } else {
            this.$root.showSnackbar('Error fetching report fields', 'error');
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      async downloadReport() {
        try {
          const response = await apiService.get(`/company/reports/export-report/${this.$route.params.id}/${this.selectedFormat}/`, {
            responseType: this.selectedFormat === 'json' ? 'json' : 'blob',
          });

          if (this.selectedFormat === 'json') {
            const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report.${this.selectedFormat}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.$root.showSnackbar('Report downloaded successfully', 'success');
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report.${this.selectedFormat}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } catch (error) {
          console.error('Error downloading report:', error);
          this.$root.showSnackbar('Error downloading report', 'error');
        }
      },
      goBack() {
        this.$router.back();
      },
    },
  };
</script>

<style scoped>
  .standard-btn {
    overflow: inherit;
  }
</style>
