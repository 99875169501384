<template>
  <div class="flex flex-col gap-2 justify-center">
    <div class="flex flex-row items-center gap-2">
      <InputField id="aka" ref="aka" :value="aka" v-model.trim="aka" :disable-validation="true" @onKeyPressed="handleKeyPressed" />
      <div class="flex">
        <Button variant="primary" @click="handleAddAKA">Add AKA</Button>
      </div>
    </div>
    <div class="flex flex-col gap-2 items-start">
      <div class="text-md text-gray font-semibold mb-2">Borrower's AKAs</div>
      <div v-for="(item, index) in akas" :key="index">
        <div class="flex items-center p-2 bg-gray-100 rounded-md">
          <div class="text-md text-gray">{{ item }}</div>
          <button @click="handleRemoveAKA(index)" class="ml-2 text-red-500">
            <font-awesome-icon icon="times" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Button from '@/components/Button/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Akas',
    components: {
      InputField,
      Button,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        akas: [],
        aka: '',
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchBorrower() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/borrowers/${this.borrowerId}/`);
          this.akas = [...response.data.akas];
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleKeyPressed(key) {
        if (key === 'Enter') {
          this.handleAddAKA();
        }
      },
      async handleAddAKA() {
        this.setLoading(true);
        try {
          const response = await apiService.patch(`/loan/borrowers/${this.borrowerId}/`, {
            akas: [...this.akas, this.aka],
          });
          this.akas = [...response.data.akas];
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
        this.aka = '';
      },
      async handleRemoveAKA(index) {
        this.setLoading(true);
        try {
          const response = await apiService.patch(`/loan/borrowers/${this.borrowerId}/`, {
            akas: this.akas.filter((_, i) => i !== index),
          });
          this.akas = [...response.data.akas];
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {
      borrowerId: {
        handler(newVal) {
          if (newVal) {
            this.fetchBorrower();
          }
        },
        immediate: true,
      },
    },
  };
</script>
