<template>
  <Card customClass="flex flex-col min-h-full">
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goBack" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Document Templates</h1>
      </div>
    </template>
    <template v-slot:action>
      <div class="flex items-center">
        <input v-model="searchQuery" class="search-input mr-[15px]" placeholder="Search by title..." />
        <Dropdown>
          <template v-slot:button>
            <button class="standard-btn h-[39px]"><font-awesome-icon icon="fa-solid fa-plus" /><span class="ml-2">Create New</span></button>
          </template>
          <button class="text-nowrap" @click="navigateToCreateNew('pdf')">PDF Template</button>
          <button class="text-nowrap" @click="navigateToCreateNew('custom')">Custom Template</button>
        </Dropdown>
      </div>
    </template>

    <div class="grow">
      <div v-if="noTemplatesLoaded" class="flex justify-center items-center">
        <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
          <img src="../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
          <h2 class="text-2xl font-semibold text-gray-800 mb-2">No Templates</h2>
          <p class="text-gray-600 mb-4">There is no created templates yet.</p>
          <div class="flex justify-center gap-4">
            <Dropdown>
              <template v-slot:button>
                <Button><font-awesome-icon icon="fa-solid fa-plus" /> Create New</Button>
              </template>
              <button @click="navigateToCreateNew('pdf')">PDF Template</button>
              <button @click="navigateToCreateNew('custom')">Custom Template</button>
            </Dropdown>
          </div>
        </div>
      </div>
      <div v-if="existTemplates" class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
        <DocumentCard
          v-for="(template, index) in filteredTemplates"
          :key="index"
          :title="template.name"
          :pdf-link="template.templateFileUrl"
          @edit="editTemplate(template.id, template.type)"
          @delete="deleteTemplate(template.id)"
        />
      </div>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Dropdown from '@/components/Dropdown/index.vue';
  import DocumentCard from '../../components/DocumentCard/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'DocumentTemplate',
    components: {
      Button,
      Card,
      Dropdown,
      DocumentCard,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchTemplates();
    },
    data() {
      return {
        templates: undefined,
        searchQuery: '',
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchTemplates() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/documents/templates/');
          this.templates = response.data;
        } catch (error) {
          this.templates = undefined;
          this.$root.showSnackbar(`Failed to fetch document templates: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      editTemplate(id, type) {
        if (type == 'CUSTOM') this.$router.push({ name: 'CustomTemplateUpdate', params: { id: id } });
        else this.$router.push({ name: 'PDFTemplateUpdate', params: { id: id } });
      },
      async deleteTemplate(id) {
        this.setLoading(true);
        try {
          await apiService.delete(`/documents/templates/${id}/`);
          this.$root.showSnackbar('Document template deleted successfully', 'success');
          await this.fetchTemplates();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete document template: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      navigateToCreateNew(format) {
        if (format == 'pdf') this.$router.push({ name: 'PDFTemplateCreate' });
        else this.$router.push({ name: 'CustomTemplateCreate' });
      },
      goBack() {
        this.$router.push({ name: 'Document' });
      },
    },
    computed: {
      noTemplatesLoaded() {
        return this.templates?.length == 0;
      },
      existTemplates() {
        return !!this.templates && this.templates.length > 0;
      },
      filteredTemplates() {
        if (!this.searchQuery || this.searchQuery == '') {
          return this.templates;
        }
        const query = this.searchQuery.toLowerCase();
        return this.templates.filter((template) => {
          return template.name.toLowerCase().includes(query);
        });
      },
    },
  };
</script>

<style scoped>
  .dropdown-container {
    padding: 0 !important;
  }
</style>
