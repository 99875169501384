<template>
  <div class="input-box">
    <!-- Input Label -->
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">{{ label }}</label>

      <!-- Tooltip Icon with tooltip text on hover -->
      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>
    <!-- Input Field -->
    <input
      type="date"
      :id="id"
      v-model="inputValue"
      @input="validateInput"
      :class="[{ invalid: !isValid && !disableValidation }, isHolidayOrSunday ? 'text-red-500 border-red-500' : '']"
      @blur="validateInput"
    />

    <!-- Error Message -->
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  import { isHoliday, isSunday } from '@/utils';
  import Tooltip from '@/components/Tooltip';
  export default {
    name: 'DateInput',
    components: {
      Tooltip,
    },
    props: {
      label: String,
      id: String,
      value: String, // Bind value prop to display default value
      disableValidation: Boolean,
      historyShow: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        default: '',
      },
    },
    setup() {
      return {
        isHoliday,
        isSunday,
      };
    },
    data() {
      return {
        inputValue: this.value,
        isValid: true,
        isDirty: false,
        isHolidayOrSunday: false, // Flag to track if the date is a holiday or Sunday
      };
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue;
      },
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        // Basic validation rule: Check if input value is not empty
        this.isValid = this.inputValue !== '';
        this.checkHolidayOrSunday(); // Check if the input value is a holiday or Sunday
        this.$emit('update:modelValue', this.inputValue);
      },
      checkHolidayOrSunday() {
        // Convert input value to Date object using EST timezone
        const date = new Date(this.inputValue + 'T00:00:00-05:00');

        if (!isNaN(date.getTime())) {
          // Ensure the date is valid
          const isHolidayCheck = isHoliday(date);

          const isSundayCheck = date.getUTCDay() === 0; // Use UTC day to avoid timezone shifts

          this.isHolidayOrSunday = isHolidayCheck || isSundayCheck;
        } else {
          this.isHolidayOrSunday = false;
        }
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
        return '';
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }

  .text-red-500 {
    color: #ef4444;
  }

  .border-red-500 {
    border-color: #ef4444;
  }
</style>
