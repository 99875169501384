<template>
  <Card title="Closing & Settlement">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <InputField
          label="Trustee for Deed of Trust"
          id="trusteeForDeedOfTrust"
          :value="formData.trusteeForDeedOfTrust"
          v-model.trim="formData.trusteeForDeedOfTrust"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
        />
      </div>
      <div>
        <InputField
          label="Settlement Agent Case #"
          id="settlementAgentCase"
          :value="formData.settlementAgentCase"
          v-model.trim="formData.settlementAgentCase"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
        />
      </div>
      <div>
        <SelectInput label="Settlement Agent" id="settlementAgent" :options="settlementAgentsOptions" v-model="formData.settlementAgent" :value="formData.settlementAgent" />
      </div>
      <div class="flex justify-end items-end gap-2">
        <Button variant="primary" :disabled="!writePermission" @click="handleObtainMin" class="w-fit" style="width: fit-content">Obtain MIN</Button>
        <Button :disabled="!formData.min?.length || !writePermission" variant="primary" @click="handleRegisterMin" class="w-fit" style="width: fit-content">Register MIN</Button>
      </div>
    </div>
    <div>
      <h3 class="mt-5 text-lg font-semibold">Dates</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
        <div>
          <DateInput
            label="RESPA Application Date"
            id="respaApplicationDate"
            v-model="formData.respaApplicationDate"
            :value="formData.respaApplicationDate"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
        <div>
          <DateInput
            label="Application Date"
            id="applicationDate"
            v-model="formData.applicationDate"
            :value="formData.applicationDate"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
          <span v-if="!formData.applicationDate && validationFormErrors.applicationDate" class="text-sm text-red">
            {{ validationFormErrors.applicationDate }}
          </span>
        </div>
        <div>
          <DateInput
            label="Case # Date"
            id="caseDate"
            v-model="formData.caseAssignedDate"
            :value="formData.caseAssignedDate"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
        <div>
          <DateInput
            label="Closing Date"
            id="closingDate"
            v-model="formData.closingDate"
            :value="formData.closingDate"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
          <span v-if="!formData.closingDate && validationFormErrors.closingDate" class="text-sm text-red">
            {{ validationFormErrors.closingDate }}
          </span>
        </div>
        <div>
          <DateInput
            label="Re-Disclosure Date"
            id="reDisclosureDate"
            v-model="formData.reDisclosureDate"
            :value="formData.reDisclosureDate"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
        <div>
          <DateInput
            label="Funding Date"
            id="fundingDate"
            v-model="formData.fundingDate"
            :value="formData.fundingDate"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
          <span v-if="!formData.fundingDate && validationFormErrors.fundingDate" class="text-sm text-red">
            {{ validationFormErrors.fundingDate }}
          </span>
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <Button variant="secondary" :disabled="!writePermission" @click="handleCalcFundDate" class="w-fit" style="width: fit-content"
          >Calculate Funding Date based on Closing Date</Button
        >
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
        <div>
          <InputField
            label="MIN #:"
            id="min"
            :value="formData.min"
            v-model.trim="formData.min"
            :disable-validation="true"
            :disabled="true"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
        <div>
          <InputField
            label="Signature Line"
            id="signatureLine"
            :value="formData.signatureLine"
            v-model.trim="formData.signatureLine"
            :disable-validation="true"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
      </div>
      <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
        <div>
          <TextareaInput
            label="Notary Acknowledgement"
            id="notaryAcknowledgement"
            :value="formData.notaryAcknowledgement"
            v-model.trim="formData.notaryAcknowledgement"
            :disable-validation="true"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
        <div>
          <InputField
            label="Legal Vesting"
            id="legalVesting"
            :value="formData.legalVesting"
            v-model.trim="formData.legalVesting"
            :disable-validation="true"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
        <div>
          <SelectInput
            label="Living Trust"
            id="livingTrust"
            :options="livingTrustOptions"
            v-model="formData.livingTrust"
            :value="formData.livingTrust"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
          />
        </div>
      </div>
      <div class="flex justify-end mt-5 gap-4">
        <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
        <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
      </div>
    </div>
  </Card>
  <!-- History Dialog -->
  <HistoryDialog ref="historyDialog" />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Button from '@/components/Button/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import HistoryDialog from '../HistoryDialog/index.vue';

  const initialData = {
    trusteeForDeedOfTrust: '',
    settlementAgentCase: '',
    settlementAgent: null,
    respaApplicationDate: null,
    applicationDate: null,
    cellDate: null,
    closingDate: null,
    reDisclosureDate: null,
    fundingDate: null,
    min: '',
    signatureLine: '',
    notaryAcknowledgement: '',
    legalVesting: '',
    livingTrust: null,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Closing',
    components: {
      Card,
      InputField,
      Button,
      DateInput,
      TextareaInput,
      SelectInput,
      HistoryDialog,
    },
    props: {
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchClosingSettlement();
      this.fetchSettlementAgents();
    },
    data() {
      return {
        loanId: this.$route.params.id,
        isDraft: false,
        formData: { ...initialData },
        localData: { ...initialData },
        settlementAgentsOptions: [],
        livingTrustOptions: [
          { label: 'Sample Legal Vesting', value: 'Sample Legal Vesting' },
          { label: 'HECM for Purchase', value: 'HECM for Purchase' },
        ],
        validationFormErrors: {
          closingDate: '',
          fundingDate: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchClosingSettlement() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/closing_settlements/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) {
            this.localData = {
              ...this.localData,
              ...response.data[0],
            };
          } else {
            this.resetLocalData();
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchSettlementAgents() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/settlement-agents/`);
          this.settlementAgentsOptions = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong while fetching settlement agents.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleCalcFundDate() {
        this.setLoading(true);
        try {
          const response = await apiService.post('/loan/calculate-funding-date/', {
            closing_date: this.formData.closingDate,
          });
          this.formData.fundingDate = response.data.fundingDate;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Validate form data: Closing and Funding date are required.
      validateForm() {
        this.validationFormErrors = {
          // applicationDate: !this.formData.applicationDate ? 'This field is required.' : '',
          closingDate: !this.formData.closingDate ? 'This field is required.' : '',
          fundingDate: !this.formData.fundingDate ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.closingDate && !this.validationFormErrors.fundingDate;
      },
      async handleSaveChanges() {
        if (!this.validateForm()) return;
        try {
          this.setLoading(true);
          if (this.localData.id)
            await apiService.patch(`/loan/closing_settlements/${this.localData.id}/?loan=${this.$route.params.id}`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/closing_settlements/`, {
              ...this.formData,
              loan: this.loanId,
            });
          this.$root.showSnackbar(`Closing settlement information has been updated successfully!`, 'success');

          await this.fetchClosingSettlement();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
        this.resetValidationErrors();
      },
      resetLocalData() {
        this.localData = { ...initialData };
      },
      resetValidationErrors() {
        this.validationFormErrors = {
          closingDate: '',
          fundingDate: '',
        };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      async handleObtainMin() {
        this.setLoading(true);
        try {
          const response = await apiService.post(`/loan/calculate-min/`, {
            loan: this.loanId,
          });
          this.formData.min = response.data.MIN;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.response?.data?.error ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleRegisterMin() {
        this.setLoading(true);
        try {
          await apiService.post('/loan/mers-registration/', {
            loan_id: this.loanId,
          });
          this.$root.showSnackbar(`MIN has been registered successfully!`, 'success');
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.response?.data?.error ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openHistoryDialog({ id }) {
        this.$refs.historyDialog.openHistoryDialog(id, 'ClosingSettlement');
      },
    },
    watch: {
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
