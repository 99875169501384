<template>
  <div class="input-box">
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">
        {{ label }}
      </label>

      <!-- Tooltip Icon with tooltip text on hover -->
      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>

    <input
      :id="id"
      :type="type"
      :value="internalValue"
      :maxlength="maxLength"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="handleInput"
      :class="[{ invalid: !isValid && !disableValidation }, disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : '']"
      @keypress="handleKeyPress"
      @change="handleOnChange"
    />

    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  import Tooltip from '@/components/Tooltip';
  export default {
    name: 'InputField',
    components: {
      Tooltip,
    },
    props: {
      label: String,
      type: {
        type: String,
        default: 'text',
      },
      id: String,
      value: [String, Number],
      disableValidation: Boolean,
      disabled: {
        type: Boolean,
        default: false,
      },
      maxLength: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: '',
      },
      onKeyPressed: {
        type: Function,
        required: false,
        default: () => {},
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
      onBlur: {
        type: Function,
        required: false,
        default: () => {},
      },
      tooltip: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        internalValue: this.value || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput() {
        this.isDirty = true;
        this.isValid = this.internalValue !== '';
      },
      handleInput(event) {
        this.internalValue = event.target.value;
        this.$emit('update:modelValue', this.internalValue);
        this.validateInput();
      },
      handleKeyPress(event) {
        this.$emit('onKeyPressed', event.key);
      },
      handleOnChange(event) {
        this.$emit('onChange', event.key);
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
    },
    watch: {
      value(newValue) {
        this.internalValue = newValue;
      },
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
      },
    },
  };
</script>

<style>
  .invalid {
    border-color: red;
  }
</style>
