<template>
  <Card title="Licenses">
    <template v-slot:action>
      <div class="flex items-center">
        <input v-model="searchQuery" class="search-input flex-grow mr-[15px]" placeholder="Search ..." />
        <div>
          <button v-if="permissionToCreateLicense" class="standard-btn whitespace-nowrap" @click="openAddLicenseDialog">Add License</button>
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="filteredTableData" @action="handleAction" />
  </Card>
  <!-- Add/Edit License Dialog -->
  <Dialog :title="isEditMode ? 'Edit License' : 'Add New License'" :isOpen="isLicenseDialogOpen" @confirm="handleConfirmLicenseDialog" @close="handleCloseLicenseDialog">
    <form @submit.prevent="handleConfirmLicenseDialog">
      <div class="form-group">
        <SelectInput label="User *" id="user" :options="userOptions" v-model="licenseForm.user" :value="licenseForm.user" :validation="{ required: true }" />
        <span v-if="!licenseForm.user && validationErrors.user" class="text-sm text-red">{{ validationErrors.user }}</span>
      </div>
      <div class="form-group">
        <SelectInput label="State *" id="state" :options="stateOptions" v-model="licenseForm.state" :value="licenseForm.state" :validation="{ required: true }" />
        <span v-if="!licenseForm.state && validationErrors.state" class="text-sm text-red">{{ validationErrors.state }}</span>
      </div>
      <div class="form-group">
        <RadioInput label="Is License Active?" id="status" :options="radioInputOptions" v-model="licenseForm.status" :value="licenseForm.status" />
      </div>
      <div class="form-group">
        <DateInput label="Expiration Date *" id="expiration_date" v-model="licenseForm.expirationDate" :value="licenseForm.expirationDate" :validation="{ required: true }" />
        <span v-if="!licenseForm.expirationDate && validationErrors.expirationDate" class="text-sm text-red">{{ validationErrors.expirationDate }}</span>
      </div>
      <div class="form-group">
        <InputField label="License No *" id="license-no" v-model="licenseForm.licenseNo" :value="licenseForm.licenseNo" :disable-validation="true" />
        <span v-if="!licenseForm.licenseNo && validationErrors.licenseNo" class="text-sm text-red">{{ validationErrors.licenseNo }}</span>
      </div>
      <div class="form-actions">
        <Button type="submit">{{ isEditMode ? 'Save Changes' : 'Add License' }}</Button>
      </div>
    </form>
  </Dialog>
  <!-- Add Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this product?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import { US_STATES } from '@/constants/index.js';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Licenses',
    components: {
      // PageHeader,
      Button,
      DataTable,
      DateInput,
      RadioInput,
      Dialog,
      Card,
      Confirmation,
      InputField,
      SelectInput,
    },
    data() {
      return {
        isLicenseDialogOpen: false,
        isDeleteConfirmationOpen: false,
        isEditMode: false,
        stateOptions: US_STATES,
        userOptions: [],
        permissions: {
          'Manage own licenses': {
            read: true,
            write: true,
          },
          "Manage other users' licenses": {
            read: true,
            write: true,
          },
        },
        licenseForm: {
          user: '',
          state: '',
          status: false,
          expirationDate: '',
          licenseNo: '',
        },
        validationErrors: {
          user: '',
          state: '',
          expirationDate: '',
          licenseNo: '',
        },
        headers: [
          { key: 'userName', label: 'User' },
          { key: 'state', label: 'State' },
          { key: 'status', label: 'Status' },
          { key: 'expirationDate', label: 'Expiration Date' },
          { key: 'licenseNo', label: 'License No' },
        ],
        tableData: [],
        searchQuery: '',
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) {
          return this.tableData;
        }
        const query = this.searchQuery.toLowerCase();
        return this.tableData.filter((license) => {
          return (
            license.state.toLowerCase().includes(query) ||
            license.status.toLowerCase().includes(query) ||
            license.expirationDate.toLowerCase().includes(query) ||
            license.licenseNo.toLowerCase().includes(query) ||
            license.user.toLowerCase().includes(query)
          );
        });
      },
      permissionToCreateLicense() {
        return this.permissions['Manage own licenses'].write || this.permissions["Manage other users' licenses"].write;
      },
    },
    async mounted() {
      await this.fetchUsers();
      await this.setPermissions();
      this.fetchLicenses();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchLicenses() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/licenses/');
          this.tableData = response.data.map((license, key) => ({
            id: key + 1,
            dataId: license.id,
            userName: license.user_name,
            user: license.user,
            state: license.LoanOfficerState,
            active: license.LicenseIsActive,
            expirationDate: license.LicenseExpirationDate,
            status: license.LicenseIsActive ? 'Active' : 'InActive',
            licenseNo: license.LoanOfficerLicense,
            actions:
              (this.permissions['Manage own licenses'].write && license.own_license) || (this.permissions["Manage other users' licenses"].write && !license.own_license)
                ? ['edit', 'delete']
                : [],
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch licenses: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchUsers() {
        try {
          const response = await apiService.get('/loan/licenses/license-users/');
          if (response.status !== 200) {
            throw new Error('Failed to fetch users');
          }
          this.userOptions = response.data.map((user) => ({
            label: user.full_name,
            value: user.id,
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch users: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async setPermissions() {
        try {
          const response = await apiService.get('/loan/licenses/permissions/');
          if (response.status !== 200) {
            throw new Error('Failed to fetch permissions');
          }
          this.permissions = {
            ...this.permissions,
            ...response.data.permissions,
          };
          if (this.permissions['Manage own licenses'].write || this.permissions["Manage other users' licenses"].write) {
            this.headers.push({ key: 'action', label: 'Action' });
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch permissions: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      validateForm() {
        this.validationErrors = {
          state: !this.licenseForm.state ? 'State is required' : '',
          expirationDate: !this.licenseForm.expirationDate ? 'Expiration Date is required' : '',
          licenseNo: !this.licenseForm.licenseNo ? 'License No is required' : '',
        };

        return !this.validationErrors.state && !this.validationErrors.expirationDate && !this.validationErrors.licenseNo;
      },
      openAddLicenseDialog() {
        this.resetForm();
        this.isEditMode = false;
        this.isLicenseDialogOpen = true;
      },
      openEditLicenseDialog(license) {
        this.selectedLicense = license;
        this.licenseForm = {
          ...license,
          expirationDate: license.expirationDate,
          status: license.status == 'Active' ? true : false,
        };
        this.isEditMode = true;
        this.isLicenseDialogOpen = true;
      },
      resetForm() {
        this.licenseForm = {
          state: '',
          status: false,
          expirationDate: '',
          licenseNo: '',
          user: '',
        };
        this.validationErrors = {
          user: '',
          state: '',
          expirationDate: '',
          licenseNo: '',
        };
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditLicenseDialog(item);
        } else if (action === 'delete') {
          this.selectedLicense = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmLicenseDialog() {
        if (!this.validateForm()) {
          return;
        }
        if (this.isEditMode) {
          this.setLoading(true);
          const updatedLicense = {
            LicenseExpirationDate: this.licenseForm.expirationDate,
            LicenseIsActive: this.licenseForm.status,
            LoanOfficerLicense: this.licenseForm.licenseNo,
            LoanOfficerState: this.licenseForm.state,
            user: this.licenseForm.user,
          };
          try {
            await apiService.put(`/loan/licenses/${this.selectedLicense.dataId}/`, updatedLicense);
            await this.fetchLicenses();
            this.$root.showSnackbar('License has been updated successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to update license: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        } else {
          this.setLoading(true);
          // Add the new license
          const newLicense = {
            LicenseExpirationDate: this.licenseForm.expirationDate,
            LicenseIsActive: this.licenseForm.status,
            LoanOfficerLicense: this.licenseForm.licenseNo,
            LoanOfficerState: this.licenseForm.state,
            user: this.licenseForm.user,
          };
          try {
            await apiService.post('/loan/licenses/', newLicense);
            await this.fetchLicenses();
            this.$root.showSnackbar('License has been created successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to create license: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
        this.isLicenseDialogOpen = false;
        this.resetForm();
      },
      handleCloseLicenseDialog() {
        this.isLicenseDialogOpen = false;
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          if (this.selectedLicense && this.selectedLicense.dataId) {
            await apiService.delete(`/loan/licenses/${this.selectedLicense.dataId}/`);
            await this.fetchLicenses();
            this.$root.showSnackbar('License deleted successfully', 'success');
          } else {
            throw new Error('No license selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete license: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
          this.isDeleteConfirmationOpen = false;
          this.selectedLicense = null;
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    box-sizing: border-box;
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
  }

  .inline-block {
    display: flex;
    align-items: center;
  }

  .inline-block label {
    margin: 0;
    margin-right: 10px;
  }
</style>
