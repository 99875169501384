<template>
  <div v-if="visible" class="snackbar warning">
    <div class="warning-content">
      <slot>
        <div v-if="Array.isArray(message)">
          <div v-if="message.length === 1">{{ message[0] }}</div>
          <ul v-else>
            <li v-for="(msg, index) in message" :key="index">{{ msg }}</li>
          </ul>
        </div>
        <div v-else>{{ message }}</div>
      </slot>
    </div>
    <button class="dismiss-button" @click.stop="dismiss">×</button>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PersistentWarningMessage',
    inject: ['dismissWarning'],
    data() {
      return {
        visible: false,
        dismissed: false,
        message: null,
        name: null,
      };
    },
    methods: {
      show(message, name = '') {
        this.message = message;
        this.visible = true;
        this.name = name;
      },
      isDismissed() {
        return this.dismissed;
      },
      hide() {
        this.visible = false;
      },
      dismiss() {
        this.visible = false;
        this.dismissed = true;
        this.dismissWarning(this.name);
      },
    },
  };
</script>

<style scoped>
  .snackbar.warning {
    background-color: #fffae6; /* Light yellow */
    color: #665c00; /* Dark yellow text */
    padding: 10px;
    border: 1px solid #ffe58f;
    border-radius: 4px;
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  li {
    margin-top: 5px;
    margin-left: 1.2rem;
    list-style: circle;
  }
  li:first-child {
    margin-top: 0;
  }

  .dismiss-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #665c00;
  }
</style>
