import store from '@/store/index.js';
import { COMPANY_TYPES, USER_HIERARCHIES, STATIC_HOLIDAYS } from '@/constants';

/**
 * Format a number with commas and two decimal places.
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string (e.g., "1,234.56").
 */
export const formatNumberWithCommas = (number, decimalPlaces = 2) => {
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

/**
 * Format a number as a currency string with a dollar sign and commas.
 * @param {number} value - The number to format.
 * @returns {string} - The formatted currency string (e.g., "$1,234.56").
 */
export const formatMoney = (value) => {
  const isNegative = value < 0;
  const formattedValue = formatNumberWithCommas(Math.abs(value));
  return isNegative ? `-$${formattedValue}` : `$${formattedValue}`;
};

/**
 * Format a percentage value as a string with a percentage sign.
 * @param {number} value - The percentage value to format.
 * @returns {string} - The formatted percentage value as a string (e.g., "10.5%").
 */
export const formatPercentage = (value, decimalPlaces = 2) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }
  return `${formatNumberWithCommas(value, decimalPlaces)}%`;
};

/**
 * Get the formatted company type name based on the company type constant.
 * @param {string} type - The type of the company (e.g., "BROKER").
 * @returns {string} - The formatted company type or 'Broker' if the type is not defined.
 */
export const getCompanyTypeFormatted = (type) => {
  return COMPANY_TYPES[type] || 'Broker';
};

/**
 * Check if the current user is a Super User.
 * @returns {boolean} - True if the user is a Super User, false otherwise.
 */
export const isSuperUser = () => {
  const userInfo = store.getters.getUserInfo;
  const hierarchy = userInfo?.hierarchy;
  return hierarchy === USER_HIERARCHIES.SUPER_USER;
};

/**
 * Check if the current user is a Company Administrator.
 * @returns {boolean} - True if the user is a Company Administrator, false otherwise.
 */
export const isCompanyAdmin = () => {
  const userInfo = store.getters.getUserInfo;
  const hierarchy = userInfo?.hierarchy;
  return hierarchy === USER_HIERARCHIES.COMPANY_ADMINISTRATOR;
};

/**
 * Check if the current user is a Standard Conditions Pack Admiminstrator.
 * @returns {boolean} - True if the user is a Standard Conditions Pack Admiminstrator, false otherwise.
 */
export const isStandardConditionsAdmin = () => {
  const userInfo = store.getters.getUserInfo;
  const roles = userInfo?.roles;
  return !isSuperUser() && (isCompanyAdmin() || roles?.includes('Underwriter Manager') || roles?.includes('Underwriter'));
};

/**
 * Check if the current user is a Company User.
 * @returns {boolean} - True if the user is a Company User, false otherwise.
 */
export const isCompanyUser = () => {
  const userInfo = store.getters.getUserInfo;
  const hierarchy = userInfo?.hierarchy;
  return hierarchy === USER_HIERARCHIES.COMPANY_USER;
};

/**
 * Format a date string into 'YYYY-MM-DD' format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date string in 'YYYY-MM-DD' format, or an empty string if the input is invalid.
 */
export const formatDate = (dateString, format = 'YYYY-MM-DD') => {
  if (!dateString) {
    return ''; // Return an empty string if no date is provided
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; // Return an empty string if the date is invalid
  }

  const isoString = date.toISOString();
  const [year, month, day] = isoString.split('T')[0].split('-');
  return format === 'YYYY-MM-DD' ? `${year}-${month}-${day}` : `${month}/${day}/${year}`;
};

/**
 * Format a date string into 'DD/MM/YYYY HH:MM:SS' format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date string in 'DD/MM/YYYY HH:MM:SS' format, or an empty string if the input is invalid.
 */
export const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return ''; // Return an empty string if no date is provided
  }

  const date = new Date(dateTimeString);
  if (isNaN(date.getTime())) {
    return ''; // Return an empty string if the date is invalid
  }

  return date.toLocaleString('en-GB', { timeZone: 'America/New_York', hour12: false }).replace(',', '');
};

/**
 * Calculate the nth occurrence of a specific weekday in a given month and year.
 * @param {number} year - The year.
 * @param {number} month - The month (1-based: January is 1, December is 12).
 * @param {number} weekday - The day of the week (0: Sunday, 1: Monday, ... 6: Saturday).
 * @param {number} n - The occurrence (1st, 2nd, 3rd, etc.).
 * @returns {Date} - The date of the nth occurrence of the given weekday.
 */
function nthWeekdayOfMonth(year, month, weekday, n) {
  const firstDay = new Date(year, month - 1, 1); // JS months are 0-based
  const firstWeekdayDate = new Date(firstDay.setDate(1 + ((weekday - firstDay.getDay() + 7) % 7)));
  return new Date(firstWeekdayDate.setDate(firstWeekdayDate.getDate() + (n - 1) * 7));
}

/**
 * Calculate the date of the last occurrence of a specific weekday in a given month and year.
 * @param {number} year - The year.
 * @param {number} month - The month (1-based: January is 1, December is 12).
 * @param {number} weekday - The day of the week (0: Sunday, 1: Monday, ... 6: Saturday).
 * @returns {Date} - The date of the last weekday of the given month.
 */
function lastWeekdayOfMonth(year, month, weekday) {
  const lastDay = new Date(year, month, 0); // Day 0 of the next month gives us the last day of the current month
  const daysToSubtract = (lastDay.getDay() - weekday + 7) % 7;
  return new Date(lastDay.setDate(lastDay.getDate() - daysToSubtract));
}

/**
 * Get a list of dynamic holidays for a given year.
 * @param {number} year - The year for which to calculate dynamic holidays.
 * @returns {Array<Date>} - Array of dynamic holiday dates.
 */
function getDynamicHolidays(year) {
  return [
    nthWeekdayOfMonth(year, 1, 1, 3), // Martin Luther King Jr. Day (3rd Monday of January)
    nthWeekdayOfMonth(year, 2, 1, 3), // Washington’s Birthday (3rd Monday of February)
    lastWeekdayOfMonth(year, 5, 1), // Memorial Day (Last Monday of May)
    nthWeekdayOfMonth(year, 9, 1, 1), // Labor Day (1st Monday of September)
    nthWeekdayOfMonth(year, 10, 1, 2), // Columbus Day (2nd Monday of October)
    nthWeekdayOfMonth(year, 11, 4, 4), // Thanksgiving Day (4th Thursday of November)
  ];
}

/**
 * Check if a given date is a holiday (static or dynamic).
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is a holiday, false otherwise.
 */
export const isHoliday = (date) => {
  // Check if the date matches any static holiday
  const isStaticHoliday = STATIC_HOLIDAYS.some(([month, day]) => date.getMonth() + 1 === month && date.getDate() === day);

  // Check if the date matches any dynamic holiday
  const isDynamicHoliday = getDynamicHolidays(date.getFullYear()).some(
    (holiday) => date.getFullYear() === holiday.getFullYear() && date.getMonth() === holiday.getMonth() && date.getDate() === holiday.getDate()
  );

  return isStaticHoliday || isDynamicHoliday;
};

/**
 * Check if a given date is a Sunday.
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is a Sunday, false otherwise.
 */
export const isSunday = (date) => {
  return date.getDay() === 0; // In JavaScript, Sunday is represented by 0
};
