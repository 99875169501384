<template>
  <Card title="Loan Products">
    <template v-slot:action>
      <Button @click="openAddDialog">Add Product</Button>
    </template>
    <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
  </Card>
  <!-- add or edit loan product -->
  <Dialog :title="`${isOpenDialog} Loan Product`" :isOpen="isOpenDialog != ''" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
      <div class="form-group">
        <InputField label="Name *" :maxLength="50" :value="formData.name" v-model="formData.name" :disable-validation="true" />
        <span v-if="validationFormErrors.name.length" class="text-sm text-red">
          {{ validationFormErrors.name }}
        </span>
      </div>
      <div class="form-group">
        <SelectInput label="Type" :value="formData.type" v-model="formData.type" :options="loanProductTypeOptions" />
      </div>
      <div class="form-group" v-if="formData.type === LOAN_PRODUCT_TYPES.HECM">
        <SelectInput label="Interest Rate Type" :value="formData.interestRateType" v-model="formData.interestRateType" :options="interestRateTypeOptions" />
        <span v-if="validationFormErrors.interestRateType.length" class="text-sm text-red">
          {{ validationFormErrors.interestRateType }}
        </span>
      </div>
      <div class="form-group" v-if="formData.interestRateType !== 'fixed' && formData.type === LOAN_PRODUCT_TYPES.HECM">
        <SelectInput label="Initial Index Name" :value="formData.initialIndexName" v-model="formData.initialIndexName" :options="indexNameOptions" />
        <span v-if="validationFormErrors.initialIndexName.length" class="text-sm text-red">
          {{ validationFormErrors.initialIndexName }}
        </span>
      </div>
      <div class="form-group" v-if="formData.interestRateType !== 'fixed' && formData.type === LOAN_PRODUCT_TYPES.HECM">
        <SelectInput label="Expected Index Name" :value="formData.expectedIndexName" v-model="formData.expectedIndexName" :options="indexNameOptions" />
        <span v-if="validationFormErrors.expectedIndexName.length" class="text-sm text-red">
          {{ validationFormErrors.expectedIndexName }}
        </span>
      </div>
      <div class="form-group" v-if="formData.interestRateType === 'fixed' && formData.type === LOAN_PRODUCT_TYPES.HECM">
        <FloatInput label="Fixed rate (%)" :value="formData.fixedRate" v-model="formData.fixedRate" />
        <span v-if="validationFormErrors.fixedRate.length" class="text-sm text-red">
          {{ validationFormErrors.fixedRate }}
        </span>
      </div>
      <div class="form-group">
        <MoneyInput label="Lending Limit" :value="formData.lendingLimit" v-model="formData.lendingLimit" />
      </div>
      <div class="form-group" v-if="formData.type !== LOAN_PRODUCT_TYPES.HECM">
        <label for="plfCsvFile" class="font-semibold text-[#212121]">Choose PLF CSV</label>
        <input type="file" id="plfCsvFile" @change="onFileChange" accept="text/csv" class="block w-full px-3 py-2" />
        <div
          v-if="this.formData.plfCsvPath"
          class="cursor-pointer text-sm text-gray-500 overflow-hidden text-ellipsis"
          v-tooltip="{ content: formData.plfCsvPath, triggers: ['hover'], autoHide: true }"
          @click="handleClickFilePath(formData.plfCsvPath)"
        >
          {{ parseFileName(formData.plfCsvPath) }}
        </div>
        <span v-if="validationFormErrors.plfCsvFile.length" class="text-sm text-red">
          {{ validationFormErrors.plfCsvFile }}
        </span>
      </div>
      <div class="form-group" v-if="formData.type === LOAN_PRODUCT_TYPES.HECM">
        <FloatInput label="MIP Rate (%)" :value="formData.mipRate" v-model="formData.mipRate" />
        <span v-if="validationFormErrors.mipRate.length" class="text-sm text-red">
          {{ validationFormErrors.mipRate }}
        </span>
      </div>
      <div class="form-group" v-if="formData.type === LOAN_PRODUCT_TYPES.HECM">
        <MoneyInput label="Monthly Servicing Fee (0 ~ 30)" :value="formData.monthlyServicingFee" v-model="formData.monthlyServicingFee" />
        <span v-if="validationFormErrors.monthlyServicingFee.length" class="text-sm text-red">
          {{ validationFormErrors.monthlyServicingFee }}
        </span>
      </div>
      <div class="form-group" v-if="formData.interestRateType !== 'fixed' && formData.type === LOAN_PRODUCT_TYPES.HECM">
        <FloatInput label="Interest Rate Cap (%)" :value="formData.interestRateCap" v-model="formData.interestRateCap" />
      </div>
    </div>
    <div>
      <div class="form-group">
        <CheckboxInput label="Will be used for calculation as default?" :value="formData.default" v-model="formData.default" />
      </div>
    </div>
    <div>
      <div class="form-group">
        <CheckboxInput label="Equity Share?" :value="formData.equityShare" v-model="formData.equityShare" />
      </div>
    </div>
    <div>
      <div class="form-group">
        <CheckboxInput label="Other Special Features?" :value="formData.otherSpecialFeatures" v-model="formData.otherSpecialFeatures" />
      </div>
    </div>
    <div v-if="formData.otherSpecialFeatures">
      <div class="form-group">
        <TextareaInput
          label="Other Special Features Description"
          :value="formData.otherSpecialFeaturesDescription"
          v-model="formData.otherSpecialFeaturesDescription"
          :disable-validation="true"
        />
        <span v-if="validationFormErrors.otherSpecialFeaturesDescription.length" class="text-sm text-red">
          {{ validationFormErrors.otherSpecialFeaturesDescription }}
        </span>
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300"></div>
    <div class="flex justify-end gap-2 mt-2">
      <Button variant="primary" @click="handleConfirmDialog">Save Product</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
  <!-- Delete Product Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this product?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import FloatInput from '@/components/FormInput/FloatInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import { INDEX_NAMES, INTEREST_RATE_TYPES, INTEREST_RATE_TYPE_OPTIONS, INDEX_NAMES_OPTIONS, LOAN_PRODUCT_TYPES, LOAN_PRODUCT_TYPE_OPTIONS } from '@/constants';
  import { formatNumberWithCommas } from '@/utils/index.js';
  import Confirmation from '@/components/Confirmation/index.vue';

  const initialData = {
    name: '',
    type: LOAN_PRODUCT_TYPES.HECM,
    interestRateType: 'fixed',
    initialIndexName: '1_month',
    expectedIndexName: '1_month',
    lendingLimit: 0,
    fixedRate: 0,
    mipRate: 0,
    monthlyServicingFee: 0,
    interestRateCap: 0,
    plfCsvFile: null,
    plfCsvPath: null,
    default: false,
    equityShare: false,
    otherSpecialFeatures: false,
    otherSpecialFeaturesDescription: '',
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LoanProducts',
    components: {
      Button,
      Card,
      CheckboxInput,
      DataTable,
      Dialog,
      InputField,
      SelectInput,
      MoneyInput,
      FloatInput,
      Confirmation,
      TextareaInput,
    },
    setup() {
      return {
        formatNumberWithCommas,
        LOAN_PRODUCT_TYPES,
      };
    },
    data() {
      return {
        tableHeaders: [
          { key: 'name', label: 'Name' },
          { key: 'type', label: 'Type' },
          { key: 'interestRateType', label: 'Interest Rate Type' },
          { key: 'fixedRate', label: 'Fixed Rate' },
          { key: 'initialIndexName', label: 'Initial Index Name' },
          { key: 'expectedIndexName', label: 'Expected Index Name' },
          { key: 'lendingLimit', label: 'Lending Limit' },
          { key: 'mipRate', label: 'MIP Rate' },
          { key: 'monthlyServicingFee', label: 'Monthly Servicing Fee' },
          { key: 'interestRateCap', label: 'Interest Rate Cap' },
          { key: 'default', label: 'Default Use for Calculation?' },
          { key: 'equityShare', label: 'Equity Share?' },
          { key: 'otherSpecialFeatures', label: 'Other Special Features?' },
          { key: 'otherSpecialFeaturesDescription', label: 'Other Special Features Description' },
          { key: 'action', label: 'Action' },
        ],
        products: [],
        tableData: [],
        isOpenDialog: '',
        interestRateTypeOptions: INTEREST_RATE_TYPE_OPTIONS,
        indexNameOptions: INDEX_NAMES_OPTIONS,
        loanProductTypeOptions: LOAN_PRODUCT_TYPE_OPTIONS,
        formData: {},
        validationFormErrors: {
          name: '',
          interestRateType: '',
          initialIndexName: '',
          expectedIndexName: '',
          mipRate: '',
          fixedRate: '',
          interestRateCap: '',
          monthlyServicingFee: '',
          plfCsvFile: '',
          otherSpecialFeaturesDescription: '',
        },
        selectedProduct: null,
        isDeleteConfirmationOpen: false,
      };
    },
    async created() {
      await this.fetchProducts();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchProducts() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/products/`);
          this.products = response.data;
          this.updateTableData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openAddDialog() {
        this.formData = { ...initialData };
        this.isOpenDialog = 'Add';
      },
      openEditDialog(id) {
        const item = this.products.find((el) => el.id == id);
        console.log(item);
        this.formData = { ...initialData, ...item };
        this.isOpenDialog = 'Edit';
      },
      validateForm() {
        this.validationFormErrors = {
          // name
          name: !this.formData.name?.length ? 'This field is required.' : '',
          // monthlyServicingFee
          monthlyServicingFee:
            this.formData.type === LOAN_PRODUCT_TYPES.HECM &&
            (!(Number(this.formData.monthlyServicingFee) >= 0 && Number(this.formData.monthlyServicingFee) <= 30) || this.formData.monthlyServicingFee === null)
              ? 'This field must be in the range of 0 to 30.'
              : '',
          // interestRateType
          interestRateType: this.formData.type === LOAN_PRODUCT_TYPES.HECM && !this.formData.interestRateType?.length ? 'This field is required.' : '',
          // fixedRate
          fixedRate:
            this.formData.type === LOAN_PRODUCT_TYPES.HECM && this.formData.interestRateType === 'fixed' && this.formData.fixedRate === null ? 'This field is required.' : '',
          // initialIndexName
          initialIndexName:
            this.formData.type === LOAN_PRODUCT_TYPES.HECM && this.formData.interestRateType !== 'fixed' && !this.formData.initialIndexName?.length
              ? 'This field is required.'
              : '',
          // expectedIndexName
          expectedIndexName:
            this.formData.type === LOAN_PRODUCT_TYPES.HECM && this.formData.interestRateType !== 'fixed' && !this.formData.expectedIndexName?.length
              ? 'This field is required.'
              : '',
          // interestRateCap
          interestRateCap:
            this.formData.type === LOAN_PRODUCT_TYPES.HECM && this.formData.interestRateType !== 'fixed' && !this.formData.interestRateCap?.length ? 'This field is required.' : '',
          // mipRate
          mipRate: this.formData.type === LOAN_PRODUCT_TYPES.HECM && this.formData.mipRate === null ? 'This field is required.' : '',
          // otherSpecialFeaturesDescription
          otherSpecialFeaturesDescription: this.formData.otherSpecialFeatures && !this.formData.otherSpecialFeaturesDescription?.length ? 'This field is required.' : '',
        };

        // Add case when change type
        if (!this.formData.id) {
          // plfCsvFile
          this.validationFormErrors.plfCsvFile = this.formData.type !== LOAN_PRODUCT_TYPES.HECM && !this.formData.plfCsvFile ? 'This field is required.' : '';
        }

        // Edit case when change type
        if (this.formData.id) {
          // plfCsvFile
          this.validationFormErrors.plfCsvFile =
            this.formData.type === LOAN_PRODUCT_TYPES.HOME_FOR_LIFE && !this.formData.plfCsvPath && !this.formData.plfCsvFile ? 'This field is required.' : '';
        }

        return (
          !this.validationFormErrors.name.length &&
          !this.validationFormErrors.monthlyServicingFee.length &&
          !this.validationFormErrors.interestRateType.length &&
          !this.validationFormErrors.fixedRate.length &&
          !this.validationFormErrors.initialIndexName.length &&
          !this.validationFormErrors.expectedIndexName.length &&
          !this.validationFormErrors.interestRateCap.length &&
          !this.validationFormErrors.mipRate.length &&
          !this.validationFormErrors.plfCsvFile.length &&
          !this.validationFormErrors.otherSpecialFeaturesDescription.length
        );
      },
      async handleConfirmDialog() {
        if (!this.validateForm()) return;

        const data = { ...this.formData };

        if (data.type === LOAN_PRODUCT_TYPES.HECM) {
          if (data.interestRateType === 'fixed') {
            data.initialIndexName = null;
            data.expectedIndexName = null;
            data.interestRateCap = null;
          } else {
            data.fixedRate = null;
          }
          // PLF CSV file is not required for HECM
          data.plfCsvFile = null;
          data.plfCsvPath = null;
        } else {
          // Home for Life, only lending limit is required
          data.interestRateType = null;
          data.fixedRate = null;
          data.initialIndexName = null;
          data.expectedIndexName = null;
          data.interestRateCap = null;
          data.mipRate = null;
          data.monthlyServicingFee = null;
        }

        if (!data.otherSpecialFeatures) {
          data.otherSpecialFeaturesDescription = '';
        }

        this.setLoading(true);
        try {
          if (data.id)
            await apiService.patch(
              `/loan/products/${data.id}/`,
              { ...data },
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
          else
            await apiService.post(
              `/loan/products/`,
              {
                ...data,
              },
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
          this.$root.showSnackbar(`Loan product information has been updated successfully!`, 'success');
          await this.fetchProducts();
          this.handleCloseDialog();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.isOpenDialog = '';
        this.resetForm();
      },
      updateTableData() {
        this.tableData = this.products.map((product) => ({
          ...product,
          type: LOAN_PRODUCT_TYPES[product.type],
          fixedRate: product.fixedRate && product.type === LOAN_PRODUCT_TYPES.HECM ? `${product.fixedRate}%` : 'Not Applicable',
          interestRateType: INTEREST_RATE_TYPES[product.interestRateType ?? 'not_applicable'],
          initialIndexName: INDEX_NAMES[product.initialIndexName ?? 'not_applicable'],
          expectedIndexName: INDEX_NAMES[product.expectedIndexName ?? 'not_applicable'],
          lendingLimit: `$${formatNumberWithCommas(product.lendingLimit)}`,
          mipRate: product.mipRate && product.type === LOAN_PRODUCT_TYPES.HECM ? `${product.mipRate}%` : 'Not Applicable',
          monthlyServicingFee:
            product.monthlyServicingFee && product.type === LOAN_PRODUCT_TYPES.HECM ? `$${formatNumberWithCommas(product.monthlyServicingFee)}` : 'Not Applicable',
          interestRateCap: product.interestRateCap && product.type === LOAN_PRODUCT_TYPES.HECM ? `${formatNumberWithCommas(product.interestRateCap)}%` : 'Not Applicable',
          equityShare: product.equityShare ? true : false,
          otherSpecialFeatures: product.otherSpecialFeatures ? true : false,
          otherSpecialFeaturesDescription: product.otherSpecialFeaturesDescription,
          actions: ['edit', 'delete'],
        }));
      },
      async handleAction(action, item) {
        if (action === 'edit') {
          this.openEditDialog(item.id);
        } else if (action === 'delete') {
          this.selectedProduct = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/loan/products/${this.selectedProduct.id}/`);
          this.$root.showSnackbar('Loan product information has been deleted successfully', 'success');
          await this.fetchProducts();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete loan product information: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      resetForm() {
        this.formData = { ...initialData };
        this.validationFormErrors = {
          name: '',
          monthlyServicingFee: '',
          interestRateType: '',
          fixedRate: '',
          initialIndexName: '',
          expectedIndexName: '',
          interestRateCap: '',
          mipRate: '',
          plfCsvFile: '',
          otherSpecialFeaturesDescription: '',
        };
      },
      onFileChange(event) {
        const file = event.target.files[0];
        this.formData.plfCsvFile = file;
      },
      parseFileName(path) {
        // Extract the part after 'plf_csv_files/'
        const regex = /plf_csv_files\/[a-f0-9-]+-(.*)/;
        const match = path.match(regex);
        if (match && match[1]) {
          return match[1];
        }
        return null;
      },
      handleClickFilePath(path) {
        navigator.clipboard
          .writeText(path)
          .then(() => {
            this.$root.showSnackbar('File path copied to clipboard', 'success');
          })
          .catch((err) => {
            this.$root.showSnackbar(`Failed to copy file path: ${err.message}`, 'error');
          });
      },
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }
</style>
