<template>
  <Card title="FHA Case Number Details">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5 border-b border-gray mb-5">
      <div>
        <SelectInput
          label="Loan Type:"
          id="loanType"
          :options="loanTypeOptions"
          v-model="formData.loanType"
          :value="formData.loanType"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
        />
      </div>
    </div>
    <!-- Show below fields when only loan type is HECM to HECM -->
    <div v-if="formData.loanType === 'HECM to HECM'" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 pb-5 border-b border-gray mb-5">
      <div>
        <CaseNumberInput
          label="Previous FHA Case Number"
          id="prevFhaCaseNumber"
          ref="prevFhaCaseNumber"
          :value="formData.prevFhaCaseNumber"
          v-model.trim="formData.prevFhaCaseNumber"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The case number of any prior FHA HECM loan on the property. Applies to HECM to HECM transactions only."
        />
      </div>
      <div>
        <MoneyInput
          label="Prior IMIP Paid"
          id="priorImipPaid"
          ref="priorImipPaid"
          :value="formData.priorImipPaid"
          v-model.trim="formData.priorImipPaid"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The initial mortgage insurance premium paid on a prior FHA HECM loan. Applies to HECM to HECM transactions only."
        />
      </div>
      <div>
        <MoneyInput
          label="Prior Max Claim Amount"
          id="priorMaxClaimAmount"
          ref="priorMaxClaimAmount"
          :value="formData.priorMaxClaimAmount"
          v-model.trim="formData.priorMaxClaimAmount"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The maximum claim amount on a previous FHA loan. Applies to HECM to HECM transactions only."
        />
      </div>
      <div>
        <MoneyInput
          label="Principal Limit"
          id="principalLimit"
          ref="principalLimit"
          :value="formData.principalLimit"
          v-model.trim="formData.principalLimit"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The principal limit of the previous FHA loan. Applies to HECM to HECM transactions only."
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <CaseNumberInput
          label="FHA Case Number"
          id="fhaCaseNumber"
          ref="fhaCaseNumber"
          :value="formData.fhaCaseNumber"
          v-model.trim="formData.fhaCaseNumber"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The case number for the current FHA loan application."
        />
      </div>
      <div>
        <DateInput
          label="Date Case # Assigned"
          id="caseAssignedDate"
          ref="caseAssignedDate"
          :value="formatDate(formData.caseAssignedDate)"
          v-model.trim="formData.caseAssignedDate"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The date the FHA case number was assigned to this loan."
        />
      </div>
      <div>
        <DateInput
          label="Date Sent to Counseling"
          id="sentToCounselingDate"
          ref="sentToCounselingDate"
          :value="formatDate(formData.sentToCounselingDate)"
          v-model.trim="formData.sentToCounselingDate"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The date when the borrower was sent for HUD-approved counseling, as required for reverse mortgages."
        />
      </div>
      <div>
        <DateInput
          label="Date of Counseling"
          id="counselingDate"
          ref="counselingDate"
          :value="formatDate(formData.counselingDate)"
          v-model.trim="formData.counselingDate"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The actual date the borrower attended counseling. Required for HECM for Purchase transactions."
        />
      </div>
      <div>
        <DateInput
          label="RESPA Application Date"
          id="respaApplicationDate"
          ref="respaApplicationDate"
          :value="formatDate(formData.respaApplicationDate)"
          v-model.trim="formData.respaApplicationDate"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The date the application was completed according to the Real Estate Settlement Procedures Act."
        />
      </div>
      <div>
        <DateInput
          label="Application Date"
          id="applicationDate"
          ref="applicationDate"
          :value="formatDate(formData.applicationDate)"
          v-model.trim="formData.applicationDate"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          historyShow="true"
          tooltip="The official date of loan application submission."
        />
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Purpose of Loan</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
        <div>
          <CheckboxInput label="Additional Income" id="additionalIncome" :value="formData.additionalIncome" @change="updateField('additionalIncome')" />
        </div>
        <div>
          <CheckboxInput label="Home Improvements" id="homeImprovements" :value="formData.homeImprovements" @change="updateField('homeImprovements')" />
        </div>
        <div>
          <CheckboxInput label="Payment of Taxes" id="paymentOfTaxes" :value="formData.paymentOfTaxes" @change="updateField('paymentOfTaxes')" />
        </div>
        <div>
          <CheckboxInput label="Payment of Insurance" id="paymentInsurance" :value="formData.paymentOfInsurance" @change="updateField('paymentOfInsurance')" />
        </div>
        <div>
          <CheckboxInput label="Leisure" id="leisure" :value="formData.leisure" @change="updateField('leisure')" />
        </div>
        <div>
          <CheckboxInput label="Medical" id="medical" :value="formData.medical" @change="updateField('medical')" />
        </div>
        <div>
          <CheckboxInput
            label="Extinguish Forward Mortgage"
            id="extinguishForwardMortgage"
            :value="formData.extinguishForwardMortgage"
            @change="updateField('extinguishForwardMortgage')"
          />
        </div>
        <div>
          <CheckboxInput label="Other" id="other" :value="formData.other" @change="updateField('other')" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
    </div>
  </Card>
  <div v-if="formData.id">
    <TabCard class="my-15 overflow-x-auto">
      <ul class="tabs flex items-center">
        <li v-for="tabOption in tabOptions" :key="tabOption.title" class="tab" :class="{ active: tabOption.title === activeTab.title }" @click="changeActiveTab(tabOption.title)">
          {{ tabOption.title }}
        </li>
      </ul>
    </TabCard>
    <div class="content-container">
      <component :writePermission="writePermission" :is="activeTabComponent" v-model:caseNumberId="formData.id" />
    </div>
  </div>
  <HistoryDialog ref="historyDialog" />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import CaseNumberInput from '@/components/FormInput/CaseNumberInput/index.vue';
  import RealEstateOwned from './components/RealEstateOwned/index.vue';
  import ExistingFHALoans from './components/ExistingFHALoans/index.vue';
  import HistoryDialog from '../HistoryDialog/index.vue';

  const initialData = {
    loanType: null,
    fhaCaseNumber: null,
    prevFhaCaseNumber: null,
    priorImipPaid: null,
    priorMaxClaimAmount: null,
    principalLimit: null,
    caseAssignedDate: null,
    sentToCounselingDate: null,
    counselingDate: null,
    respaApplicationDate: null,
    applicationDate: null,
    additionalIncome: null,
    homeImprovements: null,
    paymentOfTaxes: null,
    paymentOfInsurance: null,
    leisure: null,
    medical: null,
    extinguishForwardMortgage: null,
    other: null,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'CaseNumber',
    components: {
      Button,
      Card,
      DateInput,
      TabCard,
      InputField,
      MoneyInput,
      SelectInput,
      CheckboxInput,
      CaseNumberInput,
      RealEstateOwned,
      ExistingFHALoans,
      HistoryDialog,
    },
    props: {
      activeTabTitle: {
        type: String,
        default: 'Appraisal',
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        loanId: this.$route.params.id,
        isDraft: false,
        formData: { ...initialData },
        localData: { ...initialData },
        loanTypeOptions: [
          { label: 'HECM Traditional', value: 'HECM Traditional' },
          { label: 'HECM to HECM', value: 'HECM to HECM' },
          { label: 'HECM for Purchase', value: 'HECM for Purchase' },
        ],
        tabOptions: [
          {
            title: 'Schedule of Real Estate Owned',
            component: RealEstateOwned,
          },
          { title: 'Existing FHA Loans', component: ExistingFHALoans },
        ],
        activeTab: { title: 'Schedule of Real Estate Owned', isActive: true },
      };
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchCaseNumber();
      await this.fetchClosingSettlement();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchClosingSettlement() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/closing_settlements/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) {
            this.localData = {
              ...this.localData,
              closingSettlementId: response.data[0].id,
              respaApplicationDate: response.data[0].respaApplicationDate,
              applicationDate: response.data[0].applicationDate,
              caseAssignedDate: response.data[0].caseAssignedDate,
              closingDate: response.data[0].closingDate,
            };
          } else this.resetLocalData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchCaseNumber() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/case_numbers/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) {
            this.localData = {
              ...this.localData,
              ...response.data[0],
              caseNumberId: response.data[0].id,
            };
          } else this.resetLocalData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      validateForm() {
        return true;
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          this.setLoading(true);

          const closingSettlementDates = {
            respaApplicationDate: this.formData.respaApplicationDate,
            applicationDate: this.formData.applicationDate,
            caseAssignedDate: this.formData.caseAssignedDate,
            closingDate: this.formData.closingDate,
          };
          if (this.localData.closingSettlementId)
            await apiService.patch(`/loan/closing_settlements/${this.localData.closingSettlementId}/?loan=${this.$route.params.id}`, {
              ...closingSettlementDates,
            });
          else
            await apiService.post(`/loan/closing_settlements/`, {
              ...closingSettlementDates,
              loan: this.loanId,
            });

          if (this.localData.id)
            await apiService.patch(`/loan/case_numbers/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/case_numbers/`, {
              ...this.formData,
              loan: this.loanId,
            });
          this.$root.showSnackbar(`Case number information has been updated successfully!`, 'success');

          await this.fetchCaseNumber();
          await this.fetchClosingSettlement();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      updateField(field) {
        this.formData[field] = !this.formData[field];
      },
      // Format date from datetime string to YYYY-MM-DD
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Return an empty string or handle it as needed
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return ''; // Handle invalid date values if necessary
        }

        const isoString = date.toISOString();
        const [year, month, day] = isoString.split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      },
      resetLocalData() {
        this.localData = { ...initialData };
      },
      openHistoryDialog({ id }) {
        this.$refs.historyDialog.openHistoryDialog(id, 'CaseNumber');
      },
    },
    watch: {
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
      activeTabTitle: {
        handler(newTitle) {
          if (newTitle != '') this.activeTab.title = newTitle;
          else this.activeTab.title = this.tabOptions[0].title;
        },
        immediate: true,
      },
    },
    computed: {
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
    },
  };
</script>
