<template>
  <Card title="Loans">
    <template v-slot:action>
      <div class="flex items-center">
        <input v-model="searchQuery" class="search-input mr-[15px] flex-grow" placeholder="Filter ..." />
        <div>
          <button class="standard-btn whitespace-nowrap" @click="handleOpenDialog">Add Loan</button>
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="filteredTableData" @action="handleAction" />
  </Card>
  <Dialog :title="'Add New Loan'" :isOpen="isOpenDialog" @confirm="handleConfirmDialog" @close="handleCloseDialog">
    <div class="form-group">
      <SelectInput label="Loan Officer" id="loanOfficer" :options="loanOfficers" :disable-validation="true" v-model="formData.loanOfficer" :value="formData.loanOfficer" />
      <!-- <SelectInput label="Active Borrower" id="activeBorrower" :options="activeBorrowerOptions" v-model="activeBorrower" :value="activeBorrower" :disable-validation="true" /> -->
    </div>
    <div class="form-actions gap-2">
      <Button variant="primary" :disabled="!formData.loanOfficer" @click="handleConfirmDialog">Save Loan</Button>
      <Button variant="secondary" @click="handleCloseDialog">Close</Button>
    </div>
  </Dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FindLoan',
    components: {
      Button,
      DataTable,
      Dialog,
      Card,
      SelectInput,
    },
    data() {
      return {
        isOpenDialog: false,
        formData: {
          loanOfficer: null,
          status: null,
        },
        searchQuery: '',
        headers: [
          { key: 'borrowerName', label: 'Borrower Name' },
          { key: 'id', label: 'Loan Number' },
          { key: 'city', label: 'City' },
          { key: 'state', label: 'State' },
          { key: 'loanOfficerFullName', label: 'Loan Officer Name' },
          { key: 'status', label: 'Status' },
          // { key: 'amount', label: 'Amount' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        loanOfficers: [],
      };
    },
    computed: {
      ...mapGetters(['getUserInfo']),
      userInfo() {
        return this.getUserInfo; // Use the Vuex getter to get the user email
      },
      filteredTableData() {
        if (!this.searchQuery) {
          return this.tableData;
        }
        const query = this.searchQuery.toLowerCase();
        return this.tableData.filter((loan) => {
          const queryLower = query.toLowerCase();
          let flag =
            loan.loanOfficerFullName?.toLowerCase()?.includes(queryLower) ||
            loan.status?.toLowerCase()?.includes(queryLower) ||
            loan.id?.toString()?.includes(queryLower) ||
            loan.city?.toLowerCase()?.includes(queryLower) ||
            loan.state?.toLowerCase()?.includes(queryLower) ||
            loan.borrowerName?.toLowerCase()?.includes(queryLower);
          return flag;
        });
      },
    },
    async created() {
      await this.fetchLoans();
      await this.fetchLoanOfficers();
    },
    methods: {
      ...mapActions(['setLoading']),
      async handleOpenDialog() {
        const officer = this.loanOfficers.find((user) => user.email === this.userInfo.email);
        if (officer != undefined) {
          this.formData.loanOfficer = officer.id;
          await this.handleConfirmDialog();
        } else {
          this.isOpenDialog = true;
        }
      },
      resetFormData() {
        this.formData = {
          officer: null,
        };
      },
      async fetchLoanOfficers() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/company/users/`, {
            role: 'Loan Officer',
          });
          this.loanOfficers = response.data.map((officer) => ({
            ...officer,
            label: `${officer.firstName} ${officer.lastName}`,
            value: officer.id.toString(),
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch loan officers: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchLoans() {
        this.setLoading(true);
        try {
          const query = this.$route.query.q;
          const endpoint = query ? `/loan/loans/search/?q=${query}` : '/loan/loans/';
          const response = await apiService.get(endpoint);
          this.tableData = response.data.map((loan) => ({
            id: loan.id,
            officerFullName: loan.officerFullName,
            status: loan.status,
            borrowerName: loan.primaryBorrower ? `${loan.primaryBorrower?.firstName} ${loan.primaryBorrower?.lastName}` : '',
            loanOfficerFullName: loan.loanOfficerFullName,
            amount: loan.amount ? `$${loan.amount}` : 'Not calculated',
            city: loan.property?.city,
            state: loan.property?.state,
            actions: ['view'],
          }));
        } catch (error) {
          this.tableData = [];
          this.$root.showSnackbar(`Failed to fetch loan data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleConfirmDialog() {
        this.setLoading(true);
        try {
          const response = await apiService.post('/loan/loans/', { ...this.formData, status: 'Prospect' });
          this.$root.showSnackbar(`New Loan data has been created successfully!`, 'success');
          await this.fetchLoans();
          this.handleCloseDialog();
          this.$router.push({ name: 'Loan', params: { id: response.data.id } });
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDialog() {
        this.resetFormData();
        this.isOpenDialog = false;
      },
      async handleAction(action, item) {
        if (action === 'view') {
          this.$router.push({ name: 'Loan', params: { id: item.id } });
        }
      },
    },
    watch: {
      '$route.query.q': 'fetchLoans',
    },
  };
</script>

<style scoped>
  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    box-sizing: border-box;
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
  }

  .inline-block {
    display: flex;
    align-items: center;
  }

  .inline-block label {
    margin: 0;
    margin-right: 10px;
  }
</style>
