<template>
  <div class="h-screen bg-primary p-10 flex justify-center items-center">
    <div class="flex justify-center items-center flex-col pb-[70px]">
      <div class="">
        <img src="../../../assets/image/login-img.png" alt="login image" width="500px" />
      </div>
      <div class="slider login-slider">
        <div ref="carousel" class="carousel">
          <div v-for="item in items" :key="item.id" class="item">
            <h3 class="text-2xl text-white font-bold">{{ item.text }}</h3>
            <p class="text-white mt-3">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';
  import $ from 'jquery';
  import 'slick-carousel';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Slider',
    data() {
      return {
        items: [
          {
            id: 1,
            text: 'Make your loans fly using ReversePilot.',
            description: 'Best in class loan origination software from the first phone call to boarding the loan for servicing.',
          },
          {
            id: 2,
            text: 'Make your loans fly using ReversePilot.',
            description: 'Best in class loan origination software from the first phone call to boarding the loan for servicing.',
          },
          {
            id: 3,
            text: 'Make your loans fly using ReversePilot.',
            description: 'Best in class loan origination software from the first phone call to boarding the loan for servicing.',
          },
        ],
      };
    },
    mounted() {
      $(this.$refs.carousel).slick({
        dots: true,
        prevArrow:
          '<button class="custom-prev-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg></button>',
        nextArrow:
          '<button class="custom-next-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></button>',
      });
    },
    beforeUnmount() {
      $(this.$refs.carousel).slick('unslick');
    },
  };
</script>
<style scoped>
  .slider {
    width: 100%;
    position: relative;
  }

  .carousel {
    margin: 0 auto;
    max-width: 530px;
  }

  .item {
    padding: 20px;
    text-align: center;
  }
</style>
