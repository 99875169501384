<template>
  <Card title="Deal Status">
    <div class="flex items-end">
      <h3 class="text-black text-2xl font-semibold">{{ totalLoans }}</h3>
      <div
        :class="percentageChange > 0 ? 'bg-[#E9F9F4] text-[#26BF94]' : percentageChange == 0 ? 'bg-[#F0F0F0] text-[#414141]' : 'bg-[#FDE8E8] text-[#FF0000]'"
        class="inline-block py-[2px] px-[5px] text-sm rounded-[2px] mx-2"
      >
        <span class="font-semibold mr-1">{{ percentageChange }}</span
        ><font-awesome-icon v-if="percentageChange != 0" :icon="percentageChange > 0 ? 'caret-up' : 'caret-down'" />
      </div>
      <p class="text-base text-[#8C9097] font-semibold">compared to last week</p>
    </div>
    <div class="progress-bar flex items-center mt-4">
      <div
        v-for="status in dealStatus"
        :key="status.status"
        :style="{ width: `${(status.count / totalLoans.replace(/,/g, '')) * 100}%` }"
        :class="`${getTagColor(status.status)}`"
      ></div>
    </div>
    <ul class="deal-listing mt-5">
      <li
        v-for="(status, index) in dealStatus"
        v-bind:key="index"
        :class="[
          getTagColor(status.status, 'before:'),
          'before:content-[\'\']',
          'before:absolute',
          'before:w-2',
          'before:h-2',
          'before:rounded-full',
          'before:top-2',
          'before:left-0',
          'flex',
          'justify-between',
          'mb-1',
        ]"
      >
        <p class="text-dark font-semibold font-base">{{ status.status }}</p>
        <span class="text-[#8C9097] font-base">{{ status.count }}</span>
      </li>
    </ul>
  </Card>
</template>

<script>
  import Card from './../../../../components/Card/index.vue';
  import apiService from '@/api/apiService';
  export default {
    name: 'DealStatus',
    data: () => ({
      dealStatus: [],
      percentageChange: 0,
      totalLoans: 0,
    }),
    components: { Card },
    beforeMount() {
      this.fetchDealStatus();
    },
    methods: {
      async fetchDealStatus() {
        try {
          const response = await apiService.get('/loan/dashboard/deal-status/');

          if (response?.data) {
            const data = response.data;
            this.totalLoans = Number(data.total_loans).toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            this.percentageChange = (data.percentage_change_in_this_week || 0).toFixed(2);
            this.dealStatus = data.status_counts;
          }
        } catch (e) {
          console.log(e);
        }
      },
      getTagColor(status, prefix = '') {
        const colors = {
          Active: 'bg-[#1E90FF]', // before:bg-[#1E90FF]
          Close: 'bg-[#FF4500]', // before:bg-[#FF4500]
          Sold: 'bg-[#32CD32]', // before:bg-[#32CD32]
          Rental: 'bg-[#4682B4]', // before:bg-[#4682B4]
          Pending: 'bg-[#FFD700]', // before:bg-[#FFD700]
          Adversed: 'bg-[#8A2BE2]', // before:bg-[#8A2BE2]
          InActive: 'bg-[#FF8C00]', // before:bg-[#FF8C00]
          OnHold: 'bg-[#FF6347]', // before:bg-[#FF6347]
          Prospect: 'bg-[#20B2AA]', // before:bg-[#20B2AA]
          Origination: 'bg-[#FFA500]', // before:bg-[#FFA500]
          ProcessingSetup: 'bg-[#696969]', // before:bg-[#696969]
          Processing: 'bg-[#FF4500]', // before:bg-[#FF4500]
          Underwriting: 'bg-[#DC143C]', // before:bg-[#DC143C]
          ClearToClose: 'bg-[#4169E1]', // before:bg-[#4169E1]
          Closing: 'bg-[#FF8C00]', // before:bg-[#FF8C00]
          Funding: 'bg-[#FF8C00]', // before:bg-[#FF8C00]
          Shipping: 'bg-[#FFD700]', // before:bg-[#FFD700]
          PrePurchaseQC: 'bg-[#DC143C]', // before:bg-[#DC143C]
          PrePurchase: 'bg-[#FF4500]', // before:bg-[#FF4500]
          PurchaseCompleted: 'bg-[#8B0000]', // before:bg-[#8B0000]
          Yes: 'bg-[#32CD32]', // before:bg-[#32CD32]
          No: 'bg-[#FF4500]', // before:bg-[#FF4500]
          Insured: 'bg-[#00CED1]', // before:bg-[#00CED1]
          'Submitted to Underwriting': 'bg-[#2F4F4F]', // before:bg-[#2F4F4F]
          'Post Closing': 'bg-[#FFD700]', // before:bg-[#FFD700]
        };
        return prefix + (colors[status] || 'bg-orange-200'); // before:bg-orange-200
      },
    },
  };
</script>
<style scoped>
  .deal-listing li {
    padding-left: 15px;
    position: relative;
  }
  .progress-bar div {
    height: 5px;
    border-radius: 5px;
  }
</style>
