<template>
  <div :class="['border border-gray bg-white rounded-lg', customClass, { 'cursor-pointer': link || href }]" @click="navigateTo">
    <div
      v-if="title || $slots.title || $slots.action"
      :class="[
        $slots.action ? `block sm:flex items-center justify-between  ${isFlex ? 'flex' : ''}` : 'block',
        { 'py-4 sm:py-2': $slots.action, 'py-4': !$slots.action },
        'card-header px-15 border-b border-gray shadow-card',
      ]"
    >
      <slot name="title"></slot>
      <h1 class="text-lg font-semibold leading-none py-2" v-if="title">{{ title }}</h1>
      <slot name="action"></slot>
    </div>
    <div class="p-15">
      <slot></slot>
    </div>

    <div v-if="$slots.footer" class="card-footer px-15 py-4 border-t border-gray shadow-card">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Card',
    props: {
      title: {
        type: String,
        required: false,
      },
      customClass: {
        type: String,
        required: false,
        default: '',
      },
      isFlex: {
        type: Boolean,
        required: false,
        default: false,
      },
      link: {
        type: String,
        required: false,
        default: '',
      },
      href: {
        type: String,
        required: false,
        default: '',
      },
    },
    methods: {
      navigateTo() {
        if (this.link) {
          this.$router.push(this.link);
        } else if (this.href) {
          window.location.href = this.href;
        }
      },
    },
  };
</script>
