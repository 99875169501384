<template>
  <div class="input-box">
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">
        {{ label }}
      </label>

      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <div class="relative flex items-center">
        <input
          type="text"
          :id="id"
          :value="inputValue"
          @input="handleInput"
          @keypress="validateNumber"
          class="pl-3"
          :class="['case-number-input', { invalid: !isValid && !disableValidation }]"
          placeholder="###-#######"
        />
      </div>
      <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
  import Tooltip from '@/components/Tooltip';

  export default {
    name: 'CaseNumberInput',
    components: {
      Tooltip,
    },
    props: {
      label: String,
      id: String,
      value: {
        type: [String, Number],
        default: '',
      },
      maxLength: {
        type: Number,
        default: 11, // 10 digits + 1 hyphen
      },
      disableValidation: Boolean,
      tooltip: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        inputValue: this.value?.toString() || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      handleInput(event) {
        this.isDirty = true;
        const cleaned = event.target.value.replace(/\D/g, '');
        const part1 = cleaned.slice(0, 3);
        const part2 = cleaned.slice(3, 10);
        const formattedInput = part1 + (part2 ? '-' + part2 : '');

        if (formattedInput.length <= this.maxLength) {
          this.inputValue = formattedInput;
        }

        const regex = /^\d{3}-\d{7}$/;
        this.isValid = regex.test(this.inputValue);
        this.$emit('update:modelValue', this.inputValue);
      },
      validateNumber(event) {
        const charCode = event.charCode;

        // Allow only numbers and hyphen
        if (((charCode < 48 || charCode > 57) && charCode !== 45) || this.inputValue.length >= this.maxLength) {
          event.preventDefault();
        }
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} must be in the format ###-#######.`;
        }
        return '';
      },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue !== null && newValue !== undefined ? newValue.toString() : '';
      },
    },
    mounted() {
      this.inputValue = this.value ? this.value.toString() : '';
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }

  input {
    padding-left: 15px;
  }
</style>
