import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/components/Layout/layout.vue';
import Login from '@/services/Auth/Login/index.vue';
import ForgetPassword from '@/services/Auth/ForgetPassword/index.vue';
import ResetPassword from '@/services/Auth/ResetPassword/index.vue';
import Dashboard from '@/pages/Dashboard/index.vue';
import Reporting from '@/pages/Reporting/index.vue';
import LoanExport from '@/pages/Reporting/components/pages/LoanExport/index.vue';
import UserAccess from '@/pages/Reporting/components/pages/UserAccess/index.vue';
import DocumentHistory from '@/pages/Reporting/components/pages/DocumentHistory/index.vue';
import ReportEngine from '@/pages/Reporting/components/pages/ReportEngine/index.vue';
import MortgageCallReport from '@/pages/Reporting/components/pages/MortgageCallReport/index.vue';
import ReportView from '@/pages/Reporting/components/pages/ReportView/index.vue';
import Analytics from '@/pages/Analytics/index.vue';
import Companies from '@/pages/Companies/index.vue';
import AddCompany from '@/pages/Companies/components/AddCompany/index.vue';
import CompanyDetails from '@/pages/Companies/components/CompanyDetails/index.vue';
import Users from '@/pages/Users/index.vue';
import AddUser from '@/pages/Users/components/AddUser/index.vue';
import UserDetails from '@/pages/Users/components/UserDetails/index.vue';
import Settings from '@/pages/Settings/index.vue';
import CompanySettings from '@/pages/Settings/pages/CompanySettings/index.vue';
import CompliancePolicies from '@/pages/Settings/pages/CompliancePolicies/index.vue';
import StandardConditions from '@/pages/Settings/pages/StandardConditions/index.vue';
import ThirdPartyIntegrations from '@/pages/Settings/pages/ThirdPartyIntegrations/index.vue';
import UserCredentials from '@/pages/Settings/pages/UserCredentials/index.vue';
import RolesPermission from '@/pages/Settings/pages/RolesPermission/index.vue';
import Fees from '@/pages/Settings/pages/Fees/index.vue';
import SettlementAgents from '@/pages/Settings/pages/SettlementAgents/index.vue';
import Trustees from '@/pages/Settings/pages/Trustees/index.vue';
import Payees from '@/pages/Settings/pages/Payees/index.vue';
import Licenses from '@/pages/Licenses/index.vue';
import FindLoan from '@/pages/FindLoan/index.vue';
import LoanDetail from '@/pages/LoanDetail/index.vue';
import Document from '@/pages/Document/index.vue';
import DocumentPackage from '@/pages/Document/pages/DocumentPackage/index.vue';
import PackageForm from '@/pages/Document/pages/DocumentPackage/pages/PackageForm/index.vue';
import DocumentTemplate from '@/pages/Document/pages/DocumentTemplate/index.vue';
import PDFTemplateForm from '@/pages/Document/pages/DocumentTemplate/pages/PDFTemplateForm/index.vue';
import CustomTemplateForm from '@/pages/Document/pages/DocumentTemplate/pages/CustomTemplateForm/index.vue';
import Profile from '@/pages/Profile/index.vue';
import { isSuperUser } from '@/utils';
import apiService from '@/api/apiService';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'admin',
        name: 'AdminRedirect',
        beforeEnter: async (_, __, next) => {
          if (isSuperUser()) {
            // Define the BASE_URL (you might want to move this to a config file)
            const BASE_URL = process.env.VUE_APP_API_URL;
            const authToken = localStorage.getItem('auth_token');

            // Implement the logic to log in to admin
            const logInToAdmin = async () => {
              try {
                const response = await axios.get(`${BASE_URL}/company/get-signature/`, {
                  headers: { Authorization: `Bearer ${authToken}` },
                });
                const signature = response.data.qs;
                window.open(`${BASE_URL}/company/redirect-to-admin-panel/?${signature}`, '_blank');
              } catch (error) {
                console.error('Error logging in to admin:', error);
              }
            };

            // Call the function to open admin panel in new tab
            await logInToAdmin();
            // Stay on the current page
            next(false);
          } else {
            next({ name: 'Dashboard' });
          }
        },
      },
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: { title: 'Dashboard' },
      },
      {
        path: 'reporting',
        name: 'Reporting',
        component: Reporting,
        meta: { title: 'Reporting' },
      },
      {
        path: 'reporting/loan-export',
        name: 'LoanExport',
        component: LoanExport,
        meta: { title: 'Loan Export - Reporting' },
      },
      {
        path: 'reporting/user-access',
        name: 'UserAccess',
        component: UserAccess,
        meta: { title: 'User Access - Reporting' },
      },
      {
        path: 'reporting/document-history',
        name: 'DocumentHistory',
        component: DocumentHistory,
        meta: { title: 'Document History - Reporting' },
      },
      {
        path: 'reporting/report-engine',
        name: 'ReportEngine',
        component: ReportEngine,
        meta: { title: 'Report Engine - Reporting' },
      },
      {
        path: 'reporting/report/:id/:name',
        name: 'ReportView',
        component: ReportView,
        meta: { title: 'Report - Reporting' },
      },
      {
        path: 'reporting/mortgate-call-report',
        name: 'MortgageCallReport',
        component: MortgageCallReport,
        meta: { title: 'Report - MortgageCallReport' },
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: Analytics,
        meta: { title: 'Analytics' },
      },
      {
        path: 'companies',
        name: 'Companies',
        component: Companies,
        meta: { title: 'Companies' },
      },
      {
        parent: 'companies',
        path: '/companies/add-company',
        name: 'AddCompany',
        component: AddCompany,
        meta: { title: 'Add Company' },
      },
      {
        parent: 'companies',
        path: 'companies/company/:id',
        name: 'CompanyDetail',
        component: CompanyDetails,
        meta: { title: 'Company Detail' },
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: { title: 'Users' },
      },
      {
        parent: 'users',
        path: '/users/add-user',
        name: 'AddUser',
        component: AddUser,
        meta: { title: 'Add User' },
      },
      {
        parent: 'users',
        path: 'users/user/:id',
        name: 'UserDetail',
        component: UserDetails,
        meta: { title: 'User Detail' },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        meta: { title: 'Settings' },
      },
      {
        path: 'company-settings',
        name: 'CompanySettings',
        component: CompanySettings,
        meta: { title: 'Company Settings' },
      },
      {
        path: 'compliance-settings',
        name: 'CompliancePolicies',
        component: CompliancePolicies,
        meta: { title: 'Compliance Policies' },
      },
      {
        path: 'standard-conditions',
        name: 'StandardConditions',
        component: StandardConditions,
        meta: { title: 'Standard Conditions' },
      },
      {
        path: 'third-party-integrations',
        name: 'ThirdPartyIntegrations',
        component: ThirdPartyIntegrations,
        meta: { title: 'Third Party Integrations' },
      },
      {
        path: 'user-credentials',
        name: 'UserCredentials',
        component: UserCredentials,
        meta: { title: 'User Credentials' },
      },
      {
        path: 'roles-permission',
        name: 'RolesPermission',
        component: RolesPermission,
        meta: { title: 'Roles Permission' },
      },
      {
        path: 'fees',
        name: 'Fees',
        component: Fees,
        meta: { title: 'Fees' },
      },
      {
        path: 'settlement-agents',
        name: 'SettlementAgents',
        component: SettlementAgents,
        meta: { title: 'Settlement Agents' },
      },
      {
        path: 'trustees',
        name: 'Trustees',
        component: Trustees,
        meta: { title: 'Trustees' },
      },
      {
        path: 'payees',
        name: 'Payees',
        component: Payees,
        meta: { title: 'Payees' },
      },
      {
        path: 'licenses',
        name: 'Licenses',
        component: Licenses,
        meta: { title: 'Licenses' },
      },
      {
        path: 'find-loan',
        name: 'FindLoan',
        component: FindLoan,
        meta: { title: 'Find Loan' },
      },
      {
        path: 'loan/:id',
        name: 'Loan',
        component: LoanDetail,
        meta: { title: 'Loan Detail' },
      },
      {
        path: 'document',
        name: 'Document',
        component: Document,
        meta: { title: 'Document' },
      },
      {
        path: 'document-package',
        name: 'DocumentPackage',
        component: DocumentPackage,
        meta: { title: 'Document Package' },
      },
      {
        path: 'package/create',
        name: 'PackageCreate',
        component: PackageForm,
        meta: { title: 'Package Create' },
      },
      {
        path: 'package/update/:id',
        name: 'PackageUpdate',
        component: PackageForm,
        meta: { title: 'Document Package Update' },
      },
      {
        path: 'document-template',
        name: 'DocumentTemplate',
        component: DocumentTemplate,
        meta: { title: 'Document Template' },
      },
      {
        path: 'pdf-template/create',
        name: 'PDFTemplateCreate',
        component: PDFTemplateForm,
        meta: { title: 'Template Create' },
      },
      {
        path: 'pdf-template/update/:id',
        name: 'PDFTemplateUpdate',
        component: PDFTemplateForm,
        meta: { title: 'PDF Template Update' },
      },
      {
        path: 'custom-template/create',
        name: 'CustomTemplateCreate',
        component: CustomTemplateForm,
        meta: { title: 'Custom Template Create' },
      },
      {
        path: 'custom-template/update/:id',
        name: 'CustomTemplateUpdate',
        component: CustomTemplateForm,
        meta: { title: 'Custom Template Update' },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: { title: 'Profile' },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login - ReversePilot' },
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: { title: 'Forget Password - ReversePilot' },
  },
  {
    path: '/reset/:uidb64/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { title: 'Reset Password - ReversePilot' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('auth_token');

  if ((to.name === 'Login' || to.name === 'ForgetPassword') && isAuthenticated) {
    next({ name: 'Dashboard' });
  } else if (!isAuthenticated && to.name !== 'Login' && to.name !== 'ForgetPassword' && to.name !== 'ResetPassword') {
    next({ name: 'Login' });
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use the meta title if it exists
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  const isAuthenticated = !!localStorage.getItem('auth_token');
  if (isAuthenticated) {
    // Log page access to the backend
    apiService
      .post('company/log-page-access/', {
        page_requested: to.meta.title,
        associated_variables: {
          full_path: to.fullPath,
          params: to.params,
          query: to.query,
        }, // Add additional query params if needed
      })
      .catch((error) => {
        console.error('Error logging page access:', error);
      });
  }
});

export default router;
