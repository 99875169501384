<template>
  <Dialog title="Activation Dialog" :isOpen="isOpen" @close="handleClose">
    <div class="content-container">
      <table class="table">
        <thead>
          <tr>
            <th>Condition</th>
            <th>Values</th>
          </tr>
        </thead>
        <tbody>
          <!-- Property State is -->
          <tr>
            <td>Property State is</td>
            <td>
              <SelectInput :options="US_STATES" :value="formData.property_state" v-model="formData.property_state" />
            </td>
          </tr>
          <!-- Property State is in -->
          <tr>
            <td>Property State is in</td>
            <td>
              <div class="flex flex-wrap gap-2 mb-2">
                <span class="text-sm text-gray-500 bg-gray-100 rounded-md px-2 py-1 flex items-center" v-for="state in formData.property_state_in" :key="state">
                  {{ US_STATES.find((s) => s.value === state)?.label }}
                  <button @click="handleRemoveStateIn(state)" class="ml-2 text-red-500 hover:text-red-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </span>
              </div>
              <SelectInput :options="US_STATES" @change="handlePropertyStateInChange" />
            </td>
          </tr>
          <!-- Property Type is -->
          <tr>
            <td>Property Type is</td>
            <td>
              <SelectInput :options="PROPERTY_TYPE_OPTIONS" :value="formData.property_type" v-model="formData.property_type" />
            </td>
          </tr>
          <!-- Property Type is in -->
          <tr>
            <td>Property Type is in</td>
            <td>
              <div class="flex flex-wrap gap-2 mb-2">
                <span class="text-sm text-gray-500 bg-gray-100 rounded-md px-2 py-1 flex items-center" v-for="type in formData.property_type_in" :key="type">
                  {{ PROPERTY_TYPE_OPTIONS.find((t) => t.value === type)?.label }}
                  <button @click="handleRemoveTypeIn(type)" class="ml-2 text-red-500 hover:text-red-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </span>
              </div>
              <SelectInput :options="PROPERTY_TYPE_OPTIONS" @change="handlePropertyTypeInChange" />
            </td>
          </tr>
          <!-- Product name contains -->
          <tr>
            <td>Product name contains</td>
            <td>
              <InputField v-model="formData.product_name_contains" :value="formData.product_name_contains" />
            </td>
          </tr>
          <!-- LESA_REQUIRED is -->
          <tr>
            <td>LESA_REQUIRED is</td>
            <td>
              <RadioInput :options="RADIO_INPUT_OPTIONS" :value="formData.lesa_required" v-model="formData.lesa_required" />
            </td>
          </tr>
          <!-- Property year built is -->
          <tr>
            <td>Property year built is</td>
            <td>
              <div class="flex gap-2">
                <SelectInput :options="afterOrBeforeOptions" :value="formData.property_year_built_after_or_before" v-model="formData.property_year_built_after_or_before" />
                <IntegerInput v-model="formData.property_year_built" :value="formData.property_year_built" />
              </div>
              <span v-if="validationFormErrors?.property_year_built?.length" class="text-sm text-red">
                {{ validationFormErrors.property_year_built }}
              </span>
            </td>
          </tr>
          <!-- Loan has multiple borrowers -->
          <tr>
            <td>Loan has multiple borrowers</td>
            <td>
              <RadioInput :options="RADIO_INPUT_OPTIONS" :value="formData.loan_has_multiple_borrowers" v-model="formData.loan_has_multiple_borrowers" />
            </td>
          </tr>
          <!-- Loan has an eligible nbs -->
          <tr>
            <td>Loan has an eligible NBS</td>
            <td>
              <RadioInput :options="RADIO_INPUT_OPTIONS" :value="formData.loan_has_eligible_nbs" v-model="formData.loan_has_eligible_nbs" />
            </td>
          </tr>
          <!-- Loan has ineligible nbs -->
          <tr>
            <td>Loan has ineligible NBS</td>
            <td>
              <RadioInput :options="RADIO_INPUT_OPTIONS" :value="formData.loan_has_ineligible_nbs" v-model="formData.loan_has_ineligible_nbs" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-end mt-4">
        <Button variant="primary" class="mr-2" @click="handleSave">Save</Button>
        <Button variant="secondary" @click="handleClose">Cancel</Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
  import Button from '@/components/Button/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import { US_STATES, PROPERTY_TYPE_OPTIONS, RADIO_INPUT_OPTIONS } from '@/constants';

  const initialFormData = {
    property_state: null,
    property_state_in: null,
    property_type: null,
    property_type_in: null,
    product_name_contains: null,
    lesa_required: null,
    property_year_built: null,
    property_year_built_after_or_before: null,
    loan_has_multiple_borrowers: null,
    loan_has_eligible_nbs: null,
    loan_has_ineligible_nbs: null,
  };

  export default {
    name: 'ActivationDialog',
    components: {
      Button,
      Dialog,
      SelectInput,
      InputField,
      IntegerInput,
      RadioInput,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      info: {
        type: Object,
        required: true,
      },
    },
    setup() {
      return {
        US_STATES,
        PROPERTY_TYPE_OPTIONS,
        RADIO_INPUT_OPTIONS,
      };
    },
    data() {
      return {
        formData: {
          ...initialFormData,
        },
        afterOrBeforeOptions: [
          { label: '', value: null },
          { label: 'After', value: 'after' },
          { label: 'Before', value: 'before' },
        ],
        validationFormErrors: {},
      };
    },
    methods: {
      handlePropertyStateInChange(event) {
        const selectedState = event.target.value;
        if (this.formData.property_state_in?.includes(selectedState)) {
          this.formData.property_state_in = this.formData.property_state_in.filter((state) => state !== selectedState);
        } else {
          this.formData.property_state_in = [...(this.formData.property_state_in || []), selectedState];
        }
      },
      handleRemoveStateIn(state) {
        this.formData.property_state_in = this.formData.property_state_in.filter((s) => s !== state);
      },
      handlePropertyTypeInChange(event) {
        const selectedType = event.target.value;
        if (this.formData.property_type_in?.includes(selectedType)) {
          this.formData.property_type_in = this.formData.property_type_in.filter((type) => type !== selectedType);
        } else {
          this.formData.property_type_in = [...(this.formData.property_type_in || []), selectedType];
        }
      },
      handleRemoveTypeIn(type) {
        this.formData.property_type_in = this.formData.property_type_in.filter((t) => t !== type);
      },
      isValidForm() {
        this.validationFormErrors = {};

        // if property year built after or before is not null, check if property year built is between 1900 and 2025
        if (
          this.formData.property_year_built_after_or_before?.length > 0 &&
          (this.formData.property_year_built === null || this.formData.property_year_built < 1900 || this.formData.property_year_built > 2025)
        ) {
          this.validationFormErrors.property_year_built = 'Property year built must be between 1900 and 2025';
        }

        // if property year built after or before is null, set property year built to null
        if (this.formData.property_year_built_after_or_before?.length === 0) {
          this.formData.property_year_built = null;
          this.formData.property_year_built_after_or_before = null;
        }

        return Object.keys(this.validationFormErrors).length === 0;
      },
      resetFormErrors() {
        this.validationFormErrors = {};
      },
      handleSave() {
        if (this.isValidForm()) {
          this.resetFormErrors();
          this.$emit('save', this.formData);
        }
      },
      handleClose() {
        this.resetFormErrors();
        this.$emit('close');
      },
    },
    watch: {
      info: {
        handler(newVal) {
          if (newVal) {
            this.formData = { ...initialFormData, ...newVal.activation_logic };
          }
        },
      },
    },
  };
</script>
