<template>
  <Card>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 gap-1">
      <!-- First Line -->
      <div class="lg:col-span-1">
        <InputField
          label="Street #"
          id="street"
          :value="formData.streetNumber"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          v-model.trim="formData.streetNumber"
          :disable-validation="true"
        />
        <span v-if="validationFormErrors?.streetNumber?.length" class="text-sm text-red">
          {{ validationFormErrors.streetNumber }}
        </span>
      </div>
      <div class="lg:col-span-1">
        <InputField
          label="Suffix"
          id="suffix"
          ref="suffix"
          :value="formData.suffix"
          v-model.trim="formData.suffix"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
        />
      </div>
      <div class="lg:col-span-1">
        <SelectInput
          label="Pre Direction"
          id="preDirection"
          :options="DIRECTION_OPTIONS"
          v-model="formData.preDirection"
          :value="formData.preDirection"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
      </div>
      <div class="lg:col-span-2">
        <InputField
          label="Street Name"
          id="streetName"
          ref="streetName"
          :value="formData.streetName"
          v-model.trim="formData.streetName"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
        />
      </div>
      <div class="lg:col-span-1">
        <SelectInput
          label="Street Type"
          id="streetType"
          :options="STREET_TYPES"
          v-model="formData.streetType"
          :value="formData.streetType"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
        />
      </div>
      <div class="lg:col-span-1">
        <SelectInput
          label="Post Direction"
          id="postDirection"
          :options="DIRECTION_OPTIONS"
          v-model="formData.postDirection"
          :value="formData.postDirection"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
      </div>
      <div class="lg:col-span-1">
        <InputField
          label="Address Suffix"
          id="addressSuffix"
          ref="addressSuffix"
          :value="formData.addressSuffix"
          v-model.trim="formData.addressSuffix"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
      </div>
    </div>

    <!-- Second Line -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-1 mt-4">
      <div class="lg:col-span-1">
        <InputField
          label="Zip Code"
          id="zipCode"
          ref="zipCode"
          :value="formData.zipCode"
          v-model.trim="formData.zipCode"
          :disable-validation="true"
          @onChange="handleFetchZipCodeDetails"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
        <span v-if="validationFormErrors?.zipCode?.length" class="text-sm text-red">
          {{ validationFormErrors.zipCode }}
        </span>
      </div>
      <div class="lg:col-span-1">
        <InputField
          label="Zip 4"
          id="zip4"
          ref="zip4"
          :value="formData.zip4"
          v-model.trim="formData.zip4"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
      </div>
      <div class="lg:col-span-1">
        <SelectInput
          label="State"
          id="state"
          :options="US_STATES"
          v-model="formData.state"
          :value="formData.state"
          :disable-validation="true"
          :disabled="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
        <span v-if="validationFormErrors?.state?.length" class="text-sm text-red">
          {{ validationFormErrors.state }}
        </span>
      </div>
      <div class="lg:col-span-1">
        <InputField
          label="County"
          id="county"
          ref="county"
          :value="formData.county"
          v-model.trim="formData.county"
          :disable-validation="true"
          :disabled="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
        <span v-if="validationFormErrors?.county?.length" class="text-sm text-red">
          {{ validationFormErrors.county }}
        </span>
      </div>
      <div class="lg:col-span-1">
        <InputField
          label="City"
          id="city"
          ref="city"
          :value="formData.city"
          v-model.trim="formData.city"
          :disable-validation="true"
          :disabled="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
        <span v-if="validationFormErrors?.city?.length" class="text-sm text-red">
          {{ validationFormErrors.city }}
        </span>
      </div>
    </div>

    <!-- Third Line: Property Type and Radio Buttons -->
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-1 mt-4">
      <div class="md:col-span-2">
        <SelectInput
          label="Property Type"
          id="propertyType"
          :options="propertyTypeOptions"
          v-model="formData.propertyType"
          :value="formData.propertyType"
          :disable-validation="true"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
        <span v-if="!formData.propertyType && validationFormErrors.propertyType" class="text-sm text-red">
          {{ validationFormErrors.propertyType }}
        </span>
      </div>

      <div class="md:col-span-2">
        <RadioInput
          label="Property has well?"
          id="propertyHasWell"
          :options="RADIO_INPUT_OPTIONS"
          :value="formData.propertyHasWell"
          v-model="formData.propertyHasWell"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
      </div>

      <div class="md:col-span-2">
        <RadioInput
          label="Property has septic?"
          id="propertyHasSeptic"
          :options="RADIO_INPUT_OPTIONS"
          :value="formData.propertyHasSeptic"
          v-model="formData.propertyHasSeptic"
          @trigger-open-history-dialog="openHistoryDialog"
          :historyShow="true"
          historyModel="Property"
        />
      </div>
    </div>

    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" style="width: fit-content" :disabled="!this.isDraft || !writePermission" @click="handleSaveChanges"> Save Changes </Button>
    </div>
  </Card>
  <div v-if="formData.id">
    <TabCard class="my-15 overflow-x-auto">
      <ul class="tabs flex items-center">
        <li v-for="tabOption in tabOptions" :key="tabOption.title" class="tab" :class="{ active: tabOption.title === activeTab.title }" @click="changeActiveTab(tabOption.title)">
          {{ tabOption.title }}
        </li>
      </ul>
    </TabCard>
    <div class="content-container">
      <component :is="activeTabComponent" :writePermission="writePermission" :activeTabSelectId="subTabSelectId" v-model:propertyId="formData.id" />
    </div>
  </div>
  <!-- History Dialog -->
  <HistoryDialog ref="historyDialog" />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Button from '@/components/Button/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Appraisal from './components/Appraisal/index.vue';
  import Title from './components/Title/index.vue';
  import Repairs from './components/Repairs/index.vue';
  import PropertyCharge from './components/PropertyCharge/index.vue';
  import FloodZone from './components/FloodZone/index.vue';
  import Payoffs from './components/Payoffs/index.vue';
  import RealEstateOwned from './components/RealEstateOwned/index.vue';
  import AppraisalAnalysis from './components/AppraisalAnalysis/index.vue';
  import HistoryDialog from '../HistoryDialog/index.vue';
  import { US_STATES, STREET_TYPES, DIRECTION_OPTIONS, RADIO_INPUT_OPTIONS, PROPERTY_TYPE_OPTIONS } from '@/constants/index.js';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Property',
    components: {
      Card,
      TabCard,
      InputField,
      Button,
      SelectInput,
      RadioInput,
      Title,
      Repairs,
      PropertyCharge,
      FloodZone,
      Payoffs,
      AppraisalAnalysis,
      HistoryDialog,
    },
    props: {
      activeTabTitle: {
        type: String,
        default: 'Appraisal',
      },
      activeTabSelectId: {
        type: String,
        default: '',
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {
        US_STATES,
        STREET_TYPES,
        DIRECTION_OPTIONS,
        RADIO_INPUT_OPTIONS,
        PROPERTY_TYPE_OPTIONS,
      };
    },
    data() {
      return {
        loanId: this.$route.params.id,
        isDraft: false,
        formData: {
          streetNumber: '',
          suffix: '',
          preDirection: '',
          streetName: '',
          streetType: '',
          postDirection: '',
          addressSuffix: '',
          zipCode: '',
          zip4: '',
          city: '',
          state: '',
          county: '',
          propertyType: '',
          propertyHasWell: null,
          propertyHasSeptic: null,
        },
        localData: {
          streetNumber: '',
          suffix: '',
          preDirection: '',
          streetName: '',
          streetType: '',
          postDirection: '',
          addressSuffix: '',
          zipCode: '',
          zip4: '',
          city: '',
          state: '',
          county: '',
          propertyType: '',
          propertyHasWell: null,
          propertyHasSeptic: null,
        },
        propertyTypeOptions: PROPERTY_TYPE_OPTIONS,
        tabOptions: [
          { title: 'Appraisal', component: Appraisal },
          { title: 'Title', component: Title },
          { title: 'Repairs', component: Repairs },
          { title: 'Property Charge', component: PropertyCharge },
          { title: 'Flood Zone', component: FloodZone },
          { title: 'Payoffs', component: Payoffs },
          { title: 'Real Estate Owned', component: RealEstateOwned },
        ],
        activeTab: { title: 'Appraisal', isActive: true },
        subTabSelectId: '',
        validationFormErrors: {
          streetNumber: '',
          state: '',
          propertyType: '',
          zipCode: '',
          zipCodeNotValid: '',
          city: '',
          county: '',
        },
      };
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchProperty();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchProperty() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/properties/', {
            loan: this.loanId,
          });
          if (response.data?.length > 0) this.localData = response.data[0];
          else this.resetLocalData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      // Validate form data: Real Estate Tax Amount and Hazard Insurance Amount are required fields for Calculation.
      validateForm() {
        this.validationFormErrors = {
          ...this.validationFormErrors,
          streetNumber: !this.formData.streetNumber ? 'This field is required.' : '',
          propertyType: !this.formData.propertyType ? 'This field is required.' : '',
          zipCode: !this.formData.zipCode
            ? 'This field is required.'
            : this.formData.zipCode.length !== 5 || !/^\d+$/.test(this.formData.zipCode)
              ? 'Zip code must be 5 digits.'
              : this.validationFormErrors.zipCode,
        };

        return (
          !this.validationFormErrors.streetNumber.length &&
          !this.validationFormErrors.propertyType.length &&
          !this.validationFormErrors.state.length &&
          !this.validationFormErrors.zipCode.length &&
          !this.validationFormErrors.zipCodeNotValid.length
        );
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/properties/${this.localData.id}/`, {
              ...this.formData,
            });
          else await apiService.post(`/loan/properties/`, { ...this.formData, loan: this.loanId });
          this.$root.showSnackbar(`Property information has been updated successfully!`, 'success');
          await this.fetchProperty();
        } catch (error) {
          const response = error?.response;
          if (response && response.status && (response.status === 404 || response.status === 400)) {
            if (response.data) {
              Object.keys(response.data).forEach((key) => {
                this.validationFormErrors[key] = response.data[key].join(', ');
              });
            }
            this.$root.showSnackbar('There were validation errors with your submission. Please check the highlighted fields and try again.', 'error');
          } else this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      resetLocalData() {
        this.localData = {
          streetNumber: '',
          suffix: '',
          preDirection: '',
          streetType: '',
          postDirection: '',
          addressSuffix: '',
          zipCode: '',
          zip4: '',
          city: '',
          state: '',
          county: '',
          propertyType: '',
          propertyHasWell: null,
          propertyHasSeptic: null,
        };
        this.validationFormErrors = {
          streetNumber: '',
          state: '',
          propertyType: '',
          zipCode: '',
          city: '',
          county: '',
        };
      },
      resetAddressFields() {
        this.formData = {
          ...this.formData,
          city: '',
          county: '',
          state: '',
        };
        this.validationFormErrors.zipCode = '';
        this.validationFormErrors.city = '';
        this.validationFormErrors.county = '';
        this.validationFormErrors.state = '';
        this.validationFormErrors.zipCodeNotValid = '';
      },
      async handleFetchZipCodeDetails() {
        this.resetAddressFields();
        if (!this.formData.zipCode) {
          this.validationFormErrors.zipCode = '';
          return;
        }
        if (this.formData.zipCode.length !== 5 || !/^\d+$/.test(this.formData.zipCode)) {
          this.validationFormErrors.zipCode = 'Zip code must be 5 digits.';
          return;
        } else {
          this.validationFormErrors.zipCode = '';
        }
        try {
          this.setLoading(true);
          const response = await apiService.get('/loan/properties/by-zipcode/', {
            zipcode: this.formData.zipCode,
          });

          if (response.status == 200) {
            const result = response.data;
            this.formData = {
              ...this.formData,
              city: result.usps_zip_pref_city,
              county: result.county_name,
              state: result.usps_zip_pref_state,
            };
          }
        } catch (error) {
          const response = error?.response;
          if (response && response.status && (response.status === 404 || response.status === 400)) {
            this.validationFormErrors.zipCodeNotValid = response.data.detail;
            this.validationFormErrors.zipCode = response.data.detail;
            this.$root.showSnackbar(response.data.detail, 'error');
          } else this.$root.showSnackbar(`${error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      getAddressComponent(components, type) {
        // Helper function to get a specific address component by its type
        const component = components.find((comp) => comp.types.includes(type));
        return component ? component.long_name : null;
      },
      openHistoryDialog({ id }) {
        this.$refs.historyDialog.openHistoryDialog(id, 'Property');
      },
    },
    watch: {
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
      activeTabSelectId: {
        handler(newId) {
          if (this.subTabSelectId !== newId) {
            this.subTabSelectId = newId;
          }
        },
        immediate: true,
      },
      activeTabTitle: {
        handler(newTitle) {
          if (newTitle != '') this.activeTab.title = newTitle;
          else this.activeTab.title = this.tabOptions[0].title;
        },
        immediate: true,
      },
    },
    computed: {
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
    },
  };
</script>
