<template>
  <div class="filter-group border p-4 rounded mb-4">
    <!-- Logical Operator Selector -->
    <div class="flex items-center mb-4">
      <select v-model="localGroup.operator" @change="updateGroup" class="border p-2 rounded">
        <option value="AND">AND</option>
        <option value="OR">OR</option>
      </select>
      <button @click="$emit('remove-group')" class="ml-4 text-red-500">Remove Group</button>
    </div>

    <!-- Render Conditions and Nested Groups -->
    <div v-for="(condition, index) in localGroup.conditions" :key="index" class="mb-2">
      <!-- Render a Condition -->
      <div v-if="!condition.operator" class="flex items-center space-x-2">
        <select v-model="condition.field" :value="condition.field" @change="updateGroup" class="border p-2 rounded">
          <option v-for="field in fields" :key="field" :value="field">{{ field }}</option>
        </select>
        <select v-model="condition.operand" @change="updateGroup" class="border p-2 rounded">
          <option v-for="operand in operands" :key="operand" :value="operand">{{ operand }}</option>
        </select>
        <input v-model="condition.value" @input="updateGroup" placeholder="null, True, False, 123, abc" class="border p-2 rounded" />
        <button @click="removeCondition(index)" class="text-red-500">Remove</button>
      </div>

      <!-- Render Nested Group Recursively -->
      <filter-group
        v-else
        :group="condition"
        :fields="fields"
        :operands="operands"
        @update="updateNestedGroup(index, $event)"
        @remove-group="removeNestedGroup(index)"
      ></filter-group>
    </div>

    <!-- Add Buttons -->
    <div class="flex space-x-4 mt-4">
      <button @click="addCondition" class="bg-blue-500 text-white px-4 py-2 rounded">Add Condition</button>
      <button @click="addGroup" class="bg-green-500 text-white px-4 py-2 rounded">Add Group</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      group: { type: Object, required: true },
      fields: { type: Array, required: true },
      operands: { type: Array, required: true },
    },
    data() {
      return {
        localGroup: JSON.parse(JSON.stringify(this.group)), // Local reactive copy
      };
    },
    watch: {
      localGroup: {
        handler(newValue) {
          this.$emit('update', newValue); // Emit updated group to parent
        },
        deep: true,
      },
    },
    methods: {
      addCondition() {
        this.localGroup.conditions.push({ field: '', operand: '', value: '' });
      },
      addGroup() {
        this.localGroup.conditions.push({ operator: 'AND', conditions: [] });
      },
      removeCondition(index) {
        this.localGroup.conditions.splice(index, 1);
      },
      removeNestedGroup(index) {
        this.localGroup.conditions.splice(index, 1);
      },
      updateGroup() {
        this.$emit('update', this.localGroup);
      },
      updateNestedGroup(index, updatedGroup) {
        this.localGroup.conditions.splice(index, 1, updatedGroup);
      },
    },
  };
</script>

<style scoped>
  .filter-group {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
</style>
