<template>
  <Card title="Loan Program Comparison">
    <template v-slot:action>
      <div class="flex items-center gap-2">
        <button class="standard-btn" @click="handleOpenPaymentPlanDialog">Modify Payment Plan</button>
        <button class="standard-btn" @click="handleOpenChooseProductsDialog">Select Products</button>
      </div>
    </template>

    <!-- Alert panel when there is no borrower for this loan -->
    <div v-if="noBorrower" class="flex justify-center items-center">
      <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <img src="../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
        <h2 class="text-2xl font-semibold text-gray-800 mb-2">Invalid Loan For Calculation</h2>
        <p class="text-gray-600 mb-4">There is no existing borrower for this loan, so not able to calculate.</p>
      </div>
    </div>

    <!-- Alert panel when there is no avilable product -->
    <div v-if="noAvailableProduct && !noBorrower" class="flex justify-center">
      <div class="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <img src="../../../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
        <h2 class="text-2xl font-semibold text-gray-800 mb-2">No Default Product</h2>
        <p class="text-gray-600 mb-4">There is no selected product, you need to select at least one.</p>
        <button class="standard-btn" :disabled="false" @click="handleOpenChooseProductsDialog">Select Products</button>
      </div>
    </div>

    <!-- Main table panel for calculation results for each products -->
    <div class="w-full overflow-x-auto" v-if="!noAvailableProduct && !noBorrower">
      <table class="border-collapse border border-gray w-full price-comparison-table">
        <tbody class="">
          <tr v-if="productsData.length > 0">
            <th></th>
            <th></th>
            <th v-for="(product, index) in productsData" :key="index" @dblclick="handleActivate(product.id)" :class="{ 'active-product': product.id === activeProduct }">
              <div class="flex justify-between w-full">
                <span>{{ product.name }}</span>
                <button @click="handleHideProduct(product.id)">
                  <font-awesome-icon icon="fas fa-eye-slash" />
                </button>
              </div>
            </th>
          </tr>

          <tr>
            <th rowspan="10">Interest Rate</th>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('interestRateType')">Interest Rate Type</span>
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ interestRateTypes[data.interestRateType] }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('initialIndexName')">Initial Index Name</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ indexNames[data.initialIndexName] }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('initialIndexRate')">Initial Index Rate</span></td>
            <td v-for="(data, index) in productsData" :key="index">
              <div v-if="data.type === LOAN_PRODUCT_TYPES.HECM">{{ formatPercentage(data.initialIndexRate) }}</div>
              <div v-else>
                <SelectInput
                  :options="availableInterestRatesOptions(data.availableInterestRates)"
                  :value="data.initialIndexRate"
                  v-model="data.initialIndexRate"
                  :disable-validation="true"
                  :input-class="'h-auto p-1'"
                  @change="handleProductVariableChange('initialIndexRate', index)"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('expectedIndexName')">Expected Index Name</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ indexNames[data.expectedIndexName] }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('expectedIndexRate')">Expected Index Rate</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatPercentage(data.expectedIndexRate) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('margin')">Margin (%)</span></td>
            <td id="highlight-margin" v-for="(data, index) in productsData" :key="index">
              <SelectInput
                v-if="data.interestRateType !== 'fixed'"
                :options="marginOptions"
                :value="data.margin"
                v-model="data.margin"
                :disable-validation="true"
                :input-class="'h-auto p-1'"
                @change="handleProductVariableChange('margin', index)"
              />
              <div v-else>Not Applicable</div>
            </td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('expectedRate')">Expected Rate</span></td>
            <td id="highlight-expectedRate" v-for="(data, index) in productsData" :key="index">{{ formatPercentage(data.expectedRate) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('mip')">MIP Rate (%)</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatPercentage(data.mipRate) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('monthlyServicingFee')">Monthly Servicing Fee</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.monthlyServicingFee) }}</td>
          </tr>
          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lineOfCreditGrowthRate')">Line of Credit Growth Rate</span>
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatPercentage(data.lineOfCreditGrowthRate) }}</td>
          </tr>

          <tr>
            <th rowspan="3">Home Value</th>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('homeValue')">Home Value</span></td>
            <td id="highlight-homeValue" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.homeValue) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lendingLimit')">Lending Limit</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.lendingLimit) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('maxClaimAmount')">Max Claim Amount</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.maxClaimAmount) }}</td>
          </tr>

          <tr>
            <th rowspan="3">Principal Limit</th>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('principalLimit')">Principal Limit</span></td>
            <td id="highlight-principalLimit" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.principalLimit) }}</td>
          </tr>
          <tr>
            <td>PLF</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatPercentage(data.plf * 100) }}</td>
          </tr>
          <tr>
            <td>Servicing Fee Set Aside</td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.servicingFeeSetAside) }}</td>
          </tr>

          <tr>
            <th rowspan="3">Closing Costs</th>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('imip')">Initial Mortgage Insurance Premium</span></td>
            <td id="highlight-imip" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.imip) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('originationFee')">Origination Fee</span></td>
            <td id="highlight-orginationFee" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.originationFee) }}</td>
          </tr>
          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('thirdPartyClosingCosts')">Third Party Closing Costs</span>
            </td>
            <td id="highlight-otherClosingCost" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.thirdPartyClosingCosts) }}</td>
          </tr>

          <tr>
            <th rowspan="6">Payoffs And Set Asides</th>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('totalPayoffs')">Total Payoffs</span></td>
            <td id="highlight-payOffs" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.totalPayoffs) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('repairSetAside')">Repair Set Aside</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.repairSetAsideAmount) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lesaRequired')">LESA Required?</span></td>
            <td v-for="(data, index) in productsData" :key="index" class="text-left">
              <CheckboxInput :value="data.lesaRequired" @change="handleProductVariableChange('lesaRequired', index)" />
              <!-- <input type="checkbox" :checked="data.lesaRequired" @change="handleProductVariableChange('lesaRequired', index)" class="cursor-pointer" /> -->
            </td>
          </tr>
          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lifeExpectancySetAside')">Life Expectancy Set Aside</span>
            </td>
            <td id="highlight-lessa" v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.lifeExpectancySetAside) }}</td>
          </tr>
          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lesaYear1')">Life Expectancy Set Aside 1st Year</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.lifeExpectancySetAside1stYear) }}</td>
          </tr>
          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('remainingLESA')">Remaining Life Expectancy Set Aside</span>
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.remainingLifeExpectancySetAside) }}</td>
          </tr>

          <tr>
            <th rowspan="8">Cash Out Available</th>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('mandatoryObligations')">Mandatory Obligations</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.mandatoryObiligations) }}</td>
          </tr>

          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('idl')">Initial Disbursement Limit</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.idl) }}</td>
          </tr>

          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('fundsAvailableAtClosing')">Available Principal Limit</span>
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.availablePrincipalLimit) }}</td>
          </tr>

          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lineOfCreditAvailableYear1')"
                >Line of Credit Available Year 1</span
              >
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.lineOfCreditAvailableYear1) }}</td>
          </tr>

          <tr>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('lineOfCreditAfterYear1')">Line of Credit After Year 1</span>
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.lineOfCreditAfterYear1) }}</td>
          </tr>

          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('monthlyPaymentRequest')">Monthly Payment Request</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.monthlyPayoutRequest) }}</td>
          </tr>

          <tr>
            <td><span class="cursor-pointer">Monthly Payment Term (# of months)</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ Number.parseInt(data.term) }}</td>
          </tr>

          <tr>
            <td><span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('monthlyTenureAvailable')">Monthly Tenure Available</span></td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatPercentage(data.monthlyTenureAvailable) }}</td>
          </tr>

          <tr>
            <th rowspan="1">Pricing Details</th>
            <td>
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('initialBalance')">Initial Loan Balance</span>,
              <span class="cursor-pointer" title="Click to see more details..." @click="handleOpenModalDescription('utilization')">Utilization</span>
            </td>
            <td v-for="(data, index) in productsData" :key="index">{{ formatMoney(data.initialBalance) }}, {{ formatPercentage(data.utilization * 100) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
  <!-- Payment Plans Dialog -->
  <PaymentPlansDialog
    :title="`Payment Plan Options`"
    :activated-product-type="getActivatedProductType"
    :is-open="isPaymentPlanDialogOpen"
    @close="handleClosePaymentPlanDialog"
    @confirm="handleConfirmPaymentPlanDialog"
  />
  <!-- Products Dialog -->
  <ProductsDialog :writePermission="writePermission" :is-open="isDialogOpen" :products="products" @close="handleCloseChooseProductsDialog" @confirm="handleDialogConfirm" />
  <!-- Modal Description -->
  <ModalDescription :isOpen="descriptionModalInfo.open" :title="descriptionModalInfo.title" :content="descriptionModalInfo.content" @close="handleCloseModalDescription" />
</template>

<script>
  import { mapActions } from 'vuex';
  import { debounce } from 'lodash';
  import apiService from '@/api/apiService';
  import { formatNumberWithCommas, formatMoney, formatPercentage } from '@/utils/index.js';
  import Card from '@/components/Card/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import ModalDescription from '@/components/ModalDescription/index.vue';
  import ProductsDialog from './components/ProductsDialog/index.vue';
  import PaymentPlansDialog from './components/PaymentPlansDialog/index.vue';
  import { MARGINS, RADIO_INPUT_OPTIONS, INTEREST_RATE_TYPE_OPTIONS, INTEREST_RATE_TYPES, INDEX_NAMES, COMPARISON_DESCRIPTION_TEXTS, LOAN_PRODUCT_TYPES } from '@/constants';

  export const initialProductData = {
    interestRateType: 'adjustable',
    initialIndexName: '1_month',
    initialIndexRate: 0,
    expectedIndexName: '1_month',
    expectedIndexRate: 0,
    margin: 1.5,
    expectedRate: 0,
    mipRate: 0.5,
    monthlyServicingFee: 0,
    lineOfCreditGrowthRate: 0,
    homeValue: 0,
    lendingLimit: 0,
    maxClaimAmount: 0,
    imip: 0,
    mandatoryObiligations: 0,
    originationFee: 0,
    thirdPartyClosingCosts: 0,
    totalPayoffs: 0,
    lesaRequired: false,
    repairSetAsideAmount: 0,
    lifeExpectancySetAside: 0,
    lifeExpectancySetAside1stYear: 0,
    remainingLifeExpectancySetAside: 0,
    fundsAvailableAtClosing: 0,
    lineOfCreditAfterYear1: 0,
    monthlyTenureAvailable: 0,
    requestedCashoutAmountAtClosing: 0,
    monthlyPayoutRequest: 0,
    term: 0,
    idl: 0,
    lineOfCreditAvailableYear1: 0,
    initialBalance: 0,
    utilization: 0,
    principalLimit: 0,
    plf: 0,
    servicingFeeSetAside: 0,
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Comparison',
    components: {
      Card,
      CheckboxInput,
      ModalDescription,
      SelectInput,
      ProductsDialog,
      PaymentPlansDialog,
    },
    props: {
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      return {
        formatMoney,
        formatNumberWithCommas,
        formatPercentage,
        COMPARISON_DESCRIPTION_TEXTS,
        LOAN_PRODUCT_TYPES,
      };
    },
    data() {
      return {
        loan: null,
        loanId: this.$route.params.id,
        activeProduct: null,
        products: [],
        productsData: [],
        noBorrower: false,
        noAvailableProduct: false,
        indexNames: INDEX_NAMES,
        interestRateTypes: INTEREST_RATE_TYPES,
        interestRateTypeOptions: INTEREST_RATE_TYPE_OPTIONS,
        marginOptions: MARGINS,
        resaRequiredOptions: RADIO_INPUT_OPTIONS,
        hecmOptions: RADIO_INPUT_OPTIONS,
        isDialogOpen: false,
        debouncedUpdateCalcResult: null,
        descriptionModalInfo: {
          open: false,
          title: '',
          content: '',
        },
        isPaymentPlanDialogOpen: false,
      };
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchProducts();
      this.debouncedUpdateCalcResult = debounce(this.updateProductCalcResult, 1000);
    },
    computed: {
      getActivatedProductType() {
        return this.products.find((product) => product.id === this.activeProduct)?.type;
      },
      availableInterestRatesOptions() {
        return (availableInterestRates) => {
          if (!availableInterestRates) return [];
          return availableInterestRates.map((rate) => ({ label: formatPercentage(rate), value: rate }));
        };
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      async getActiveProduct() {
        try {
          const response = await apiService.get(`/loan/loans/${this.$route.params.id}/`);
          this.loan = response.data;
          this.activeProduct = this.loan.loanProduct;
        } catch (error) {
          this.activeProduct = null;
        }
      },
      async fetchProducts() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/products/');
          this.products = response.data;
          await this.getActiveProduct();
          await this.fetchLoanCalculationResults();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchLoanCalculationResults() {
        if (!this.products.length) {
          this.productsData = [];
        } else {
          this.setLoading(true);
          try {
            const defaultProducts = this.products.filter((product) => product.default === true);
            const calculationPromises = defaultProducts.map((product) =>
              this.fetchCalculationResult({
                ...initialProductData,
                ...product,
                initialIndexRate: product.type === LOAN_PRODUCT_TYPES.HECM ? (product.fixedRate ?? 7.92) : 0, // Only fixed hecm loan product have fixed rate.
              })
            );

            // Use Promise.allSettled to handle each promise individually
            const results = await Promise.allSettled(calculationPromises);

            // Filter out successful responses and map to your desired structure
            this.productsData = results.reduce((accumulator, result, index) => {
              if (result.status === 'fulfilled') {
                accumulator.push({
                  ...initialProductData,
                  ...defaultProducts[index],
                  ...result.value.data,
                });
              }
              return accumulator;
            }, []);

            // Check if there are any rejections due to no borrower
            const rejectedResults = results.filter((res) => {
              if (res.status === 'rejected') {
                if (res.reason.response?.status == 405) {
                  this.noBorrower = true;
                }
                return true;
              }
              return false;
            });

            // Show error message if there are any rejected results
            if (rejectedResults.length) {
              const errorMessage = rejectedResults[0].reason.response?.data?.detail || 'Something went wrong.';
              this.$root.showSnackbar(errorMessage, 'error');
            }
          } catch (error) {
            this.$root.showSnackbar(`${error.message || 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
        // If there is no products data, then no need to display anything.
        this.noAvailableProduct = !this.productsData.length;
      },
      async fetchUpdatedLoanCalculationResults() {
        if (!this.products.length) {
          this.productsData = [];
        } else {
          this.setLoading(true);
          try {
            const defaultProducts = this.productsData.filter((product) => product.default === true);
            const calculationPromises = defaultProducts.map((product) =>
              this.fetchCalculationResult({
                ...product,
              })
            );

            // Use Promise.allSettled to handle each promise individually
            const results = await Promise.allSettled(calculationPromises);

            // Filter out successful responses and map to your desired structure
            this.productsData = results.reduce((accumulator, result, index) => {
              if (result.status === 'fulfilled') {
                accumulator.push({
                  ...initialProductData,
                  ...defaultProducts[index],
                  ...result.value.data,
                });
              }
              return accumulator;
            }, []);

            // Check if there are any rejections due to no borrower
            const rejectedResults = results.filter((res) => {
              if (res.status === 'rejected') {
                if (res.reason.response?.status == 405) {
                  this.noBorrower = true;
                }
                return true;
              }
              return false;
            });

            // Show error message if there are any rejected results
            if (rejectedResults.length) {
              const errorMessage = rejectedResults[0].reason.response?.data?.detail || 'Something went wrong.';
              this.$root.showSnackbar(errorMessage, 'error');
            }
          } catch (error) {
            this.$root.showSnackbar(`${error.message || 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
        // If there is no products data, then no need to display anything.
        this.noAvailableProduct = !this.productsData.length;
      },
      async fetchCalculationResult(requestBody) {
        return await apiService.post('/loan/loan-calculation/', {
          ...requestBody,
          loan: this.loanId,
        });
      },
      async updateProductCalcResult(index) {
        try {
          this.setLoading(true);
          const calcResponse = await this.fetchCalculationResult({ ...this.productsData[index] });
          if (calcResponse.statusText === 'OK') {
            this.productsData[index] = { ...this.productsData[index], ...calcResponse.data };
          } else {
            console.error('Unexpected response status:', calcResponse.statusText);
            this.$root.showSnackbar(`Unexpected response status: ${calcResponse.statusText})`, 'error');
          }
        } catch (error) {
          console.error('Error updating product calculation result:', error);
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleProductVariableChange(field, index) {
        if (field === 'margin' || field === 'initialIndexRate') {
          await this.updateProductCalcResult(index);
        } else if (field === 'cashout') {
          this.debouncedUpdateCalcResult(index);
        } else if (field === 'lesaRequired') {
          try {
            // TODO: Update the loan's lesaRequired field.
            await apiService.patch(`/loan/loans/${this.loanId}/`, { lesaRequired: !this.productsData[index].lesaRequired });
            // update all the products' lesaRequired field.
            this.productsData.forEach((product) => {
              product.lesaRequired = !product.lesaRequired;
            });
            // And then update the calculation result.
            await this.fetchUpdatedLoanCalculationResults();
          } catch (error) {
            this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          }
        }
      },
      async handleActivate(productId) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (productId == this.activeProduct) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/loans/${this.loanId}/activate_product/`, { productId: productId });
          this.$root.showSnackbar(`Loan's active product has been changed successfully!`, 'success');
          this.activeProduct = productId;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleHideProduct(productId) {
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/products/${productId}/`, { default: false });
          await this.fetchProducts();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Add product logic beginning.
      handleOpenChooseProductsDialog() {
        this.isDialogOpen = true;
      },
      handleOpenPaymentPlanDialog() {
        this.isPaymentPlanDialogOpen = true;
      },
      handleCloseChooseProductsDialog() {
        this.isDialogOpen = false;
      },
      async handleConfirmPaymentPlanDialog(requestedCashAtClosing, lineOfCredit, termOrTenure, monthlyPayment, term) {
        const maxSafeValue = Number.MAX_SAFE_INTEGER;
        requestedCashAtClosing = requestedCashAtClosing === 'Max Value Requested' ? maxSafeValue : requestedCashAtClosing;
        lineOfCredit = lineOfCredit === 'Max Value Requested' ? maxSafeValue : lineOfCredit;

        const activeProduct = this.productsData.find((product) => product.id === this.activeProduct);

        if (activeProduct) {
          Object.assign(activeProduct, {
            requestedCashoutAmountAtClosing: requestedCashAtClosing,
            paymentPlan: termOrTenure,
            term: term,
            desiredLineOfCredit: lineOfCredit,
            monthlyPayment: monthlyPayment,
          });

          const productIndex = this.productsData.indexOf(activeProduct);
          await this.updateProductCalcResult(productIndex);
        } else {
          this.$root.showSnackbar('No activated product found.', 'error');
        }

        this.handleClosePaymentPlanDialog();
      },
      handleClosePaymentPlanDialog() {
        this.isPaymentPlanDialogOpen = false;
      },
      handleOpenModalDescription(key) {
        const description = COMPARISON_DESCRIPTION_TEXTS[key];
        if (description) {
          this.descriptionModalInfo = {
            open: true,
            title: description.title,
            content: description.description,
          };
        }
      },
      handleCloseModalDescription() {
        this.descriptionModalInfo = {
          open: false,
          title: '',
          content: '',
        };
      },
      async handleDialogConfirm() {
        await this.fetchProducts();
        this.handleCloseChooseProductsDialog();
      },
    },
  };
</script>
<style scoped>
  th,
  td {
    border: 1px solid #ddd;
    padding: 0.5rem 0.75rem; /* Reduced padding for a tighter layout */
    text-align: left;
    font-size: 14px; /* Adjust font size */
  }

  tr {
    border: 1px solid transparent;
  }

  tr:nth-child(even) {
    background-color: #f9fafb; /* Light gray background for every other row */
  }

  tr:nth-child(odd) {
    background-color: white; /* White background for other rows */
  }

  .price-comparison-table {
    border-collapse: collapse;
    font-size: 14px; /* Make text a bit smaller to fit more content */
    line-height: 1.4;
  }

  .price-comparison-table th {
    background-color: #f3f4f6; /* Lighter header background */
    font-weight: 600;
    color: #111827; /* Darker text color for headers */
  }

  .price-comparison-table th.active-product {
    background-color: #104862 !important; /* Use !important if necessary */
    color: white !important; /* Override existing text color */
  }

  .price-comparison-table td {
    padding: 0.25rem 0.5rem; /* Even smaller padding for tighter spacing */
  }

  .standard-btn {
    padding: 0.5rem 1rem; /* Standard button size */
    background-color: #104862;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    border: none;
  }

  .standard-btn:disabled {
    background-color: #cbd5e1;
    cursor: not-allowed;
  }

  .standard-btn:hover {
    background-color: #0d3a52; /* Darker shade on hover */
  }
</style>
