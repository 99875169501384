<template>
  <div class="overflow-x-auto">
    <table class="table">
      <!-- Table headers -->
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.key" :class="sortable ? 'cursor-pointer' : ''" :style="{ width: header.width }">
            <span @click="sortTable(header.key)">
              {{ header.label }}
              <span class="ml-1 cursor-pointer" v-if="header.key !== 'action' && sortable">
                <template v-if="sortKey === header.key">
                  {{ sortOrder === 'asc' ? '▲' : '▼' }}
                </template>
                <template v-else>&#8597;</template>
              </span>
            </span>
            <div v-if="columnFilter">
              <input v-if="header.filterType === 'text'" type="text" v-model="filters[header.key]" @input="applyFilter" placeholder="Filter" />
              <select v-else-if="header.filterType === 'select'" v-model="filters[header.key]" @change="applyFilter">
                <option value="">All</option>
                <option v-for="option in header.options" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
              <VueMultiselect
                v-else-if="header.filterType === 'multiselect'"
                v-model="filters[header.key]"
                :options="[...new Set(data.map((row) => row.status))]"
                :searchable="true"
                :allow-empty="true"
                :hideSelected="true"
                :showLabels="false"
                :placeholder="'Filter'"
                :close-on-select="true"
                :multiple="true"
                class="max-w-96 text-sm"
                :max-height="200"
                @update:modelValue="applyFilter"
              ></VueMultiselect>
            </div>
          </th>
        </tr>
      </thead>
      <!-- Table body -->
      <tbody>
        <tr v-if="paginatedData.length === 0">
          <td :colspan="headers.length" class="!text-center">
            <div class="icon">
              <img src="../../assets//image/info.svg" alt="icon" class="mx-auto mb-1" />
              <span>No data available</span>
              <slot name="no-data"></slot>
            </div>
          </td>
        </tr>
        <tr v-for="row in paginatedData" :key="row.id">
          <td v-for="header in headers" :key="header.key" :id="`highlight-${header.key}`">
            <!-- Active Checkbox -->
            <div v-if="header.type === 'checkbox'" class="flex justify-center">
              <CheckboxInput :value="row[header.key]" v-model="row[header.key]" @change="handleCheckboxClicked($event, header.key, row)" />
            </div>
            <!-- Input Area -->
            <div v-else-if="header.type === 'input'">
              <input v-model="row[header.key]" @keyup.enter="handleInputUpdate($event, header.key, row)" />
            </div>
            <!-- Text Area -->
            <div v-else-if="header.type === 'textarea'">
              <textarea v-model="row[header.key]" @keyup.ctrl.enter="handleInputUpdate($event, header.key, row)" @change="handleInputUpdate($event, header.key, row)"></textarea>
            </div>
            <!-- Select Input Area -->
            <div v-else-if="header.type === 'select'">
              <select v-model="row[header.key]" @change="handleSelectInputUpdate($event, header.key, row)">
                <option v-for="option in header.options" :key="option.value" :value="option.value" class="bg-white text-gray-900">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <!-- Boolean Tag -->
            <span v-else-if="typeof row[header.key] == 'boolean'">
              <TagComponent :title="row[header.key] ? 'Yes' : 'No'" />
            </span>
            <!-- Status Tag -->
            <span v-else-if="header.key === 'status'">
              <TagComponent :color="row[header.key]" :title="row[header.key]" />
            </span>
            <!-- Date Input -->
            <div v-else-if="header.key === 'date'">
              <input type="date" :value="formattedDate(row.date)" />
            </div>
            <div v-else-if="header.type === 'money'">
              <span> ${{ row[header.key] }} </span>
            </div>
            <div v-else-if="header.type === 'link'">
              <a href="#" @click="$emit('action', 'view', row)">{{ row[header.key] }}</a>
            </div>
            <!-- Action Buttons -->
            <div class="flex gap-2" v-else-if="header.key === 'action'">
              <button class="icon-button bg-primary" v-if="row.actions?.includes('edit')" @click="$emit('action', 'edit', row)">
                <font-awesome-icon icon="fas fa-pen-to-square" />
              </button>
              <button class="icon-button bg-secondary" v-if="row.actions?.includes('print')" @click="$emit('action', 'print', row)">
                <font-awesome-icon icon="print" />
              </button>
              <button class="icon-button bg-primary" v-if="row.actions?.includes('view')" @click="$emit('action', 'view', row)">
                <font-awesome-icon icon="arrow-up-right-from-square" />
              </button>
              <button class="icon-button bg-cyan-500" v-if="row.actions?.includes('eye')" @click="$emit('action', 'eye', row)">
                <font-awesome-icon icon="fa fa-eye" />
              </button>
              <button class="icon-button bg-red" v-if="row.actions?.includes('delete')" @click="$emit('action', 'delete', row)">
                <font-awesome-icon icon="trash-can" />
              </button>
              <slot name="custom" v-bind:row="row"></slot>
            </div>
            <!-- Default Cell -->
            <span v-else>
              {{ row[header.key] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination controls -->
    <div v-if="totalPages > 1 || filteredData.length > itemsPerPage" class="mt-4 flex justify items-center gap-4">
      <div class="flex items-center space-x-2">
        <span class="text-sm text-gray-600">Page Size:</span>
        <select
          class="w-[60px] px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          v-model="itemsPerPage"
          @change="handleRowsPerPageChange"
        >
          <option v-for="option in rowsPerPageOptions" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
      <div class="flex items-center space-x-4">
        <span class="text-sm text-gray-600"> {{ startIndex + 1 }}-{{ Math.min(endIndex, filteredData.length) }} of {{ data.length }} </span>
        <div class="flex space-x-2">
          <button
            @click="prevPage"
            :disabled="currentPage === 1"
            class="px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            @click="nextPage"
            :disabled="currentPage === totalPages"
            class="px-3 py-1 text-sm text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TagComponent from '@/components/TagComponent/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import VueMultiselect from 'vue-multiselect';

  export default {
    name: 'DataTable',
    components: {
      TagComponent,
      CheckboxInput,
      VueMultiselect,
    },
    props: {
      headers: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
      onDataUpdate: {
        type: Function,
        required: false,
        default: () => {},
      },
      sortable: {
        type: Boolean,
        required: false,
        default: false,
      },
      columnFilter: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        currentPage: 1,
        itemsPerPage: 50,
        maxDisplayedPages: 5,
        rowsPerPageOptions: [50, 75, 100, 250, 500],
        sortOrder: 'asc',
        sortKey: '',
        filters: {},
      };
    },
    computed: {
      formattedDate() {
        return (dateString) => {
          return new Date(dateString).toISOString().slice(0, 10);
        };
      },
      startIndex() {
        return (this.currentPage - 1) * this.itemsPerPage;
      },
      endIndex() {
        return this.startIndex + this.itemsPerPage;
      },
      paginatedData() {
        return this.filteredData.slice(this.startIndex, this.endIndex);
      },
      totalPages() {
        return Math.ceil(this.data.length / this.itemsPerPage);
      },
      filteredData() {
        return this.data.filter((row) => {
          return Object.keys(this.filters).every((key) => {
            const filterValue = this.filters[key];
            const rowValue = row[key];

            if (Array.isArray(filterValue)) {
              // Multiselect filter logic
              return filterValue.length === 0 || filterValue.includes(rowValue);
            }

            if (typeof filterValue === 'string') {
              // Text or single select filter logic
              return filterValue === '' || String(rowValue).toLowerCase().includes(filterValue.toLowerCase());
            }

            return true; // If no filters applied to the column
          });
        });
      },
    },
    watch: {
      data: {
        handler(newData) {
          this.generateFilters(newData);
        },
        immediate: true,
      },
    },
    methods: {
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      handleRowsPerPageChange() {
        this.currentPage = 1; // Reset to the first page whenever rows per page changes
      },
      goToPage(page) {
        this.currentPage = page;
      },
      handleCheckboxClicked(event, key, row) {
        this.$emit('onDataUpdate', key, event.target.checked, row);
      },
      handleInputUpdate(event, key, row) {
        this.$emit('onDataUpdate', key, event.target.value, row);
      },
      handleSelectInputUpdate(event, key, row) {
        this.$emit('onDataUpdate', key, event.target.value, row);
      },
      sortTable(key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        this.sortKey = key;
        this.$emit('sortTable', this.sortKey, this.sortOrder);
      },
      applyFilter() {
        this.currentPage = 1;
      },
      generateFilters() {
        this.filters = this.headers.reduce((acc, header) => {
          acc[header.key] = header.filterType === 'multiselect' ? [] : '';
          return acc;
        }, {});
      },
    },
  };
</script>

<style scoped>
  .multiselect__tag {
    background-color: gray !important; /* Change selected item color */
    color: white !important;
  }

  .table {
    width: 100%;
    min-width: 700px;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 11px 15px;
    font-size: 14px;
    text-align: left;
    border: 1px solid #f3f3f3;
  }

  .word-break {
    word-break: break-word;
  }
</style>
