<template>
  <div class="input-box">
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">
        {{ label }}
      </label>

      <!-- Tooltip Icon with tooltip text on hover -->
      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>

    <div class="relative flex items-center">
      <input
        type="text"
        :id="id"
        :value="formattedInputValue"
        @input="validateInput"
        @keypress="validateNumber"
        class="pl-3"
        :class="['float-input', { invalid: !isValid && !disableValidation }]"
      />
    </div>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  import Tooltip from '@/components/Tooltip';

  export default {
    name: 'FloatInput',
    components: {
      Tooltip,
    },
    props: {
      label: String,
      id: String,
      value: {
        type: [String, Number],
        default: 0,
      },
      disableValidation: Boolean,
      tooltip: {
        type: String,
        default: '',
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputValue: this.value?.toString() || '',
        isValid: true,
        isDirty: false,
      };
    },
    computed: {
      formattedInputValue() {
        if (!this.inputValue) return '';
        const parts = this.inputValue.split('.');
        if (parts[0][0] === '0' && parts[0].length > 1) {
          parts[0] = parts[0].replace(/^0+/, '');
        }
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to the integer part
        return parts.join('.');
      },
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required and must be a valid number with up to two decimal places.`;
        }
        return '';
      },
    },
    methods: {
      validateInput(event) {
        this.isDirty = true;
        // Remove commas for validation and keep the raw number in `inputValue`
        this.inputValue = event.target.value.replace(/,/g, '').replace(/^0+(?!\.|$)/, '');

        if (this.inputValue === '') {
          this.inputValue = '0';
        }

        // Allow up to 2 decimal places
        const regex = /^\d+(\.\d{0,2})?$/;
        this.isValid = regex.test(this.inputValue);

        // Emit the raw number value
        this.$emit('update:modelValue', this.inputValue);
      },
      validateNumber(event) {
        const charCode = event.charCode;
        const charStr = String.fromCharCode(charCode);

        if (
          (charCode < 48 || charCode > 57) && // Not a digit
          charStr !== '.' // Not a decimal point
        ) {
          event.preventDefault();
        } else if (charStr === '.' && this.inputValue.includes('.')) {
          event.preventDefault();
        } else {
          const decimalIndex = this.inputValue.indexOf('.');
          const cursorPosition = event.target.selectionStart - 1;
          const selectionEnd = event.target.selectionEnd - 1;
          const selectedTextLength = selectionEnd - cursorPosition;

          if (decimalIndex !== -1 && cursorPosition > decimalIndex) {
            const decimalPart = this.inputValue.split('.')[1];
            const newDecimalPartLength = decimalPart.length - selectedTextLength + 1;

            if (newDecimalPartLength > 2) {
              event.preventDefault();
            }
          }
        }
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue !== null && newValue !== undefined ? newValue.toString() : '';
      },
      inputValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    mounted() {
      this.inputValue = this.value?.toString() ?? '';
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }

  input {
    padding-left: 15px;
  }
</style>
