<template>
  <Card>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-5 border-b border-gray mb-5">
      <div>
        <SelectInput
          label="Application Taken By *"
          id="applicationTakenBy"
          ref="applicationTakenBy"
          :options="applicationTakenByOptions"
          v-model.trim="formData.applicationTakenBy"
          :value="formData.applicationTakenBy"
          :disable-validation="true"
          tooltip="Indicates the manner in which the loan application has been taken (telephone, face-to-face, fax or email, or over internet)"
        />
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <RadioInput
          label="Ethnicity collected by visual observation?"
          id="ethnicityObservation"
          :options="RADIO_INPUT_OPTIONS"
          v-model="formData.isEthnicityVisual"
          :value="formData.isEthnicityVisual"
          tooltip="Shows if the borrower’s ethnicity was recorded based on the loan officer’s observation. Only used if the borrower did not self-identify."
        />
      </div>
      <div>
        <RadioInput
          label="Race collected by visual observation?"
          id="raceCollectedObservation"
          :options="RADIO_INPUT_OPTIONS"
          v-model="formData.isRaceVisual"
          :value="formData.isRaceVisual"
          tooltip="Indicates if the borrower’s race was recorded based on observation. Only used if the borrower did not self-identify."
        />
      </div>
      <div>
        <RadioInput
          label="Sex collected by visual observation?"
          id="sexCollectedObservation"
          :options="RADIO_INPUT_OPTIONS"
          v-model="formData.isGenderVisual"
          :value="formData.isGenderVisual"
          tooltip="Indicates if the borrower’s sex was recorded based on observation. Only used if the borrower did not self-identify."
        />
      </div>
    </div>

    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Ethnicity</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput
            label="Does Not Wish To Provide"
            id="ethnicityDoesNotWishToProvide"
            :value="formData.ethnicity == 'Does Not Wish To Provide'"
            @change="updateField('ethnicity', 'Does Not Wish To Provide')"
          />
        </div>
        <div>
          <CheckboxInput
            label="Hispanic or Latino"
            id="ethnicityHispanicOrLatino"
            :value="formData.ethnicity == 'Hispanic or Latino'"
            @change="updateField('ethnicity', 'Hispanic or Latino')"
          />
          <div v-if="formData.ethnicity == 'Hispanic or Latino'" class="mt-2">
            <SelectInput
              label="Detailed Ethnicity"
              id="detailedEthnicity"
              :options="HISPANIC_OR_LATINO_OPTIONS"
              v-model="formData.detailedEthnicity"
              :value="formData.detailedEthnicity"
              :disable-validation="true"
            />
            <span v-if="validationErrors.detailedEthnicity" class="text-red text-sm">{{ validationErrors.detailedEthnicity }}</span>
          </div>
          <div v-if="formData.detailedEthnicity == 'Other Hispanic' && formData.ethnicity == 'Hispanic or Latino'" class="mt-2">
            <InputField
              label="Other Hispanic"
              id="otherHispanic"
              v-model.trim="formData.detailedEthnicityOther"
              :value="formData.detailedEthnicityOther"
              :disable-validation="true"
            />
            <span v-if="validationErrors.detailedEthnicityOther" class="text-red text-sm">{{ validationErrors.detailedEthnicityOther }}</span>
          </div>
        </div>
        <div>
          <CheckboxInput
            label="Not Hispanic or Latino"
            id="ethnicityNotHispanicOrLatino"
            :value="formData.ethnicity == 'Not Hispanic or Latino'"
            @change="updateField('ethnicity', 'Not Hispanic or Latino')"
          />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Race</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput
            label="Does Not Wish To Provide"
            id="raceDoesNotWishToProvide"
            :value="formData.race == 'Does Not Wish To Provide'"
            @change="updateField('race', 'Does Not Wish To Provide')"
          />
        </div>
        <div>
          <CheckboxInput
            label="American Indian or Alaska Native"
            id="raceAmericanIndianOrAlaskaNative"
            :value="formData.race == 'American Indian or Alaska Native'"
            @change="updateField('race', 'American Indian or Alaska Native')"
          />
          <div v-if="formData.race == 'American Indian or Alaska Native'" class="mt-2">
            <InputField label="American Indian or Alaska Native" v-model.trim="formData.detailedRaceOther" :value="formData.detailedRaceOther" :disable-validation="true" />
            <span v-if="validationErrors.detailedRaceOther" class="text-red text-sm">{{ validationErrors.detailedRaceOther }}</span>
          </div>
        </div>
        <div>
          <CheckboxInput label="Asian" id="raceAsian" :value="formData.race == 'Asian'" @change="updateField('race', 'Asian')" />
          <div v-if="formData.race == 'Asian'" class="mt-2">
            <SelectInput
              label="Detailed Race"
              id="detailedRace"
              :options="ASIAN_OPTIONS"
              v-model="formData.detailedRace"
              :value="formData.detailedRace"
              :disable-validation="true"
            />
            <span v-if="formData.race == 'Asian' && validationErrors.detailedRace" class="text-red text-sm">{{ validationErrors.detailedRace }}</span>
          </div>
          <div v-if="formData.race == 'Asian' && formData.detailedRace == 'Other Asian'" class="mt-2">
            <InputField label="Other Asian" v-model.trim="formData.detailedRaceOther" :value="formData.detailedRaceOther" :disable-validation="true" />
            <span v-if="validationErrors.detailedRaceOther" class="text-red text-sm">{{ validationErrors.detailedRaceOther }}</span>
          </div>
        </div>
        <div>
          <CheckboxInput
            label="Black or African American"
            id="raceBlackOrAfricanAmerican"
            :value="formData.race == 'Black or African American'"
            @change="updateField('race', 'Black or African American')"
          />
        </div>
        <div>
          <CheckboxInput
            label="Native Hawaiian or Pacific Islander"
            id="raceNativeHawaiianOrPacificIslander"
            :value="formData.race == 'Native Hawaiian or Pacific Islander'"
            @change="updateField('race', 'Native Hawaiian or Pacific Islander')"
          />
          <div v-if="formData.race == 'Native Hawaiian or Pacific Islander'" class="mt-2">
            <SelectInput
              label="Detailed Race"
              id="detailedRace"
              :options="NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER_OPTIONS"
              v-model="formData.detailedRace"
              :value="formData.detailedRace"
              :disable-validation="true"
            />
            <span v-if="formData.race == 'Native Hawaiian or Pacific Islander' && validationErrors.detailedRace" class="text-red text-sm">{{ validationErrors.detailedRace }}</span>
          </div>
          <div v-if="formData.race == 'Native Hawaiian or Pacific Islander' && formData.detailedRace == 'Other Pacific Islander'" class="mt-2">
            <InputField
              label="Other Pacific Islander"
              id="otherNativeHawaiianOrPacificIslander"
              v-model.trim="formData.detailedRaceOther"
              :value="formData.detailedRaceOther"
              :disable-validation="true"
            />
            <span v-if="validationErrors.detailedRaceOther" class="text-red text-sm">{{ validationErrors.detailedRaceOther }}</span>
          </div>
        </div>
        <div>
          <CheckboxInput label="White" id="raceWhite" :value="formData.race == 'White'" @change="updateField('race', 'White')" />
        </div>
      </div>
    </div>
    <div>
      <h3 class="mt-4 mb-3 text-lg font-semibold">Sex</h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <CheckboxInput
            label="Does Not Wish To Provide"
            id="sexDoesNotWishToProvide"
            :value="formData.sex == 'Does Not Wish To Provide'"
            @change="updateField('sex', 'Does Not Wish To Provide')"
          />
        </div>
        <div>
          <CheckboxInput label="Male" id="sexMale" :value="formData.sex == 'Male'" @change="updateField('sex', 'Male')" />
        </div>
        <div>
          <CheckboxInput label="Female" id="sexFemale" :value="formData.sex == 'Female'" @change="updateField('sex', 'Female')" />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import Button from '@/components/Button/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import { RADIO_INPUT_OPTIONS, HISPANIC_OR_LATINO_OPTIONS, ASIAN_OPTIONS, NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER_OPTIONS } from '@/constants';

  const initialData = {
    applicationTakenBy: null,
    isEthnicityVisual: null,
    isRaceVisual: null,
    isGenderVisual: null,
    ethnicity: null,
    detailedEthnicity: null,
    detailedEthnicityOther: null,
    race: null,
    detailedRace: null,
    detailedRaceOther: null,
    sex: null,
  };

  export default {
    name: 'DemographicInfo',
    components: {
      Button,
      Card,
      RadioInput,
      CheckboxInput,
      SelectInput,
      InputField,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {
        RADIO_INPUT_OPTIONS,
        HISPANIC_OR_LATINO_OPTIONS,
        ASIAN_OPTIONS,
        NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER_OPTIONS,
      };
    },
    data() {
      return {
        localData: { ...initialData },
        formData: { ...initialData },
        applicationTakenByOptions: [
          { label: 'Face-to-Face Interview', value: 'Face-to-Face Interview' },
          { label: 'Telephone Interview', value: 'Telephone Interview' },
          { label: 'Fax or Email', value: 'Fax or Email' },
          { label: 'Email or Internet', value: 'Email or Internet' },
        ],
        validationErrors: {
          detailedEthnicity: '',
          detailedRace: '',
          detailedEthnicityOther: '',
          detailedRaceOther: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        this.isDraft = !isEqual(this.formData, this.localData);
      },
      updateField(field, value) {
        this.formData[field] = this.formData[field] == value ? null : value;
      },
      async fetchDemographicInfo() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/demographic_infos/by-borrower/${this.borrowerId}/`);
          this.localData = { ...response.data };
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      validateForm() {
        this.validationErrors = {
          detailedEthnicity: this.formData.ethnicity == 'Hispanic or Latino' && !this.formData.detailedEthnicity ? 'Detailed ethnicity is required' : '',
          detailedRace:
            (this.formData.race == 'Asian' || this.formData.race == 'Native Hawaiian or Pacific Islander') && !this.formData.detailedRace ? 'Detailed race is required' : '',
          detailedEthnicityOther:
            this.formData.ethnicity == 'Hispanic or Latino' && this.formData.detailedEthnicity == 'Other Hispanic' && !this.formData.detailedEthnicityOther?.length
              ? 'Other ethnicity is required'
              : '',
          detailedRaceOther:
            (this.formData.race == 'Native Hawaiian or Pacific Islander' && this.formData.detailedRace == 'Other Pacific Islander' && !this.formData.detailedRaceOther?.length) ||
            (this.formData.race == 'American Indian or Alaska Native' && !this.formData.detailedRaceOther?.length) ||
            (this.formData.race == 'Asian' && this.formData.detailedRace == 'Other Asian' && !this.formData.detailedRaceOther?.length)
              ? 'Other race is required'
              : '',
        };
        return (
          !this.validationErrors.detailedEthnicity.length &&
          !this.validationErrors.detailedRace.length &&
          !this.validationErrors.detailedEthnicityOther.length &&
          !this.validationErrors.detailedRaceOther.length
        );
      },
      resetForm() {
        this.formData = { ...this.localData };
        this.validationErrors = {
          detailedEthnicity: '',
          detailedRace: '',
          detailedEthnicityOther: '',
        };
      },
      handleRevertChanges() {
        this.resetForm();
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId) return;
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.formData.ethnicity != 'Hispanic or Latino') {
            // If ethnicity is not Hispanic or Latino, detailed ethnicity and detailed ethnicity other should be null
            this.formData['detailedEthnicity'] = null;
            this.formData['detailedEthnicityOther'] = null;
          } else if (this.formData.detailedEthnicity != 'Other Hispanic') {
            // If detailed ethnicity is not Other Hispanic, detailed ethnicity other should be null
            this.formData['detailedEthnicityOther'] = null;
          }

          if (this.formData.race != 'Native Hawaiian or Pacific Islander') {
            // If race is not Native Hawaiian or Pacific Islander, detailed race and detailed race other should be null
            this.formData['detailedRace'] = null;
            this.formData['detailedRaceOther'] = null;
          } else if (this.formData.detailedRace != 'Other Pacific Islander') {
            // If detailed race is not Other Pacific Islander, detailed race other should be null
            this.formData['detailedRaceOther'] = null;
          }

          if (this.formData.race != 'American Indian or Alaska Native') {
            // If race is not American Indian or Alaska Native, detailed race other should be null
            this.formData['detailedRaceOther'] = null;
          }

          if (this.formData.race != 'Asian') {
            // If race is not Asian, detailed race and detailed race other should be null
            this.formData['detailedRace'] = null;
            this.formData['detailedRaceOther'] = null;
          } else if (this.formData.detailedRace != 'Other Asian') {
            // If detailed race is not Other Asian, detailed race other should be null
            this.formData['detailedRaceOther'] = null;
          }

          if (this.localData.id) await apiService.patch(`/loan/demographic_infos/${this.localData.id}/`, { ...this.formData });
          else
            await apiService.post(`/loan/demographic_infos/`, {
              borrower: this.borrowerId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Demographic information has been updated successfully!`, 'success');
          await this.fetchDemographicInfo();
          this.resetForm();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      resetLocalData() {
        this.localData = {
          applicationTakenBy: null,
          isEthnicityVisual: null,
          isRaceVisual: null,
          isGenderVisual: null,
          ethnicity: null,
          race: null,
          sex: null,
        };
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchDemographicInfo();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
