<template>
  <section class="border border-gray rounded-lg bg-white h-full">
    <div class="flex justify-between py-3 pl-2.5 pr-4">
      <h3 class="text-lg font-semibold text-dark">Quick Calculations</h3>
      <font-awesome-icon icon="xmark" class="cursor-pointer" @click="$emit('close')" />
    </div>
    <ul class="quick-cal-box" v-if="localQuickCalculationData?.quickCalculation">
      <li>
        <p @click="showField('monthlyIncome')">Income</p>
        <span @click="showField('monthlyIncome')">{{ formattedNumber(localQuickCalculationData?.quickCalculation?.totalIncome?.monthly, '$') }}</span>
      </li>
      <li>
        <p @click="showField('expenses')">Expenses</p>
        <span @click="showField('expenses')">{{ formattedNumber(localQuickCalculationData?.quickCalculation?.totalExpenses?.monthly, '$') }}</span>
      </li>
      <li>
        <p @click="showField('residualIncome')">Residual Income (RI)</p>
        <span @click="showField('residualIncome')">{{ formattedNumber(localQuickCalculationData?.quickCalculation?.residualIncome?.monthly, '$') }}</span>
      </li>
      <li>
        <p @click="showField('familySize')">Family Size</p>
        <span @click="showField('familySize')">{{ localQuickCalculationData?.quickCalculation?.familySize }}</span>
      </li>
      <li>
        <p @click="showField('residualIncomeRequired')">Residual Income Required</p>
        <span @click="showField('residualIncomeRequired')">{{ formattedNumber(localQuickCalculationData?.quickCalculation?.residualIncomeRequired?.monthly, '$') }}</span>
      </li>
      <li>
        <p @click="showField('residualIncomeFullFilled')">Residual Income Fulfilled</p>
        <span @click="showField('residualIncomeFullFilled')">{{ localQuickCalculationData?.quickCalculation?.percentageResidualIncomeFulfilled + '%' }}</span>
      </li>
    </ul>
    <h3 v-if="localQuickCalculationData?.loanProceedsData" class="text-lg font-semibold text-dark p-2.5">Loan Proceeds</h3>
    <ul class="loan-proceeds-box" v-if="localQuickCalculationData?.loanProceedsData">
      <li>
        <p @click="showField('homeValue')">Home Value</p>
        <span @click="showField('homeValue')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.homeValue, '$') }}</span>
      </li>
      <li>
        <p @click="showField('expectedRate')">Expected Rate</p>
        <span @click="showField('expectedRate')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.expectedRate) }}%</span>
      </li>
      <li>
        <p @click="showField('margin')">Margin</p>
        <span @click="showField('margin')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.margin) }}%</span>
      </li>
      <li>
        <p @click="showField('principalLimit')">Principal Limit</p>
        <span @click="showField('principalLimit')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.principalLimit, '$') }}</span>
      </li>
      <li>
        <p @click="showField('orginationFee')">Origination Fee</p>
        <span @click="showField('orginationFee')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.originationFee, '$') }}</span>
      </li>
      <li>
        <p @click="showField('imip')">IMIP</p>
        <span @click="showField('imip')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.imip, '$') }}</span>
      </li>
      <li>
        <p @click="showField('otherClosingCost')">Other Closing Costs</p>
        <span @click="showField('otherClosingCost')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.thirdPartyClosingCosts, '$') }}</span>
      </li>
      <li>
        <p @click="showField('payOffs')">Payoffs</p>
        <span @click="showField('payOffs')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.totalPayoffs, '$') }}</span>
      </li>
      <li>
        <p @click="showField('lessa')">LESA</p>
        <span @click="showField('lessa')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData?.lifeExpectancySetAside, '$') }}</span>
      </li>
      <li>
        <p @click="showField('cashToBorrower')">Cash to Borrower</p>
        <span @click="showField('cashToBorrower')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.fundsAvailableAtClosing, '$') }}</span>
      </li>
      <li>
        <p @click="showField('lineOfCredit')">Line of Credit</p>
        <span @click="showField('lineOfCredit')">{{ formattedNumber(localQuickCalculationData?.loanProceedsData.lineOfCreditAfterYear1, '$') }}</span>
      </li>
    </ul>
    <h3 class="text-lg font-semibold text-white bg-primary text-center py-3 cursor-pointer" @click="showField('comparison')">Go to Full Comparison</h3>
    <div class="flex items-center py-3 pl-2.5 pr-4 cursor-pointer" @click="openDialog">
      <h3 class="text-lg font-semibold text-dark mr-2">Loan Parties</h3>
      <font-awesome-icon icon="arrow-right" class="cursor-pointer" />
    </div>
    <ul>
      <li>
        <p>LO: {{ getAvailableUserValue('loan_officer') }}</p>
      </li>
      <li>
        <p>Processor: {{ getAvailableUserValue('processor') }}</p>
      </li>
      <li>
        <p>Underwriter: {{ getAvailableUserValue('underwriter') }}</p>
      </li>
      <li>
        <p>Closer: {{ getAvailableUserValue('closer') }}</p>
      </li>
      <li>
        <p>Funder: {{ getAvailableUserValue('funder') }}</p>
      </li>
      <li>
        <p>Post Closer: {{ getAvailableUserValue('post_closer') }}</p>
      </li>
    </ul>
  </section>
  <Dialog :title="'Edit Loan Parties'" :isOpen="isDialogOpen" @close="closeDialog" @confirm="handleConfirm">
    <p class="text-sm text-gray-500 mb-4">Clearing a field will unassign the assigned officer.</p>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
      <div>
        <AutoCompleteField
          mode="select"
          label="Loan Officer"
          id="loan_officer"
          ref="loan_officer"
          :items="loanOfficers"
          :value="formData.loan_officer"
          v-model.trim="formData.loan_officer"
          :disable-validation="false"
        />
      </div>

      <div>
        <AutoCompleteField
          mode="select"
          label="Processor"
          id="processor"
          ref="processor"
          :items="processors"
          :value="formData.processor"
          v-model.trim="formData.processor"
          :disable-validation="false"
        />
      </div>
      <div>
        <AutoCompleteField
          mode="select"
          label="Underwriter"
          id="underwriter"
          ref="underwriter"
          :items="underwriters"
          :value="formData.underwriter"
          v-model.trim="formData.underwriter"
          :disable-validation="false"
        />
      </div>
      <div>
        <AutoCompleteField
          mode="select"
          label="Closer"
          id="closer"
          ref="closer"
          :items="closers"
          :value="formData.closer"
          v-model.trim="formData.closer"
          :disable-validation="false"
        />
      </div>
      <div>
        <AutoCompleteField
          mode="select"
          label="Funder"
          id="funder"
          ref="funder"
          :items="funders"
          :value="formData.funder"
          v-model.trim="formData.funder"
          :disable-validation="false"
        />
      </div>
      <div>
        <AutoCompleteField
          mode="select"
          label="Post Closer"
          id="post_closer"
          ref="post_closer"
          :items="postClosers"
          :value="formData.post_closer"
          v-model.trim="formData.post_closer"
          :disable-validation="false"
        />
      </div>
    </div>
    <div class="flex justify-end mt-4 gap-2">
      <Button variant="primary" @click="updateLoanParties">Save Changes</Button>
      <Button variant="primary" @click="handleConfirm">Close</Button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from './../../../../components/Dialog/index.vue';
  import AutoCompleteField from '@/components/FormInput/AutoCompleteField/index.vue';
  import Button from './../../../../components/Button/index.vue';
  import apiService from '@/api/apiService';
  import { formatNumberWithCommas } from '@/utils';
  export default {
    name: 'QuickCalculations',
    components: { Dialog, AutoCompleteField, Button },
    inject: ['quickCalculationData', 'setQuickCalculationData'],
    data() {
      return {
        isDialogOpen: false,
        formData: {
          loan_officer: '',
          creditAnalyst: '',
          processor: '',
          pricingAnalyst: '',
          underwriter: '',
          closer: '',
          post_closer: '',
        },
        formDataBackup: {},
        loanOfficers: [],
        underwriters: [],
        processors: [],
        closers: [],
        postClosers: [],
        funders: [],
        availableUsers: null,
      };
    },
    async created() {
      await this.fetchLoanParties();
      await this.fetchAvailableUsers();
      this.formDataBackup = { ...this.formData };
    },
    methods: {
      openDialog() {
        this.isDialogOpen = true;
      },
      closeDialog() {
        this.isDialogOpen = false;
      },
      handleConfirm() {
        this.isDialogOpen = false;
      },
      requestError(e) {
        if (e.response && e.response.status === 403) {
          console.log('403 Forbidden: ', e.message);
          return;
        }
        this.$root.showSnackbar('An error occurred while processing your request. Please try again later.', 'error');
        console.log('Request Error: ', e.message);
      },
      showField(key) {
        this.$emit('directToOrginalFieldLocation', { key: key });
      },
      async fetchLoanParties() {
        try {
          const response = await apiService.get('/loan/parties/', {
            loan_id: this.$route.params.id,
          });
          const data = response.data;
          //map identify by keys
          this.loanOfficers = data['Loan officer'].map((officer) => ({
            value: officer.id,
            label: officer.name,
          }));
          this.processors = data['Processor'].map((processor) => ({
            value: processor.id,
            label: processor.name,
          }));
          this.underwriters = data['Underwriter'].map((underwriter) => ({
            value: underwriter.id,
            label: underwriter.name,
          }));
          this.closers = data['Closer'].map((closer) => ({
            value: closer.id,
            label: closer.name,
          }));
          this.funders = data['Funder'].map((funder) => ({
            value: funder.id,
            label: funder.name,
          }));
          this.postClosers = data['Post-Closer'].map((postCloser) => ({
            value: postCloser.id,
            label: postCloser.name,
          }));
        } catch (e) {
          this.requestError(e);
        }
      },
      async fetchAvailableUsers() {
        try {
          const response = await apiService.get('/loan/parties/available-users/', {
            loan_id: this.$route.params.id,
          });
          const data = response.data;
          if (!data || Object.keys(data).length === 0) {
            this.availableUsers = {};
          }
          this.availableUsers = data;
          if (data['loan_officer']) {
            this.formData.loan_officer = data['loan_officer'][0]?.name || '';
          }
          if (data['processor']) {
            this.formData.processor = data['processor'][0]?.name || '';
          }

          if (data['underwriter']) {
            this.formData.underwriter = data['underwriter'][0]?.name || '';
          }
          if (data['closer']) {
            this.formData.closer = data['closer'][0]?.name || '';
          }
          if (data['funder']) {
            this.formData.funder = data['funder'][0]?.name || '';
          }
          if (data['post_closer']) {
            this.formData.post_closer = data['post_closer'][0]?.name || '';
          }
        } catch (e) {
          this.requestError(e);
        }
      },
      async updateLoanParties() {
        const deleteParty = async (id) => {
          return await apiService.delete(`/loan/parties/${id}/`);
        };

        const addParty = async (key) => {
          await apiService.post('/loan/parties/', {
            is_delete: false,
            is_active: true,
            role: key,
            loan: this.$route.params.id,
            user: this.formData[key],
          });
          this.formDataBackup[key] = this.formData[key];
          changesFlag = true;
        };

        let changesFlag = false;
        let deleteFlag = false;
        const promises = [];

        for (const key in this.formData) {
          const currentData = this.formData[key];
          const backupData = this.formDataBackup[key];
          const isNumber = typeof currentData === 'number' && !isNaN(currentData);
          const isEmptyString = currentData === '';

          if (currentData !== backupData) {
            if (isNumber) {
              if (this.availableUsers && this.availableUsers[key]) {
                promises.push(deleteParty(this.availableUsers[key][0].id));
              }
              promises.push(addParty(key));
            } else if (isEmptyString && this.availableUsers[key]) {
              promises.push(
                deleteParty(this.availableUsers[key][0].id).then((response) => {
                  this.formDataBackup[key] = currentData;
                  if (response.status === 200) {
                    this.$root.showSnackbar(response.data.message, 'error');
                    deleteFlag = true;
                  }
                })
              );
            }
          }
        }

        try {
          await Promise.all(promises).then(() => {
            if (changesFlag || deleteFlag) {
              if (changesFlag) this.$root.showSnackbar('Loan parties updated successfully', 'success');
              this.formDataBackup = { ...this.formData };
              this.fetchAvailableUsers();
            } else {
              this.$root.showSnackbar('No Changes', 'warning');
            }
          });
        } catch (e) {
          this.requestError(e);
          return;
        }
      },
      getAvailableUserValue(key) {
        if (this.availableUsers && this.availableUsers[key]) {
          return this.availableUsers[key][0]?.name || '';
        }
        return '';
      },
      formattedNumber(value, leadingSign = '') {
        if (value === null || value === undefined) {
          return '--';
        }
        return leadingSign + formatNumberWithCommas(value);
      },
    },
    computed: {
      localQuickCalculationData: {
        get() {
          return this.quickCalculationData();
        },
        set() {
          this.setQuickCalculationData();
        },
      },
    },
  };
</script>

<style scoped>
  ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
  }

  ul li p,
  ul li span {
    font-size: 14px;
  }

  ul li:nth-child(odd) {
    background: rgba(229, 229, 229, 0.4);
  }
  .quick-cal-box p,
  .loan-proceeds-box p,
  .quick-cal-box span,
  .loan-proceeds-box span {
    cursor: pointer;
  }
  .quick-cal-box p:hover,
  .loan-proceeds-box p:hover,
  .quick-cal-box span:hover,
  .loan-proceeds-box span:hover {
    color: #000;
  }
</style>
