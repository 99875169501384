export const DEFAULT_FONT_SIZE = 14;
export const DOCUMENT_GAP = 8;
export const PAGE_GAP = 12;

export const PAGE_MARGINS = {
  MEDIUM: '20mm',
  SMALL: '15mm',
  SLIM: '10mm',
  TINY: '5mm',
};

export const PAPER_SIZES = {
  LEGAL: [215.9, 355.6],
  LETTER: [215.9, 279.4],
};

export const MODEL_FIELDS = [
  {
    title: 'Borrower Information',
    key: 'borrower',
    items: [
      {
        title: 'First Name',
        key: 'firstName',
        jinjaExpression: 'borrower.firstName',
      },
      {
        title: 'Middle Name',
        key: 'middleName',
        jinjaExpression: 'borrower.middleName',
      },
      {
        title: 'Last Name',
        key: 'lastName',
        jinjaExpression: 'borrower.lastName',
      },
      {
        title: 'SSN',
        key: 'ssn',
        jinjaExpression: 'borrower.ssn',
      },
      {
        title: 'DOB',
        key: 'dob',
        jinjaExpression: 'borrower.dob',
      },
      {
        title: 'Monthly Income',
        key: 'monthlyIncome',
        jinjaExpression: 'borrower.monthlyIncome',
      },
      {
        title: 'Real Estate Assets',
        key: 'realEstateAssets',
        jinjaExpression: 'borrower.realEstateAssets',
      },
      {
        title: 'Available Assets',
        key: 'availableAssets',
        jinjaExpression: 'borrower.availableAssets',
      },
      {
        title: 'Home Phone',
        key: 'homePhone',
        jinjaExpression: 'borrower.homePhone',
      },
      {
        title: 'Years of Residence',
        key: 'yearsOfResidence',
        jinjaExpression: 'borrower.yearsOfResidence',
      },
      {
        title: 'Marital Status',
        key: 'maritalStatus',
        jinjaExpression: 'borrower.maritalStatus',
      },
      {
        title: 'Non Real Estate Debt',
        key: 'nonRealEstateDebt',
        jinjaExpression: 'borrower.nonRealEstateDebt',
      },
      {
        title: 'Answers to all "Declarations" questions',
        key: 'answers',
        jinjaExpression: 'borrower.answers',
      },
    ],
  },
  {
    title: 'Alternative Contact',
    key: 'alternativeContact',
    items: [
      {
        title: 'First Name',
        key: 'firstName',
        jinjaExpression: 'alternativeContact.firstName',
      },
      {
        title: 'Last Name',
        key: 'lastName',
        jinjaExpression: 'alternativeContact.lastName',
      },
      {
        title: 'Address',
        key: 'address',
        jinjaExpression: 'alternativeContact.address',
      },
      {
        title: 'City',
        key: 'city',
        jinjaExpression: 'alternativeContact.city',
      },
      {
        title: 'State',
        key: 'state',
        jinjaExpression: 'alternativeContact.state',
      },
      {
        title: 'Zip',
        key: 'zip',
        jinjaExpression: 'alternativeContact.zip',
      },
      {
        title: 'Phone',
        key: 'phone',
        jinjaExpression: 'alternativeContact.phone',
      },
    ],
  },
  {
    title: 'Payoffs',
    key: 'creditor',
    items: [
      {
        title: 'Creditor Name',
        key: 'creditorName',
        jinjaExpression: 'creditor.creditorName',
      },
      {
        title: 'Creditor Address',
        key: 'creditorAddress',
        jinjaExpression: 'creditor.creditorAddress',
      },
      {
        title: 'Creditor City',
        key: 'creditorCity',
        jinjaExpression: 'creditor.creditorCity',
      },
      {
        title: 'Creditor Zip',
        key: 'creditorZip',
        jinjaExpression: 'creditor.creditorZip',
      },
      {
        title: 'Creditor State',
        key: 'creditorState',
        jinjaExpression: 'creditor.creditorState',
      },
      {
        title: 'Balance',
        key: 'balance',
        jinjaExpression: 'creditor.balance',
      },
      {
        title: 'Account Number',
        key: 'accountNumber',
        jinjaExpression: 'payoffs.accountNumber',
      },
    ],
  },
  {
    title: 'Property Information',
    key: 'property',
    items: [
      {
        title: 'Property Address',
        key: 'address',
        jinjaExpression: 'property.address',
      },
      {
        title: 'Property City',
        key: 'city',
        jinjaExpression: 'property.city',
      },
      {
        title: 'Property State',
        key: 'state',
        jinjaExpression: 'property.state',
      },
      {
        title: 'Property Zip',
        key: 'zipCode',
        jinjaExpression: 'property.zipCode',
      },
      {
        title: 'Property Legal Description',
        key: 'propertyLegalDescription',
        jinjaExpression: 'property.propertyLegalDescription',
      },
      {
        title: 'Property Title Held As',
        key: 'propertyTitleHeldAs',
        jinjaExpression: 'property.propertyTitleHeldAs',
      },
    ],
  },
  {
    title: 'Appraisal Information',
    key: 'appraisal',
    items: [
      {
        title: 'Year Built',
        key: 'yearBuilt',
        jinjaExpression: 'appraisal.yearBuilt',
      },
      {
        title: 'Appraised Value',
        key: 'appraisedValue',
        jinjaExpression: 'appraisal.appraisedValue',
      },
      {
        title: 'Number of Units',
        key: 'numberOfUnits',
        jinjaExpression: 'appraisal.numberOfUnits',
      },
    ],
  },
  {
    title: 'Title Information',
    key: 'title',
    items: [
      {
        title: 'Title Held As',
        key: 'titleHeldAs',
        jinjaExpression: 'title.titleHeldAs',
      },
      {
        title: 'Title Living Trust',
        key: 'livingTrust',
        jinjaExpression: 'title.livingTrust',
      },
    ],
  },
  {
    title: 'Loan Information',
    key: 'loan',
    items: [
      {
        title: 'FHA Case Number',
        key: 'fhaCaseNumber',
        jinjaExpression: 'loan.fhaCaseNumber',
      },
      {
        title: 'Loan Number',
        key: 'loanNumber',
        jinjaExpression: 'loan.loanNumber',
      },
      {
        title: 'Loan Purpose',
        key: 'loanPurpose',
        jinjaExpression: 'loan.loanPurpose',
      },
    ],
  },
  {
    title: 'Loan Officer',
    key: 'loanOfficer',
    items: [
      {
        title: 'Loan Officer Name',
        key: 'name',
        jinjaExpression: 'loanOfficer.name',
      },
      {
        title: 'Loan Officer NMLS',
        key: 'nmls',
        jinjaExpression: 'loanOfficer.nmls',
      },
      {
        title: 'Loan Officer State License',
        key: 'stateLicense',
        jinjaExpression: 'loanOfficer.stateLicense',
      },
      {
        title: 'Loan Officer Phone Number',
        key: 'phone',
        jinjaExpression: 'loanOfficer.phone',
      },
    ],
  },
  {
    title: 'Company',
    key: 'company',
    items: [
      {
        title: 'Company',
        key: 'companyName',
        jinjaExpression: 'company.name',
      },
      {
        title: 'Company NMLS',
        key: 'nmls',
        jinjaExpression: 'company.nmls',
      },
      {
        title: 'Company State License',
        key: 'stateLicense',
        jinjaExpression: 'company.stateLicense',
      },
      {
        title: 'Company Address',
        key: 'address',
        jinjaExpression: 'company.address',
      },
    ],
  },
  {
    title: 'Loan Calculation',
    key: 'loanCalculation',
    items: [
      {
        title: 'Origination Fee',
        key: 'originationFee',
        jinjaExpression: 'loanCalculation.originationFee',
      },
      {
        title: 'IMIP',
        key: 'imip',
        jinjaExpression: 'loanCalculation.imip',
      },
      {
        title: 'Closing Costs',
        key: 'closingCosts',
        jinjaExpression: 'loanCalculation.closingCosts',
      },
      {
        title: 'Principal Limit',
        key: 'principalLimit',
        jinjaExpression: 'loanCalculation.principalLimit',
      },
      {
        title: 'UPB',
        key: 'upb',
        jinjaExpression: 'loanCalculation.upb',
      },
      {
        title: 'Cash Out',
        key: 'cashOut',
        jinjaExpression: 'loanCalculation.cashOut',
      },
      {
        title: 'Line of Credit',
        key: 'lineOfCredit',
        jinjaExpression: 'loanCalculation.lineOfCredit',
      },
      {
        title: 'Initial Disbursement Limit',
        key: 'idl',
        jinjaExpression: 'loanCalculation.idl',
      },
      {
        title: 'LESA Year 1',
        key: 'lesaYear1',
        jinjaExpression: 'loanCalculation.lesaYear1',
      },
      {
        title: 'LESA Remaining',
        key: 'lesaRemaining',
        jinjaExpression: 'loanCalculation.lesaRemaining',
      },
      {
        title: 'Total LESA',
        key: 'totalLesa',
        jinjaExpression: 'loanCalculation.totalLesa',
      },
    ],
  },
  {
    title: 'Dates',
    key: 'dates',
    items: [
      {
        title: 'Application Date',
        key: 'applicationDate',
        jinjaExpression: 'dates.applicationDate',
      },
      {
        title: 'Closing Date',
        key: 'closingDate',
        jinjaExpression: 'dates.closingDate',
      },
      {
        title: 'Scheduled Funding Date',
        key: 'scheduledFundingDate',
        jinjaExpression: 'dates.scheduledFundingDate',
      },
    ],
  },
];
