<template>
  <Dialog :title="'History'" :isOpen="isHistoryDialogOpen" @confirm="closeDialog" @close="closeDialog">
    <div class="w-100" v-if="!historyLaoading">
      <table class="table">
        <tr>
          <th>Time</th>
          <th>Changed By</th>
          <th>Old Value</th>
          <th>New Value</th>
        </tr>
        <tr v-for="history in historyData" :key="history.date">
          <td>{{ history.date }}</td>
          <td>{{ history.changedBy }}</td>
          <td>
            <span v-if="typeof history.oldValue === 'string'">{{ history.oldValue }}</span>
            <span v-else-if="typeof history.oldValue === 'boolean'">{{ history.oldValue ? 'Yes' : 'No' }}</span>
            <span v-else-if="typeof history.oldValue === 'number'">{{ history.oldValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
          </td>
          <td>
            <span v-if="typeof history.newValue === 'string'">{{ history.newValue }}</span>
            <span v-else-if="typeof history.newValue === 'boolean'">{{ history.newValue ? 'Yes' : 'No' }}</span>
            <span v-else-if="typeof history.newValue === 'number'">{{ history.newValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="4" v-if="historyData.length == 0 && !historyLoading" class="item">No history found</td>
        </tr>
      </table>
    </div>
    <template v-slot:footer>
      <div class="flex justify-end gap-4 p-5 border-t">
        <Button variant="primary" v-if="page != 1 && totalPages != 0" style="width: fit-content" @click="pageChange(this.page - 1)"> Prev </Button>
        <Button variant="secondary" v-if="totalPages > page" style="width: fit-content" @click="pageChange(this.page + 1)"> Next </Button>
      </div>
    </template>
    <Loading :isLoading="historyLoading" />
  </Dialog>
</template>

<script>
  import apiService from '@/api/apiService';
  import Loading from '@/components/Loading/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  export default {
    name: 'HistoryDialog',
    components: {
      Loading,
      Dialog,
    },
    methods: {
      openHistoryDialog(field, model, model_id = null) {
        this.page = 1;
        this.historyLoading = true;
        this.historyData = [];
        this.isHistoryDialogOpen = true;
        this.field = field;
        this.model = model;
        this.loadHistoryData(this.field, this.model, model_id);
      },
      pageChange(page) {
        this.page = page;
        this.historyLoading = true;
        this.loadHistoryData(this.field, this.model);
      },
      async loadHistoryData(field, model, model_id) {
        const response = await this.apiService.get(`/loan/field-history/`, {
          fields: field,
          model: model,
          loan_id: this.loanId,
          model_id: model_id,
          page: this.page,
        });

        if (response) {
          if (response.data.data) {
            const data = response.data;
            this.totalPages = data.total_pages;
            this.historyData = data.data.map((history) => ({
              date: history.date,
              changedBy: history.changedBy,
              oldValue: history.old_value,
              newValue: history.new_value,
            }));
          } else {
            this.historyData = [];
            this.totalPages = 0;
          }
        }
        this.historyLoading = false;
      },
      closeDialog() {
        this.isHistoryDialogOpen = false;
      },
    },
    data() {
      return {
        loanId: this.$route.params.id,
        isHistoryDialogOpen: this.dialog,
        historyData: [],
        model: null,
        field: null,
        totalPages: 0,
        page: 1,
        isLoading: false,
        historyLoading: false,
        apiService: apiService,
      };
    },
  };
</script>

<style scoped>
  .item {
    padding: 20px;
    text-align: center;
  }
</style>
