<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goToReporting" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">User Access</h1>
      </div>
    </template>
    <template v-slot:action>
      <div class="flex items-center gap-2">
        <div class="flex items-center gap-1">
          <label class="mb-0">From: </label>
          <input type="datetime-local" class="p-2 cursor-pointer" v-model="fromDate" @keydown.prevent @focus="showDateTimePicker" />
        </div>
        <div class="flex items-center gap-1">
          <label class="mb-0">To: </label>
          <input type="datetime-local" class="p-2 cursor-pointer" v-model="toDate" @keydown.prevent @focus="showDateTimePicker" />
        </div>
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" :sortable="true" @sortTable="handleSortTable" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { formatDateTime } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'UserAccess',
    components: {
      Card,
      DataTable,
    },
    setup() {
      return {
        formatDateTime,
      };
    },
    data() {
      return {
        headers: [
          { key: 'user', label: 'User' },
          { key: 'pageVisited', label: 'Page Visited' },
          { key: 'ipAddress', label: 'IP Address' },
          { key: 'time', label: 'Time' },
        ],
        tableData: [],
        fromDate: null,
        toDate: null,
      };
    },
    async created() {
      await this.fetchUserAccess();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchUserAccess() {
        if ((this.fromDate && !this.isValidDate(this.fromDate)) || (this.toDate && !this.isValidDate(this.toDate))) {
          this.$root.showSnackbar('Please enter valid dates.', 'error');
          return;
        }
        this.setLoading(true);
        try {
          const response = await apiService.post('company/list-user-access/', {
            start_timestamp: this.fromDate,
            end_timestamp: this.toDate,
          });
          this.handleTableData(response);
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch user access logs: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      isValidDate(date) {
        return !isNaN(new Date(date).getTime());
      },
      handleTableData(response) {
        this.tableData = response.data.map((log) => ({
          user: log.user_full_name,
          pageVisited: log.page_requested,
          ipAddress: log.ip_address,
          time: formatDateTime(log.timestamp),
        }));
      },
      handleSortTable(key, order) {
        this.tableData.sort((a, b) => {
          if (order === 'asc') {
            return a[key] < b[key] ? -1 : 1;
          } else {
            return a[key] > b[key] ? -1 : 1;
          }
        });
      },
      showDateTimePicker(event) {
        event.target.showPicker();
      },
      goToReporting() {
        this.$router.push({ name: 'Reporting' });
      },
    },
    computed: {},
    watch: {
      fromDate() {
        this.fetchUserAccess();
      },
      toDate() {
        this.fetchUserAccess();
      },
    },
  };
</script>
