<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goToReporting" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Document History</h1>
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" :sortable="true" @sortTable="handleSortTable" @action="handleAction" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { formatDateTime } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'DocumentHistory',
    components: {
      Card,
      DataTable,
    },
    setup() {
      return {
        formatDateTime,
      };
    },
    data() {
      return {
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'user', label: 'Printed By' },
          { key: 'time', label: 'Printed At' },
          { key: 'loan_number', label: 'Loan Number' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        fromDate: null,
        toDate: null,
      };
    },
    async created() {
      await this.fetchDocumentHistory();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchDocumentHistory() {
        this.setLoading(true);
        try {
          const response = await apiService.post('documents/histories/', {});
          this.handleTableData(response);
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch document history: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleTableData(response) {
        this.tableData = response.data.map((item) => ({
          name: item.package_name,
          user: item.user,
          time: formatDateTime(item.created_at),
          loan_number: item.loan_number,
          document_url: item.document_url,
          actions: ['print'],
        }));
      },
      handleSortTable(key, order) {
        this.tableData.sort((a, b) => {
          if (order === 'asc') {
            return a[key] < b[key] ? -1 : 1;
          } else {
            return a[key] > b[key] ? -1 : 1;
          }
        });
      },
      async handleAction(action, item) {
        if (action === 'print') {
          this.setLoading(true);
          try {
            const url = item.document_url;
            const responseFile = await fetch(url);
            const blob = await responseFile.blob();
            const objectUrl = window.URL.createObjectURL(blob);
            const linkElement = document.createElement('a');
            linkElement.href = objectUrl;
            linkElement.download = url.split('/').pop();
            linkElement.click();
            window.URL.revokeObjectURL(objectUrl);

            this.$root.showSnackbar('Document package downloaded successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to download document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
      goToReporting() {
        this.$router.push({ name: 'Reporting' });
      },
    },
    computed: {},
  };
</script>
