<template>
  <!-- Tooltip Icon with tooltip text on hover -->
  <span v-if="tooltip?.length > 0" class="ml-1 cursor-pointer text-primary" v-tooltip="{ content: tooltip, triggers: ['hover'], autoHide: true }">
    <font-awesome-icon :icon="['fa', 'circle-info']" :style="{ fontSize: '14px' }" />
  </span>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Tooltip',
    props: {
      tooltip: {
        type: String,
        required: false,
        default: '',
      },
    },
  };
</script>
