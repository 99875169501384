<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goToSettings" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Company Settings</h1>
      </div>
    </template>
    <div v-if="isCompanyAdmin()">
      <Card title="Settings">
        <template v-slot:action>
          <Button variant="primary" @click="handleSaveCompany" :disabled="!isFormValid">Save Changes</Button>
        </template>
        <div v-if="company !== null">
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 pb-5">
            <div>
              <InputField label="Name *" id="name" v-model="company.name" :value="company.name" :disable-validation="true" />
            </div>
            <div>
              <SelectInput label="Company Type" id="type" :options="companyTypeOptions" v-model="company.company_type" :value="company.company_type" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="EIN" id="ein" :value="company.ein" v-model.trim="company.ein" :disable-validation="true" />
            </div>
            <div>
              <InputField label="LEI" id="lei" :value="company.lei" v-model.trim="company.lei" :disable-validation="true" />
            </div>
            <div v-if="company.company_type != 'broker'">
              <IntegerInput label="FHA ID" id="fha_id" :value="company.fha_id" v-model.trim="company.fha_id" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="MERS Organization ID" id="org_id" :value="company.org_id" v-model.trim="company.org_id" :max-length="7" :disable-validation="true" />
            </div>
            <div>
              <IntegerInput label="NMLS ID" id="nmls_id" max-length="5" :value="company.nmls_id" v-model.trim="company.nmls_id" :disable-validation="true" />
            </div>
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 pb-5">
            <div>
              <InputField label="Address 1" id="address_1" :value="company.address_line_1" v-model.trim="company.address_line_1" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Address 2" id="address_2" :value="company.address_line_2" v-model.trim="company.address_line_2" :disable-validation="true" />
            </div>
            <div>
              <InputField label="City" id="city" :value="company.city" v-model.trim="company.city" :disable-validation="true" />
            </div>
            <div>
              <SelectInput label="State" id="state" :options="stateOptions" v-model="company.state" :value="company.state" :disable-validation="true" />
            </div>
            <div>
              <InputField label="Zip Code" id="zip_code" :value="company.zip_code" v-model.trim="company.zip_code" :disable-validation="true" />
            </div>
          </div>
        </div>

        <!-- Divider -->
        <div class="w-full h-px my-2 bg-gray-300"></div>

        <div class="flex items-center justify-between mb-2">
          <h1 class="text-lg font-semibold leading-none">Company Licenses</h1>
          <Button variant="primary" @click="openAddLicenseDialog">Add License</Button>
        </div>
        <DataTable :headers="headers" :data="licensesData" @action="handleAction" />
      </Card>

      <div class="py-3"></div>
      <!-- Loan Products Area -->
      <LoanProducts />

      <div class="py-3"></div>
      <!-- Loan Standard Conditions Area -->
    </div>
    <!-- <LoanStandardConditions :enable-add="false" /> -->
  </Card>
  <Dialog :title="isLicenseEditMode ? 'Edit License' : 'Add New License'" :isOpen="isLicenseDialogOpen" @confirm="handleConfirmLicenseDialog" @close="handleCloseLicenseDialog">
    <form @submit.prevent="handleConfirmLicenseDialog">
      <div class="form-group">
        <SelectInput label="State *" id="state" :options="stateOptions" v-model="licenseForm.state" :value="licenseForm.state" :validation="{ required: true }" />
        <span v-if="!licenseForm.state && validationErrors.state" class="text-sm text-red">{{ validationErrors.state }}</span>
      </div>
      <br />
      <div class="form-group">
        <RadioInput label="Is License Active?" id="status" :options="radioInputOptions" v-model="licenseForm.status" :value="licenseForm.status" />
      </div>
      <br />
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 pb-5">
        <div class="form-group">
          <DateInput label="Expiration Date *" id="expiration_date" v-model="licenseForm.expirationDate" :value="licenseForm.expirationDate" :validation="{ required: true }" />
          <span v-if="!licenseForm.expirationDate && validationErrors.expirationDate" class="text-sm text-red">{{ validationErrors.expirationDate }}</span>
        </div>
        <div class="form-group">
          <InputField label="License No *" id="license-no" v-model="licenseForm.licenseNo" :value="licenseForm.licenseNo" :disable-validation="true" />
          <span v-if="!licenseForm.licenseNo && validationErrors.licenseNo" class="text-sm text-red">{{ validationErrors.licenseNo }}</span>
        </div>
      </div>
      <div class="form-actions flex justify-end">
        <Button type="submit">{{ isLicenseEditMode ? 'Save Changes' : 'Add License' }}</Button>
      </div>
    </form>
  </Dialog>
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this product?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import LoanProducts from './components/LoanProducts/index.vue';
  import { isSuperUser, isCompanyAdmin, isCompanyUser, isStandardConditionsAdmin } from '@/utils';
  import Button from '@/components/Button/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  // import LoanStandardConditions from '@/pages/LoanDetail/components/Condition/components/StandardLoanConditions/index.vue';
  import { US_STATES } from '@/constants';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'CompanySettings',
    components: {
      Card,
      Button,
      LoanProducts,
      InputField,
      Dialog,
      SelectInput,
      IntegerInput,
      DataTable,
      RadioInput,
      Confirmation,
      DateInput,
      // LoanStandardConditions,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
        isStandardConditionsAdmin,
      };
    },
    data() {
      return {
        company: {
          id: null,
          name: '',
          company_type: 'broker',
          ein: 0,
          lei: '',
          fha_id: 0,
          org_id: 0,
          nmls_id: 0,
          address_line_1: '',
          address_line_2: '',
          state: '',
          city: '',
          zip_code: '',
        },
        companyTypeOptions: [
          { label: 'Broker', value: 'broker' },
          { label: 'Lender', value: 'lender' },
        ],
        stateOptions: US_STATES,
        isLicenseDialogOpen: false,
        isDeleteConfirmationOpen: false,
        isLicenseEditMode: false,
        licenseForm: {
          state: '',
          status: false,
          expirationDate: '',
          licenseNo: '',
        },
        validationErrors: {
          state: '',
          expirationDate: '',
          licenseNo: '',
        },
        headers: [
          { key: 'state', label: 'State' },
          { key: 'status', label: 'Status' },
          { key: 'expirationDate', label: 'Expiration Date' },
          { key: 'licenseNo', label: 'License No' },
          { key: 'action', label: 'Action' },
        ],
        licensesData: [],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    async created() {
      if (!isCompanyAdmin() && !isStandardConditionsAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchCompanyData();
    },
    computed: {
      isFormValid() {
        const { name, ein, fha_id, company_type, org_id } = this.company;
        const areFieldsFilled = name && ein && org_id && (company_type == 'broker' || (company_type == 'lender' && fha_id));
        return areFieldsFilled;
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCompanyData() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/company/companies/me/');
          this.company = { ...response.data };
          this.licensesData = this.company.licenses.map((license, key) => ({
            id: key + 1,
            dataId: license.id,
            state: license.state,
            active: license.isActive,
            expirationDate: license.expirationDate,
            status: license.isActive ? 'Active' : 'InActive',
            licenseNo: license.licenseNo,
            actions: ['edit', 'delete'],
          }));
        } catch (error) {
          this.$root.showSnackbar(`${error.response ? error.response.data.message : error.message}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveCompany() {
        let payload = {
          ...this.company,
          licenses: this.licensesData,
        };
        if (this.company.company_type == 'broker') payload['fha_id'] = null;

        this.setLoading(true);
        try {
          await apiService.patch(`/company/companies/${this.company.id}/`, payload);
          this.$root.showSnackbar('Company data has been updated successfully!', 'success');

          // this.goToSettings();
        } catch (error) {
          let errorMessage = '';
          if (error.response && error.response.data) {
            const errorData = { ...error.response.data };

            const firstKey = Object.keys(errorData)[0];
            errorMessage = Array.isArray(errorData[firstKey]) ? errorData[firstKey][0] : errorData[firstKey];
          } else {
            errorMessage = error.message;
          }
          this.$root.showSnackbar(`Error: ${errorMessage}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      resetForm() {
        this.licenseForm = {
          state: '',
          status: false,
          expirationDate: '',
          licenseNo: '',
        };
        this.validationErrors = {
          state: '',
          expirationDate: '',
          licenseNo: '',
        };
      },
      validateForm() {
        this.validationErrors = {
          state: !this.licenseForm.state ? 'State is required' : '',
          expirationDate: !this.licenseForm.expirationDate ? 'Expiration Date is required' : '',
          licenseNo: !this.licenseForm.licenseNo ? 'License No is required' : '',
        };

        return !this.validationErrors.state && !this.validationErrors.expirationDate && !this.validationErrors.licenseNo;
      },
      openAddLicenseDialog() {
        this.resetForm();
        this.isLicenseEditMode = false;
        this.isLicenseDialogOpen = true;
      },
      openEditLicenseDialog(license) {
        this.selectedLicense = license;
        this.licenseForm = {
          ...license,
          expirationDate: license.expirationDate,
          status: license.status == 'Active' ? true : false,
        };
        this.isLicenseEditMode = true;
        this.isLicenseDialogOpen = true;
      },
      handleCloseLicenseDialog() {
        this.isLicenseDialogOpen = false;
        this.resetForm();
      },
      async handleConfirmDeleteConfirmation() {
        this.licensesData = this.licensesData.filter((license) => license.id !== this.selectedLicense.id).map((license, index) => ({ ...license, id: index }));
        await this.handleSaveCompany();
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditLicenseDialog(item);
        } else if (action === 'delete') {
          this.selectedLicense = { ...item };
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleConfirmLicenseDialog() {
        if (!this.validateForm()) {
          return;
        }
        if (this.isLicenseEditMode) {
          this.licensesData = this.licensesData.map((license) => {
            if (this.selectedLicense.id == license.id) {
              return {
                ...license,
                state: this.licenseForm.state,
                active: this.licenseForm.status,
                expirationDate: this.licenseForm.expirationDate,
                status: this.licenseForm.status ? 'Active' : 'InActive',
                licenseNo: this.licenseForm.licenseNo,
              };
            }
            return { ...license };
          });
        } else {
          const newLicenseData = {
            id: this.licensesData.length + 1,
            dataId: -1,
            state: this.licenseForm.state,
            active: this.licenseForm.status,
            expirationDate: this.licenseForm.expirationDate,
            status: this.licenseForm.status ? 'Active' : 'InActive',
            licenseNo: this.licenseForm.licenseNo,
            actions: ['edit', 'delete'],
          };
          let data = this.licensesData;
          data.push({ ...newLicenseData });
          this.licensesData = data;
        }

        await this.handleSaveCompany();

        this.isLicenseDialogOpen = false;
        this.resetForm();
      },
      goToSettings() {
        this.$router.push({ name: 'Settings' });
      },
    },
  };
</script>

<style scoped></style>
