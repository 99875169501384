<template>
  <Card customClass="flex flex-col min-h-full">
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goBack" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Document Packages</h1>
      </div>
    </template>
    <template v-slot:action>
      <div class="flex items-center">
        <input v-model="searchQuery" class="search-input mr-[15px]" placeholder="Search by title..." />
        <Dropdown>
          <template v-slot:button>
            <button @click="navigateToCreateNew" class="standard-btn h-[39px]"><font-awesome-icon icon="fa-solid fa-plus" /><span class="ml-2">Create New</span></button>
          </template>
        </Dropdown>
      </div>
    </template>

    <div class="grow">
      <DataTable :headers="headers" :data="filteredTableData" @action="handleAction" />
    </div>
  </Card>
  <!-- Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete the package?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser } from '@/utils';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Dropdown from '@/components/Dropdown/index.vue';
  import { HARD_CODED_DOCUMENT_TYPES } from '@/constants';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'DocumentPackage',
    components: {
      Card,
      Confirmation,
      DataTable,
      Dropdown,
    },
    setup() {
      return {
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
        HARD_CODED_DOCUMENT_TYPES,
      };
    },
    async created() {
      if (!isCompanyAdmin()) {
        this.$router.push({ name: 'Dashboard' });
        return;
      }
      await this.fetchInitialData();
    },
    data() {
      return {
        templates: [],
        tableData: [],
        headers: [
          { key: 'idx', label: 'No' },
          { key: 'name', label: 'Title' },
          { key: 'templates', label: 'Templates' },
          { key: 'action', label: 'Action' },
        ],
        searchQuery: '',
        deleteId: -1,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchInitialData() {
        this.setLoading(true);
        try {
          let response = await apiService.get('/documents/templates/');
          this.templates = response.data;
          response = await apiService.get('/documents/packages/');
          this.handleTableData(response.data);
          await this.fetchPackages(); // Fetch packages after fetching templates
        } catch (error) {
          this.setLoading(false);
          this.$root.showSnackbar(`Failed to fetch templates: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchPackages() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/documents/packages/');
          this.handleTableData(response.data);
        } catch (error) {
          this.tableData = [];
          this.$root.showSnackbar(`Failed to fetch document packages: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleTableData(data) {
        this.tableData = data.map((element, index) => {
          const normalTemplatesNames = element.normal_templates.map((template) => this.templates.find((t) => t.id === template.id).name).join(', ');

          const hardcodedTemplatesNameList = element.hardcoded_templates
            .map((el) => HARD_CODED_DOCUMENT_TYPES.find((type) => type.value === el.name)?.name)
            .filter(Boolean)
            .join(', ');

          const combinedTemplatesNameList = `${normalTemplatesNames || ''}${hardcodedTemplatesNameList ? `${normalTemplatesNames ? ', ' : ''}${hardcodedTemplatesNameList}` : ''}`;

          return {
            idx: index + 1,
            id: element.id,
            name: element.name,
            templates: combinedTemplatesNameList,
            actions: ['edit', 'delete'],
          };
        });
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.$router.push({ name: 'PackageUpdate', params: { id: item.id } });
        } else if (action === 'delete') {
          this.deleteId = item.id;
        }
      },
      async handleConfirmDeleteConfirmation() {
        this.setLoading(true);
        try {
          await apiService.delete(`/documents/packages/${this.deleteId}/`);
          this.$root.showSnackbar('Document package deleted successfully', 'success');
          await this.fetchPackages();
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleCloseDeleteConfirmation() {
        // Means close delete confirmation
        this.deleteId = -1;
      },
      navigateToCreateNew() {
        this.$router.push({ name: 'PackageCreate' });
      },
      goBack() {
        this.$router.push({ name: 'Document' });
      },
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) {
          return this.tableData;
        }
        const query = this.searchQuery.toLowerCase();
        return this.tableData.filter((packageData) => {
          return packageData.name.toLowerCase().includes(query);
        });
      },
      isDeleteConfirmationOpen() {
        return this.deleteId != -1;
      },
    },
  };
</script>

<style scoped></style>
