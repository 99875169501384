<template>
  <div class="flex grow h-full">
    <!-- Suggested Fields Section -->
    <div v-if="isLeftSidebarOpen" class="relative w-1/3 p-2 bg-gray-100 overflow-y-auto">
      <h3 class="text-md font-semibold mb-2">Suggested Fields</h3>
      <div v-for="(field, index) in fields" :key="index" class="py-1 mb-1">
        <div class="mb-1">**{{ field.title }}</div>
        <div v-for="(item, index) in field.items" :key="index" class="text-sm bg-gray-200 p-1 mb-1 cursor-pointer rounded" @mousedown.prevent @click.stop="insertField(item)">
          {{ item.title }} ({{ item.jinjaExpression }})
        </div>
      </div>
      <div></div>
    </div>

    <!-- Close left sidebar button -->
    <div v-else class="relative">
      <button class="absolute top-1/2 transform -translate-y-1/2 p-2 bg-gray-900 text-white rounded-r-md z-10" @click="toggleSidebar('left')">
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </button>
    </div>

    <!-- Main Editor -->
    <div
      :class="{
        'w-2/3': isLeftSidebarOpen,
        'w-full': !isLeftSidebarOpen,
      }"
      class="h-full border relative"
    >
      <div class="main w-full h-full overflow-auto bg-gray-300">
        <!-- Tool bar -->
        <vue-file-toolbar-menu :content="menu" class="bar" />
        <!-- Document editor -->
        <div class="w-full relative">
          <vue-document-editor
            class="editor"
            ref="editor"
            v-model:content="content"
            :zoom="zoom"
            :page_format_mm="page_format_mm"
            :page_margins="page_margins"
            :display="display"
          />
        </div>
      </div>

      <!-- Buttons to close left & right sidebars -->
      <button v-if="isLeftSidebarOpen" @click="toggleSidebar('left')" class="absolute top-1/2 transform -translate-y-1/2 p-2 bg-gray-900 text-white rounded-r-md z-10">
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
    </div>
  </div>
</template>

<script>
  import { MODEL_FIELDS, PAPER_SIZES, PAGE_MARGINS } from '../const.js';
  import VueFileToolbarMenu from 'vue-file-toolbar-menu';
  import VueDocumentEditor from 'vue-document-editor';

  export default {
    name: 'CustomEditor',
    components: {
      VueFileToolbarMenu,
      VueDocumentEditor,
    },
    props: {
      pagesData: {
        type: Array,
        required: true,
        default: () => [''],
      },
      paperSize: {
        type: String,
        required: true,
        default: 'LEGAL',
      },
      pageMargins: {
        type: String,
        required: true,
        default: PAGE_MARGINS.SLIM,
      },
    },
    data() {
      return {
        fields: MODEL_FIELDS,
        isLeftSidebarOpen: true,

        // From here, for editor
        content: this.pagesData,
        page_format_mm: PAPER_SIZES[this.paperSize],
        page_margins: this.pageMargins,
        zoom: 1,
        zoom_min: 0.5,
        zoom_max: 2.0,
        display: 'vertical',
        mounted: false, // will be true after this component is mounted
        undo_count: -1, // contains the number of times user can undo (= current position in content_history)
        content_history: [], // contains the content states for undo/redo operations
      };
    },
    created() {
      // Manage history undo/redo events
      const manage_undo_redo = (e) => {
        switch (e && e.inputType) {
          case 'historyUndo':
            e.preventDefault();
            e.stopPropagation();
            this.undo();
            break;
          case 'historyRedo':
            e.preventDefault();
            e.stopPropagation();
            this.redo();
            break;
        }
      };
      window.addEventListener('beforeinput', manage_undo_redo);
      window.addEventListener('input', manage_undo_redo); // in case of beforeinput event is not implemented (Firefox)
    },
    mounted() {
      this.mounted = true;
      window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.$forceUpdate();
      },
      getCssProperty(elm, property) {
        // return window.getComputedStyle(elm,null).getPropertyValue(property);
        return parseFloat(elm.style[property].slice(5));
      },
      async toggleSidebar(side) {
        if (side === 'left') {
          this.isLeftSidebarOpen = !this.isLeftSidebarOpen;
        }
        await this.$nextTick();
        this.$refs.editor.update_editor_width();
        this.$forceUpdate();
      },
      emitUpdatePagesData(newContent) {
        this.$emit('update:pagesData', newContent);
      },
      emitUpdatePaperSize() {
        const newSize = this.current_format_name === 'Legal' ? 'LEGAL' : 'LETTER';
        this.$emit('update:paperSize', newSize);
      },
      emitUpdatePageMargin(newMargin) {
        this.$emit('update:pageMargins', newMargin);
      },

      // Editor methods
      // Undo / redo functions examples
      undo() {
        if (this.can_undo) {
          this._mute_next_content_watcher = true;
          this.content = this.content_history[--this.undo_count];
        }
      },
      redo() {
        if (this.can_redo) {
          this._mute_next_content_watcher = true;
          this.content = this.content_history[++this.undo_count];
        }
      },
      // Insert page break function example
      async insertPageBreak() {
        // insert paragraph at caret position
        document.execCommand('insertParagraph');

        // insert a marker at caret position (start of the new paragraph)
        const marker = '###PB###'; // must be regex compatible
        document.execCommand('insertText', false, marker);

        // wait for v-model content update (two ticks are needed to reactivate watch on content)
        await this.$nextTick();
        await this.$nextTick();

        // find the marker inside content items and split this content item in two items between the two paragraphs
        // only match root tags (p, div, h1, h2...) to avoid non-root tags like <li>
        const regexp = new RegExp('<(p|div|h\\d)( [^/>]+)*>(<[^/>]+>)*' + marker);
        for (let i = 0; i < this.content.length; i++) {
          const item = this.content[i];
          if (typeof item != 'string') continue;
          const match = regexp.exec(item);
          if (match) {
            const tags_open = match[0].slice(0, -marker.length);
            let content_plus_tags_close = item.substr(match.index + match[0].length);
            // insert <br> to empty pages that would not be handled correctly by contenteditable
            if (content_plus_tags_close.indexOf('</') == 0) content_plus_tags_close = '<br>' + content_plus_tags_close;
            this.content.splice(i, 1, item.substr(0, match.index), tags_open + content_plus_tags_close);
            return;
          }
        }

        // if the code didn't return before, the split didn't work (e.g. inside a <li>). just remove the marker from the content
        for (let i = 0; i < this.content.length; i++) {
          const item = this.content[i];
          if (typeof item != 'string' || item.indexOf(marker) < 0) continue;
          this.content.splice(i, 1, item.replace(marker, ''));
          break;
        }
      },

      // Insert field to editor
      async insertField(field) {
        const insertText = `{{ ${field.jinjaExpression} }}`;
        document.execCommand('insertText', false, insertText);
        await this.$nextTick();
        await this.$nextTick();
      },
    },
    watch: {
      content: {
        immediate: true,
        // Fill undo / redo history stack on user input
        handler(newContent) {
          // Emit content change to parent component
          this.emitUpdatePagesData(newContent);

          if (!this._mute_next_content_watcher) {
            // only update the stack when content is changed by user input, not undo/redo commands
            this.content_history[++this.undo_count] = newContent;
            this.content_history.length = this.undo_count + 1; // remove all redo items
          }
          this._mute_next_content_watcher = false;
        },
      },
      pagesData: {
        handler(newValue) {
          this.content = newValue;
        },
        immediate: true,
        deep: true,
      },
      paperSize: {
        handler(newValue) {
          this.page_format_mm = PAPER_SIZES[newValue];
        },
        immediate: true,
        deep: true,
      },
      pageMargins: {
        handler(newValue) {
          this.page_margins = newValue;
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      menu() {
        return [
          // Undo / redo commands
          { title: 'Undo', icon: 'undo', disabled: !this.can_undo, hotkey: this.isMacLike ? 'command+z' : 'ctrl+z', click: () => this.undo() },
          { title: 'Redo', icon: 'redo', disabled: !this.can_redo, hotkey: this.isMacLike ? 'shift+command+z' : 'ctrl+y', click: () => this.redo() },

          { is: 'spacer' },

          // Rich text menus
          {
            icon: 'format_align_left',
            title: 'Align left',
            active: this.isLeftAligned,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'shift+command+l' : 'ctrl+shift+l',
            click: () => document.execCommand('justifyLeft'),
          },
          {
            icon: 'format_align_center',
            title: 'Align center',
            active: this.isCentered,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'shift+command+e' : 'ctrl+shift+e',
            click: () => document.execCommand('justifyCenter'),
          },
          {
            icon: 'format_align_right',
            title: 'Align right',
            active: this.isRightAligned,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'shift+command+r' : 'ctrl+shift+r',
            click: () => document.execCommand('justifyRight'),
          },
          {
            icon: 'format_align_justify',
            title: 'Justify content',
            active: this.isJustified,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'shift+command+j' : 'ctrl+shift+j',
            click: () => document.execCommand('justifyFull'),
          },

          { is: 'separator' },

          {
            icon: 'format_bold',
            title: 'Bold',
            active: this.isBold,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'command+b' : 'ctrl+b',
            click: () => document.execCommand('bold'),
          },
          {
            icon: 'format_italic',
            title: 'Italic',
            active: this.isItalic,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'command+i' : 'ctrl+i',
            click: () => document.execCommand('italic'),
          },
          {
            icon: 'format_underline',
            title: 'Underline',
            active: this.isUnderline,
            disabled: !this.current_text_style,
            hotkey: this.isMacLike ? 'command+u' : 'ctrl+u',
            click: () => document.execCommand('underline'),
          },
          {
            icon: 'format_strikethrough',
            title: 'Strike through',
            active: this.isStrikeThrough,
            disabled: !this.current_text_style,
            click: () => document.execCommand('strikethrough'),
          },
          {
            is: 'button-color',
            type: 'compact',
            menu_class: 'align-center',
            disabled: !this.current_text_style,
            color: this.curColor,
            update_color: (new_color) => document.execCommand('foreColor', false, new_color.hex8),
          },
          { is: 'separator' },
          { html: '<b>H1</b>', title: 'Header 1', active: this.isH1, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h1>') },
          { html: '<b>H2</b>', title: 'Header 2', active: this.isH2, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h2>') },
          { html: '<b>H3</b>', title: 'Header 3', active: this.isH3, disabled: !this.current_text_style, click: () => document.execCommand('formatBlock', false, '<h3>') },
          {
            icon: 'format_clear',
            title: 'Clear format',
            disabled: !this.current_text_style,
            click() {
              document.execCommand('removeFormat');
              document.execCommand('formatBlock', false, '<div>');
            },
          },
          { icon: 'splitscreen', title: 'Page break', disabled: !this.current_text_style, click: () => this.insertPageBreak() },

          { is: 'spacer' },

          {
            // Format menu
            text: this.current_format_name,
            title: 'Format',
            icon: 'crop_free',
            chevron: true,
            menu: this.formats.map(([text, w, h]) => {
              return {
                text,
                active: this.page_format_mm[0] == w && this.page_format_mm[1] == h,
                click: () => {
                  this.page_format_mm = [w, h];
                  // Emit event to update parent's paper size
                  this.emitUpdatePaperSize();
                },
              };
            }),
            menu_width: 80,
            menu_height: 280,
          },
          {
            // Margins menu
            text: this.current_margins_name,
            title: 'Margins',
            icon: 'select_all',
            chevron: true,
            menu: this.margins.map(([text, value]) => {
              return {
                text: text + ' (' + value + ')',
                active: this.page_margins == value,
                click: () => {
                  this.page_margins = value;
                  // Emit event to update parent's page margin
                  this.emitUpdatePageMargin(value);
                },
              };
            }),
            menu_width: 200,
            menu_class: 'align-center',
          },
          {
            // Zoom menu
            text: Math.floor(this.zoom * 100) + '%',
            title: 'Zoom',
            icon: 'zoom_in',
            chevron: true,
            menu: [
              ['200%', 2.0],
              ['150%', 1.5],
              ['125%', 1.25],
              ['100%', 1.0],
              ['75%', 0.75],
              ['50%', 0.5],
            ].map(([text, zoom]) => {
              return {
                text,
                active: this.zoom == zoom,
                click: () => {
                  this.zoom = zoom;
                  setTimeout(() => {
                    this.$forceUpdate();
                  }, 100);
                },
              };
            }),
            menu_width: 80,
            menu_height: 280,
            menu_class: 'align-center',
          },
        ];
      },

      // Formats management
      current_format_name() {
        const format = this.formats.find(([, width_mm, height_mm]) => this.page_format_mm[0] == width_mm && this.page_format_mm[1] == height_mm);
        return format ? format[0] : this.page_format_mm[0] + 'mm x ' + this.page_format_mm[1] + 'mm';
      },
      formats: () => [
        ['Legal', ...PAPER_SIZES.LEGAL],
        ['Letter', ...PAPER_SIZES.LETTER],
      ],

      // Margins management
      current_margins_name() {
        const margins = this.margins.find(([, margins]) => this.page_margins == margins);
        return margins ? margins[0] : this.page_margins;
      },
      margins: () => [
        ['Medium', PAGE_MARGINS.MEDIUM],
        ['Small', PAGE_MARGINS.SMALL],
        ['Slim', PAGE_MARGINS.SLIM],
        ['Tiny', PAGE_MARGINS.TINY],
      ],

      // Current text style management
      current_text_style() {
        return this.mounted ? this.$refs.editor.current_text_style : false;
      },
      isLeftAligned() {
        return ['start', 'left', '-moz-left'].includes(this.current_text_style.textAlign);
      },
      isRightAligned() {
        return ['end', 'right', '-moz-right'].includes(this.current_text_style.textAlign);
      },
      isCentered() {
        return ['center', '-moz-center'].includes(this.current_text_style.textAlign);
      },
      isJustified() {
        return ['justify', 'justify-all'].includes(this.current_text_style.textAlign);
      },
      isBold() {
        const fontWeight = this.current_text_style.fontWeight;
        return fontWeight && (parseInt(fontWeight) > 400 || fontWeight.indexOf('bold') == 0);
      },
      isItalic() {
        return this.current_text_style.fontStyle == 'italic';
      },
      isUnderline() {
        // text-decoration is not overridden by children, so we query the parent stack
        const stack = this.current_text_style.textDecorationStack;
        return stack && stack.some((d) => d.indexOf('underline') == 0);
      },
      isStrikeThrough() {
        // text-decoration is not overridden by children, so we query the parent stack
        const stack = this.current_text_style.textDecorationStack;
        return stack && stack.some((d) => d.indexOf('line-through') == 0);
      },
      isH1() {
        return this.current_text_style.headerLevel == 1;
      },
      isH2() {
        return this.current_text_style.headerLevel == 2;
      },
      isH3() {
        return this.current_text_style.headerLevel == 3;
      },
      curColor() {
        return this.current_text_style.color || 'transparent';
      },

      // Platform management
      isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),

      // Undo / redo flags
      can_undo() {
        return this.undo_count > 0;
      },
      can_redo() {
        return this.content_history.length - this.undo_count - 1 > 0;
      },
    },
  };
</script>
<style>
  .editor {
    font-family: 'Times New Roman', Times, serif;
  }

  .editor h1 {
    font-size: 32px;
    font-weight: 700;
  }

  .editor h2 {
    font-size: 24px;
    font-weight: 700;
  }

  .editor h3 {
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style scoped>
  .bar {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1000;
    background: rgba(248, 249, 250, 0.8);
    border-bottom: solid 1px rgb(248, 249, 250);
    backdrop-filter: blur(10px);
    --bar-button-active-color: #188038;
    --bar-button-open-color: #188038;
    --bar-button-active-bkg: #e6f4ea;
    --bar-button-open-bkg: #e6f4ea;
  }
</style>
