<template>
  <!-- <LoanStandardConditions :writePermission="writePermission" @addConditionPack="handleAddConditionPack" /> -->
  <Card title="Conditions" class="mt-4">
    <template v-slot:action>
      <div class="flex items-center gap-4">
        <Button :disabled="!writePermission" class="standard-btn" @click="openAddConditionDialog">Add Condition</Button>
        <Button :disabled="!writePermission" class="standard-btn" @click="openAddConditionPackDialog">Add Conditions From Package</Button>
      </div>
    </template>
    <DataTable :headers="headers" :data="tableData" @action="handleAction" @onDataUpdate="handleKeyValueUpdate" />
  </Card>
  <!-- Add/Edit Condition Dialog -->
  <Dialog :title="isEditMode ? 'Edit Condition' : 'Add New Condition'" :isOpen="isConditionDialogOpen" @close="handleCloseConditionDialog">
    <form @submit.prevent="handleConfirmConditionDialog">
      <div class="mb-4">
        <MultilineSelectInput
          label="Condition Type"
          id="conditionType"
          :options="CONDITION_TYPE_OPTIONS"
          v-model="formData.conditionType"
          :value="formData.conditionType"
          :disable-validation="true"
        />
        <span v-if="!formData.conditionType && validationErrors.conditionType" class="text-sm text-red">{{ validationErrors.conditionType }}</span>
      </div>
      <div class="flex justify-end">
        <Button :disabled="!writePermission" type="submit">{{ isEditMode ? 'Save Changes' : 'Add Condition' }}</Button>
      </div>
    </form>
  </Dialog>
  <!-- Add Conditions From Package Dialog -->
  <Dialog :title="'Add Conditions From Package'" :isOpen="isAddConditionPackDialogOpen" @close="handleCloseAddConditionPackDialog">
    <div class="mb-4">
      <MultilineSelectInput
        label="Standard Conditions Pack"
        id="conditionPack"
        :options="standardConditionPacksOptions"
        v-model="addConditionPack"
        :value="addConditionPack"
        :disable-validation="true"
      />
    </div>
    <span v-if="!addConditionPack && validationErrors.addConditionPack" class="text-sm text-red">{{ validationErrors.addConditionPack }}</span>
    <div class="flex justify-end">
      <Button :disabled="!writePermission" @click="handleConfirmAddConditionPackDialog">Add Conditions</Button>
    </div>
  </Dialog>
  <!-- Add Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete the condition?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />

  <Card title="Loan Approval Dates Info" class="mt-4">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div>
        <DateInput label="Loan Expiration Date" :value="loanApprovalDatesInfo.loanExpirationDate" v-model="loanApprovalDatesInfo.loanExpirationDate" :disable-validation="true" />
      </div>
      <div>
        <DateInput
          label="Credit Report Expiration Date"
          :value="loanApprovalDatesInfo.creditReportExpirationDate"
          v-model="loanApprovalDatesInfo.creditReportExpirationDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Title Expiration Date"
          :value="loanApprovalDatesInfo.titleExpirationDate"
          v-model="loanApprovalDatesInfo.titleExpirationDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Hazard Expiration Date"
          :value="loanApprovalDatesInfo.hazardExpirationDate"
          v-model="loanApprovalDatesInfo.hazardExpirationDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Flood Expiration Date"
          :value="loanApprovalDatesInfo.floodExpirationDate"
          v-model="loanApprovalDatesInfo.floodExpirationDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Case Number Expiration Date"
          :value="loanApprovalDatesInfo.caseNumberExpirationDate"
          v-model="loanApprovalDatesInfo.caseNumberExpirationDate"
          :disable-validation="true"
        />
      </div>
      <div>
        <DateInput
          label="Case Number Lock Valid Until Date"
          :value="loanApprovalDatesInfo.caseNumberLockValidUntilDate"
          v-model="loanApprovalDatesInfo.caseNumberLockValidUntilDate"
          :disable-validation="true"
        />
      </div>
      <div />
      <div>
        <TextareaInput label="Loan Specific Notes" :value="loanApprovalDatesInfo.loanSpecificNotes" v-model="loanApprovalDatesInfo.loanSpecificNotes" :disable-validation="true" />
      </div>
      <div>
        <FloatInput label="Locked Rate" :value="loanApprovalDatesInfo.lockedRate" v-model="loanApprovalDatesInfo.lockedRate" :disable-validation="true" />
      </div>
    </div>
    <div class="flex justify-end">
      <Button variant="primary" :disabled="!writePermission" @click="handleSaveLoanApprovalDatesInfo">Save Changes</Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import MultilineSelectInput from '@/components/FormInput/MultilineSelectInput/index.vue';
  import FloatInput from '@/components/FormInput/FloatInput/index.vue';
  import TextareaInput from '@/components/FormInput/TextareaInput/index.vue';
  import { formatDate } from '@/utils';
  import { CONDITION_TYPE_OPTIONS } from '@/constants';

  const conditionTypeOrder = ['General', 'Prior to Docs', 'Prior to Closing', 'Prior to Funding'];

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Condition',
    components: {
      Button,
      Card,
      Confirmation,
      DataTable,
      Dialog,
      DateInput,
      MultilineSelectInput,
      FloatInput,
      TextareaInput,
    },
    props: {
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      return {
        formatDate,
        CONDITION_TYPE_OPTIONS,
      };
    },
    async created() {
      await this.fetchConditions();
      this.fetchStandardConditionPacks();
      this.fetchLoanApprovalDatesInfo();
    },
    data() {
      return {
        loanId: this.$route.params.id,
        headers: [
          { key: 'cleared', label: 'Cleared', type: 'checkbox', width: '80px' },
          { key: 'conditionType', label: 'Condition Type', type: 'select', options: CONDITION_TYPE_OPTIONS, width: '200px' },
          { key: 'description', label: 'Description', type: 'textarea' },
          { key: 'dateAddedFormatted', label: 'Date Added', width: '120px' },
          { key: 'action', label: 'Action', width: '80px' },
        ],
        tableData: [],
        formData: {
          conditionType: '',
        },
        validationErrors: {
          conditionType: '',
        },
        selectedCondition: null,
        isConditionDialogOpen: false,
        isEditMode: false,
        isDeleteConfirmationOpen: false,
        isAddConditionPackDialogOpen: false,
        standardConditionPacks: [],
        isAddStandardConditionPackDialogOpen: false,
        addConditionPack: null,
        standardConditionPacksOptions: [],
        loanApprovalDatesInfo: {
          id: null,
          loanExpirationDate: null,
          creditReportExpirationDate: null,
          titleExpirationDate: null,
          hazardExpirationDate: null,
          floodExpirationDate: null,
          caseNumberExpirationDate: null,
          caseNumberLockValidUntilDate: null,
          loanSpecificNotes: null,
          lockedRate: null,
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchConditions() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/conditions/', {
            loan: this.$route.params.id,
          });
          this.tableData = response.data.map((condition) => ({
            ...condition,
            dateAddedFormatted: this.formatDate(condition.dateAdded),
            actions: ['delete'],
          }));

          this.tableData = response.data
            .map((condition) => ({
              ...condition,
              dateAddedFormatted: this.formatDate(condition.dateAdded),
              actions: ['delete'],
            }))
            .sort((a, b) => {
              const typeComparison = conditionTypeOrder.indexOf(a.conditionType) - conditionTypeOrder.indexOf(b.conditionType);
              if (typeComparison !== 0) return typeComparison;
              return new Date(b.dateAdded) - new Date(a.dateAdded);
            });
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch conditions: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchStandardConditionPacks() {
        try {
          const response = await apiService.get(`/loan/standard_condition_pack/`);
          this.standardConditionPacks = response.data;
          this.standardConditionPacksOptions = this.standardConditionPacks.map((pack) => ({
            label: pack.name,
            value: pack.id,
          }));
        } catch (error) {
          this.standardConditionPacks = [];
          this.$root.showSnackbar(`Failed to fetch standard condition packs: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchLoanApprovalDatesInfo() {
        try {
          const response = await apiService.get(`/loan/loan_approval_dates_info/`, {
            loan: this.$route.params.id,
          });
          if (response.data?.length > 0) this.loanApprovalDatesInfo = response.data[0];
          else {
            this.loanApprovalDatesInfo = {
              id: null,
              loanExpirationDate: null,
              creditReportExpirationDate: null,
              titleExpirationDate: null,
              hazardExpirationDate: null,
              floodExpirationDate: null,
              caseNumberExpirationDate: null,
              caseNumberLockValidUntilDate: null,
              loanSpecificNotes: null,
              lockedRate: null,
            };
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch loan approval dates info: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      openAddConditionDialog() {
        this.isEditMode = false;
        this.isConditionDialogOpen = true;
      },
      handleAction(action, item) {
        if (action === 'edit') {
          this.openEditConditionDialog(item);
        } else if (action === 'delete') {
          this.selectedCondition = item;
          this.isDeleteConfirmationOpen = true;
        }
      },
      async handleKeyValueUpdate(key, newValue, data) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          const payload = { [key]: newValue };
          await apiService.patch(`/loan/conditions/${data.id}/`, payload);
          await this.fetchConditions();
          this.$root.showSnackbar('Condition has been updated successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`Failed to update condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      openEditConditionDialog(condition) {
        this.selectedCondition = condition;
        this.formData = {
          ...condition,
        };
        this.isEditMode = true;
        this.isConditionDialogOpen = true;
      },
      validateForm() {
        this.validationErrors = {
          conditionType: !this.formData.conditionType ? 'Condition type is required' : '',
        };
        return !this.validationErrors.conditionType;
      },
      async handleConfirmConditionDialog() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.validateForm()) return;
        const data = {
          ...this.formData,
          loan: this.$route.params.id,
        };
        if (this.isEditMode) {
          this.setLoading(true);
          try {
            await apiService.patch(`/loan/conditions/${this.selectedCondition.id}/`, data);
            await this.fetchConditions();
            this.$root.showSnackbar('Condition has been updated successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to update condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        } else {
          this.setLoading(true);
          // Add the new Condition
          try {
            await apiService.post('/loan/conditions/', data);
            await this.fetchConditions();
            this.$root.showSnackbar('New condition has been created successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to create condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
        this.handleCloseConditionDialog();
      },
      handleCloseConditionDialog() {
        this.isConditionDialogOpen = false;
        this.resetForm();
      },
      async handleConfirmDeleteConfirmation() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          if (this.selectedCondition && this.selectedCondition.id) {
            await apiService.delete(`/loan/conditions/${this.selectedCondition.id}/`);
            await this.fetchConditions();
            this.$root.showSnackbar('Condition deleted successfully', 'success');
          } else {
            throw new Error('No Condition selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete condition: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
          this.handleCloseDeleteConfirmation();
        }
      },
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
        this.selectedCondition = null;
      },
      resetForm() {
        this.formData = {
          conditionType: '',
        };
        this.validationErrors = {
          conditionType: '',
        };
      },
      openAddConditionPackDialog() {
        this.isAddConditionPackDialogOpen = true;
      },
      async handleAddConditionPack(conditionPack) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          const addConditionsPromises = conditionPack.conditions.map(async (condition) => {
            const data = {
              loan: this.$route.params.id,
              cleared: condition.cleared,
              conditionType: condition.conditionType,
              description: condition.description,
            };
            return apiService.post('/loan/conditions/', data);
          });
          await Promise.all(addConditionsPromises);
          await this.fetchConditions();
          this.$root.showSnackbar('Condition pack has been added successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`Failed to add condition pack: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      validateAddConditionPack() {
        this.validationErrors = {
          addConditionPack: !this.addConditionPack ? 'Standard conditions pack is required' : '',
        };
        return !this.validationErrors.addConditionPack;
      },
      async handleConfirmAddConditionPackDialog() {
        if (!this.validateAddConditionPack()) return;
        const pack = this.standardConditionPacks.find((pack) => pack.id === this.addConditionPack);
        await this.handleAddConditionPack(pack);
        this.handleCloseAddConditionPackDialog();
      },
      handleCloseAddConditionPackDialog() {
        this.isAddConditionPackDialogOpen = false;
        this.addConditionPack = null;
      },
      async handleSaveLoanApprovalDatesInfo() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          if (this.loanApprovalDatesInfo.id) {
            await apiService.patch(`/loan/loan_approval_dates_info/${this.loanApprovalDatesInfo.id}/?loan=${this.$route.params.id}`, {
              ...this.loanApprovalDatesInfo,
            });
          } else {
            await apiService.post(`/loan/loan_approval_dates_info/`, {
              ...this.loanApprovalDatesInfo,
              loan: this.$route.params.id,
            });
          }
          this.$root.showSnackbar('Loan approval dates info has been updated successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`Failed to update loan approval dates info: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
    watch: {},
  };
</script>
<style scoped></style>
