<template>
  <div class="flex">
    <div class="lg:w-1/2 hidden lg:block h-screen">
      <Slider />
    </div>
    <div class="w-full lg:w-1/2 h-screen">
      <div class="p-3 lg:p-10 h-screen relative flex justify-center items-center flex-col">
        <div class="lg:absolute lg:top-[40px] lg:right-[40px] relative mx-auto mb-5">
          <img src="../../../assets/image/brand-logo.svg" alt="login image" />
        </div>
        <div class="login-form-content mx-auto">
          <div class="text-center mb-8">
            <h3 class="text-dark md:text-4xl text-3xl font-bold mb-2">Log In to ReversePilot</h3>
            <p class="text-dark-gray text-1xl">Sign in using the credentials provided by your company.</p>
          </div>
          <form @submit.prevent="submitForm">
            <div class="form-group mb-4">
              <label for="email">Email</label>
              <input type="email" placeholder="@mail.com" id="email" v-model="formData.email" required />
              <span v-if="errors.email" class="error">{{ errors.email }}</span>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <div class="relative">
                <input :type="showPassword ? 'text' : 'password'" id="password" placeholder="•••••••••••" v-model="formData.password" required />
                <span class="toggle-password" @click="togglePasswordVisibility">
                  <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
                </span>
              </div>
              <span v-if="errors.password" class="error">{{ errors.password }}</span>
            </div>
            <div class="flex items-center justify-between mt-4 mb-5">
              <div class="form-group flex items-center cursor-pointer">
                <input class="w-auto checkbox mr-2" type="checkbox" id="remember-me" v-model="formData.rememberMe" />
                <label for="remember-me" class="m-0 text-base" style="color: #282828"> Remember me </label>
              </div>
              <router-link to="/forget-password" class="text-primary">Forgot password?</router-link>
            </div>
            <button class="standard-btn w-full" type="submit">
              <span>Sign in</span>
              <font-awesome-icon icon="fa-solid fa-arrow-right" class="icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { ref } from 'vue';
  import Slider from './../Slider/index.vue';
  import apiService from '@/api/apiService';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Login',
    components: {
      Slider,
    },
    setup() {
      const formData = ref({
        email: '',
        password: '',
        rememberMe: false,
      });

      const errors = ref({
        email: '',
        password: '',
      });

      const showPassword = ref(false);

      const validateForm = () => {
        let valid = true;

        if (!formData.value.email) {
          errors.value.email = 'Email is required';
          valid = false;
        } else {
          errors.value.email = '';
        }

        if (!formData.value.password) {
          errors.value.password = 'Password is required';
          valid = false;
        } else {
          errors.value.password = '';
        }

        return valid;
      };

      return {
        formData,
        errors,
        showPassword,
        validateForm,
      };
    },
    methods: {
      ...mapActions(['setLoading', 'setUserInfo']),
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      async submitForm() {
        if (this.validateForm()) {
          this.setLoading(true);
          try {
            const response = await apiService.login(this.formData.email.toLowerCase(), this.formData.password);
            this.handleSaveUserInfo(response.data);
            this.$root.showSnackbar('User logged in successfully!', 'success');
            this.$router.push({ name: 'Dashboard' });
          } catch (error) {
            this.$root.showSnackbar(`Error: ${error.response ? error.response.data.error_description : error.message}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
      handleSaveUserInfo(userInfo) {
        localStorage.setItem('auth_token', userInfo.access_token);
        localStorage.setItem('refresh_token', userInfo.refresh_token);
        this.setUserInfo({
          name: userInfo.first_name + ' ' + userInfo.last_name,
          email: userInfo.email,
          hierarchy: userInfo.hierarchy,
          roles: userInfo.roles,
        });
      },
    },
  };
</script>

<style scoped>
  .login-form-content {
    max-width: 480px;
  }
  label {
    color: #686868;
  }
  input::placeholder {
    color: #9a9a9a;
  }
  input {
    border-radius: 8px;
    background: #f9f9f9;
    border-color: #e6e6e6;
    padding: 17px 15px;
  }
  button {
    padding: 16px 15px;
    border-radius: 8px;
  }

  .standard-btn:hover .icon {
    margin-left: 20px;
  }
  .standard-btn .icon {
    margin-left: 15px;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 20px;
  }
  .toggle-password {
    color: #9a9a9a;
    position: absolute;
    top: 14px;
    font-size: 20px;
    right: 15px;
    cursor: pointer;
  }
  @media (max-width: 720px) {
    p {
      font-size: 0.75rem !important;
    }
  }
</style>
