<template>
  <Dialog :title="title" :isOpen="isOpen">
    <div class="content-container">
      <DataTable :headers="headers" :data="tableData" @action="handleAction" />
    </div>
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Dialog from '@/components/Dialog/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import { formatDateTime } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'DocumentHistoryDialog',
    components: {
      Dialog,
      DataTable,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      isOpen: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      return {
        formatDateTime,
      };
    },
    data() {
      return {
        headers: [
          { key: 'name', label: 'Name' },
          { key: 'user', label: 'Printed By' },
          { key: 'time', label: 'Printed At' },
          { key: 'loan_number', label: 'Loan Number' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchDocumentHistory() {
        this.setLoading(true);
        try {
          const loanId = this.$route.params.id;
          const response = await apiService.post('documents/histories/', { loan_id: loanId });
          this.handleTableData(response);
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch document history: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleTableData(response) {
        this.tableData = response.data.map((item) => ({
          name: item.package_name,
          user: item.user,
          time: formatDateTime(item.created_at),
          loan_number: item.loan_number,
          document_url: item.document_url,
          actions: ['print'],
        }));
      },
      async handleAction(action, item) {
        if (action === 'print') {
          this.setLoading(true);
          try {
            const url = item.document_url;
            const responseFile = await fetch(url);
            const blob = await responseFile.blob();
            const objectUrl = window.URL.createObjectURL(blob);
            const linkElement = document.createElement('a');
            linkElement.href = objectUrl;
            linkElement.download = url.split('/').pop();
            linkElement.click();
            window.URL.revokeObjectURL(objectUrl);

            this.$root.showSnackbar('Document package downloaded successfully', 'success');
          } catch (error) {
            this.$root.showSnackbar(`Failed to download document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    watch: {
      isOpen(newVal) {
        if (newVal) {
          this.fetchDocumentHistory();
        }
      },
    },
  };
</script>
