<template>
  <div class="overflow-x-auto p-2">
    <table class="table">
      <!-- Table headers -->
      <thead>
        <tr>
          <th v-for="header in headers" class="text-nowrap cursor-pointer" :key="header.key" @click="sortTable(header.key)">
            {{ header.label }}
            <span class="ml-1 cursor-pointer" v-if="header.key !== 'action'">
              <template v-if="sortKey === header.key">
                {{ sortOrder === 'asc' ? '▲' : '▼' }}
              </template>
              <template v-else>&#8597;</template>
            </span>
          </th>
        </tr>
      </thead>
      <!-- Table body -->
      <tbody>
        <tr v-if="paginatedData.length === 0">
          <td :colspan="headers.length" class="!text-center">
            <div class="icon">
              <img src="../../assets//image/info.svg" alt="icon" class="mx-auto mb-1" />
              <span>No data available</span>
              <slot name="no-data"></slot>
            </div>
          </td>
        </tr>

        <tr v-for="row in paginatedData" :key="row.id" @dblclick="enterEditMode(row)">
          <td v-for="header in headers" :key="header.key">
            <!-- Text Input -->
            <div v-if="header.type === 'text'">
              <div v-if="!row.isEditMode" class="history-icon-container pr-[30px]">
                <label :for="id">
                  {{ row[header.key] }}
                </label>
                <button v-if="historyShow" class="icon-button history" @click="triggerOpenHistoryDialog(header.key, row.id)">
                  <font-awesome-icon class="text-primary" icon="history" />
                </button>
              </div>
              <input
                v-else
                v-model="row[header.key]"
                @keyup.enter="saveEdit(row)"
                :disabled="row.editDisabledFields?.includes(header.key)"
                :class="{ 'cursor-not-allowed': row.editDisabledFields?.includes(header.key) }"
              />
            </div>

            <!-- Select Input -->
            <div v-if="header.type === 'select'" class="relative flex items-center">
              <div v-if="!row.isEditMode" class="history-icon-container">
                <label :for="id">
                  {{ row[header.key] }}
                </label>
                <button v-if="historyShow" class="icon-button history" @click="triggerOpenHistoryDialog(header.key, row.id)">
                  <font-awesome-icon class="text-primary" icon="history" />
                </button>
              </div>
              <select
                v-else
                v-model="row[header.key]"
                :disabled="row.editDisabledFields?.includes(header.key)"
                :class="{ 'cursor-not-allowed': row.editDisabledFields?.includes(header.key) }"
              >
                <option v-for="option in header.options" :key="option.value" :value="option.value">{{ option.label }}</option>
              </select>
            </div>

            <!-- Money Input -->
            <div v-if="header.type === 'money'" class="relative flex items-center">
              <div v-if="!row.isEditMode" class="history-icon-container pr-[30px]">
                <label :for="id"> {{ formatMoney(row[header.key]) }} </label>
                <button v-if="historyShow" class="icon-button history" @click="triggerOpenHistoryDialog(header.key, row.id)">
                  <font-awesome-icon class="text-primary" icon="history" />
                </button>
              </div>
              <div v-else class="relative flex items-center">
                <MoneyInput
                  :value="row[header.key]"
                  v-model="row[header.key]"
                  :disable-validation="true"
                  @keyup.enter="saveEdit(row)"
                  input-class="h-[30px]"
                  :disabled="row.editDisabledFields?.includes(header.key)"
                  :class="{ 'cursor-not-allowed': row.editDisabledFields?.includes(header.key) }"
                />
              </div>
            </div>

            <!-- Radio Input -->
            <div v-if="header.type === 'boolean'" class="flex items-center justify-center">
              <div v-if="!row.isEditMode" class="history-icon-container">
                <label :for="id" class="leading-none py-1 px-2 rounded-full text-sm" :class="row[header.key] ? 'bg-[#1E90FF] text-white' : 'bg-[#FF4500] text-white'">
                  {{ row[header.key] ? 'Yes' : 'No' }}
                </label>
                <button v-if="historyShow" class="icon-button history py-1" @click="triggerOpenHistoryDialog(header.key, row.id)">
                  <font-awesome-icon class="text-primary" icon="history" />
                </button>
              </div>
              <input
                v-else
                type="checkbox"
                v-model="row[header.key]"
                class="w-5 h-5 p-0 border border-[#757575] rounded cursor-pointer relative flex-shrink-0 focus:outline-none"
                :disabled="row.editDisabledFields?.includes(header.key)"
                :class="{ 'cursor-not-allowed': row.editDisabledFields?.includes(header.key) }"
              />
            </div>

            <!-- Auto Complete -->
            <div v-if="header.type === 'auto-complete'" class="relative flex items-center">
              <div v-if="!row.isEditMode" class="history-icon-container pr-[30px]">
                <label :for="id" class="text-[#212121]">
                  {{ row[header.key] }}
                </label>
                <button v-if="historyShow" class="icon-button history" @click="triggerOpenHistoryDialog(header.key, row.id)">
                  <font-awesome-icon class="text-primary" icon="history" />
                </button>
              </div>
              <div v-else class="relative w-full">
                <input
                  v-model="row[header.key]"
                  @input="filterOptions($event, row, header.key, header.options)"
                  @focus="
                    filterOptions(null, row, header.key, header.options);
                    showSuggestions(row);
                  "
                  @blur="hideSuggestions(row)"
                  class="w-full p-2 border rounded"
                  type="text"
                  @keyup.enter="saveEdit(row)"
                  :disabled="row.editDisabledFields?.includes(header.key)"
                  :class="{ 'cursor-not-allowed': row.editDisabledFields?.includes(header.key) }"
                />
                <ul v-if="row.showSuggestions" class="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg max-h-60 overflow-auto">
                  <li v-for="option in row.filteredOptions" :key="option" @mousedown="selectOption(row, header.key, option)" class="p-2 hover:bg-gray-100 cursor-pointer">
                    {{ option }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- Action Buttons -->
            <div v-if="header.key === 'action'" class="flex items-center justify-between">
              <!-- Edit, Save, Cancel buttons -->
              <div class="flex items-center gap-2">
                <!-- Edit Button -->
                <button v-if="!row.isEditMode && row.actions?.includes('edit')" class="icon-button bg-primary" @click="enterEditMode(row)">
                  <font-awesome-icon icon="fas fa-pen-to-square" />
                </button>

                <!-- Save and Cancel Buttons -->
                <div v-if="row.isEditMode" class="flex items-center gap-2">
                  <button class="icon-button bg-green-600" @click="saveEdit(row)">
                    <font-awesome-icon icon="fas fa-check" />
                  </button>
                  <button class="icon-button bg-gray-500" @click="cancelEdit(row)">
                    <font-awesome-icon icon="fas fa-times" />
                  </button>
                </div>

                <!-- Delete Button -->
                <button class="icon-button bg-red" v-if="row.actions?.includes('delete')" @click="$emit('action', 'delete', row)">
                  <font-awesome-icon icon="trash-can" />
                </button>
                <slot name="custom" v-bind:row="row"></slot>
              </div>
            </div>

            <div v-if="!header.type">
              {{ row[header.key] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination controls -->
    <div v-if="totalPages > 1 || data.length > itemsPerPage" class="mt-4 flex justify-center items-center gap-4">
      <div class="flex items-center space-x-2">
        <span class="text-sm text-gray-600">Page Size:</span>
        <select v-model="itemsPerPage" @change="handleRowsPerPageChange" class="page-size-select">
          <option v-for="option in rowsPerPageOptions" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
      <div class="flex items-center space-x-4">
        <span class="text-sm text-gray-600">{{ startIndex + 1 }}-{{ Math.min(endIndex, data.length) }} of {{ data.length }}</span>
        <div class="flex space-x-2">
          <button @click="prevPage" :disabled="currentPage === 1" class="page-button">Previous</button>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="page-button">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import { formatMoney } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'EditableDataTable',
    components: {
      MoneyInput,
    },
    setup() {
      return {
        formatMoney,
      };
    },
    props: {
      headers: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
      onDataUpdate: {
        type: Function,
        required: false,
        default: () => {},
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentPage: 1,
        itemsPerPage: 50,
        rowsPerPageOptions: [50, 75, 100, 250, 500],
        backupRow: null, // To store the original data for rollback
        sortKey: null,
        sortOrder: 'asc',
      };
    },
    computed: {
      startIndex() {
        return (this.currentPage - 1) * this.itemsPerPage;
      },
      endIndex() {
        return this.startIndex + this.itemsPerPage;
      },
      paginatedData() {
        const sortedData = [...this.data];
        if (this.sortKey) {
          sortedData.sort((a, b) => {
            const aValue = a[this.sortKey];
            const bValue = b[this.sortKey];
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              return this.sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
              return this.sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
            } else {
              return this.sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
            }
          });
        }
        return sortedData.slice(this.startIndex, this.endIndex);
      },
      totalPages() {
        return Math.ceil(this.data.length / this.itemsPerPage);
      },
    },
    methods: {
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      handleRowsPerPageChange() {
        this.currentPage = 1;
      },
      // Enter edit mode for the row
      enterEditMode(row) {
        row.isEditMode = true;
        this.data.forEach((r) => {
          if (r.id !== row.id && r.isEditMode) this.cancelEdit(r);
        });
        this.backupRow = { ...row }; // Make a deep copy of the original row data
      },
      // Save edits and exit edit mode
      saveEdit(row) {
        row.isEditMode = false;
        this.$emit('onDataUpdate', row);
      },
      // Cancel edits and revert data to the original state
      cancelEdit(row) {
        Object.assign(row, this.backupRow); // Revert back to original data
        row.isEditMode = false;
      },
      handleNumberInput(event, row, key) {
        const charStr = String.fromCharCode(event.charCode);
        const isDigit = /\d/.test(charStr);
        const isDecimal = charStr === '.';
        const hasDecimal = row[key].includes('.');
        const cursorPosition = event.target.selectionStart;
        const decimalIndex = row[key].indexOf('.');

        if (
          (!isDigit && !isDecimal) ||
          (isDecimal && hasDecimal) ||
          (hasDecimal && cursorPosition > decimalIndex && row[key].split('.')[1].length - (event.target.selectionEnd - cursorPosition) + 1 > 2)
        ) {
          event.preventDefault();
        }
      },
      filterOptions(event, row, key, options) {
        const pattern = event?.target?.value || row[key] || '';
        row.filteredOptions = options.filter((option) => option?.toLowerCase().includes(pattern?.toLowerCase()));
      },
      showSuggestions(row) {
        row.showSuggestions = true;
      },
      hideSuggestions(row) {
        row.showSuggestions = false;
      },
      selectOption(row, key, option) {
        row.showSuggestions = false;
        row[key] = option;
      },
      sortTable(key) {
        if (this.sortKey === key) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortKey = key;
          this.sortOrder = 'asc';
        }
      },
      triggerOpenHistoryDialog(fieldName, id) {
        this.$emit('trigger-open-history-dialog', { id: fieldName, model_id: id });
      },
    },
  };
</script>

<style scoped>
  select {
    padding: 5px 5px;
    width: auto;
  }

  select,
  input {
    height: 30px;
  }

  select,
  input:not([type='checkbox']) {
    min-width: 150px;
  }

  .table {
    width: 100%;
    min-width: 700px;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }

  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .input-cell,
  .select-cell,
  .money-cell {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
  }

  .page-size-select {
    padding: 5px;
  }

  .page-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
  }

  .page-button[disabled] {
    background-color: #eee;
    cursor: not-allowed;
  }
</style>
