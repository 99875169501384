<template>
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style="z-index: 1000">
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
      <header class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">{{ title }}</h2>
        <button @click="closeModal" class="text-red-500 hover:text-red-700">&times;</button>
      </header>
      <div class="content">
        <!-- Handle multiline descriptions -->
        <p v-for="line in content.split('\n')" :key="line" class="mb-2">
          {{ line }}
        </p>
      </div>
      <footer class="mt-4 flex justify-end">
        <button @click="closeModal" class="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ModalDescription',
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      content: {
        type: String,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
  };
</script>
