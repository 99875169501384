<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pt-3 mt-3 gap-3">
    <div>
      <InputField label="Creditor Name" id="creditorName" ref="creditorName" :value="formData.creditorName" v-model.trim="formData.creditorName" :disable-validation="true" />
      <span v-if="validationFormErrors.creditorName.length > 0" class="text-sm text-red">
        {{ validationFormErrors.creditorName }}
      </span>
    </div>
    <div>
      <InputField
        label="Account Number *"
        id="accountNumber"
        ref="accountNumber"
        :value="formData.accountNumber"
        v-model.trim="formData.accountNumber"
        :disable-validation="true"
      />
    </div>
    <div>
      <MoneyInput label="Balance Due" id="balanceDue" ref="balanceDue" :value="formData.balanceDue" v-model="formData.balanceDue" :disable-validation="true" />
    </div>
    <div>
      <MoneyInput label="Per Diem" id="perDiem" ref="perDiem" :value="formData.perDiem" v-model="formData.perDiem" :disable-validation="true" />
    </div>
    <div>
      <SelectInput label="Type" id="type" v-model="formData.type" :value="formData.type" :options="typeOptions" :disable-validation="true" />
    </div>
    <div>
      <DateInput label="Expiration Date *" id="expirationDate" ref="expirationDate" v-model="formData.expirationDate" :value="formData.expirationDate" :disable-validation="true" />
      <span v-if="validationFormErrors.expirationDate.length > 0" class="text-sm text-red">
        {{ validationFormErrors.expirationDate }}
      </span>
    </div>
  </div>
  <div class="flex justify-end gap-4 mt-5 pt-5 border-t">
    <Button variant="primary" style="width: fit-content" @click="emitConfirm"> Save </Button>
    <Button variant="secondary" style="width: fit-content" @click="emitClose"> Close </Button>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  // import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import { PAYOFF_TYPES } from '@/constants/index.js';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PayoffForm',
    components: {
      Button,
      InputField,
      MoneyInput,
      SelectInput,
      DateInput,
    },
    data() {
      return {
        formData: {
          creditorName: '',
          accountNumber: '',
          balanceDue: 0,
          perDiem: 0,
          type: null,
          expirationDate: null,
        },
        typeOptions: PAYOFF_TYPES,
        validationFormErrors: {
          creditorName: '',
          expirationDate: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      validateForm() {
        this.validationFormErrors = {
          creditorName: !this.formData.creditorName ? 'This field is required.' : '',
          expirationDate: !this.formData.expirationDate ? 'This field is required.' : '',
        };
        return !this.validationFormErrors.creditorName.length && !this.validationFormErrors.expirationDate.length;
      },
      emitConfirm() {
        if (!this.validateForm()) return;
        this.$emit('confirm', this.formData);
      },
      emitClose() {
        this.$emit('close');
      },
    },
  };
</script>
