export const LOAN_PRODUCT_TYPES = {
  HECM: 'HECM',
  HOME_FOR_LIFE: 'Home for Life',
};

export const LOAN_PRODUCT_TYPE_OPTIONS = [
  { label: 'HECM', value: 'HECM' },
  { label: 'Home for Life', value: 'HOME_FOR_LIFE' },
];

export const TOLERANCE_OPTIONS = [
  { label: '0%', value: '0%' },
  { label: '10%', value: '10%' },
  { label: 'Unlimited', value: 'Unlimited' },
];

export const STATIC_HOLIDAYS = [
  [1, 1], // New Year's Day
  [6, 19], // Juneteenth
  [7, 4], // Independence Day
  [11, 11], // Veteran’s Day
  [12, 25], // Christmas Day
];

export const BORROWER_TYPES = {
  CO_BOROWER: 'Co-Borrower',
  NON_BORROWING_SPOUSE: 'Non-Borrowing Spouse',
};

export const USER_HIERARCHIES = {
  SUPER_USER: 'Super User',
  COMPANY_ADMINISTRATOR: 'Company Administrator',
  COMPANY_USER: 'Company User',
};

export const USER_HIERARCHY_OPTIONS = [
  { label: 'Super User', value: 'Super User' },
  { label: 'Company Administrator', value: 'Company Administrator' },
  { label: 'Company User', value: 'Company User' },
];

export const COMPANY_TYPES = {
  broker: 'Broker',
  lender: 'Lender',
};

export const INTEREST_RATE_TYPES = {
  fixed: 'Fixed',
  adjustable: 'Adjustable',
  not_applicable: 'Not Applicable',
};

export const INDEX_NAMES = {
  '1_month': '1 Month CMT',
  '1_year': '1 Year CMT',
  '10_year': '10 Year CMT',
  'N/A': 'Not Applicable',
  not_applicable: 'Not Applicable',
};

export const INTEREST_RATE_TYPE_OPTIONS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Adjustable', value: 'adjustable' },
];

export const INDEX_NAMES_OPTIONS = [
  { label: '1 Month CMT', value: '1_month' },
  { label: '1 Year CMT', value: '1_year' },
  { label: '10 Year CMT', value: '10_year' },
];

export const MARGINS = [
  { label: '1.5%', value: 1.5 },
  { label: '1.675%', value: 1.675 },
  { label: '1.75%', value: 1.75 },
  { label: '1.875%', value: 1.875 },
  { label: '2%', value: 2 },
  { label: '2.125%', value: 2.125 },
  { label: '2.25%', value: 2.25 },
  { label: '2.375%', value: 2.375 },
  { label: '2.5%', value: 2.5 },
  { label: '2.625%', value: 2.625 },
  { label: '2.75%', value: 2.75 },
  { label: '2.875%', value: 2.875 },
  { label: '3%', value: 3 },
];
export const PAYOFF_TYPES = [
  { label: 'Mandatory Obligation', value: 'Mandatory Obligation' },
  { label: 'Tax Payoff', value: 'Tax Payoff' },
  { label: 'Non-mandatory Payoff', value: 'Non-mandatory Payoff' },
  { label: 'Unseasoned HELOC', value: 'Unseasoned HELOC' },
];

export const COMPANY_USER_ROLE_OPTIONS = [
  'Loan officer',
  'Loan officer Manager',
  'Processor',
  'Processor Manager',
  'Underwriter',
  'Underwriter Manager',
  'Closer',
  'Closer Manager',
  'Funder',
  'Funder Manager',
  'Shipper',
  'Shipper Manager',
  'Post-Closer',
  'Post-Closer Manager',
];

export const LOAN_STATUSES = {
  ADVERSED: 'Adversed',
  WITHDRAWN: 'Withdrawn',
  PROSPECT: 'Prospect',
  ORIGINATION: 'Origination',
  PROCESSING: 'Processing',
  UNDERWRITING: 'Underwriting',
  SUBMITTED_TO_UNDERWRITING: 'Submitted to Underwriting',
  CLEAR_TO_CLOSE: 'Clear to Close',
  CLOSING: 'Closing',
  FUNDING: 'Funding',
  POST_CLOSING: 'Post Closing',
  PREPURCHASE_QC: 'PrePurchaseQC',
  PREPURCHASE: 'PrePurchase',
  SOLD: 'Sold',
  INSURED: 'Insured',
};

export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const DIRECTION_OPTIONS = [
  { label: 'E', value: 'E' },
  { label: 'W', value: 'W' },
  { label: 'S', value: 'S' },
  { label: 'N', value: 'N' },
  { label: 'NE', value: 'NE' },
  { label: 'NW', value: 'NW' },
  { label: 'SE', value: 'SE' },
  { label: 'SW', value: 'SW' },
];

export const STREET_TYPES = [
  { label: 'ALLEY', value: 'ALY' },
  { label: 'ANNEX', value: 'ANX' },
  { label: 'ARCADE', value: 'ARC' },
  { label: 'AVENUE', value: 'AVE' },
  { label: 'BAYOU', value: 'BYU' },
  { label: 'BEACH', value: 'BCH' },
  { label: 'BEND', value: 'BND' },
  { label: 'BLUFF', value: 'BLF' },
  { label: 'BLUFFS', value: 'BLFS' },
  { label: 'BOTTOM', value: 'BTM' },
  { label: 'BOULEVARD', value: 'BLVD' },
  { label: 'BRANCH', value: 'BR' },
  { label: 'BRIDGE', value: 'BRG' },
  { label: 'BROOK', value: 'BRK' },
  { label: 'BROOKS', value: 'BRKS' },
  { label: 'BURG', value: 'BG' },
  { label: 'BURGS', value: 'BGS' },
  { label: 'BYPASS', value: 'BYP' },
  { label: 'CAMP', value: 'CP' },
  { label: 'CANYON', value: 'CYN' },
  { label: 'CAPE', value: 'CPE' },
  { label: 'CAUSEWAY', value: 'CSWY' },
  { label: 'CENTER', value: 'CTR' },
  { label: 'CENTERS', value: 'CTRS' },
  { label: 'CIRCLE', value: 'CIR' },
  { label: 'CIRCLES', value: 'CIRS' },
  { label: 'CLIFF', value: 'CLF' },
  { label: 'CLIFFS', value: 'CLFS' },
  { label: 'CLUB', value: 'CLB' },
  { label: 'COMMON', value: 'CMN' },
  { label: 'COMMONS', value: 'CMNS' },
  { label: 'CONCOURSE', value: 'CONC' },
  { label: 'CORNER', value: 'COR' },
  { label: 'CORNERS', value: 'CORS' },
  { label: 'COURSE', value: 'CRSE' },
  { label: 'COURT', value: 'CT' },
  { label: 'COURTS', value: 'CTS' },
  { label: 'COVE', value: 'CV' },
  { label: 'COVES', value: 'CVS' },
  { label: 'CREEK', value: 'CRK' },
  { label: 'CRESCENT', value: 'CRES' },
  { label: 'CREST', value: 'CRST' },
  { label: 'CROSSING', value: 'XING' },
  { label: 'CROSSROAD', value: 'XRD' },
  { label: 'CROSSROADS', value: 'XRDS' },
  { label: 'CURVE', value: 'CURV' },
  { label: 'DALE', value: 'DL' },
  { label: 'DAM', value: 'DM' },
  { label: 'DIVIDE', value: 'DV' },
  { label: 'DRIVE', value: 'DR' },
  { label: 'DRIVES', value: 'DRS' },
  { label: 'ESTATE', value: 'EST' },
  { label: 'ESTATES', value: 'ESTS' },
  { label: 'EXPRESSWAY', value: 'EXPY' },
  { label: 'EXTENSION', value: 'EXT' },
  { label: 'EXTENSIONS', value: 'EXTS' },
  { label: 'FALL', value: 'FALL' },
  { label: 'FALLS', value: 'FLS' },
  { label: 'FERRY', value: 'FRY' },
  { label: 'FIELD', value: 'FLD' },
  { label: 'FIELDS', value: 'FLDS' },
  { label: 'FLAT', value: 'FLT' },
  { label: 'FLATS', value: 'FLTS' },
  { label: 'FORD', value: 'FRD' },
  { label: 'FORDS', value: 'FRDS' },
  { label: 'FOREST', value: 'FRST' },
  { label: 'FORGE', value: 'FRG' },
  { label: 'FORGES', value: 'FRGS' },
  { label: 'FORK', value: 'FRK' },
  { label: 'FORKS', value: 'FRKS' },
  { label: 'FORT', value: 'FT' },
  { label: 'FREEWAY', value: 'FWY' },
  { label: 'GARDEN', value: 'GDN' },
  { label: 'GARDENS', value: 'GDNS' },
  { label: 'GATEWAY', value: 'GTWY' },
  { label: 'GLEN', value: 'GLN' },
  { label: 'GLENS', value: 'GLNS' },
  { label: 'GREEN', value: 'GRN' },
  { label: 'GREENS', value: 'GRNS' },
  { label: 'GROVE', value: 'GRV' },
  { label: 'GROVES', value: 'GRVS' },
  { label: 'HARBOR', value: 'HBR' },
  { label: 'HARBORS', value: 'HBRS' },
  { label: 'HAVEN', value: 'HVN' },
  { label: 'HEIGHTS', value: 'HTS' },
  { label: 'HIGHWAY', value: 'HWY' },
  { label: 'HILL', value: 'HL' },
  { label: 'HILLS', value: 'HLS' },
  { label: 'HOLLOW', value: 'HOLW' },
  { label: 'INLET', value: 'INLT' },
  { label: 'ISLAND', value: 'IS' },
  { label: 'ISLANDS', value: 'ISS' },
  { label: 'ISLE', value: 'ISLE' },
  { label: 'JUNCTION', value: 'JCT' },
  { label: 'JUNCTIONS', value: 'JCTS' },
  { label: 'KEY', value: 'KY' },
  { label: 'KEYS', value: 'KYS' },
  { label: 'KNOLL', value: 'KNL' },
  { label: 'KNOLLS', value: 'KNLS' },
  { label: 'LAKE', value: 'LK' },
  { label: 'LAKES', value: 'LKS' },
  { label: 'LAND', value: 'LAND' },
  { label: 'LANDING', value: 'LNDG' },
  { label: 'LANE', value: 'LN' },
  { label: 'LIGHT', value: 'LGT' },
  { label: 'LIGHTS', value: 'LGTS' },
  { label: 'LOAF', value: 'LF' },
  { label: 'LOCK', value: 'LCK' },
  { label: 'LOCKS', value: 'LCKS' },
  { label: 'LODGE', value: 'LDG' },
  { label: 'LOOP', value: 'LOOP' },
  { label: 'MALL', value: 'MALL' },
  { label: 'MANOR', value: 'MNR' },
  { label: 'MANORS', value: 'MNRS' },
  { label: 'MEADOW', value: 'MDW' },
  { label: 'MEADOWS', value: 'MDWS' },
  { label: 'MEWS', value: 'MEWS' },
  { label: 'MILL', value: 'ML' },
  { label: 'MILLS', value: 'MLS' },
  { label: 'MISSION', value: 'MSN' },
  { label: 'MOTORWAY', value: 'MTWY' },
  { label: 'MOUNT', value: 'MT' },
  { label: 'MOUNTAIN', value: 'MTN' },
  { label: 'MOUNTAINS', value: 'MTNS' },
  { label: 'NECK', value: 'NCK' },
  { label: 'ORCHARD', value: 'ORCH' },
  { label: 'OVERPASS', value: 'OPAS' },
  { label: 'PARK', value: 'PARK' },
  { label: 'PARKS', value: 'PARK' },
  { label: 'PARKWAY', value: 'PKWY' },
  { label: 'PARKWAYS', value: 'PKWY' },
  { label: 'PASS', value: 'PASS' },
  { label: 'PASSAGE', value: 'PSGE' },
  { label: 'PATH', value: 'PATH' },
  { label: 'PIKE', value: 'PIKE' },
  { label: 'PINE', value: 'PNE' },
  { label: 'PINES', value: 'PNES' },
  { label: 'PLACE', value: 'PL' },
  { label: 'PLAIN', value: 'PLN' },
  { label: 'PLAINS', value: 'PLNS' },
  { label: 'PLAZA', value: 'PLZ' },
  { label: 'POINT', value: 'PT' },
  { label: 'POINTS', value: 'PTS' },
  { label: 'PORT', value: 'PRT' },
  { label: 'PORTS', value: 'PRTS' },
  { label: 'PRAIRIE', value: 'PR' },
  { label: 'RADIAL', value: 'RADL' },
  { label: 'RAMP', value: 'RAMP' },
  { label: 'RANCH', value: 'RNCH' },
  { label: 'RAPID', value: 'RPD' },
  { label: 'RAPIDS', value: 'RPDS' },
  { label: 'REST', value: 'RST' },
  { label: 'RIDGE', value: 'RDG' },
  { label: 'RIDGES', value: 'RDGS' },
  { label: 'RIVER', value: 'RIV' },
  { label: 'ROAD', value: 'RD' },
  { label: 'ROADS', value: 'RDS' },
  { label: 'ROUTE', value: 'RTE' },
  { label: 'ROW', value: 'ROW' },
  { label: 'RUE', value: 'RUE' },
  { label: 'RUN', value: 'RUN' },
  { label: 'SHOAL', value: 'SHL' },
  { label: 'SHOALS', value: 'SHLS' },
  { label: 'SHORE', value: 'SHR' },
  { label: 'SHORES', value: 'SHRS' },
  { label: 'SKYWAY', value: 'SKWY' },
  { label: 'SPRING', value: 'SPG' },
  { label: 'SPRINGS', value: 'SPGS' },
  { label: 'SPUR', value: 'SPUR' },
  { label: 'SPURS', value: 'SPUR' },
  { label: 'SQUARE', value: 'SQ' },
  { label: 'SQUARES', value: 'SQS' },
  { label: 'STATION', value: 'STA' },
  { label: 'STRAVENUE', value: 'STRA' },
  { label: 'STREAM', value: 'STRM' },
  { label: 'STREET', value: 'ST' },
  { label: 'STREETS', value: 'STS' },
  { label: 'SUMMIT', value: 'SMT' },
  { label: 'TERRACE', value: 'TER' },
  { label: 'THROUGHWAY', value: 'TRWY' },
  { label: 'TRACE', value: 'TRCE' },
  { label: 'TRACK', value: 'TRAK' },
  { label: 'TRAFFICWAY', value: 'TRFY' },
  { label: 'TRAIL', value: 'TRL' },
  { label: 'TRAILER', value: 'TRLR' },
  { label: 'TUNNEL', value: 'TUNL' },
  { label: 'TURNPIKE', value: 'TPKE' },
  { label: 'UNDERPASS', value: 'UPAS' },
  { label: 'UNION', value: 'UN' },
  { label: 'UNIONS', value: 'UNS' },
  { label: 'VALLEY', value: 'VLY' },
  { label: 'VALLEYS', value: 'VLYS' },
  { label: 'VIADUCT', value: 'VIA' },
  { label: 'VIEW', value: 'VW' },
  { label: 'VIEWS', value: 'VWS' },
  { label: 'VILLAGE', value: 'VLG' },
  { label: 'VILLAGES', value: 'VLGS' },
  { label: 'VILLE', value: 'VL' },
  { label: 'VISTA', value: 'VIS' },
  { label: 'WALK', value: 'WALK' },
  { label: 'WALKS', value: 'WALK' },
  { label: 'WALL', value: 'WALL' },
  { label: 'WAY', value: 'WAY' },
  { label: 'WAYS', value: 'WAYS' },
  { label: 'WELL', value: 'WL' },
  { label: 'WELLS', value: 'WLS' },
];

export const RADIO_INPUT_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const PROPERTY_TYPE_OPTIONS = [
  { label: '2 to 4 Units', value: '2 to 4 Units' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Condominium', value: 'Condominium' },
  { label: 'Cooperative', value: 'Cooperative' },
  { label: 'Farm', value: 'Farm' },
  { label: 'Land', value: 'Land' },
  { label: 'Manufactured Condo', value: 'Manufactured Condo' },
  { label: 'Manufactured Home', value: 'Manufactured Home' },
  { label: 'Multi-Family 4+', value: 'Multi-Family 4+' },
  { label: 'Single Family', value: 'Single Family' },
  { label: 'Site Condominium', value: 'Site Condominium' },
  { label: 'Townhouse', value: 'Townhouse' },
];

export const CONDITION_TYPE_OPTIONS = [
  { label: 'General', value: 'General' },
  { label: 'Prior to Docs', value: 'Prior to Docs' },
  { label: 'Prior to Closing', value: 'Prior to Closing' },
  { label: 'Prior to Funding', value: 'Prior to Funding' },
];

export const HARD_CODED_DOCUMENT_TYPES = [
  { name: 'Addendum To Residential', value: 'ADDENDUM' },
  { name: 'Conditional Commitment', value: 'CONDITIONAL_COMMITMENT' },
  { name: 'Demographic Information', value: 'DEMOGRAPHIC_INFORMATION' },
  { name: 'Good Faith Estimate (GFE)', value: 'GFE' },
  { name: 'HUD 1 Settlement Statement', value: 'HUD' },
  { name: 'Loan Approval', value: 'LOAN_APPROVAL' },
  { name: 'Residential Loan Application', value: 'RESIDENTIAL_LOAN_APPLICATION' },
  { name: 'Sample FA Worksheet', value: 'SAMPLE_FA_WORKSHEET' },
  { name: 'Total Annual Loan Cost Rate', value: 'TALC' },
  { name: 'Amortization Schedule - Annual Projections', value: 'AMORTIZATION' },
  { name: 'Closing Instruction', value: 'CLOSING_INSTRUCTION' },
  { name: 'Comparison', value: 'COMPARISON_PAGE' },
  { name: 'HECM Printscreen', value: 'HECM' },
  { name: 'Anti-Churning Disclosure', value: 'ACD' },
  { name: 'HOME EQUITY CONVERSION DEED OF TRUST', value: 'HECM_DOT' },
  { name: 'HUD Addendum to Uniform Residential Loan Application', value: 'HUD_TO_RESIDENTIAL_LOAN_APP' },
  { name: 'Home Equity Conversion Mortgage (HECM)', value: 'HECM_NOTE' },
  { name: 'Home Equity Conversion Adjustable Rate Mortgage', value: 'HECM_PAYMENT_PLAN_ARM' },
  { name: 'Home Equity Conversion Mortgage', value: 'HECM_TIL_CLOSING' },
  { name: 'List of HUD Approved Counselors', value: 'HUD_APPROVED_COUNSELORS' },
  { name: 'Alternate Contact', value: 'ALTERNATE_CONTACT' },
  { name: 'Settlement Service Provider', value: 'SETTLEMENT_SERVICE_PROVIDER' },
  { name: 'Lead Based Paint Certification for Property Built Before 1978', value: 'LEAD_BASED_PAINT' },
  { name: '4506-C', value: 'C_MODEL_LINKING_4506C' },
];

export const BORROWER_DESCRIPTION_TEXTS = {
  borrowerType: {
    title: 'Borrower Type',
    description: `There are three types of borrowers, each with a varying level of responsibility and protections:

    Co-Borrower - A co-borrower is obligated on the loan and receives all of the protections of the loan if the other borrower dies or leaves the home.

    Non-Borrowing Spouse (NBS) - A non-borrowing spouse is obligated on the loan but does not receive the same legal protections as a borrower on the loan. An NBS is generally not considered a "borrower" on the loan in the traditional sense.

    Non-Borrowing Household Member - A non-borrowing household member is not obligated on the loan and does not receive any of the protections of the loan if the borrower dies or leaves the home.`,
  },
  nbsType: {
    title: 'Non-Borrowing Spouse Type',
    description: `The types of non-borrowing spouses are generally defined by their ability to remain in the home after the borrower dies or leaves the home. This is known as the "deferral" period, and NBS are either eligible for deferral or ineligible for deferral.

    Eligible NBS - In order for an NBS to be eligible, they must live in the subject property. An eligible NBS has their age considered when calculating the principal limit of the HECM loan.

    Ineligible NBS - In order for an NBS to be ineligible, they may not live in the subject property. An ineligible NBS does not have their age considered when calculating the principal limit of the HECM loan.`,
  },
};

export const COMPARISON_DESCRIPTION_TEXTS = {
  interestRateType: {
    title: 'Interest Rate Type',
    description: 'The interest rate type for this loan. Interest rates can be fixed or adjustable.',
  },
  initialIndexName: {
    title: 'Initial Index Name',
    description: `The name of the initial index, used only for adjustable-rate loans. Valid indices include: 
    - 1-month CMT
    - 1-year CMT
    - 10-year CMT`,
  },
  initialIndexRate: {
    title: 'Initial Index Rate',
    description: 'The actual value associated with the index, used only for adjustable-rate loans.',
  },
  expectedIndexName: {
    title: 'Expected Index Name',
    description: `The name of the expected index, used only for adjustable-rate loans. Valid indices include:
    - 1-month CMT
    - 1-year CMT
    - 10-year CMT`,
  },
  expectedIndexRate: {
    title: 'Expected Index Rate',
    description: 'The actual value associated with the expected index, used only for adjustable-rate loans.',
  },
  margin: {
    title: 'Margin',
    description: 'The number added to the index to determine the interest rate, used only for adjustable-rate loans.',
  },
  expectedRate: {
    title: 'Expected Rate',
    description: 'The sum of the margin and the expected index rate, used only for adjustable-rate loans.',
  },
  initialRate: {
    title: 'Initial Rate',
    description: 'The sum of the margin and the initial index rate, used only for adjustable-rate loans.',
  },
  mip: {
    title: 'MIP',
    description: 'The mortgage insurance premium rate, set by the FHA. Currently, it is 0.50% of the loan balance.',
  },
  monthlyServicingFee: {
    title: 'Monthly Servicing Fee',
    description: 'The monthly servicing fee for the loan, typically ranging from $0 to $30.',
  },
  lineOfCreditGrowthRate: {
    title: 'Line of Credit Growth Rate',
    description: 'The rate at which the line of credit grows. It equals the fully indexed rate plus the MIP.',
  },
  homeValue: {
    title: 'Home Value',
    description:
      "The value of the home, sourced from the Property > Appraisal tab and used to calculate the maximum claim amount. The value is based on the appraisal selected for 'Use for Calculations.'",
  },
  lendingLimit: {
    title: 'Lending Limit',
    description: 'The lending limit set annually by the FHFA and confirmed by the FHA.',
  },
  maxClaimAmount: {
    title: 'Max Claim Amount',
    description: 'The lower of the home value or the lending limit.',
  },
  principalLimitFactor: {
    title: 'Principal Limit Factor',
    description: `A value (between 0 and 1) from PLF tables used to calculate the principal limit. It depends on:
    - The age of the youngest borrower
    - The expected interest rate

    **Note:**
    - For eligible non-borrowing spouses, the age of the youngest eligible borrower is used.
    - For ineligible non-borrowing spouses, the age of the youngest borrower is used.s
    `,
  },
  principalLimit: {
    title: 'Principal Limit',
    description: 'The maximum amount that can be borrowed. It is the max claim amount multiplied by the principal limit factor.',
  },
  servicingFeeSetAside: {
    title: 'Servicing Fee Set Aside',
    description: 'The amount deducted from the principal limit to cover the servicing fee. Refer to the knowledge base for detailed calculations.',
  },
  imip: {
    title: 'Initial Mortgage Insurance Premium (IMIP)',
    description: `The upfront MIP charged for HECM loans. Typically:
    - 2% of the max claim amount for most loans.
    - A reduced MIP for HECM-to-HECM refinances.
    
    *IMIP is not charged on proprietary loans.*`,
  },
  originationFee: {
    title: 'Origination Fee',
    description: 'A fee charged by the loan originator. HUD caps this fee at $6,000 for HECM loans. Proprietary loans may have different caps.',
  },
  thirdPartyClosingCosts: {
    title: 'Third Party Closing Costs',
    description: 'The sum of all fees from the Fees tab, excluding IMIP and origination fees.',
  },
  lenderCredits: {
    title: 'Lender Credits',
    description: 'Any credits applied to reduce borrower fees.',
  },
  remainingPrincipalLimit: {
    title: 'Remaining Principal Limit',
    description: 'The remaining amount available after deductions.',
  },
  totalPayoffs: {
    title: 'Total Payoffs',
    description: 'The total payoffs entered on the Payoffs tab.',
  },
  lesaRequired: {
    title: 'LESA Required?',
    description: 'Whether the LESA is required for this loan.',
  },
  repairSetAside: {
    title: 'Repair Set Aside',
    description: `Funds allocated for repairs, calculated as the contractor's estimate times 50%. 
    - Example: A $500 repair invoice becomes a $750 set aside.
    **Note:** Repair set asides cannot exceed 15% of the home's value.`,
  },
  lifeExpectancySetAside: {
    title: 'Life Expectancy Set Aside (LESA)',
    description: 'Funds set aside for future property charges, if required. Refer to the knowledge base for detailed calculations.',
  },
  lesaYear1: {
    title: 'LESA Year 1',
    description: 'The actual costs for LESA in the first year, excluding the 20% cushion for future charges.',
  },
  remainingLESA: {
    title: 'Remaining LESA',
    description: 'The remaining costs for LESA after the first year, with the 20% cushion applied for future property charges.',
  },
  mandatoryObligations: {
    title: 'Mandatory Obligations',
    description: `Fees and charges required for loan approval, paid at closing or during the first 12-month disbursement period. These include:
    - IMIP
    - Loan origination fee
    - HECM counseling fee
    - Closing costs
    - Repair set asides
    - Repair administration fee
    - Delinquent federal debt
    - Property liens (payoffs)
    - Contractor payments for closing conditions
    - First-year property charges`,
  },
  idl: {
    title: 'Initial Disbursement Limit',
    description: `The initial disbursement limit is the maximum amount of money that can be drawn within the first year on the loan. It is calculated as the greater of two cases:
    - 60% of the principal limit
    - The sum of mandatory obligations and 10% of the principal limit`,
  },
  fundsAvailableAtClosing: {
    title: 'Maximum Available Funds at Closing',
    description:
      'The maximum amount of money available at closing, calculated as the items left over after mandatory obligations are subtracted from the initial disbursement limit.',
  },
  cashRequest: {
    title: 'Cash Request',
    description: 'The amount of money being requested by the borrower at closing.',
  },
  totalLineOfCredit: {
    title: 'Total Line of Credit',
    description: 'The total amount of money available in the line of credit after the first year.',
  },
  lineOfCreditAvailableFirstYear: {
    title: 'Line of Credit Available First Year',
    description: 'The amount of money available in the line of credit during the first year. (i.e. before the initial disbursement limit has passed)',
  },
  lineOfCreditAfterYear1: {
    title: 'Line of Credit After Year 1',
    description: 'The amount of money available in the line of credit after the first year (i.e. after the initial disbursement limit has passed)',
  },
  monthlyPaymentRequest: {
    title: 'Monthly Payment Request',
    description: "The borrower's requested monthly payment, if any.",
  },
  monthlyTenureAvailable: {
    title: 'Monthly Tenure Available',
    description: 'The amount of money available in the tenure payment option.',
  },
  initialBalance: {
    title: 'Initial Loan Balance (UPB)',
    description: `The unpaid balance of the loan at closing. This includes all funds drawn by the borrower immediately at closing, but does not include fees that have been set aside.`,
  },
  utilization: {
    title: 'Utilization',
    description: 'This is the usage of the UPB divided by the principal limit. It is a percentage that shows how much of the principal limit has been used.',
  },
};

export const HISPANIC_OR_LATINO_OPTIONS = [
  { label: 'Mexican', value: 'Mexican' },
  { label: 'Puerto Rican', value: 'Puerto Rican' },
  { label: 'Cuban', value: 'Cuban' },
  { label: 'Other Hispanic', value: 'Other Hispanic' },
];

export const ASIAN_OPTIONS = [
  { label: 'Asian Indian', value: 'Asian Indian' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Filipino', value: 'Filipino' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Other Asian', value: 'Other Asian' },
];

export const NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER_OPTIONS = [
  { label: 'Native Hawaiian', value: 'Native Hawaiian' },
  { label: 'Guamanian or Chamorro', value: 'Guamanian or Chamorro' },
  { label: 'Samoan', value: 'Samoan' },
  { label: 'Other Pacific Islander', value: 'Other Pacific Islander' },
];

export const INCOME_TYPE_OPTIONS = [
  { label: 'Employment', value: 'Employment' },
  { label: 'Pension', value: 'Pension' },
  { label: 'Social Security', value: 'Social Security' },
  { label: 'Disability', value: 'Disability' },
  { label: 'Alimony', value: 'Alimony' },
  { label: 'IRA and 401k Income', value: 'IRA and 401k Income' },
  {
    label: 'Rental Income from Subject Property',
    value: 'Rental Income from Subject Property',
  },
  { label: 'Rental Income from REO', value: 'Rental Income from REO' },
  {
    label: 'Rental Income from Boarders',
    value: 'Rental Income from Boarders',
  },
  { label: 'Expected Income', value: 'Expected Income' },
  { label: 'Annuity Income', value: 'Annuity Income' },
  { label: 'Trust Account', value: 'Trust Account' },
  { label: 'Notes Receivable', value: 'Notes Receivable' },
];
