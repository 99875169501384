<template>
  <div class="input-box">
    <!-- Optional label for the select input -->
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">
        {{ label }}
      </label>

      <!-- Tooltip Icon with tooltip text on hover -->
      <Tooltip v-if="!showModalDescription && tooltip?.length > 0" :tooltip="tooltip" />

      <!-- Show Icon for Modal Description -->
      <span
        v-if="showModalDescription"
        class="ml-1 cursor-pointer text-primary"
        v-tooltip="{ content: 'Click to see more details...', triggers: ['hover'], autoHide: true }"
        @click="triggerOpenModalDescription"
      >
        <font-awesome-icon :icon="['fa', 'circle-info']" :style="{ fontSize: '14px' }" />
      </span>

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>
    <!-- Container for the custom select -->
    <div class="custom-select-container">
      <!-- Bind the `inputClass` prop and conditional validation class to the `select` element -->
      <select
        :id="id"
        v-model="selectedOption"
        :disabled="disabled"
        @change="validateInput"
        :class="[{ 'custom-invalid': !isValid && !disableValidation }, inputClass, disabled ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : '']"
      >
        <!-- Render options dynamically -->
        <option v-for="option in options" :value="option.value" :key="option.value" class="custom-option">
          {{ option.label }}
        </option>
      </select>

      <!-- Custom arrow icon for the select -->
      <div class="custom-select-arrow">
        <font-awesome-icon icon="fa-solid fa-chevron-down" />
      </div>
    </div>

    <!-- Error message display if the input is invalid and validation is enabled -->
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  import Tooltip from '@/components/Tooltip';
  export default {
    name: 'SelectInput',
    components: {
      Tooltip,
    },
    props: {
      label: String, // Optional label for the input
      id: String, // ID for the select element
      options: {
        type: Array,
        required: true, // Options array for select input
      },
      value: String, // Bind value prop to selectedOption
      disableValidation: Boolean, // Disable validation if true
      inputClass: {
        // New prop to accept custom classes
        type: String,
        default: '', // Default to empty string if no class is provided
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
      showModalDescription: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        selectedOption: this.value || '', // Use selectedOption for v-model
        isValid: true, // Track validation status
        isDirty: false, // Track if the input has been modified
      };
    },
    methods: {
      // Validate the input when value changes
      validateInput() {
        this.isDirty = true;
        this.isValid = this.selectedOption !== ''; // Basic validation to check if input is not empty
        this.$emit('update:modelValue', this.selectedOption); // Emit the new value
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
      triggerOpenModalDescription() {
        this.$emit('trigger-open-modal-description');
      },
    },
    watch: {
      value(newValue) {
        this.selectedOption = newValue; // Update selectedOption when the value prop changes
        this.validateInput(); // Revalidate the input
      },
    },
    computed: {
      // Error message to display when validation fails
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
        return '';
      },
    },
  };
</script>

<style scoped>
  .custom-select-container {
    position: relative;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    border: 1px solid #ccc;
    width: 100%;
    padding-right: 36px;
  }

  .custom-select-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #757575;
  }

  .custom-option {
    background-color: #ffffff;
    color: #212121;
  }

  .custom-invalid {
    border-color: red;
  }
</style>
