<template>
  <Card>
    <template v-slot:title>
      <div class="flex items-center">
        <button @click="goToReporting" class="mr-2">
          <font-awesome-icon icon="arrow-left" />
        </button>
        <h1 class="text-lg font-semibold leading-none">Loan Export</h1>
      </div>
    </template>
    <template v-slot:action>
      <div class="flex items-center">
        <input v-model="searchQuery" class="search-input mr-[15px] flex-grow" placeholder="Filter ..." />
      </div>
    </template>
    <DataTable :headers="headers" :data="filteredTableData" @action="handleAction" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LoanExport',
    components: {
      Card,
      DataTable,
    },
    data() {
      return {
        headers: [
          { key: 'borrowerName', label: 'Borrower Name' },
          { key: 'id', label: 'Loan Number' },
          { key: 'city', label: 'City' },
          { key: 'state', label: 'State' },
          { key: 'loanOfficerFullName', label: 'Loan Officer Name' },
          { key: 'status', label: 'Status' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        loans: [],
        searchQuery: '',
      };
    },
    async created() {
      await this.fetchLoans();
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchLoans() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/loans/get-hierarchy-loans/');
          this.tableData = response.data.map((loan) => ({
            id: loan.id,
            officerFullName: loan.officerFullName,
            status: loan.status,
            borrowerName: loan.primaryBorrower ? `${loan.primaryBorrower?.firstName} ${loan.primaryBorrower?.lastName}` : '',
            loanOfficerFullName: loan.loanOfficerFullName,
            city: loan.property?.city,
            state: loan.property?.state,
            actions: ['print'],
          }));
        } catch (error) {
          this.tableData = [];
          this.$root.showSnackbar(`Failed to fetch loans: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
        this.setLoading(false);
      },
      async handleAction(action, item) {
        if (action === 'print') {
          this.setLoading(true);
          try {
            const response = await apiService.post(
              `/loan/export-loan/`,
              {
                id: item.id,
              },
              {
                responseType: 'blob', // Expect a blob response from the server
              }
            );

            if (!response || !response.data) {
              throw new Error('Failed to receive valid data from the server.');
            }

            let fileName = `${item.id}.json`;

            // Access the Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
              if (fileNameMatch && fileNameMatch.length === 2) {
                fileName = fileNameMatch[1];
              }
            }

            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
              const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
              if (fileNameMatch.length === 2) {
                fileName = fileNameMatch[1];
              }
            }

            const blob = new Blob([response.data], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

            this.$root.showSnackbar('Loan exported successfully', 'success');
          } catch (error) {
            if (error.response && error.response.data instanceof Blob) {
              try {
                const errorJSONString = await error.response.data.text();
                const errorJSON = JSON.parse(errorJSONString);
                this.$root.showSnackbar(`${errorJSON.detail ?? errorJSON.error ?? 'Something went wrong.'}`, 'error');
              } catch (error) {
                this.$root.showSnackbar(`Failed to export loan: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
              }
            } else {
              this.$root.showSnackbar(`Failed to export loan: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
            }
          } finally {
            this.setLoading(false);
          }
        }
      },
      goToReporting() {
        this.$router.push({ name: 'Reporting' });
      },
    },
    computed: {
      filteredTableData() {
        if (!this.searchQuery) {
          return this.tableData;
        }
        const query = this.searchQuery.toLowerCase();
        return this.tableData.filter((loan) => {
          const queryLower = query.toLowerCase();
          let flag =
            loan.loanOfficerFullName?.toLowerCase()?.includes(queryLower) ||
            loan.status?.toLowerCase()?.includes(queryLower) ||
            loan.id?.toString()?.includes(queryLower) ||
            loan.city?.toLowerCase()?.includes(queryLower) ||
            loan.state?.toLowerCase()?.includes(queryLower) ||
            loan.borrowerName?.toLowerCase()?.includes(queryLower);
          return flag;
        });
      },
    },
  };
</script>
