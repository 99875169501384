<template>
  <Dialog :title="'Edit Asset Type'" :isOpen="isOpen" @close="closeDialog" @confirm="emitConfirm">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <SelectInput label="Asset Type" :options="typeOptions" :value="formData.assetType" v-model.trim="formData.assetType" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Description" :value="formData.description" :max-length="160" v-model.trim="formData.description" :disable-validation="true" />
      </div>
      <div>
        <MoneyInput label="Amount" :value="formData.amount" v-model.trim="formData.amount" :disable-validation="true" />
      </div>
      <div class="text-center flex flex-col">
        <label class="font-semibold text-[#212121]">Discount Rate (%)</label>
        <p class="text-[#212121] flex justify-center items-center h-full">
          {{ discountRates[formData.assetType] }}
        </p>
      </div>
      <div>
        <MoneyInput label="Discounted Value" :value="formData.discountedValue" v-model.trim="formData.discountedValue" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Account #" :value="formData.accountNumber" v-model.trim="formData.accountNumber" :disable-validation="true" />
      </div>
      <div>
        <InputField label="Institution Address" :value="formData.institutionAddress" v-model.trim="formData.institutionAddress" :disable-validation="true" />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="emitConfirm" :disabled="!isValidForm"> Save Changes </Button>
      <Button variant="secondary" @click="closeDialog"> Cancel </Button>
    </div>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';

  export default {
    name: 'AssetDialog',
    components: {
      Dialog,
      InputField,
      SelectInput,
      MoneyInput,
      Button,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      editItem: {
        type: Object,
        required: true,
      },
      typeOptions: {
        type: Array,
        required: true,
      },
      discountRates: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formData: {
          assetType: 'Checking',
          amount: 0,
          description: '',
          discountedValue: 0,
          accountNumber: '',
          institutionAddress: '',
        },
      };
    },
    watch: {
      editItem: {
        handler(newValue) {
          this.formData = { ...newValue };
        },
        deep: true,
        immediate: true,
      },
      'formData.assetType': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[newValue]) / 100;
          if (this.formData.amount !== this.formData.discountedValue / discountFactor) {
            this.formData.discountedValue = discountFactor * this.formData.amount;
          }
        },
        immediate: true,
      },
      'formData.amount': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[this.formData.assetType]) / 100;
          if (newValue !== this.formData.discountedValue / discountFactor) {
            this.formData.discountedValue = discountFactor * newValue;
          }
        },
        immediate: true,
      },
      'formData.discountedValue': {
        handler(newValue) {
          const discountFactor = (100 - this.discountRates[this.formData.assetType]) / 100;
          if (newValue !== this.formData.amount * discountFactor) {
            this.formData.amount = newValue / discountFactor;
          }
        },
        immediate: true,
      },
    },
    computed: {
      isValidForm() {
        return this.formData.description.length > 0 && this.formData.discountedValue > 0;
      },
    },
    methods: {
      closeDialog() {
        this.$emit('close');
      },
      emitConfirm() {
        this.$emit('confirm', this.formData);
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
