<template>
  <Card>
    <!-- Active Borrower Selection -->
    <div class="flex justify-between" v-if="existBorrower">
      <div>
        <div v-if="this.items.length > 1">
          <SelectInput label="Active Borrower" id="activeBorrower" :options="activeBorrowerOptions" v-model="activeBorrower" :value="activeBorrower" :disable-validation="true" />
        </div>
      </div>
      <div class="flex items-end">
        <Button variant="primary" :disabled="!writePermission" @click="openAddDialog" style="width: fit-content"
          >Add {{ activeBorrowerOptions.length > 0 ? 'Borrower' : 'Co-Borrower' }}</Button
        >
      </div>
    </div>
    <!-- Divider -->
    <div class="w-full h-px my-3 bg-gray-300" v-if="existBorrower"></div>
    <!-- Borrower Details Form -->
    <div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <InputField
            label="First Name *"
            id="firstName"
            ref="firstName"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
            v-model.trim="formData.firstName"
            :value="formData.firstName"
            :disable-validation="true"
            tooltip="The first name of the borrower. Used for identification on all loan documents."
          />
          <span v-if="!formData.firstName && validationFormErrors.firstName" class="text-sm text-red">
            {{ validationFormErrors.firstName }}
          </span>
        </div>
        <div>
          <InputField
            label="Middle Name"
            id="middleName"
            ref="middleName"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
            v-model.trim="formData.middleName"
            :value="formData.middleName"
            :disable-validation="true"
            tooltip="The middle name or initial of the borrower. This may be optional depending on documentation requirements."
          />
        </div>
        <div>
          <InputField
            label="Last Name *"
            id="lastName"
            ref="lastName"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
            v-model.trim="formData.lastName"
            :value="formData.lastName"
            :disable-validation="true"
            tooltip="The last name of the borrower. Used for identification on all loan documents."
          />
          <span v-if="!formData.lastName && validationFormErrors.lastName" class="text-sm text-red">
            {{ validationFormErrors.lastName }}
          </span>
        </div>
        <div>
          <DateInput
            label="Date of Birth *"
            id="dob"
            ref="dob"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
            v-model="formData.dob"
            :value="formatDate(formData.dob)"
            :disable-validation="true"
            tooltip="The borrower’s birth date, required to determine eligibility and relevant financial terms for the loan."
          />
          <span v-if="!formData.dob && validationFormErrors.dob" class="text-sm text-red">
            {{ validationFormErrors.dob }}
          </span>
        </div>
        <div>
          <SSNInputField
            label="SSN"
            id="ssn"
            ref="ssn"
            @trigger-open-history-dialog="openHistoryDialog"
            historyShow="true"
            v-model.trim="formData.ssn"
            :value="formData.ssn"
            :disable-validation="true"
            tooltip="The borrower’s Social Security Number. Used to verify identity and conduct credit check."
          />
        </div>
        <div>
          <SelectInput
            label="Immigration Status"
            id="immigrationStatus"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            :options="immigrationStatusOptions"
            v-model="formData.immigrationStatus"
            :value="formData.immigrationStatus"
            :disable-validation="true"
            tooltip="The legal status of the borrower within the U.S., which can impact eligibility."
          />
        </div>
        <div>
          <SelectInput
            label="Marital Status"
            id="maritalStatus"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            :options="maritalStatusOptions"
            v-model="formData.maritalStatus"
            :value="formData.maritalStatus"
            :disable-validation="true"
            tooltip="The current marital status of the borrower, such as married, unmarried or separated."
          />
        </div>
        <div>
          <PhoneNumberInputField
            label="Home Phone"
            id="homePhone"
            ref="homePhone"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model.trim="formData.homePhone"
            :value="formData.homePhone"
            @input="() => validateNumber(formData.homePhone)"
            :disable-validation="true"
            tooltip="The borrower’s primary residential phone number, if available, for contacting them."
          />
          <span v-if="validationFormErrors.homePhone" class="text-sm text-red">
            {{ validationFormErrors.homePhone }}
          </span>
        </div>
        <div>
          <PhoneNumberInputField
            label="Mobile Phone"
            id="mobilePhone"
            ref="mobilePhone"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model.trim="formData.mobilePhone"
            :value="formData.mobilePhone"
            @input="() => validateNumber(formData.mobilePhone)"
            :disable-validation="true"
            tooltip="The borrower’s mobile number for direct communication regarding the loan application."
          />
          <span v-if="validationFormErrors.mobilePhone" class="text-sm text-red">
            {{ validationFormErrors.mobilePhone }}
          </span>
        </div>
        <div>
          <IntegerInput
            label="Years of Residence"
            id="yearsOfResidence"
            ref="yearsOfResidence"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model="formData.yearsOfResidence"
            :value="formData.yearsOfResidence"
            :disable-validation="true"
            tooltip="The number of years the borrower has lived in the United States."
          />
        </div>
        <div>
          <InputField
            label="Signature"
            id="signature"
            ref="signature"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model="formData.signatureLine"
            :value="formData.signatureLine"
            :disable-validation="true"
            tooltip="The signature of the borrower."
          />
        </div>
        <div>
          <InputField label="Email" id="email" ref="email" v-model="formData.email" :value="formData.email" :disable-validation="true" tooltip="The email of the borrower." />
          <span v-if="validationFormErrors.email?.length > 0" class="text-sm text-red">
            {{ validationFormErrors.email }}
          </span>
        </div>
      </div>
      <!-- Is Mailing Address -->
      <div class="flex items-center mt-4">
        <CheckboxInput
          label="Mailing address is not the same as subject property address"
          id="isMailingAddress"
          v-model="formData.isMailingAddress"
          :value="formData.isMailingAddress"
        />
      </div>
      <!-- Mailing Address -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4" v-if="formData.isMailingAddress">
        <div>
          <InputField
            label="Address Line 1"
            id="mailingAddressLine1"
            v-model.trim="formData.mailingAddressLine1"
            :value="formData.mailingAddressLine1"
            :disable-validation="true"
            tooltip="The first line of the borrower’s mailing address."
          />
        </div>
        <div>
          <InputField
            label="City"
            id="mailingAddressCity"
            ref="mailingAddressCity"
            v-model.trim="formData.mailingAddressCity"
            :value="formData.mailingAddressCity"
            :disable-validation="true"
            tooltip="The city of the borrower’s mailing address."
          />
        </div>
        <div>
          <SelectInput
            label="State"
            id="mailingAddressState"
            ref="mailingAddressState"
            :options="US_STATES"
            v-model="formData.mailingAddressState"
            :value="formData.mailingAddressState"
            :disable-validation="true"
            tooltip="The state of the borrower’s mailing address."
          />
        </div>
        <div>
          <IntegerInput
            label="Zip Code"
            id="mailingAddressZip"
            ref="mailingAddressZip"
            maxLength="5"
            v-model.trim="formData.mailingAddressZip"
            :value="formData.mailingAddressZip"
            :disable-validation="true"
            tooltip="The zip code of the borrower’s mailing address."
          />
        </div>
      </div>
      <!-- Borrower Type -->
      <div v-if="!isMainBorrower" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <div v-if="activeBorrowerOptions?.length > 1 && (coborrowerCount > 1 || (coborrowerCount == 1 && selectedBorrower?.isNBS))" class="form-group">
          <SelectInput
            label="Borrower Type"
            id="borrowerType"
            :options="borrowerTypeOptions"
            v-model="formData.borrowerType"
            :value="formData.borrowerType"
            :disable-validation="true"
            :history-show="true"
            @trigger-open-history-dialog="openHistoryDialog"
            :show-modal-description="true"
            @trigger-open-modal-description="handleOpenModalDescription('borrowerType')"
          />
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE">
          <SelectInput
            label="NBS Type"
            historyShow="true"
            id="nbsType"
            :options="nbsTypeOptions"
            v-model="formData.nbsType"
            :value="formData.nbsType"
            :disable-validation="true"
            :show-modal-description="true"
            @trigger-open-modal-description="handleOpenModalDescription('nbsType')"
          />
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'"></div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'">
          <InputField
            label="NBS Address Line1 *"
            id="nbsAddressLine1"
            ref="nbsAddressLine1"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model.trim="formData.nbsAddressLine1"
            :value="formData.nbsAddressLine1"
            :disable-validation="true"
          />
          <span v-if="!formData.nbsAddressLine1 && validationFormErrors.nbsAddressLine1" class="text-sm text-red">
            {{ validationFormErrors.nbsAddressLine1 }}
          </span>
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'">
          <InputField
            label="NBS Address Line2"
            id="nbsAddressLine2"
            ref="nbsAddressLine2"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model.trim="formData.nbsAddressLine2"
            :value="formData.nbsAddressLine2"
            :disable-validation="true"
          />
        </div>

        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'">
          <InputField
            label="NBS City *"
            id="nbsCity"
            ref="nbsCity"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model.trim="formData.nbsCity"
            :value="formData.nbsCity"
            :disable-validation="true"
          />
          <span v-if="!formData.nbsCity && validationFormErrors.nbsCity" class="text-sm text-red">
            {{ validationFormErrors.nbsCity }}
          </span>
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'">
          <SelectInput
            label="NBS State *"
            id="nbsState"
            ref="nbsState"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            :options="US_STATES"
            v-model="formData.nbsState"
            :value="formData.nbsState"
            :disable-validation="true"
          />
          <span v-if="!formData.nbsState && validationFormErrors.nbsState" class="text-sm text-red">
            {{ validationFormErrors.nbsState }}
          </span>
        </div>
        <div class="form-group" v-if="formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && formData.nbsType == 'Ineligible'">
          <InputField
            label="NBS Zip Code *"
            id="nbsZip"
            ref="nbsZip"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model.trim="formData.nbsZip"
            :value="formData.nbsZip"
            :disable-validation="true"
          />
          <span v-if="!formData.nbsZip && validationFormErrors.nbsZip" class="text-sm text-red">
            {{ validationFormErrors.nbsZip }}
          </span>
        </div>
      </div>
      <div class="flex justify-end gap-4 mt-5">
        <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="(!this.isDraft && existBorrower) || !writePermission">
          {{ existBorrower ? 'Save Changes' : 'Add Borrower' }}
        </Button>
        <Button variant="secondary" @click="handleOpenDeleteConfirmation" style="width: fit-content" :disabled="removeDisabled || !writePermission" v-if="existBorrower">
          Remove Borrower
        </Button>
      </div>
    </div>
    <!-- Tabs and Content -->
    <div v-if="items?.length > 0">
      <TabCard class="my-15 overflow-x-auto">
        <ul class="tabs flex items-center">
          <li v-for="tabOption in tabOptions" :key="tabOption.title" class="tab" :class="{ active: tabOption.title === activeTab.title }" @click="changeActiveTab(tabOption.title)">
            {{ tabOption.title }}
          </li>
          <li v-if="isMainBorrower" class="tab" :class="{ active: 'Fnma' === activeTab.title }" @click="changeActiveTab('Fnma')">Fnma</li>
        </ul>
      </TabCard>
      <div class="content-container">
        <component :is="activeTabComponent" :writePermission="writePermission" v-model:borrowerId="formData.id" v-model:borrowers="items" />
      </div>
    </div>
  </Card>
  <!-- Add Co-Borrower Dialog -->
  <Dialog :title="'Add New Co-Borrower'" :isOpen="isAddDialogOpen" @confirm="handleConfirmAddDialog" @close="handleCloseAddDialog">
    <form @submit.prevent="handleConfirmAddDialog">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div class="form-group">
          <InputField
            label="First Name *"
            id="firstName-add"
            ref="firstName-add"
            v-model.trim="addFormData.firstName"
            :value="addFormData.firstName"
            :disable-validation="true"
            tooltip="The first name of the borrower. Used for identification on all loan documents."
          />
          <span v-if="!addFormData.firstName && validationAddFormErrors.firstName" class="text-sm text-red">{{ validationAddFormErrors.firstName }}</span>
        </div>
        <div class="form-group">
          <InputField
            label="Middle Name"
            id="middleName-add"
            ref="middleName-add"
            v-model.trim="addFormData.middleName"
            :value="addFormData.middleName"
            :disable-validation="true"
            tooltip="The middle name or initial of the borrower. This may be optional depending on documentation requirements."
          />
        </div>
        <div class="form-group">
          <InputField
            label="Last Name *"
            id="lastName-add"
            ref="lastName-add"
            v-model.trim="addFormData.lastName"
            :value="addFormData.lastName"
            :disable-validation="true"
            tooltip="The last name of the borrower. Used for identification on all loan documents."
          />
          <span v-if="!addFormData.lastName && validationAddFormErrors.lastName" class="text-sm text-red">{{ validationAddFormErrors.lastName }}</span>
        </div>
        <div class="form-group">
          <DateInput
            label="Date of Birth *"
            id="dob-add"
            ref="dob-add"
            v-model="addFormData.dob"
            :value="addFormData.dob"
            :disable-validation="true"
            tooltip="The borrower’s birth date, required to determine eligibility and relevant financial terms for the loan."
          />
          <span v-if="!addFormData.dob && validationAddFormErrors.dob" class="text-sm text-red">{{ validationAddFormErrors.dob }}</span>
        </div>
        <div class="form-group">
          <SSNInputField
            label="SSN"
            id="ssn-add"
            ref="ssn-add"
            v-model.trim="addFormData.ssn"
            :value="addFormData.ssn"
            :disable-validation="true"
            tooltip="The borrower’s Social Security Number. Used to verify identity and conduct credit check."
          />
        </div>
        <div class="form-group">
          <SelectInput
            label="Immigration Status"
            id="immigrationStatus-add"
            :options="immigrationStatusOptions"
            v-model="addFormData.immigrationStatus"
            :value="addFormData.immigrationStatus"
            :disable-validation="true"
            tooltip="The legal status of the borrower within the U.S., which can impact eligibility."
          />
        </div>
        <div class="form-group">
          <SelectInput
            label="Marital Status"
            id="maritalStatus-add"
            :options="maritalStatusOptions"
            v-model="addFormData.maritalStatus"
            :value="addFormData.maritalStatus"
            :disable-validation="true"
            tooltip="The current marital status of the borrower, such as married, unmarried or separated."
          />
        </div>
        <div class="form-group">
          <PhoneNumberInputField
            label="Home Phone"
            id="homePhone-add"
            ref="homePhone-add"
            v-model.trim="addFormData.homePhone"
            :value="addFormData.homePhone"
            :disable-validation="true"
            @input="() => validateNumber(addFormData.homePhone)"
            tooltip="The borrower’s primary residential phone number, if available, for contacting them."
          />
          <span v-if="validationAddFormErrors.homePhone" class="text-sm text-red">{{ validationAddFormErrors.homePhone }}</span>
        </div>
        <div class="form-group">
          <PhoneNumberInputField
            label="Mobile Phone"
            id="mobilePhone-add"
            ref="mobilePhone-add"
            v-model.trim="addFormData.mobilePhone"
            :value="addFormData.mobilePhone"
            :disable-validation="true"
            @input="() => validateNumber(addFormData.mobilePhone)"
            tooltip="The borrower’s mobile number for direct communication regarding the loan application."
          />
          <span v-if="validationAddFormErrors.mobilePhone" class="text-sm text-red">{{ validationAddFormErrors.mobilePhone }}</span>
        </div>
        <div class="form-group">
          <IntegerInput
            label="Years of Residence"
            id="yearsOfResidence-add"
            ref="yearsOfResidence-add"
            v-model.trim="addFormData.yearsOfResidence"
            :value="addFormData.yearsOfResidence"
            :disable-validation="true"
            tooltip="The number of years the borrower has lived at their current address."
          />
        </div>
        <div>
          <InputField
            label="Signature"
            id="signature-add"
            ref="signature-add"
            historyShow="true"
            @trigger-open-history-dialog="openHistoryDialog"
            v-model="addFormData.signatureLine"
            :value="addFormData.signatureLine"
            :disable-validation="true"
            tooltip="The signature of the borrower."
          />
        </div>
        <div>
          <InputField
            label="Email"
            id="email-add"
            ref="email-add"
            v-model="addFormData.email"
            :value="addFormData.email"
            :disable-validation="true"
            tooltip="The email of the borrower."
          />
          <span v-if="!addFormData.email && validationAddFormErrors.email" class="text-sm text-red">{{ validationAddFormErrors.email }}</span>
        </div>
      </div>
      <div class="flex items-center gap-2 mt-4">
        <CheckboxInput
          label="Mailing address is not the same as subject property address"
          id="isMailingAddress-add"
          v-model="addFormData.isMailingAddress"
          :value="addFormData.isMailingAddress"
        />
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4" v-if="addFormData.isMailingAddress">
        <div class="form-group">
          <InputField
            label="Address Line 1"
            id="mailingAddressLine1-add"
            ref="mailingAddressLine1-add"
            v-model.trim="addFormData.mailingAddressLine1"
            :value="addFormData.mailingAddressLine1"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <InputField
            label="City"
            id="mailingAddressCity-add"
            ref="mailingAddressCity-add"
            v-model.trim="addFormData.mailingAddressCity"
            :value="addFormData.mailingAddressCity"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <SelectInput
            label="State"
            id="mailingAddressState-add"
            ref="mailingAddressState-add"
            :options="US_STATES"
            v-model="addFormData.mailingAddressState"
            :value="addFormData.mailingAddressState"
            :disable-validation="true"
          />
        </div>
        <div class="form-group">
          <IntegerInput
            label="Zip Code"
            id="mailingAddressZip-add"
            ref="mailingAddressZip-add"
            v-model.trim="addFormData.mailingAddressZip"
            :value="addFormData.mailingAddressZip"
            :disable-validation="true"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <div class="form-group">
          <SelectInput
            label="Borrower Type"
            id="borrowerType-add"
            :options="borrowerTypeOptions"
            v-model="addFormData.borrowerType"
            :value="addFormData.borrowerType"
            :disable-validation="true"
            :show-modal-description="true"
            @trigger-open-modal-description="handleOpenModalDescription('borrowerType')"
          />
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE">
          <SelectInput
            label="NBS Type"
            id="nbsType-add"
            :options="nbsTypeOptions"
            v-model="addFormData.nbsType"
            :value="addFormData.nbsType"
            :disable-validation="true"
            :show-modal-description="true"
            @trigger-open-modal-description="handleOpenModalDescription('nbsType')"
          />
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && addFormData.nbsType == 'Ineligible'">
          <InputField
            label="NBS Address Line1 *"
            id="nbsAddressLine1-add"
            ref="nbsAddressLine1-add"
            v-model.trim="addFormData.nbsAddressLine1"
            :value="addFormData.nbsAddressLine1"
            :disable-validation="true"
          />
          <span v-if="!addFormData.nbsAddressLine1 && validationAddFormErrors.nbsAddressLine1" class="text-sm text-red">
            {{ validationAddFormErrors.nbsAddressLine1 }}
          </span>
        </div>

        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && addFormData.nbsType == 'Ineligible'">
          <InputField
            label="NBS Address Line2"
            id="nbsAddressLine2-add"
            ref="nbsAddressLine2-add"
            v-model.trim="addFormData.nbsAddressLine2"
            :value="addFormData.nbsAddressLine2"
            :disable-validation="true"
          />
          <span v-if="!addFormData.nbsAddressLine2 && validationAddFormErrors.nbsAddressLine2" class="text-sm text-red">
            {{ validationAddFormErrors.nbsAddressLine2 }}
          </span>
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && addFormData.nbsType == 'Ineligible'">
          <InputField label="NBS City *" id="nbsCity-add" ref="nbsCity-add" v-model.trim="addFormData.nbsCity" :value="addFormData.nbsCity" :disable-validation="true" />
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && addFormData.nbsType == 'Ineligible'">
          <SelectInput
            label="NBS State *"
            id="nbsState-add"
            ref="nbsState-add"
            :options="US_STATES"
            v-model="addFormData.nbsState"
            :value="addFormData.nbsState"
            :disable-validation="true"
          />
          <span v-if="!addFormData.nbsState && validationAddFormErrors.nbsState" class="text-sm text-red">
            {{ validationAddFormErrors.nbsState }}
          </span>
        </div>
        <div class="form-group" v-if="addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && addFormData.nbsType == 'Ineligible'">
          <InputField label="NBS Zip Code *" id="nbsZip-add" ref="nbsZip-add" v-model.trim="addFormData.nbsZip" :value="addFormData.nbsZip" :disable-validation="true" />
          <span v-if="!addFormData.nbsZip && validationAddFormErrors.nbsZip" class="text-sm text-red">
            {{ validationAddFormErrors.nbsZip }}
          </span>
        </div>
      </div>
      <!-- Divider -->
      <div class="w-full h-px my-3 bg-gray-300"></div>
      <!-- Dialog Buttons -->
      <div class="flex justify-end gap-4">
        <Button variant="primary" :disabled="!writePermission" type="submit" style="width: fit-content"> Add Borrower </Button>
        <Button variant="danger" @click="handleCloseAddDialog" style="width: fit-content"> Close </Button>
      </div>
    </form>
  </Dialog>
  <!-- History Dialog -->
  <HistoryDialog ref="historyDialog" />
  <!-- Delete Confirmation Modal -->
  <Confirmation
    :isOpen="isDeleteConfirmationOpen"
    message="Are you sure you want to delete this borrower?"
    @confirm="handleConfirmDeleteConfirmation"
    @close="handleCloseDeleteConfirmation"
  />
  <!-- Modal Description -->
  <ModalDescription :isOpen="descriptionModalInfo.open" :title="descriptionModalInfo.title" :content="descriptionModalInfo.content" @close="handleCloseModalDescription" />
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Button from '@/components/Button/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import PhoneNumberInputField from '@/components/FormInput/PhoneNumberInputField/index.vue';
  import SSNInputField from '@/components/FormInput/SSNInputField/index.vue';
  import CheckboxInput from '@/components/FormInput/Checkbox/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import IntegerInput from '@/components/FormInput/IntegerInput/index.vue';
  import Card from '@/components/Card/index.vue';
  import Confirmation from '@/components/Confirmation/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import ModalDescription from '@/components/ModalDescription/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import DemographicInfo from './components/DemographicInfo/index.vue';
  import Income from './components/Income/index.vue';
  import Credit from './components/Credit/index.vue';
  import Assets from './components/Assets/index.vue';
  import Declaration from './components/Declaration/index.vue';
  import AlternateContact from './components/AlternateContact/index.vue';
  import Akas from './components/Akas/index.vue';
  import Fnma from './components/Fnma/index.vue';
  import HistoryDialog from '../HistoryDialog/index.vue';
  import { US_STATES, BORROWER_TYPES, BORROWER_DESCRIPTION_TEXTS } from '@/constants/index.js';

  const initialData = {
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    ssn: '',
    immigrationStatus: '',
    maritalStatus: '',
    homePhone: '',
    mobilePhone: '',
    borrowerType: BORROWER_TYPES.CO_BOROWER,
    nbsType: 'Eligible',
    nbsAddressLine1: '',
    nbsAddressLine2: '',
    nbsCity: '',
    nbsState: '',
    nbsZip: '',
    yearsOfResidence: null,
    isMailingAddress: false,
    mailingAddressLine1: '',
    mailingAddressCity: '',
    mailingAddressState: '',
    mailingAddressZip: '',
    signatureLine: '', // will be auto generated
    email: null,
  };

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  export default {
    name: 'ActiveBorrower',
    components: {
      InputField,
      PhoneNumberInputField,
      SSNInputField,
      DateInput,
      SelectInput,
      Card,
      Confirmation,
      CheckboxInput,
      TabCard,
      Dialog,
      IntegerInput,
      DemographicInfo,
      Income,
      Button,
      Credit,
      Assets,
      Declaration,
      AlternateContact,
      HistoryDialog,
      ModalDescription,
      Akas,
      Fnma,
    },
    props: {
      activeTabTitle: {
        type: String,
        default: 'Demographic Info',
      },
      activeTabSelectId: {
        type: String,
        default: '',
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {
        BORROWER_TYPES,
        US_STATES,
        BORROWER_DESCRIPTION_TEXTS,
      };
    },
    data() {
      return {
        loanId: this.$route.params.id,
        coborrowerCount: 0,
        items: [],
        isDraft: false,
        isAddDialogOpen: false,
        isDeleteConfirmationOpen: false,
        tabOptions: [
          { title: 'Demographic Info', component: DemographicInfo },
          { title: 'Income', component: Income },
          { title: 'Credit Report', component: Credit },
          { title: 'Assets', component: Assets },
          { title: 'Declaration', component: Declaration },
          { title: 'Alternate Contact', component: AlternateContact },
          { title: 'AKAs', component: Akas },
        ],
        activeTab: { title: 'Demographic Info', isActive: true },
        selectedBorrower: {},
        formData: { ...initialData },
        addFormData: { ...initialData },
        validationAddFormErrors: {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
          nbsAddressLine1: '',
          nbsCity: '',
          nbsState: '',
          nbsZip: '',
          email: '',
        },
        validationFormErrors: {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
          nbsAddressLine1: '',
          nbsCity: '',
          nbsState: '',
          nbsZip: '',
          email: '',
        },
        maritalStatusOptions: [
          { label: 'Married', value: 'Married' },
          { label: 'Unmarried', value: 'Unmarried' },
          { label: 'Separated', value: 'Separated' },
        ],
        immigrationStatusOptions: [
          { label: 'US Citizen', value: 'US Citizen' },
          { label: 'Lawful Permanent Alien', value: 'Lawful Permanent Alien' },
          { label: 'Other', value: 'Other' },
        ],
        borrowerTypeOptions: [
          { label: 'Co-Borrower', value: 'Co-Borrower' },
          { label: 'Non-Borrowing Spouse', value: 'Non-Borrowing Spouse' },
        ],
        nbsTypeOptions: [
          { label: 'Eligible', value: 'Eligible' },
          { label: 'Ineligible', value: 'Ineligible' },
        ],
        activeBorrowerOptions: [],
        activeBorrower: '',
        descriptionModalInfo: {
          open: false,
          title: '',
          content: '',
        },
      };
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchBorrowers();
      this.updateActiveBorrower(0);
    },
    methods: {
      ...mapActions(['setLoading']),
      // Fetch all borrowers from the API
      async fetchBorrowers() {
        this.setLoading(true);
        try {
          const response = await apiService.get('/loan/borrowers/', {
            loan: this.loanId,
          });
          this.items = response.data;
          this.updateActiveBorrowerOptions();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Update Active Borrower options for the select input
      updateActiveBorrowerOptions() {
        let coborrowers = 0;
        this.coborrowerCount = 0;
        this.items.map((el) => {
          if (!el.isNBS) coborrowers++;
        });
        this.coborrowerCount = coborrowers;
        this.activeBorrowerOptions = this.items.map((el) => ({
          label: `${el.firstName} ${el.lastName}`,
          value: el.id.toString(),
        }));
      },
      // Update the Active Borrower based on mode
      updateActiveBorrower(mode) {
        if (this.activeTabSelectId !== '' && this.activeTabSelectId !== null) {
          this.activeBorrower = this.activeTabSelectId;
        } else {
          const item = this.items.length > 0 ? (mode === 0 ? this.items[0] : this.items[this.items.length - 1]) : null;
          this.activeBorrower = item ? item.id.toString() : '';
        }
      },
      // Change active tab
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      // Format date from datetime string to YYYY-MM-DD
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Return an empty string or handle it as needed
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return ''; // Handle invalid date values if necessary
        }

        const isoString = date.toISOString();
        const [year, month, day] = isoString.split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      },
      // Open add co-borrower dialog
      openAddDialog() {
        this.resetAddForm();
        this.isAddDialogOpen = true;
      },
      // Validate add form data
      validateAddForm() {
        this.validationAddFormErrors = {
          firstName: !this.addFormData.firstName ? 'First name is required.' : '',
          lastName: !this.addFormData.lastName ? 'Last name is required.' : '',
          dob: !this.addFormData.dob ? 'Date of birth is required.' : '',
          email: !this.addFormData.email ? 'Email is required.' : !emailPattern.test(this.addFormData.email) ? 'Invalid email format.' : '',
          homePhone: this.validateNumber(this.addFormData.homePhone) ? '' : 'Invalid phone number format. Expected format: (XXX) XXX-XXXX.',
          mobilePhone: this.validateNumber(this.addFormData.mobilePhone) ? '' : 'Invalid mobile phone number format. Expected format: (XXX) XXX-XXXX.',
          nbsAddressLine1:
            this.addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.addFormData.nbsType == 'Ineligible' && !this.addFormData.nbsAddressLine1
              ? 'NBS Address Line1 is required.'
              : '',
          nbsCity:
            this.addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.addFormData.nbsType == 'Ineligible' && !this.addFormData.nbsCity
              ? 'NBS City is required.'
              : '',
          nbsState:
            this.addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.addFormData.nbsType == 'Ineligible' && !this.addFormData.nbsState
              ? 'NBS State is required.'
              : '',
          nbsZip:
            this.addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.addFormData.nbsType == 'Ineligible' && !this.addFormData.nbsZip
              ? 'NBS Zip Code is required.'
              : this.addFormData.nbsType == 'Ineligible' && (this.addFormData.nbsZip.length !== 5 || !/^\d+$/.test(this.addFormData.nbsZip))
                ? 'Zip code must be 5 digits.'
                : '',
        };
        return (
          !this.validationAddFormErrors.firstName &&
          !this.validationAddFormErrors.lastName &&
          !this.validationAddFormErrors.dob &&
          !this.validationAddFormErrors.homePhone &&
          !this.validationAddFormErrors.mobilePhone &&
          !this.validationAddFormErrors.nbsAddressLine1 &&
          !this.validationAddFormErrors.nbsCity &&
          !this.validationAddFormErrors.nbsState &&
          !this.validationAddFormErrors.nbsZip &&
          !this.validationAddFormErrors.email
        );
      },
      // Validate form data
      validateForm() {
        this.validationFormErrors = {
          firstName: !this.formData.firstName ? 'First name is required.' : '',
          lastName: !this.formData.lastName ? 'Last name is required.' : '',
          dob: !this.formData.dob ? 'Date of birth is required.' : '',
          email: !this.formData.email ? 'Email is required.' : !emailPattern.test(this.formData.email) ? 'Invalid email format.' : '',
          homePhone: this.validateNumber(this.formData.homePhone) ? '' : 'Invalid phone number format. Expected format: (XXX) XXX-XXXX.',
          mobilePhone: this.validateNumber(this.formData.mobilePhone) ? '' : 'Invalid mobile phone number format. Expected format: (XXX) XXX-XXXX.',
          nbsAddressLine1:
            this.formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.formData.nbsType == 'Ineligible' && !this.formData.nbsAddressLine1
              ? 'NBS Address Line1 is required.'
              : '',
          nbsCity:
            this.formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.formData.nbsType == 'Ineligible' && !this.formData.nbsCity ? 'NBS City is required.' : '',
          nbsState:
            this.formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.formData.nbsType == 'Ineligible' && !this.formData.nbsState ? 'NBS State is required.' : '',
          nbsZip:
            this.formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE && this.formData.nbsType == 'Ineligible' && !this.formData.nbsZip
              ? 'NBS Zip Code is required.'
              : this.formData.nbsType == 'Ineligible' && (this.formData.nbsZip.length !== 5 || !/^\d+$/.test(this.formData.nbsZip))
                ? 'Zip code must be 5 digits.'
                : '',
        };
        return (
          !this.validationFormErrors.firstName &&
          !this.validationFormErrors.lastName &&
          !this.validationFormErrors.dob &&
          !this.validationFormErrors.homePhone &&
          !this.validationFormErrors.mobilePhone &&
          !this.validationFormErrors.nbsAddressLine1 &&
          !this.validationFormErrors.nbsCity &&
          !this.validationFormErrors.nbsState &&
          !this.validationFormErrors.nbsZip &&
          !this.validationFormErrors.email
        );
      },
      // Reset add form data
      resetAddForm() {
        this.addFormData = { ...initialData };
        this.validationAddFormErrors = {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
          nbsAddressLine1: '',
          nbsCity: '',
          nbsState: '',
          nbsZip: '',
        };
      },
      resetFormErrors() {
        this.validationFormErrors = {
          firstName: '',
          lastName: '',
          dob: '',
          homePhone: '',
          mobilePhone: '',
          nbsAddressLine1: '',
          nbsCity: '',
          nbsState: '',
          nbsZip: '',
        };
      },
      // Validate Number in the forms
      validateNumber(number) {
        const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        return number == '' || phonePattern.test(number);
      },
      // Handle add dialog confirmation
      async handleConfirmAddDialog() {
        if (!this.validateAddForm()) {
          return;
        }

        if (this.addFormData.signatureLine == '' || this.addFormData.signatureLine == null) {
          // if signature line is empty, set it to the borrower's name
          this.addFormData.signatureLine = this.addFormData.firstName + ' ' + this.addFormData.lastName;
        }

        if (this.addFormData.yearsOfResidence == '') {
          // if years of residence is empty, set it to null
          this.addFormData.yearsOfResidence = null;
        }

        // Add the new borrower
        let newBorrower = {
          ...this.addFormData,
          loan: this.$route.params.id,
        };

        if (this.addFormData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE) {
          newBorrower.isNBS = true;
          if (this.addFormData.nbsType == 'Ineligible') {
            newBorrower.isEligible = false;
            newBorrower.nbsAddressLine1 = this.addFormData.nbsAddressLine1;
            newBorrower.nbsAddressLine2 = this.addFormData.nbsAddressLine2;
            newBorrower.nbsCity = this.addFormData.nbsCity;
            newBorrower.nbsState = this.addFormData.nbsState;
            newBorrower.nbsZip = this.addFormData.nbsZip;
          } else {
            newBorrower.isEligible = true;
            newBorrower.nbsAddressLine1 = '';
            newBorrower.nbsAddressLine2 = '';
            newBorrower.nbsCity = '';
            newBorrower.nbsState = '';
            newBorrower.nbsZip = '';
          }
        } else {
          this.addFormData.isNBS = false;
          this.addFormData.nbsType = '';
          this.addFormData.nbsAddressLine1 = '';
          this.addFormData.nbsAddressLine2 = '';
          this.addFormData.nbsCity = '';
          this.addFormData.nbsState = '';
          this.addFormData.nbsZip = '';
        }

        this.setLoading(true);
        try {
          if (!this.writePermission) {
            this.$root.showSnackbar('You do not have permission', 'error');
            return;
          }
          await apiService.post('/loan/borrowers/', { ...newBorrower, loan: this.loanId });
          this.$root.showSnackbar(`New borrower has been created successfully!`, 'success');
          await this.fetchBorrowers();
          this.updateActiveBorrower(1);
          this.handleCloseAddDialog();
        } catch (error) {
          this.$root.showSnackbar(`Failed to create borrower: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Close add dialog
      handleCloseAddDialog() {
        this.isAddDialogOpen = false;
      },
      // Open delete confirmation dialog
      handleOpenDeleteConfirmation() {
        this.isDeleteConfirmationOpen = true;
      },
      // Handle delete confirmation
      async handleConfirmDeleteConfirmation() {
        try {
          if (!this.writePermission) {
            this.$root.showSnackbar('You do not have permission', 'error');
            return;
          }
          this.setLoading(true);
          if (this.activeBorrower != '') {
            await apiService.delete(`/loan/borrowers/${this.activeBorrower}/`);
            await this.fetchBorrowers();
            this.updateActiveBorrower(0);
            this.$root.showSnackbar('Borrower deleted successfully', 'success');
          } else {
            throw new Error('No borrower selected');
          }
        } catch (error) {
          this.$root.showSnackbar(`Failed to delete borrower: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      // Close delete confirmation dialog
      handleCloseDeleteConfirmation() {
        this.isDeleteConfirmationOpen = false;
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          if (this.formData.signatureLine == '' || this.formData.signatureLine == null) {
            // if signature line is empty, set it to the borrower's name
            this.formData.signatureLine = this.formData.firstName + ' ' + this.formData.lastName;
          }

          if (this.formData.yearsOfResidence == '') {
            // if years of residence is empty, set it to null
            this.formData.yearsOfResidence = null;
          }

          if (this.formData.borrowerType == BORROWER_TYPES.NON_BORROWING_SPOUSE) {
            this.formData.isNBS = true;
            if (this.formData.nbsType == 'Ineligible') {
              this.formData.isEligible = false;
            } else {
              this.formData.isEligible = true;
              this.addFormData.nbsAddressLine1 = '';
              this.addFormData.nbsAddressLine2 = '';
              this.addFormData.nbsCity = '';
              this.addFormData.nbsState = '';
              this.addFormData.nbsZip = '';
            }
          } else {
            this.formData.isNBS = false;
            this.formData.nbsType = '';
            this.addFormData.nbsAddressLine1 = '';
            this.addFormData.nbsAddressLine2 = '';
            this.addFormData.nbsCity = '';
            this.addFormData.nbsState = '';
            this.addFormData.nbsZip = '';
          }

          if (!this.formData.isMailingAddress) {
            this.formData.mailingAddressLine1 = '';
            this.formData.mailingAddressCity = '';
            this.formData.mailingAddressState = '';
            this.formData.mailingAddressZip = '';
          }

          if (this.formData.id) {
            await apiService.patch(`/loan/borrowers/${this.formData.id}/`, this.formData);
          } else {
            await apiService.post(`/loan/borrowers/`, { ...this.formData, loan: this.$route.params.id });
          }
          await this.fetchBorrowers();
          this.updateFormDataFromItems();
          if (!this.formData.id) this.updateActiveBorrower(0);
          this.$root.showSnackbar('Borrower information has been updated successfully', 'success');
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.resetFormErrors();
          this.setLoading(false);
        }
      },
      checkIfDraft() {
        const selectedItem = this.items?.find((item) => item.id == this.activeBorrower);
        if (isEqual(this.formData, selectedItem) || !selectedItem) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      updateFormDataFromItems() {
        let selectedItem = this.items.find((item) => item.id == this.activeBorrower);
        if (!selectedItem && this.items.length > 0) {
          selectedItem = this.items[0];
          this.activeBorrower = selectedItem.id.toString();
        }
        this.selectedBorrower = selectedItem;
        if (selectedItem) {
          let formData = {
            ...selectedItem,
            borrowerType: selectedItem.isNBS ? BORROWER_TYPES.NON_BORROWING_SPOUSE : BORROWER_TYPES.CO_BOROWER,
          };
          if (selectedItem.isNBS) {
            formData.nbsType = selectedItem.isEligible ? 'Eligible' : 'Ineligible';
            if (!selectedItem.isEligible) {
              formData.nbsAddressLine1 = selectedItem.nbsAddressLine1;
              formData.nbsAddressLine2 = selectedItem.nbsAddressLine2;
              formData.nbsCity = selectedItem.nbsCity;
              formData.nbsState = selectedItem.nbsState;
              formData.nbsZip = selectedItem.nbsZip;
            }
          }
          this.formData = { ...formData };
        } else {
          this.formData = { ...initialData };
        }
      },
      openHistoryDialog({ id }) {
        this.$refs.historyDialog.openHistoryDialog(id, 'Borrower');
      },
      handleOpenModalDescription(key) {
        const description = BORROWER_DESCRIPTION_TEXTS[key];
        if (description) {
          this.descriptionModalInfo = {
            open: true,
            title: description.title,
            content: description.description,
          };
        }
      },
      handleCloseModalDescription() {
        this.descriptionModalInfo = {
          open: false,
          title: '',
          content: '',
        };
      },
    },
    watch: {
      // Watch for changes in activeBorrower to update formData
      activeBorrower() {
        this.updateFormDataFromItems();
      },
      // Watch for changes in formData
      formData: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      items: {
        handler() {
          this.checkIfDraft();
        },
        deep: true,
        immediate: true,
      },
      activeTabSelectId: {
        handler(newId) {
          if (this.activeBorrower !== newId) {
            this.activeBorrower = newId;
          }
        },
        immediate: false,
      },
      activeTabTitle: {
        handler(newTitle) {
          if (newTitle == '' || newTitle == 'Borrower') this.activeTab.title = this.tabOptions[0].title;
          else this.activeTab.title = newTitle;
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      // Get the active tab component
      activeTabComponent() {
        return this.activeTab.title === 'Fnma' ? Fnma : this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title).component;
      },
      existBorrower() {
        return this.activeBorrowerOptions.length > 0;
      },
      removeDisabled() {
        // Disabled to remove borrower button when there is only one borrower or no active borrower.
        return this.activeBorrower === '' || this.items.length == 1 || (this.coborrowerCount == 1 && !this.selectedBorrower?.isNBS);
      },
      isMainBorrower() {
        let youngestBorrowerId = null;
        this.items.forEach((item) => {
          if (item.dob && !item.isNBS) {
            const dob = new Date(item.dob);
            if (youngestBorrowerId == null || dob > new Date(this.items.find((item) => item.id == youngestBorrowerId).dob)) {
              youngestBorrowerId = item.id;
            }
          }
        });
        return youngestBorrowerId == this.activeBorrower;
      },
    },
  };
</script>
