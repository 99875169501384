<template>
  <div class="flex">
    <div class="lg:w-1/2 hidden lg:block h-screen">
      <Slider />
    </div>
    <div class="w-full lg:w-1/2 h-screen">
      <div class="p-3 lg:p-10 h-screen relative flex justify-center items-center flex-col">
        <div class="lg:absolute lg:top-[40px] lg:right-[40px] relative mx-auto mb-5">
          <img src="../../../assets/image/brand-logo.svg" alt="login image" />
        </div>
        <div class="lg:w-[480px] mx-auto">
          <div class="text-center mb-8">
            <font-awesome-icon v-if="resendLinkSent" icon="fa-solid fa-check-circle" class="text-green-500 text-6xl mb-4" />
            <h3 class="text-dark md:text-4xl text-3xl font-bold mb-2">
              {{ resendLinkSent ? 'Resend Link Sent' : 'Forgot password?' }}
            </h3>
            <p class="text-dark-gray text-1xl" v-if="!resendLinkSent">
              Enter the email address you used when<br />
              you joined and we’ll send you instructions<br />
              to reset your password.
            </p>
            <p class="text-dark-gray text-1xl" v-else>We have sent a password reset link to your email address. Please check your email.</p>
            <p class="text-dark-gray text-1xl"></p>
          </div>
          <form @submit.prevent="submitForm" v-if="!resendLinkSent">
            <div class="form-group mb-4">
              <label for="email">Email:</label>
              <input type="email" placeholder="@mail.com" id="email" v-model="this.formData.email" required />
              <span v-if="errors.email" class="error mt-2 text-red-500">{{ errors.email }}</span>
            </div>
            <div class="lg:flex justify-start">
              <button class="standard-btn w-full" type="submit">
                <span>Reset Password </span>
                <font-awesome-icon icon=" fa-solid fa-arrow-right" class="icon" />
              </button>
              <router-link to="/login" class="standard-btn back-to-login lg:ml-2 mt-2 lg:mt-0 w-full text-center">Back to Login</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Slider from './../Slider/index.vue';
  import apiService from '@/api/apiService';
  import { mapActions } from 'vuex';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ForgetPassword',
    components: {
      Slider,
    },
    data() {
      return {
        resendLinkSent: false,
        formData: {
          email: '',
        },
        errors: {
          email: '',
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      validateForm() {
        let valid = true;
        if (!this.formData.email) {
          this.errors.email = 'Email is required';
          valid = false;
        } else {
          this.errors.email = '';
        }
        return valid;
      },

      async submitForm() {
        if (this.validateForm()) {
          this.setLoading(true);
          try {
            const response = await apiService.post('/forgot-password/', this.formData);
            if (response.status === 200) {
              this.resendLinkSent = true;
            }
          } catch (error) {
            if (error.response && error.response.status === 400) {
              this.errors.email = 'Invalid email address';
            } else {
              this.errors.email = 'An unexpected error occurred. Please try again later.';
            }
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
  };
</script>
<style scoped>
  label {
    color: #686868;
  }

  input::placeholder {
    color: #9a9a9a;
  }

  input {
    border-radius: 8px;
    background: #f9f9f9;
    border-color: #e6e6e6;
    padding: 17px 15px;
  }

  button {
    padding: 16px 15px;
    border-radius: 8px;
  }

  .standard-btn {
    background: #224760;
  }

  .back-to-login {
    background: #e78724;
    padding: 16px 15px;
    border-radius: 8px;
  }

  .standard-btn:hover .icon {
    margin-left: 20px;
  }

  .standard-btn .icon {
    margin-left: 15px;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 20px;
  }

  .toggle-password {
    color: #9a9a9a;
    position: absolute;
    top: 14px;
    font-size: 20px;
    right: 15px;
    cursor: pointer;
  }

  .error {
    color: red;
  }
</style>
