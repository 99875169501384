<template>
  <Card title="Compliance Policies">
    <div class="compliance-policies">
      <table>
        <thead>
          <tr>
            <th>Policy</th>
            <th>Enabled</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Loan officer cannot create loan in state he/she is not licensed in</td>
            <td><input type="checkbox" v-model="policies.require_user_state_license" /></td>
          </tr>
          <tr>
            <td>Loan officer cannot create loan in state the company is not licensed in</td>
            <td><input type="checkbox" v-model="policies.require_company_state_license" /></td>
          </tr>
        </tbody>
      </table>
      <button class="mt-5 me-auto" @click="savePolicies">Save Changes</button>
    </div>
  </Card>
</template>

<script>
  import apiService from '@/api/apiService';
  import { mapActions } from 'vuex';
  import Card from '@/components/Card/index.vue';

  export default {
    name: 'CompliancePolicies',
    components: {
      Card,
    },
    data() {
      return {
        policies: {
          require_company_state_license: false,
          require_user_state_license: false,
        },
      };
    },
    created() {
      this.fetchPolicies();
    },
    methods: {
      ...mapActions(['setLoading']),
      async savePolicies() {
        try {
          this.setLoading(true);
          await apiService.post(`/company/companies/company-license-policy/`, this.policies);
          this.$root.showSnackbar(`Company license policy updated successfully!`, 'success');
          this.setLoading(false);
        } catch (error) {
          console.error(error);
          this.setLoading(false);
        }
      },
      async fetchPolicies() {
        try {
          this.setLoading(true);
          const response = await apiService.get(`/company/companies/company-license-policy/`);
          if (response.data) {
            this.policies = response.data;
            this.setLoading(false);
          }
        } catch (error) {
          console.error(error);
          this.setLoading(false);
        }
      },
    },
  };
</script>

<style scoped>
  .compliance-policies {
    padding: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: #45a049;
  }
</style>
