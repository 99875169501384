<template>
  <div v-if="!show" class="sidebar-overlay" @click="toggleSidebar"></div>
  <div :class="{ sidebar: true, expanded: show }" class="bg-primary">
    <div class="flex md:justify-center justify-start items-center py-[13px] px-[10px] border-b border-[#113F55] siderbar-header">
      <font-awesome-icon icon="bars" @click="toggleSidebar" class="cursor-pointer md:hidden lg:hidden pe-3 text-[#e78724]" />
      <div class="logo-icon">
        <img v-if="show" src="../../../../assets/image/icon-logo.svg" alt="Logo Image" />
        <img v-else src="../../../../assets/image/logo.svg" alt="Logo Image" />
      </div>
    </div>
    <nav class="navbar px-[10px] py-[10px]">
      <ul>
        <li v-if="!show">
          <form @submit.prevent="handleSearchSubmit" class="relative w-full border-none bg-white rounded-lg max-w-md flex mb-3">
            <input v-model="searchQuery" type="text" class="text-sm border-none text-black-300 py-2 px-4" placeholder="Search" />
            <button type="submit" class="flex items-center pr-4">
              <font-awesome-icon icon="magnifying-glass" class="text-[#A8A8A8]" />
            </button>
          </form>
        </li>
        <li v-if="!show">
          <button class="nav-link flex w-full" @click="handleOpenLoanStartModal">
            <div class="flex items-center">
              <span class="icon">
                <font-awesome-icon icon="plus" class="text-white" />
              </span>
              <p>Start a loan</p>
            </div>
          </button>
        </li>
        <li>
          <router-link to="/" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/dashboard-icon.svg" alt="icon" />
              </span>
              <p>Dashboard</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/reporting" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/reporting-icon.svg" alt="icon" />
              </span>
              <p>Reporting</p>
            </div>
          </router-link>
        </li>
        <li v-if="isSuperUser()">
          <router-link to="/companies" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/company-icon.svg" alt="icon" />
              </span>
              <p>Companies</p>
            </div>
          </router-link>
        </li>
        <li v-if="!isCompanyUser()">
          <router-link to="/users" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/account-icon.svg" alt="icon" />
              </span>
              <p>Users</p>
            </div>
          </router-link>
        </li>
        <li v-if="isCompanyUser() || isStandardConditionsAdmin()">
          <router-link to="/settings" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/settings-icon.svg" alt="icon" />
              </span>
              <p>Settings</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/licenses" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/licenses-icon.svg" alt="icon" />
              </span>
              <p>My Licenses</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/find-loan" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/loan-icon.svg" alt="icon" />
              </span>
              <p>Find Loan</p>
            </div>
          </router-link>
        </li>
        <li v-if="isCompanyAdmin()">
          <router-link to="/document" class="nav-link flex" exact-active-class="active-exact" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/doc-icon.svg" alt="icon" />
              </span>
              <p>Document Manager</p>
            </div>
          </router-link>
        </li>
        <li>
          <a href="https://support.reversepilot.com/" class="nav-link flex" target="_blank" rel="noopener noreferrer" @click="toggleSidebar">
            <div class="flex items-center">
              <span class="icon">
                <img src="../../../../assets/image/sidebar/support-icon.svg" alt="icon" />
              </span>
              <p>Help Center</p>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <Dialog :isOpen="showLoanStartModal" @close="handleCloseLoanStartModal" title="Start a loan">
    <div class="content-container">
      <div v-if="isSuperUser()" class="mb-4">
        <SelectInput label="Loan Officer *" :options="loanOfficers" :value="formData.loanOfficer" v-model="formData.loanOfficer" :disable-validation="true" />
      </div>
      <div class="text-md font-semibold">Borrower Information</div>
      <div class="w-full h-px my-3 bg-gray-300"></div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div>
          <InputField label="First Name *" :value="formData.firstName" v-model="formData.firstName" :disable-validation="true" />
          <span v-if="!formData.firstName && validationFormErrors.firstName" class="text-sm text-red">
            {{ validationFormErrors.firstName }}
          </span>
        </div>
        <div>
          <InputField label="Last Name *" :value="formData.lastName" v-model="formData.lastName" :disable-validation="true" />
          <span v-if="!formData.lastName && validationFormErrors.lastName" class="text-sm text-red">
            {{ validationFormErrors.lastName }}
          </span>
        </div>
        <div>
          <DateInput label="Date of Birth *" :value="formData.dob" v-model="formData.dob" :disable-validation="true" />
          <span v-if="!formData.dob && validationFormErrors.dob" class="text-sm text-red">
            {{ validationFormErrors.dob }}
          </span>
        </div>
      </div>
      <div class="text-md font-semibold mt-4">Extra Information</div>
      <div class="w-full h-px my-3 bg-gray-300"></div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div>
          <SelectInput label="Property State *" :options="US_STATES" :value="formData.propertyState" v-model="formData.propertyState" :disable-validation="true" />
          <span v-if="!formData.propertyState && validationFormErrors.propertyState" class="text-sm text-red">
            {{ validationFormErrors.propertyState }}
          </span>
        </div>
        <div>
          <MoneyInput label="Home Value *" :value="formData.homeValue" v-model="formData.homeValue" :disable-validation="true" />
          <span v-if="!formData.homeValue && validationFormErrors.homeValue" class="text-sm text-red">
            {{ validationFormErrors.homeValue }}
          </span>
        </div>
        <div>
          <MoneyInput label="Payoff" :value="formData.payoff" v-model="formData.payoff" :disable-validation="true" />
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <Button variant="primary" class="mr-2" @click="handleStartLoan">Start Loan</Button>
        <Button variant="secondary" @click="handleCloseLoanStartModal">Cancel</Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
  import apiService from '@/api/apiService';
  import { mapActions } from 'vuex';
  import { isSuperUser, isCompanyAdmin, isCompanyUser, isStandardConditionsAdmin } from '@/utils';
  import Button from '@/components/Button/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import { US_STATES } from '@/constants';

  const initialFormData = {
    loanOfficer: '',
    firstName: '',
    lastName: '',
    dob: null,
    propertyState: '',
    homeValue: null,
    payoff: null,
  };

  const initialValidationFormErrors = {
    loanOfficer: '',
    firstName: '',
    lastName: '',
    dob: '',
    propertyState: '',
    homeValue: '',
  };

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Sidebar',
    components: {
      Button,
      Dialog,
      InputField,
      DateInput,
      SelectInput,
      MoneyInput,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return {
        US_STATES,
        isSuperUser,
        isCompanyAdmin,
        isCompanyUser,
        isStandardConditionsAdmin,
      };
    },
    async created() {
      await this.fetchLoanOfficers();
    },
    data() {
      return {
        searchQuery: '',
        showLoanStartModal: false,
        formData: { ...initialFormData },
        validationFormErrors: { ...initialValidationFormErrors },
        loanOfficers: [],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchLoanOfficers() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/company/users/`, {
            role: 'Loan Officer',
          });
          this.loanOfficers = response.data.map((officer) => ({
            ...officer,
            label: `${officer.firstName} ${officer.lastName}`,
            value: officer.id.toString(),
          }));
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch loan officers: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSearchSubmit() {
        this.$emit('search', this.searchQuery);
      },
      toggleSidebar() {
        if (window.innerWidth <= 767) {
          this.$emit('toggleSidebar');
        }
      },
      handleOpenLoanStartModal() {
        this.showLoanStartModal = true;
      },
      handleCloseLoanStartModal() {
        this.formData = { ...initialFormData };
        this.validationFormErrors = { ...initialValidationFormErrors };
        this.showLoanStartModal = false;
      },
      validateForm() {
        this.validationFormErrors = {
          loanOfficer: isSuperUser() && !this.formData.loanOfficer.length ? 'Loan officer is required.' : '',
          firstName: !this.formData.firstName.length ? 'First name is required.' : '',
          lastName: !this.formData.lastName.length ? 'Last name is required.' : '',
          dob: !this.formData.dob ? 'Date of birth is required.' : '',
          propertyState: !this.formData.propertyState.length ? 'Property state is required.' : '',
          homeValue: !this.formData.homeValue ? 'Home value is required.' : '',
        };
        return (
          !this.validationFormErrors.loanOfficer &&
          !this.validationFormErrors.firstName &&
          !this.validationFormErrors.lastName &&
          !this.validationFormErrors.dob &&
          !this.validationFormErrors.propertyState &&
          !this.validationFormErrors.homeValue
        );
      },
      async handleStartLoan() {
        if (!this.validateForm()) return;
        this.setLoading(true);
        try {
          const response = await apiService.post('/loan/loans/start-loan/', this.formData);
          this.$root.showSnackbar('Loan started successfully', 'success');
          this.handleCloseLoanStartModal();
          this.$router.push({ name: 'Loan', params: { id: response.data.id } });
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
  };
</script>

<style scoped>
  .sidebar {
    width: 270px;
    transition: width 0.3s ease;
  }

  .expanded {
    width: 70px;
  }
  .sidebar.expanded .logo-icon {
    background: #fff;
    padding: 9px 3px;
  }
  .navbar {
    overflow-y: auto;
    height: calc(100vh - 70px);
  }
  .navbar li p {
    color: #fff;
    font-weight: 500;
    padding-left: 10px;
  }
  .nav-link {
    cursor: pointer;
    padding: 12px 14px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  .nav-link.active-exact,
  .nav-link:hover {
    color: #fff;
    background-color: #e78724;
  }

  .sidebar.expanded .nav-link p {
    font-size: 0;
    display: none;
  }
  .sidebar.expanded .navbar {
    padding: 10px 10px;
  }

  .sidebar-search-input {
    background-color: white;
  }
  @media (max-width: 768px) {
    .sidebar {
      width: 85%;
      max-width: 310px;
      height: 100vh;
      position: absolute;
      z-index: 1000;
    }
    .sidebar-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
    .expanded {
      width: 0%;
    }
    .expanded .navbar {
      display: none;
    }
    .expanded .siderbar-header {
      display: none;
    }
    .nav-link {
      padding: 10px;
      margin-bottom: 5px;
    }

    .nav-link p {
      padding-left: 5px;
    }
  }
</style>
