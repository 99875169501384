<template>
  <div>
    <label v-if="label" class="font-bold text-[#212121]">{{ label }}</label>

    <!-- Tooltip Icon with tooltip text on hover -->
    <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

    <div class="relative">
      <!-- Dropdown List -->
      <div class="flex flex-col gap-2 max-h-96 overflow-y-auto w-full bg-white">
        <div
          v-for="option in options"
          :key="option.value"
          class="px-2 py-1 cursor-pointer hover:bg-gray-200 border-l-[3px] border-gray-400"
          :class="{ 'bg-blue-200': option.value === selectedOption }"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'MultilineSelectInput',
    props: {
      label: String,
      id: String,
      options: {
        type: Array,
        required: true,
      },
      value: String,
      disableValidation: Boolean,
      tooltip: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        selectedOption: this.value || '',
        isValid: true,
        isDirty: false,
        isDropdownVisible: false,
      };
    },
    computed: {
      selectedOptionLabel() {
        const selected = this.options.find((option) => option.value === this.selectedOption);
        return selected ? selected.label : '';
      },
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required.`;
        }
        return '';
      },
    },
    watch: {
      value(newValue) {
        this.selectedOption = newValue;
        this.validateInput();
      },
    },
    methods: {
      toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
      },
      selectOption(option) {
        this.selectedOption = option.value;
        this.isDropdownVisible = false;
        this.$emit('update:modelValue', this.selectedOption);
        this.validateInput();
      },
      validateInput() {
        this.isDirty = true;
        this.isValid = this.selectedOption !== '';
      },
    },
  };
</script>

<style scoped>
  .icon {
    height: 100%;
    width: 38px;
    display: flex;
    background-color: #104862;
    color: #fff;
    justify-content: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    align-items: center;
    left: 0;
  }
</style>
