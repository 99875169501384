<template>
  <Card title="Flood Zone Details">
    <template v-slot:action>
      <div class="flex items-center">
        <div>
          <Button variant="secondary" @click="openFloodCeritificateModal" style="width: fit-content" :disabled="!writePermission"> Order Flood Certificate </Button>
        </div>
      </div>
    </template>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <InputField
          label="Flood Certificate Number"
          id="floodCertificateNumber"
          ref="floodCertificateNumber"
          :value="formData.floodCertificateNumber"
          v-model.trim="formData.floodCertificateNumber"
          :disable-validation="true"
          tooltip="The certificate number verifying flood zone status of the property."
        />
      </div>

      <div>
        <DateInput
          label="Flood Determination Date"
          id="floodDeterminationDate"
          ref="floodDeterminationDate"
          v-model="formData.floodDeterminationDate"
          :value="formatDate(formData.floodDeterminationDate)"
          :disable-validation="true"
          tooltip="The date when the flood determination was conducted."
        />
      </div>
      <div>
        <RadioInput
          label="Flood Insurance Required?"
          id="floodInsuranceRequired"
          :value="formData.floodInsuranceRequired"
          :options="radioInputOptions"
          v-model="formData.floodInsuranceRequired"
          tooltip="Indicates if flood insurance is mandatory for the property, often based on its flood zone."
        />
      </div>
      <div>
        <SelectInput
          label="Flood Zone"
          :options="floodZoneOptions"
          v-model="formData.floodZone"
          :value="formData.floodZone"
          :disable-validation="true"
          tooltip="The official flood zone designation of the property, affecting insurance requirements and risk assessments."
        />
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-5">
      <Button variant="primary" @click="handleSaveChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Save Changes </Button>
      <Button variant="secondary" @click="handleRevertChanges" style="width: fit-content" :disabled="!this.isDraft || !writePermission"> Revert Changes </Button>
    </div>
  </Card>
  <Dialog title="Order Flood Certificate" :isOpen="isOrderFloodCertificateOpen" @confirm="handleConfirmFloodCertDialog" @close="handleCloseFloodCertDialog">
    <div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="flex flex-col">
          <label for="address" class="font-semibold">Address</label>
          <label for="">{{ floodCertificateFormData.address }}</label>
        </div>
        <div class="flex flex-col">
          <label for="loanNumber" class="font-semibold">Loan Number</label>
          <label for="">{{ floodCertificateFormData.loan_id }}</label>
        </div>
        <div class="flex flex-col">
          <label for="lenderId" class="font-semibold">Lender ID</label>
          <SelectInput id="lenderId" :options="floodCertificateRequestData.lenders" v-model="floodCertificateFormData.lender_id" :disable-validation="true" />
        </div>
        <div class="flex flex-col">
          <label for="borrowerName" class="font-semibold">Borrower Name</label>
          <SelectInput id="borrowerName" :options="floodCertificateRequestData.borrowers" v-model="floodCertificateFormData.borrower_name" :disable-validation="true" />
        </div>
        <div class="flex flex-col">
          <Button variant="primary" @click="downloadFloodCertificate" style="width: fit-content">Download Certificate</Button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import { isEqual } from 'lodash';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import DateInput from '@/components/FormInput/DateInput/index.vue';
  import Dialog from '@/components/Dialog/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FloodZone',
    inject: ['addWarningToList', 'removeWarning'],
    components: {
      Card,
      InputField,
      SelectInput,
      RadioInput,
      DateInput,
      Button,
      Dialog,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isDraft: false,
        isOrderFloodCertificateOpen: false,
        floodCertificateRequestData: {
          loan_id: null,
          address: null,
          borrowers: [],
          lenders: [],
        },
        floodCertificateFormData: {
          address: '',
          loan_number: '',
          lender_id: '',
          borrower_name: '',
        },
        localData: {
          floodCertificateNumber: null,
          floodDeterminationDate: null,
          floodInsuranceRequired: null,
          floodZone: null,
        },
        formData: {
          floodCertificateNumber: null,
          floodDeterminationDate: null,
          floodInsuranceRequired: null,
          floodZone: null,
        },
        floodZoneOptions: [
          { label: 'Zone A', value: 'Zone A' },
          { label: 'Zone AO', value: 'Zone AO' },
          { label: 'Zone AH', value: 'Zone AH' },
          { label: 'Zones A1-A30', value: 'Zones A1-A30' },
          { label: 'Zone AE', value: 'Zone AE' },
          { label: 'Zone A99', value: 'Zone A99' },
          { label: 'Zone AR', value: 'Zone AR' },
          { label: 'Zone AR/AE', value: 'Zone AR/AE' },
          { label: 'Zone AR/AO', value: 'Zone AR/AO' },
          { label: 'Zone AR/A1-A30', value: 'Zone AR/A1-A30' },
          { label: 'Zone AR/A', value: 'Zone AR/A' },
          { label: 'Zone V', value: 'Zone V' },
          { label: 'Zone VE', value: 'Zone VE' },
          { label: 'Zones V1-V3', value: 'Zones V1-V3' },
          { label: 'Zone B', value: 'Zone B' },
          { label: 'Zone X', value: 'Zone X' },
          { label: 'Zone C', value: 'Zone C' },
        ],
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfDraft() {
        if (isEqual(this.formData, this.localData)) {
          this.isDraft = false;
        } else {
          this.isDraft = true;
        }
      },
      openFloodCeritificateModal() {
        this.isOrderFloodCertificateOpen = true;
        this.fetchOrderCerificateRequestData();
      },
      async fetchOrderCerificateRequestData() {
        try {
          this.setLoading(true);
          const response = await apiService.get('/loan/flood_zones/get-request-data/', {
            loan: this.$route.params.id,
          });
          this.floodCertificateRequestData.borrowers = response.data.borrowers.map((borrower) => ({
            label: borrower.fullName,
            value: borrower.fullName,
          }));
          this.floodCertificateRequestData.lenders = (response.data.lenders ?? []).map((lender) => ({
            label: lender.name,
            value: lender.id,
          }));
          this.floodCertificateFormData.address = response.data.address;
          this.floodCertificateFormData.loan_id = response.data.loan_id;

          this.setLoading(false);
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.setLoading(false);
        }
      },
      async downloadFloodCertificate() {
        try {
          this.setLoading(true);
          const response = await apiService.get(
            '/loan/flood_zones/download-certificate/',
            {
              address: this.floodCertificateFormData.address,
              loan_number: this.$route.params.id,
              lender_id: this.floodCertificateFormData.lender_id,
              borrower_name: this.floodCertificateFormData.borrower_name,
            },
            { responseType: 'blob' }
          );

          const fileURL = URL.createObjectURL(response.data); // Assuming file data is part of response
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `flood_certificates_${this.$route.params.id}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
          this.isOrderFloodCertificateOpen = false;
          const requestId = response.headers['x-request-id'];

          if (!requestId) {
            this.$root.showSnackbar('Flood Certificate Successfully downloaded', 'success');
            this.setLoading(false);
            return;
          }

          const requestDataResponse = await apiService.get(`/loan/flood_zones/certificate-data/`, {
            request_id: requestId,
          });
          if (requestDataResponse.data) {
            const certificateInfo = requestDataResponse.data.certificate_info;
            this.formData.floodCertificateNumber = certificateInfo.certificate_id;
            this.formData.floodDeterminationDate = this.formatDate(certificateInfo.issued_at);
            this.formData.floodInsuranceRequired = /[AV]/.test(certificateInfo.flood_zone);
            this.formData.floodZone = `${['A1-A30', 'V1-V3'].includes(certificateInfo.flood_zone) ? 'Zones' : 'Zone'} ${certificateInfo.flood_zone}`;
            this.$root.showSnackbar('Flood Certificate Successfully downloaded', 'success');
          }
          this.setLoading(false);
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.data instanceof Blob) {
              const reader = new FileReader();
              reader.onload = () => {
                const errorMessage = JSON.parse(reader.result).detail || 'An error occurred while fetching the data.';
                this.$root.showSnackbar(errorMessage, 'error');
              };
              reader.readAsText(error.response.data);
            } else {
              this.$root.showSnackbar(`${error.response.data.detail || 'An error occurred while fetching the data.'}`, 'error');
            }
          } else if (error.request) {
            this.$root.showSnackbar('No response received from the server. Please try again later.', 'error');
          } else {
            this.$root.showSnackbar('An unexpected error occurred. Please try again later.', 'error');
          }
        } finally {
          this.setLoading(false);
        }
      },
      async fetchFloodZone() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/flood_zones/by-property/${this.propertyId}/`);
          this.localData = response.data;
        } catch (error) {
          if (error.response.status === 404) {
            this.resetLocalData();
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSaveChanges() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          if (this.localData.id)
            await apiService.patch(`/loan/flood_zones/${this.localData.id}/`, {
              ...this.formData,
            });
          else
            await apiService.post(`/loan/flood_zones/`, {
              property: this.propertyId,
              ...this.formData,
            });
          this.$root.showSnackbar(`Fllod Zone information has been updated successfully!`, 'success');
          await this.fetchFloodZone();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleRevertChanges() {
        this.formData = { ...this.localData };
      },
      resetLocalData() {
        this.localData = {
          floodCertificateNumber: null,
          floodDeterminationDate: null,
          floodInsuranceRequired: null,
          floodZone: null,
        };
      },
      // Format date from datetime string to YYYY-MM-DD
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Return an empty string or handle it as needed
        }

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return ''; // Handle invalid date values if necessary
        }

        const isoString = date.toISOString();
        const [year, month, day] = isoString.split('T')[0].split('-');
        return `${year}-${month}-${day}`;
      },
      handleCloseFloodCertDialog() {
        this.isOrderFloodCertificateOpen = false;
      },
      handleConfirmFloodCertDialog() {
        // Handle confirm dialog logic here
      },
    },
    watch: {
      propertyId: {
        handler() {
          this.fetchFloodZone();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler(value) {
          this.checkIfDraft();
          if (/[AV]/.test(value.floodZone) && !value.floodInsuranceRequired) {
            this.addWarningToList({ name: 'floodZone', message: 'Flood zone code and flood zone required do not match' });
          } else this.removeWarning('floodZone');
        },
        deep: true,
        immediate: true,
      },
      localData: {
        handler(newVal) {
          this.formData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
