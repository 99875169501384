<template>
  <div class="input-box">
    <div class="input-label-container">
      <label v-if="label" :for="id" class="font-semibold text-[#212121]">
        {{ label }}
      </label>

      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>

    <div class="relative flex items-center">
      <input
        type="text"
        :id="id"
        :value="inputValue"
        @input="validateInput"
        @keypress="validateNumber"
        class="pl-3"
        :class="['integer-input', { invalid: !isValid && !disableValidation }]"
      />
    </div>
    <span v-if="!isValid && isDirty && !disableValidation" class="text-sm text-red">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
  import Tooltip from '@/components/Tooltip';

  export default {
    name: 'IntegerInput',
    components: {
      Tooltip,
    },
    props: {
      label: String,
      id: String,
      value: {
        type: [String, Number],
        default: 0,
      },
      maxLength: {
        type: Number,
        default: 10,
      },
      disableValidation: Boolean,
      tooltip: {
        type: String,
        default: '',
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputValue: this.value?.toString() || '',
        isValid: true,
        isDirty: false,
      };
    },
    methods: {
      validateInput(event) {
        this.isDirty = true;

        this.inputValue = event.target.value.replace(/^0+(?!$)/, '');
        this.inputValue = this.inputValue.slice(0, this.maxLength); // Limit input to 5 characters

        if (this.inputValue === '') {
          this.inputValue = '0';
        }

        const regex = /^\d+$/;
        this.isValid = regex.test(this.inputValue);
      },
      validateNumber(event) {
        const charCode = event.charCode;

        // Allow only numbers
        if (charCode < 48 || charCode > 57 || this.inputValue.length >= this.maxLength) {
          event.preventDefault();
        }
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
    },
    computed: {
      errorMessage() {
        if (!this.isValid && this.isDirty) {
          return `${this.label} is required and must be a valid integer.`;
        }
        return '';
      },
    },
    watch: {
      value(newValue) {
        this.inputValue = newValue !== null && newValue !== undefined ? newValue.toString() : '';
      },
      inputValue(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    mounted() {
      this.inputValue = this.value ? this.value.toString() : '';
    },
  };
</script>

<style scoped>
  .invalid {
    border-color: red;
  }

  input {
    padding-left: 15px;
  }
</style>
