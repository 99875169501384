<template>
  <Card>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-2">
      <MoneyInput label="Total non-real estate debts" :value="formData.totalNonRealEstateDebts" v-model="formData.totalNonRealEstateDebts" :disable-validation="true" />
      <MoneyInput label="Total real estate assets" :value="formData.totalRealEstateAssets" v-model="formData.totalRealEstateAssets" :disable-validation="true" />
      <MoneyInput label="Available assets" :value="formData.availableAssets" v-model="formData.availableAssets" :disable-validation="true" />
    </div>
    <div class="flex justify-end mt-4">
      <Button variant="primary" class="mr-2" @click="handleSave">Save</Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Fnma',
    components: {
      Card,
      MoneyInput,
      Button,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return {};
    },
    created() {
      this.fetchFnmaInfo();
    },
    data() {
      return {
        formData: {
          totalNonRealEstateDebts: 0,
          totalRealEstateAssets: 0,
          availableAssets: 0,
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchFnmaInfo() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/fnma_infos/by-borrower/${this.borrowerId}/`);
          this.formData = { ...response.data };
        } catch (error) {
          if (error.response.status === 404) {
            this.formData = {
              totalNonRealEstateDebts: 0,
              totalRealEstateAssets: 0,
              availableAssets: 0,
            };
            return; // No error, just need to create new one.
          }
          this.$root.showSnackbar('Failed to fetch FNMA information', 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleSave() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          if (this.formData.id) await apiService.patch(`/loan/fnma_infos/${this.formData.id}/`, { ...this.formData });
          else
            await apiService.post(`/loan/fnma_infos/`, {
              borrower: this.borrowerId,
              ...this.formData,
            });
          this.$root.showSnackbar('FNMA information has been updated successfully!', 'success');
          await this.fetchFnmaInfo();
        } catch (error) {
          this.$root.showSnackbar('Failed to update FNMA information', 'error');
        } finally {
          this.setLoading(false);
        }
      },
    },
  };
</script>
