<template>
  <Card :title="'Loans Closed by Month - ' + new Date().getFullYear()">
    <div class="chart-container">
      <BarChart :chartData="data" :chartOptions="options" />
    </div>
  </Card>
</template>

<script>
  import apiService from '@/api/apiService';
  import Card from './../../../../components/Card/index.vue';
  import BarChart from './../BarChart/index.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LoansClosedAnalytics',
    components: { Card, BarChart },
    data() {
      return {
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
          datasets: [
            {
              label: 'Loans',
              backgroundColor: ['#E78724', '#104862', '#E7A724', '#106862', '#E78744', '#104882', '#E78764', '#1048A2', '#E78784', '#1048C2', '#E787A4', '#1048E2'],
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              barThickness: 30,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                stepSize: 10, // Set the step size for y-axis ticks
                callback: function (value) {
                  return value; // Ensure the ticks display correctly
                },
              },
            },
          },
        },
      };
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          const response = await apiService.get('/loan/dashboard/monthly-loan-closing/');
          if (response.status === 200) {
            this.data = {
              ...this.data,
              datasets: [
                {
                  ...this.data.datasets[0],
                  data: response.data,
                },
              ],
            };
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
</script>
<style scoped>
  .chart-container > div {
    height: 200px !important;
  }
</style>
