<template>
  <div v-if="isLoanFailed" class="flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full">
      <img src="../../assets/image/info.svg" alt="Error" class="w-20 mx-auto mb-4" />
      <h2 class="text-2xl font-semibold text-gray-800 mb-2">Invalid Loan ID</h2>
      <p class="text-gray-600 mb-4">The loan ID you entered is not valid or the loan is not associated with your account.</p>
      <div class="flex justify-center gap-4">
        <Button @click="goBack">Go Back</Button>
        <Button variant="secondary" @click="goToDashboard">Go to Dashboard</Button>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-[10px]" v-if="isLoanLoaded">
    <div v-if="showQuickCalculations" class="col-span-12 md:col-span-3 lg:col-span-2">
      <QuickCalculations @close="toggleQuickCalculations" v-model:quickCalculationDataParent="quickCalculationData" @directToOrginalFieldLocation="directToOrginalFieldLocation" />
    </div>
    <div :class="showQuickCalculations ? 'col-span-12 md:col-span-9 lg:col-span-10' : 'col-span-12'">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <button v-if="!showQuickCalculations" @click="toggleQuickCalculations">
            <font-awesome-icon icon="bars" />
          </button>
          <PageHeader :title="`${loan.primaryBorrower ? `${loan.primaryBorrower.firstName} ${loan.primaryBorrower.lastName}` : ''} #${loan.id}`" />
        </div>
        <div class="flex items-center">
          <button
            class="bg-green-700 hover:bg-green-800 text-white font-bold inline-flex m-0 items-center font-medium leading-none py-2 px-4 rounded-full lg:!text-[10px] xl:!text-[12px] 2xl:!text-sm"
            @click="handleOpenDocumentHistoryDialog"
          >
            Document History
          </button>
          <!-- Print Document -->
          <Dropdown title="Print Document" className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-full">
            <div
              class="cursor-pointer text-nowrap p-2 rounded-full bg-slate-100 hover:bg-slate-200"
              v-for="packageItem in packages"
              :key="packageItem.name"
              @click="handleDownloadPackage(packageItem.id)"
            >
              {{ packageItem.name }}
            </div>
          </Dropdown>

          <!-- Loan Status -->
          <Dropdown>
            <template v-slot:button>
              <TagComponent :title="loan.status" />
            </template>
            <div v-if="statusOptions.length > 0" class="flex flex-col gap-2">
              <div class="cursor-pointer text-nowrap" v-for="option in statusOptions" :key="option.value" @click="changeStatus(option.value)">
                <TagComponent :title="option.label" />
              </div>
            </div>
            <div v-else class="text-nowrap">No status options available</div>
          </Dropdown>
        </div>
      </div>
      <div v-for="warning in warningList" :key="warning.name">
        <PersistentWarningMessage
          @dismissWarning="handleDismissWarning"
          :ref="
            (el) => {
              if (el) componentRefs[warning.name] = el;
            }
          "
        />
      </div>
      <TabCard>
        <div class="flex items-center justify-between relative tabs-container">
          <ul class="tabs flex items-center">
            <template v-for="tabOption in tabOptions" :key="tabOption.title">
              <li
                class="tab"
                :class="{ active: tabOption.title === activeTab.title && tabOption.view }"
                @click="changeActiveTab(tabOption.title)"
                v-if="tabOption && tabOption.view"
              >
                {{ tabOption.title }}
                <ul class="sub-dropdown">
                  <template :key="subOption.title" v-for="subOption in tabOption.subOptions">
                    <li class="sub-tab sub-tab-title" @click.stop="changeSubTab(tabOption.title, subOption.title, subOption.id)">
                      {{ subOption.label ?? subOption.title }}
                    </li>

                    <template :key="subTab.title" v-for="subTab in subOption.subTabs">
                      <li class="sub-tab" @click.stop="changeSubTab(tabOption.title, subTab.title, subTab?.id)">
                        {{ subTab.label ?? subTab.title }}
                      </li>
                    </template>
                  </template>
                </ul>
              </li>
            </template>
          </ul>
          <div class="flex items-center py-3 pl-2.5 pr-4 cursor-pointer" @click="goBack">
            <font-awesome-icon icon="arrow-left" class="cursor-pointer" />
          </div>
        </div>
      </TabCard>
      <div v-if="loan.id" class="content-container mt-15">
        <component
          :is="activeTabComponent"
          :activeTabTitle="subTabTitle"
          :writePermission="tabOptions.find((tabOption) => tabOption.title === activeTab.title)?.write"
          :activeTabSelectId="subTabSelectId"
        />
      </div>
    </div>
    <DocumentHistoryDialog :title="`Document History`" :isOpen="isDocumentHistoryDialogOpen" @close="handleCloseDocumentHistoryDialog" />
  </div>
</template>

<script>
  import { cloneDeep } from 'lodash';
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import PageHeader from '@/components/PageHeader/index.vue';
  import TabCard from '@/components/TabCard/index.vue';
  import Button from '@/components/Button/index.vue';
  import Dropdown from '@/components/Dropdown/index.vue';
  import TagComponent from '@/components/TagComponent/index.vue';
  import QuickCalculations from './components/QuickCalculations/index.vue';
  import { initialProductData } from './components/Comparison/index.vue';
  import DocumentHistoryDialog from './components/DocumentHistoryDialog/index.vue';
  import { initialTabOptions, keyData, allStatusOptions, statusSequence } from './const';
  import PersistentWarningMessage from '@/components/PersistentWarningMessage/index.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'LoanDetail',
    components: {
      QuickCalculations,
      PageHeader,
      Button,
      Dropdown,
      TagComponent,
      TabCard,
      DocumentHistoryDialog,
      PersistentWarningMessage,
    },
    setup() {
      return {
        keyData,
        initialTabOptions,
        allStatusOptions,
        statusSequence,
      };
    },
    created() {
      this.fetchPackages();
      this.tabOptions = cloneDeep(initialTabOptions);
      this.fetchQuickCalculations();
      this.fetchLoanProceedsData();
    },
    data() {
      return {
        loan: undefined,
        packages: [],
        tabOptions: [],
        statusOptions: [],
        activeTab: { title: '', isActive: true },
        subTabTitle: '',
        subTabSelectId: '',
        highlightField: '',
        showQuickCalculations: true, // Show QuickCalculations by default
        quickCalculationData: {},
        warningList: [],
        componentRefs: {},
        isDocumentHistoryDialogOpen: false,
      };
    },
    provide() {
      return {
        addWarningToList: (value) => {
          if (!this.checkWarningExist(value.name)) this.warningList.push(value);
          else {
            this.warningList = this.warningList.map((warning) => {
              if (warning.name === value.name) {
                return { ...warning, hidden: false };
              }
              return warning;
            });
          }
        },
        dismissWarning: (name) => {
          this.warningList = this.warningList.map((warning) => {
            if (warning.name === name) {
              return { ...warning, dismissed: true };
            }
            return warning;
          });
        },
        removeWarning: (name) => {
          this.warningList = this.warningList.map((warning) => {
            if (warning.name === name) {
              return { ...warning, hidden: true };
            }
            return warning;
          });
        },
        quickCalculationData: () => this.quickCalculationData,
        setQuickCalculationData: (value) => {
          if (value) this.quickCalculationData = value;
          else this.fetchQuickCalculations();
        },
        setQuickCalculatioLoanProceedData: () => {
          this.fetchLoanProceedsData();
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      goBack() {
        this.$router.push({ name: 'FindLoan' }); // Go back to the find loan
      },
      goToDashboard() {
        this.$router.push({ name: 'Dashboard' }); // Navigate to the Dashboard
      },
      changeActiveTab(title) {
        if (this.activeTab.title === title) return;
        const selectedTab = this.tabOptions.find((tabOption) => tabOption.title === title);
        if (selectedTab.subOptions && selectedTab.subOptions.length > 0) {
          this.subTabTitle = selectedTab.subOptions[0].title;
        } else {
          this.subTabTitle = '';
        }
        this.activeTab = { title, isActive: true };
      },
      changeStatus(status) {
        if (status != this.loan.status) this.updateLoanStatus(status);
      },
      changeSubTab(parentTitle, title, id = null) {
        this.changeActiveTab(parentTitle);
        this.subTabTitle = title;
        this.subTabSelectId = id;
      },
      toggleQuickCalculations() {
        this.showQuickCalculations = !this.showQuickCalculations;
      },
      directToOrginalFieldLocation({ key }) {
        const selectedKeyTabDetails = keyData[key];
        this.changeSubTab(selectedKeyTabDetails.tab, selectedKeyTabDetails.subTab, key);
        this.$nextTick(() => {
          setTimeout(() => {
            const element = document.getElementById(`highlight-${key}`);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              element.classList.add('highlight');
              setTimeout(() => {
                element.classList.remove('highlight');
              }, 4000);
            }
          }, 500);
        });
      },
      async fetchQuickCalculations() {
        try {
          this.quickCalculationData.quickCalculation = (await apiService.get(`/loan/loans/quick-calculation-data/${this.$route.params.id}/`)).data;
        } catch (error) {
          this.$root.showSnackbar(`Failed to fetch quick calculation data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchLoanProceedsData() {
        try {
          const response = await apiService.get('/loan/products/');
          const products = response.data;
          const defaultProducts = products.filter((product) => product.default === true || product.is_active === true);
          const calculationPromises = defaultProducts.map((product) =>
            this.fetchCalculationResult({
              ...initialProductData,
              ...product,
              initialIndexRate: product.fixedRate ?? 7.92,
            })
          );

          // Use Promise.allSettled to handle each promise individually
          const results = await Promise.allSettled(calculationPromises);

          // Filter out successful responses and map to your desired structure
          const data = results.reduce((accumulator, result, index) => {
            if (result.status === 'fulfilled') {
              accumulator.push({
                ...initialProductData,
                ...defaultProducts[index],
                ...result.value.data,
              });
            }
            return accumulator;
          }, []);
          const firstData = data.length > 0 ? data[0] : {};
          this.quickCalculationData.loanProceedsData = firstData;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchCalculationResult(requestBody) {
        return await apiService.post('/loan/loan-calculation/', {
          ...requestBody,
          loan: this.$route.params.id,
        });
      },
      async fetchPackages() {
        try {
          const response = await apiService.get('/documents/packages/');
          this.packages = response.data;
        } catch (error) {
          this.packages = [];
          this.$root.showSnackbar(`Failed to fetch document packages: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchLoan() {
        this.setLoading(true);
        try {
          const { data: loanData } = await apiService.get(`/loan/loans/${this.$route.params.id}/`);
          loanData.appraisals = await this.fetchAppraisals(loanData);
          this.loan = loanData;
          this.updateLoanStatusOptions();
        } catch (error) {
          this.loan = {};
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async fetchAppraisals(loanData) {
        if (!loanData.property) return [];
        try {
          const response = await apiService.get(`/loan/appraisals/by-property/${loanData.property.id}/`);
          return response.data;
        } catch (error) {
          return [];
        }
      },
      async updateLoanStatus(newStatus) {
        this.setLoading(true);
        try {
          await apiService.patch(`/loan/loans/${this.loan.id}/`, {
            status: newStatus,
          });
          this.$root.showSnackbar(`Loan status has been updated successfully!`, 'success');
          await this.fetchLoan();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.response?.data?.status ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      updateLoanStatusOptions() {
        const currentStatus = this.loan.status;
        const isFinalStatus = ['Adversed', 'Withdrawn'].includes(currentStatus);
        const isEarlyStatus = ['Origination', 'Processing', 'Submitted to Underwriting', 'Underwriting'].includes(currentStatus);
        const isLateStatus = ['Clear to Close', 'Closing', 'Funding'].includes(currentStatus);

        this.statusOptions = isFinalStatus
          ? []
          : allStatusOptions.filter((option) => {
              const nextStatus = statusSequence[statusSequence.indexOf(currentStatus) + 1];
              return (
                option.value === nextStatus ||
                (isEarlyStatus && option.value === 'Adversed') ||
                (isEarlyStatus && option.value === 'Withdrawn') ||
                (isLateStatus && option.value === 'Withdrawn')
              );
            });
      },
      async handleDownloadPackage(packageId) {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/documents/packages/${packageId}/print-package/${this.$route.params.id}/`);
          const url = response.data.file_url;
          const responseFile = await fetch(url);
          const blob = await responseFile.blob();
          const objectUrl = window.URL.createObjectURL(blob);
          const linkElement = document.createElement('a');
          linkElement.href = objectUrl;
          linkElement.download = url.split('/').pop();
          linkElement.click();
          window.URL.revokeObjectURL(objectUrl);

          this.$root.showSnackbar('Document package downloaded successfully', 'success');
        } catch (error) {
          if (error.response && error.response.data instanceof Blob) {
            try {
              const errorJSONString = await error.response.data.text();
              const errorJSON = JSON.parse(errorJSONString);
              this.$root.showSnackbar(`${errorJSON.detail ?? errorJSON.error ?? 'Something went wrong.'}`, 'error');
            } catch (error) {
              this.$root.showSnackbar(`Failed to download document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
            }
          } else {
            this.$root.showSnackbar(`Failed to download document package: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          }
        } finally {
          this.setLoading(false);
        }
      },
      async loanPermissions() {
        try {
          const response = await apiService.get('/company/companies/loan-permissions/', {
            loan_id: this.$route.params.id,
          });
          if (response.status === 200) {
            const data = response.data?.permissions;
            if (data) {
              this.tabOptions = this.tabOptions.map((option) => {
                return {
                  ...option,
                  view: data[option.title]?.read ?? true,
                  write: data[option.title]?.write ?? true,
                };
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      handleOpenDocumentHistoryDialog() {
        this.isDocumentHistoryDialogOpen = true;
      },
      handleCloseDocumentHistoryDialog() {
        this.isDocumentHistoryDialogOpen = false;
      },
      checkWarningExist(name) {
        return this.warningList.some((warning) => warning.name === name);
      },
      handleWarning(warningList, compRefs) {
        warningList.forEach((warning) => {
          if (warning && warning.name) {
            const ref = compRefs[warning.name];
            if (ref) {
              if (!warning.hidden && !warning.dismissed && typeof ref.show === 'function') ref.show(warning.message, warning.name);
              else if (warning.hidden || (warning.dismissed && typeof ref.hide === 'function')) ref.hide();
            }
          }
        });
      },
    },
    computed: {
      activeTabComponent() {
        return this.tabOptions.find((tabOption) => tabOption.title === this.activeTab.title)?.component;
      },
      isLoanFailed() {
        return !!this.loan && Object.keys(this.loan).length === 0;
      },
      isLoanLoaded() {
        return !!this.loan && Object.keys(this.loan).length > 0;
      },
    },
    watch: {
      '$route.params.id': {
        handler(newId, oldId) {
          if (newId !== oldId) {
            this.fetchLoan();
          }
        },
        immediate: true,
      },
      loan: {
        handler(newVal) {
          if (newVal.id) {
            const firstBorrower = newVal.borrowers?.[0] ?? null;
            const newTitleName = firstBorrower ? `${firstBorrower.firstName} ${firstBorrower.lastName}` : 'New Loan';
            const formattedId = String(newVal.id).padStart(6, '0');
            document.title = `${newTitleName} # ${formattedId}`;

            this.tabOptions = cloneDeep(initialTabOptions);
            this.tabOptions = this.tabOptions.map((option) => {
              if (option.title === 'Borrowers') {
                if (newVal.borrowers.length === 0) option.subOptions = [];
                return {
                  ...option,
                  subOptions: newVal.borrowers.map((borrower, index) => ({
                    title: `Borrower`,
                    label: `Borrower ${index + 1} ${borrower.firstName} ${borrower.lastName}`,
                    id: borrower.id,
                    subTabs: option.subOptions.map((subTab) => ({
                      ...subTab,
                      id: borrower.id,
                    })),
                  })),
                };
              } else if (option.title === 'Property') {
                if (!newVal.property) option.subOptions = [];
                return {
                  ...option,
                  subOptions: [
                    ...(newVal.appraisals
                      ? newVal.appraisals.map((appraisal, index) => ({
                          title: `Appraisal`,
                          label: `Appraisal ${index + 1}`,
                          id: appraisal.id,
                        }))
                      : []),
                    newVal.property && (!newVal.appraisals || (newVal.appraisals && newVal.appraisals.length === 0)) ? { title: 'Appraisal' } : {},
                    ...option.subOptions,
                  ].filter((option) => Object.keys(option).length > 0),
                };
              } else {
                return option;
              }
            });
          }
          this.loanPermissions();
        },
        // immediate: true,
        deep: true,
      },
      tabOptions: {
        handler(newVal) {
          for (const element of newVal) {
            if (element.view) {
              this.changeActiveTab(element.title);
              break;
            }
          }
        },
        // immediate: true,
        deep: true,
      },
      warningList: {
        handler(newVal) {
          this.handleWarning(newVal, this.componentRefs);
        },
        immediate: true,
        deep: true,
      },
      componentRefs: {
        handler(newVal) {
          this.handleWarning(this.warningList, newVal);
        },
        immediate: true,
        deep: true,
      },
    },
  };
</script>

<style scoped>
  ::v-deep .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ::v-deep .tab {
    padding: 10px;
    cursor: pointer;
    position: relative;
    text-wrap: nowrap;
  }

  ::v-deep .tab:hover {
    background-color: #104862;
    color: white;
    border-radius: 5px 5px 0 0;
  }

  .tab.active {
    font-weight: bold;
  }

  .sub-dropdown {
    text-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    left: 0;
    background: white;
    color: black;
    display: none;
    position: absolute;
    z-index: 1000;
  }

  .sub-dropdown li {
    padding: 6px 10px;
    padding-right: 20px;
    cursor: pointer;
    background-color: #104862;
    color: white;
  }

  .sub-tab:not(.sub-tab-title) {
    padding-left: 20px;
  }

  .sub-tab:hover {
    background: #f0f0f0;
    color: #104862;
  }

  .tab:hover .sub-dropdown,
  .tab .sub-dropdown:hover {
    display: block;
  }

  ::v-deep .input-label-container {
    position: relative;
    width: calc(100% - 30px);
  }

  ::v-deep .input-box label {
    display: ruby;
    z-index: 8;
  }

  ::v-deep .history {
    display: none;
    cursor: pointer;
    position: absolute;
    z-index: 10;
  }

  ::v-deep .input-box:hover .history {
    display: inline-block;
  }

  ::v-deep .history-icon-container {
    position: relative;
  }

  ::v-deep .history-icon-container .history {
    display: none;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    margin-top: -4px;
  }

  ::v-deep .history-icon-container label {
    display: ruby;
    z-index: 8;
  }

  ::v-deep .history-icon-container:hover .history {
    display: inline-block;
  }

  ::v-deep .highlight {
    background-color: rgba(255, 255, 129, 0.466);
    transition: background-color 2s ease;
  }

  @media (max-width: 1061px) {
    .tab:hover .sub-dropdown,
    .tab .sub-dropdown:hover {
      display: none;
    }

    .tabs-container {
      overflow-x: auto;
      padding-bottom: 10px;
    }
  }
</style>
