<template>
  <Card>
    <div class="border rounded-lg mb-2">
      <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
    </div>
    <div class="border rounded-lg">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-4 border-b border-gray p-15">
        <div>
          <SelectInput label="Type *" :options="typeOptions" v-model="formData.type" :value="formData.type" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Estimated By" :value="formData.estimatedBy" v-model.trim="formData.estimatedBy" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Description of Work" :value="formData.descriptionOfWork" v-model.trim="formData.descriptionOfWork" :disable-validation="true" />
        </div>
        <div>
          <MoneyInput label="Amount" v-model="formData.amount" :value="formData.amount" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Factor" :value="formData.factor" v-model.trim="formData.factor" :disable-validation="true" />
        </div>
        <div>
          <MoneyInput label="Set Aside Amount" v-model="formData.setAsideAmount" :value="formData.setAsideAmount" :disable-validation="true" />
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-end">
      <Button variant="primary" :disabled="!isValidForm || !writePermission" @click="handleAddRepair"> Add Repair Item </Button>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import { formatNumberWithCommas } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Repairs',
    components: {
      Card,
      DataTable,
      InputField,
      SelectInput,
      MoneyInput,
      Button,
    },
    props: {
      propertyId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      return {
        formatNumberWithCommas,
      };
    },
    data() {
      return {
        isValidForm: false,
        tableHeaders: [
          { key: 'type', label: 'Type' },
          { key: 'estimatedBy', label: 'Estimated by' },
          { key: 'descriptionOfWork', label: 'Description of Work' },
          { key: 'amountWithCommas', label: 'Amount' },
          { key: 'factor', label: 'Factor' },
          { key: 'setAsideAmountWithCommas', label: 'Set aside amount' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        formData: {
          type: null,
          estimatedBy: null,
          descriptionOfWork: null,
          amount: null,
          factor: null,
          setAsideAmount: null,
        },
        typeOptions: [
          { label: 'Cosmetic', value: 'Cosmetic' },
          { label: 'Minor', value: 'Minor' },
          { label: 'Major', value: 'Major' },
          { label: 'Structural', value: 'Structural' },
        ],
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      checkIfValidForm() {
        this.isValidForm = this.formData.type;
      },
      resetFormData() {
        this.formData = {
          type: null,
          estimatedBy: null,
          descriptionOfWork: null,
          amount: null,
          factor: null,
          setAsideAmount: null,
        };
      },
      async fetchRepairs() {
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/repairs/by-property/${this.propertyId}/`);
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            amountWithCommas: formatNumberWithCommas(item.amount),
            setAsideAmountWithCommas: formatNumberWithCommas(item.setAsideAmount),
            actions: ['delete'],
            ...item,
          }));
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      async handleAddRepair() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.propertyId) return;
        this.setLoading(true);
        try {
          await apiService.post(`/loan/repairs/`, {
            property: this.propertyId,
            ...this.formData,
          });
          this.$root.showSnackbar(`Repair information has been added for the property successfully!`, 'success');
          await this.fetchRepairs();
          this.resetFormData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/repairs/${item.id}/`);
            this.$root.showSnackbar('Repair information has been deleted successfully', 'success');
            await this.fetchRepairs();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete repair: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    watch: {
      propertyId: {
        handler() {
          this.fetchRepairs();
        },
        deep: true,
        immediate: true,
      },
      formData: {
        handler() {
          this.checkIfValidForm();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
<style scoped>
  .box:last-child .grid {
    border: none;
  }
</style>
