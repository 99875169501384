<template>
  <Card title="Cash Flow Breakdown">
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white border border-gray-200">
        <tbody>
          <tr class="border-b border-gray-200">
            <th class="text-left px-3 py-4 bg-gray-100 font-semibold w-1/2">Total Income</th>
            <td class="text-left px-3 py-4 w-1/2">${{ formatNumberWithCommas(data.totalIncome.monthly) }}</td>
          </tr>
          <tr class="border-b border-gray-200">
            <th class="text-left px-3 py-4 bg-gray-100 font-semibold w-1/2">Total Expenses</th>
            <td class="text-left px-3 py-4 w-1/2">${{ formatNumberWithCommas(data.totalExpenses.monthly) }}</td>
          </tr>
          <tr id="highlight-residualIncome" class="border-b border-gray-200">
            <th class="text-left px-3 py-4 bg-gray-100 font-semibold w-1/2">Residual Income</th>
            <td class="text-left px-3 py-4 w-1/2">${{ formatNumberWithCommas(data.residualIncome.monthly) }}</td>
          </tr>
          <tr id="highlight-residualIncomeRequired" class="border-b border-gray-200">
            <th class="text-left px-3 py-4 bg-gray-100 font-semibold w-1/2">Residual Income Required</th>
            <td class="text-left px-3 py-4 w-1/2">${{ formatNumberWithCommas(data.residualIncomeRequired.monthly) }}</td>
          </tr>
          <tr id="highlight-residualIncomeFullFilled" class="border-b border-gray-200">
            <th class="text-left px-3 py-4 bg-gray-100 font-semibold w-1/2">% of Residual Income Fulfilled</th>
            <td class="text-left px-3 py-4 w-1/2">{{ formatNumberWithCommas(data.percentageResidualIncomeFulfilled) }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import { formatNumberWithCommas } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'CashFlow',
    components: {
      Card,
    },
    setup() {
      return {
        formatNumberWithCommas,
      };
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchCashFlowData();
    },
    data() {
      return {
        loanId: this.$route.params.id,
        data: {
          totalIncome: {
            monthly: 0,
            yearly: 0,
          },
          totalExpenses: {
            monthly: 0,
            yearly: 0,
          },
          residualIncome: {
            monthly: 0,
            yearly: 0,
          },
          residualIncomeRequired: {
            monthly: 0,
            yearly: 0,
          },
          percentageResidualIncomeFulfilled: 0,
        },
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchCashFlowData() {
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/loans/${this.loanId}/cash_flow/`);
          this.data = {
            ...this.data,
            ...response.data.cashFlow,
          };
        } catch (error) {
          this.resetData();
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      initialData() {
        return {
          totalIncome: { monthly: 0, yearly: 0 },
          totalExpenses: { monthly: 0, yearly: 0 },
          residualIncome: { monthly: 0, yearly: 0 },
          residualIncomeRequired: { monthly: 0, yearly: 0 },
          percentageResidualIncomeFulfilled: 0,
        };
      },
      resetData() {
        this.data = this.initialData();
      },
    },
  };
</script>
