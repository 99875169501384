<template>
  <Card>
    <!-- Fees Table -->
    <div class="border rounded-lg mb-2">
      <div class="flex items-center justify-between py-2 mx-2">
        <h3 class="text-lg font-bold">Fees Table</h3>
        <Button variant="primary" :diabled="!writePermission" @click="handleQuoteAPI">Obtain Title Fees</Button>
      </div>
      <div>
        <EditableDataTable
          :headers="tableHeaders"
          :data="tableData"
          :historyShow="true"
          @trigger-open-history-dialog="openHistoryDialog"
          @onDataUpdate="handleDataUpdate"
          @action="handleAction"
        >
          <template v-slot:custom="{ row }">
            <Dropdown>
              <template v-slot:button>
                <button class="icon-button bg-gray-300">
                  <font-awesome-icon icon="fa-solid fa-ellipsis" />
                </button>
              </template>
              <button class="text-nowrap" @click="handleGetCreditReportCost(row.id)">Get Credit Report Cost</button>
            </Dropdown>
          </template>
        </EditableDataTable>
      </div>
      <div class="flex flex-col items-end p-4">
        <div class="text-sm font-bold mb-2">Total Costs: ${{ totalCosts }}</div>
        <div class="text-sm font-bold">Total excluded from wire: ${{ totalCostsExcludedFromWire }}</div>
      </div>
    </div>
    <div class="border rounded-lg">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 gap-4 border-b border-gray p-15">
        <div>
          <SelectInput
            label="Tolerance *"
            id="tolerance"
            ref="tolerance"
            :options="toleranceOptions"
            :value="formData.tolerance"
            v-model.trim="formData.tolerance"
            :disable-validation="true"
          />
        </div>
        <div>
          <InputField label="HUD Line # (*)" id="hudLine" ref="hudLine" :value="formData.hudLine" v-model.trim="formData.hudLine" :disable-validation="true" />
        </div>
        <div>
          <InputField label="Fee Name *" id="name" ref="name" :value="formData.name" v-model.trim="formData.name" :disable-validation="true" />
        </div>
        <div>
          <RadioInput
            label="Is Finance Charge? (*)"
            id="isFinanceCharge"
            ref="isFinanceCharge"
            :options="radioInputOptions"
            :value="formData.isFinanceCharge"
            v-model="formData.isFinanceCharge"
          />
        </div>
        <div>
          <AutoCompleteField label="Payee *" id="payee" ref="payee" :items="payees" :value="formData.payee" v-model.trim="formData.payee" :disable-validation="true" />
        </div>
        <div>
          <MoneyInput label="Amount *" id="amount" ref="amount" v-model="formData.amount" :value="formData.amount" :disable-validation="true" />
        </div>
        <div>
          <RadioInput
            label="Exclude from Wire? (*)"
            id="excludeFromWire"
            ref="excludeFromWire"
            :options="radioInputOptions"
            :value="formData.excludeFromWire"
            v-model="formData.excludeFromWire"
          />
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-end">
      <Button variant="primary" class="mr-15" @click="handleAddFee" :disabled="!isValidForm || !writePermission">Add Fee</Button>
    </div>
  </Card>
  <HistoryDialog ref="historyDialog" />
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import EditableDataTable from '@/components/EditableDataTable/index.vue';
  import Card from '@/components/Card/index.vue';
  import Dropdown from '@/components/Dropdown/index.vue';
  import InputField from '@/components/FormInput/InputField/index.vue';
  import AutoCompleteField from '@/components/FormInput/AutoCompleteField/index.vue';
  import SelectInput from '@/components/FormInput/SelectInput/index.vue';
  import RadioInput from '@/components/FormInput/RadioInput/index.vue';
  import MoneyInput from '@/components/FormInput/MoneyInput/index.vue';
  import Button from '@/components/Button/index.vue';
  import HistoryDialog from '../HistoryDialog/index.vue';
  import { TOLERANCE_OPTIONS } from '@/constants';
  import { formatNumberWithCommas } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Fees',
    components: {
      AutoCompleteField,
      Card,
      EditableDataTable,
      Dropdown,
      InputField,
      SelectInput,
      RadioInput,
      MoneyInput,
      Button,
      HistoryDialog,
    },
    props: {
      writePermission: {
        type: Boolean,
        required: true,
      },
    },
    setup() {
      return {
        TOLERANCE_OPTIONS,
        formatNumberWithCommas,
      };
    },
    data() {
      return {
        loanId: this.$route.params.id,
        toleranceOptions: TOLERANCE_OPTIONS,
        tableData: [],
        payees: [],
        borrowers: [],
        formData: {
          tolerance: null,
          hudLine: null,
          name: null,
          isFinanceCharge: null,
          payee: null,
          amount: null,
          excludeFromWire: null,
        },
        radioInputOptions: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      };
    },
    async created() {
      this.loanId = this.$route.params.id;
      await this.fetchFees();
      // Fetch additional data
      this.fetchPayees();
      this.fetchBorrowers();
    },
    computed: {
      tableHeaders() {
        return [
          { key: 'tolerance', label: 'Tolerance', type: 'select', options: TOLERANCE_OPTIONS },
          { key: 'hudLine', label: 'Hud Line #', type: 'text' },
          { key: 'name', label: 'Fee Name', type: 'text' },
          { key: 'isFinanceCharge', label: 'Is Finance Charge?', type: 'boolean' },
          { key: 'payee', label: 'Payee', type: 'auto-complete', options: this.payees },
          { key: 'amount', label: 'Amount', type: 'money' },
          { key: 'excludeFromWire', label: 'Exclude from wire?', type: 'boolean' },
          { key: 'action', label: 'Action' },
        ];
      },
      // Validate form
      isValidForm() {
        return (
          this.formData.tolerance &&
          this.formData.hudLine &&
          this.formData.name &&
          this.formData.isFinanceCharge !== null &&
          this.formData.payee &&
          this.formData.amount !== null &&
          this.formData.excludeFromWire !== null
        );
      },
      totalCosts() {
        return formatNumberWithCommas(Number(this.tableData.reduce((total, item) => total + Number(item.amount), 0).toFixed(2)));
      },
      totalCostsExcludedFromWire() {
        return formatNumberWithCommas(Number(this.tableData.reduce((total, item) => total + (item.excludeFromWire ? Number(item.amount) : 0), 0).toFixed(2)));
      },
    },
    methods: {
      ...mapActions(['setLoading']),
      resetFormData() {
        this.formData = {
          tolerance: null,
          hudLine: null,
          name: null,
          isFinanceCharge: null,
          payee: null,
          amount: null,
          excludeFromWire: null,
        };
      },
      async fetchPayees() {
        try {
          const response = await apiService.get(`/loan/payees/`);
          this.payees = response.data.map((el) => el.name);
        } catch (error) {
          this.payees = []; // reset table data
          // if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        }
      },
      async fetchBorrowers() {
        try {
          const response = await apiService.get('/loan/borrowers/', {
            loan: this.loanId,
          });
          this.borrowers = response.data;
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        }
      },
      async fetchFees() {
        this.setLoading(true);
        try {
          // Fetch fees
          let response = await apiService.get(`/loan/fees/`, {
            loan: this.loanId,
          });
          this.handleTableData(response.data);
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      handleTableData(data) {
        // Check if there is already origination fee
        let originationFeeItem = data.find((el) => el.hudLine === '801');
        if (!originationFeeItem) {
          const originationFee = {
            tolerance: '0%',
            hudLine: '801',
            name: 'Origination Fee',
            isFinanceCharge: true,
            payee: null,
            amount: 0,
            excludeFromWire: false,
            editDisabledFields: ['tolerance', 'hudLine', 'name'],
          };
          data.unshift(originationFee);
        } else {
          originationFeeItem.editDisabledFields = ['tolerance', 'hudLine', 'name'];
        }

        // Check if there is already imip fee
        let imipFeeItem = data.find((el) => el.hudLine === '902');
        if (imipFeeItem) {
          imipFeeItem.editDisabledFields = ['tolerance', 'hudLine', 'name', 'amount'];
        }

        // Sort origination fee to first item and imip fee to second item
        let originationFee = data.find((el) => el.hudLine === '801');
        let imipFee = data.find((el) => el.hudLine === '902');
        if (originationFee && imipFee) {
          data = data.filter((el) => el.hudLine !== '801' && el.hudLine !== '902');
          data.unshift(originationFee);
          data.splice(1, 0, imipFee);
        }

        this.tableData = data.map((item) => ({
          actions: ['edit', 'delete'],
          ...item,
        }));
      },
      async handleAddFee() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          await apiService.post(`/loan/fees/`, {
            ...this.formData,
            loan: this.loanId,
          });
          this.$root.showSnackbar(`Fee data has been added successfully!`, 'success');
          await this.fetchFees();
          this.resetFormData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleEditFee(data) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          if (data.id) {
            await apiService.patch(`/loan/fees/${data.id}/`, {
              ...data,
            });
            this.$root.showSnackbar(`Fee data has been updated successfully!`, 'success');
            await this.fetchFees();
          } else {
            await apiService.post(`/loan/fees/`, {
              ...data,
              loan: this.loanId,
            });
            await this.fetchFees();
            this.$root.showSnackbar(`Fee data has been added successfully!`, 'success');
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleDataUpdate(data) {
        await this.handleEditFee(data);
      },
      async handleQuoteAPI() {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        this.setLoading(true);
        try {
          await apiService.post('/loan/greenlight/quote', {
            loan_id: this.loanId,
          });
          await this.fetchFees();
          this.resetFormData();
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.response?.data?.error ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleGetCreditReportCost(id) {
        const SSN = this.borrowers.find((borrower) => borrower.ssn?.length > 0)?.ssn || '';
        if (SSN === '') {
          this.$root.showSnackbar(`No invoice found!`, 'error');
          return;
        }

        this.setLoading(true);
        try {
          const response = await apiService.post('/loan/total-cost-for-borrower/', {
            first_name: null,
            last_or_company_name: null,
            ref_number: null,
            ssn: SSN,
          });
          const transactions = response?.data?.response?.transactions ?? [];
          const reportCost = transactions.reduce((total, element) => {
            const charge = element?.charge ? parseFloat(element.charge.replace(/[^0-9.-]+/g, '')) : 0;
            return total + charge;
          }, 0);

          // Update fee amount with credit report cost
          try {
            await apiService.patch(`/loan/fees/${id}/`, {
              amount: reportCost,
            });
            this.$root.showSnackbar(`Fee data has been updated with credit report cost successfully!`, 'success');
            await this.fetchFees();
          } catch (error) {
            this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          }
        } catch (error) {
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Failed to get credit report cost.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/fees/${item.id}/`);
            this.$root.showSnackbar('Fee data has been deleted successfully', 'success');
            await this.fetchFees();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete fee data: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
      openHistoryDialog({ id, model_id }) {
        this.$refs.historyDialog.openHistoryDialog(id, 'Fee', model_id);
      },
    },
  };
</script>

<style scoped>
  .box:last-child .grid {
    border: none;
  }

  .dropdown-container {
    padding: 0 !important;
  }
</style>
