<template>
  <Card>
    <div class="border rounded-lg mb-2">
      <div class="flex justify-end p-2">
        <Button variant="primary" @click="openDialog(null)"> Add Income Source </Button>
      </div>
      <DataTable :headers="tableHeaders" :data="tableData" @action="handleAction" />
    </div>
    <!-- Add / Edit Dialog Component -->
    <IncomeDialog :is-open="isDialogOpen" :edit-item="editItem" @close="closeDialog" @confirm="handleConfirmIncomeDialog" />
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import apiService from '@/api/apiService';
  import Card from '@/components/Card/index.vue';
  import DataTable from '@/components/DataTable/index.vue';
  import Button from '@/components/Button/index.vue';
  import IncomeDialog from './components/IncomeDialog/index.vue';
  import { formatMoney } from '@/utils';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Income',
    components: {
      Card,
      DataTable,
      Button,
      IncomeDialog,
    },
    props: {
      borrowerId: {
        type: String,
        required: true,
      },
      writePermission: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return { formatMoney };
    },
    inject: ['quickCalculationData', 'setQuickCalculationData'],
    data() {
      return {
        isValidForm: false,
        tableHeaders: [
          { key: 'incomeType', label: 'Income Type' },
          { key: 'monthlyIncomeWithCommas', label: 'Monthy Income' },
          { key: 'annualIncomeWithCommas', label: 'Annual Income' },
          { key: 'action', label: 'Action' },
        ],
        tableData: [],
        isDialogOpen: '',
        editItem: null,
      };
    },
    methods: {
      ...mapActions(['setLoading']),
      async fetchIncomes() {
        if (!this.borrowerId) return;
        this.setLoading(true);
        try {
          const response = await apiService.get(`/loan/incomes/by-borrower/${this.borrowerId}/`);
          this.tableData = response.data.map((item, key) => ({
            id: key + 1,
            monthlyIncomeWithCommas: formatMoney(item.monthlyIncome),
            annualIncomeWithCommas: formatMoney(item.annualIncome),
            actions: ['edit', 'delete'],
            ...item,
          }));
          this.setQuickCalculationData(null);
        } catch (error) {
          this.tableData = []; // reset table data
          if (error.response.status === 404) return; // No error, just need to create new one.
          this.$root.showSnackbar(`${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          this.tableData = [];
        } finally {
          this.setLoading(false);
        }
      },
      openDialog(item = null) {
        this.isDialogOpen = item ? 'Edit' : 'Add';
        this.editItem = item
          ? { ...item }
          : {
              incomeType: 'Employment',
              monthlyIncome: 0,
              annualIncome: 0,
            };
      },
      closeDialog() {
        this.editItem = null;
        this.isDialogOpen = '';
      },
      async handleConfirmIncomeDialog(data, mode) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (!this.borrowerId) return;

        this.setLoading(true);
        try {
          if (data.incomeType !== 'Employment') {
            // Employment is the only income type that has employment history.
            data.employmentHistory = null;
          }
          if (mode === 'Edit') {
            // Edit mode
            await apiService.patch(`/loan/incomes/${data.id}/`, {
              ...data,
              borrower: this.borrowerId, // Need to add this line later cause data contains borrower object.
            });
          } else {
            // Add mode
            await apiService.post(`/loan/incomes/`, {
              ...data,
              borrower: this.borrowerId,
            });
          }
          this.$root.showSnackbar(`Income has been ${mode === 'Edit' ? 'updated' : 'added'} for the borrower successfully!`, 'success');
          this.closeDialog();
          await this.fetchIncomes();
        } catch (error) {
          this.$root.showSnackbar(`Failed to ${mode === 'Edit' ? 'update' : 'add'} income: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
        } finally {
          this.setLoading(false);
        }
      },
      async handleAction(action, item) {
        if (!this.writePermission) {
          this.$root.showSnackbar('You do not have permission', 'error');
          return;
        }
        if (action === 'edit') {
          this.openDialog(item);
        } else if (action === 'delete') {
          this.setLoading(true);
          try {
            await apiService.delete(`/loan/incomes/${item.id}/`);
            this.$root.showSnackbar('Income has been deleted successfully', 'success');
            await this.fetchIncomes();
          } catch (error) {
            this.$root.showSnackbar(`Failed to delete income: ${error.response?.data?.detail ?? error.message ?? 'Something went wrong.'}`, 'error');
          } finally {
            this.setLoading(false);
          }
        }
      },
    },
    watch: {
      borrowerId: {
        handler() {
          this.fetchIncomes();
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
