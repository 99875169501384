<template>
  <Card title="Settings">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <Card v-for="(setting, index) in settingsOptions" :key="index" :isFlex="true">
        <template v-slot:title>
          <div class="flex items-center gap-2">
            <component :is="setting.iconComponent" />
            <h1 class="text-lg font-semibold leading-none">{{ setting.title }}</h1>
          </div>
        </template>
        <template v-slot:action>
          <button class="p-2 rounded bg-primary" @click="navigate(setting.page)">
            <img src="../../assets/image/settings/up-right-arrow.svg" />
          </button>
        </template>
        <p class="text-sm mt-2">
          {{ setting.description }}
        </p>
      </Card>
    </div>
  </Card>
</template>

<script>
  import { mapActions } from 'vuex';
  import Card from '@/components/Card/index.vue';
  import PageHeader from '@/components/PageHeader/index.vue';
  import SettingsIcon from './components/Icons/SettingsIcon.vue';
  import DollarIcon from './components/Icons/DollarIcon.vue';
  import AgentIcon from './components/Icons/AgentIcon.vue';
  import TrustIcon from './components/Icons/TrustIcon.vue';
  import PayIcon from './components/Icons/PayIcon.vue';
  import ServiceIcon from './components/Icons/ServiceIcon.vue';
  import { USER_HIERARCHIES } from '@/constants';
  import { isCompanyAdmin, isCompanyUser, isStandardConditionsAdmin } from '@/utils';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Settings',
    components: {
      Card,
      PageHeader,
    },
    data() {
      const initialOptions =
        !isCompanyAdmin() && !isStandardConditionsAdmin()
          ? []
          : [
              {
                title: 'Company-Related Settings',
                iconComponent: SettingsIcon,
                page: 'CompanySettings',
                description: 'Update your company settings, including loan products, company licenses, and company-specific information.',
              },
              {
                title: 'Roles and Permissions',
                iconComponent: TrustIcon,
                page: 'RolesPermission',
                description: 'Manage roles and permissions for users in your company.',
              },
            ];
      const settingsOptions = [
        ...initialOptions,
        ...(isCompanyUser()
          ? [
              {
                title: 'Credentials',
                iconComponent: TrustIcon,
                page: 'UserCredentials',
                description: 'Set up your account credentials to access third-party services.',
              },
            ]
          : [
              {
                title: 'Fee Templates',
                iconComponent: DollarIcon,
                page: 'Fees',
                description: 'Set up your fee templates to automatically add predefined fees to your loans. Fees can be added based on different conditions, products, and states.',
              },
              {
                title: 'Manage Settlement Agents',
                iconComponent: AgentIcon,
                page: 'SettlementAgents',
                description:
                  'Manage the settlement agents associated with your company. Settlement agents can be defined and updated to avoid re-entering the settlement agent on each loan.',
              },
              {
                title: 'Manage Trustees',
                iconComponent: TrustIcon,
                page: 'Trustees',
                description: 'Set the trustees for mortgages in states requiring a trustee.',
              },
              {
                title: 'Manage Payees',
                iconComponent: PayIcon,
                page: 'Payees',
                description: 'Manage different third-party payees. Payees can be defined and updated to avoid manually entering these for each fee.',
              },
            ]),
        ...(isCompanyAdmin()
          ? [
              {
                title: 'Compliance Policies',
                iconComponent: PayIcon,
                page: 'CompliancePolicies',
                description: 'Review and manage compliance policies to ensure your company adheres to regulatory standards and internal guidelines.',
              },
              {
                title: 'Third Party Integrations',
                iconComponent: PayIcon,
                page: 'ThirdPartyIntegrations',
                description: 'Manage third-party integrations and handle API credentials efficiently.',
              },
              {
                title: 'Standard Conditions',
                iconComponent: ServiceIcon,
                page: 'StandardConditions',
                description: 'Manage standard conditions for loans.',
              },
            ]
          : []),
        // {
        //   title: 'Service Provider',
        //   iconComponent: ServiceIcon,
        //   description: 'Service provider settings.',
        // },
      ];
      return {
        settingsOptions,
        activeTab: { title: 'Products', isActive: true },
        userHierarchies: USER_HIERARCHIES,
      };
    },
    computed: {},
    methods: {
      ...mapActions(['setLoading']),
      redirectToDashboard() {
        this.$router.push({ name: 'Dashboard' });
      },
      changeActiveTab(title) {
        this.activeTab = { title, isActive: true };
      },
      navigate(newPage) {
        if (newPage) {
          this.$router.push({ name: `${newPage}` });
        }
      },
    },
  };
</script>
