<template>
  <div class="p-2 rounded-md" :class="focused ? 'border border-primary' : 'border border-transparent'" @click="onClick">
    <div class="flex items-center justify-between">
      <label class="font-semibold text-[#212121] mb-[10px]">{{ label }}</label>

      <!-- Tooltip Icon with tooltip text on hover -->
      <Tooltip v-if="tooltip?.length > 0" :tooltip="tooltip" />

      <button v-if="historyShow" class="history p-0 ml-1" @click="triggerOpenHistoryDialog">
        <font-awesome-icon class="text-primary" icon="history" :style="{ fontSize: '14px' }" />
      </button>
    </div>
    <div class="flex items-center">
      <div v-for="option in options" :key="option.value" class="flex items-center mr-15">
        <input
          type="radio"
          :id="`${id}-${option.value}`"
          :name="id"
          :value="option.value"
          v-model="inputValue"
          @change="onInputChange"
          class="custom-radio appearance-none outline-none relative p-0 rounded-full w-6 h-6 border-dark-gray border-2 cursor-pointer"
        />
        <p :for="`${id}-${option.value}`" class="m-0 ml-2 cursor-pointer" @click="onInputChange(option.label)">
          {{ option.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RadioInput',
    props: {
      label: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      options: {
        type: Array,
        required: true,
        validator: (options) => {
          return options.every((option) => 'label' in option && 'value' in option);
        },
      },
      historyShow: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        default: '',
      },
      focused: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        inputValue: this.value,
      };
    },
    watch: {
      value(newVal) {
        this.inputValue = newVal;
      },
    },
    methods: {
      onInputChange(label = '') {
        if (label.length) {
          this.inputValue = label === 'Yes' ? true : false;
        }
        this.$emit('update:modelValue', this.inputValue);
      },
      triggerOpenHistoryDialog() {
        this.$emit('trigger-open-history-dialog', { id: this.id });
      },
      onKeyPress(event) {
        if (!this.focused) return;
        if (event.key === 'Y' || event.key === 'y') {
          this.inputValue = true;
        } else if (event.key === 'N' || event.key === 'n') {
          this.inputValue = false;
        }
      },
      onClick() {
        this.$emit('update:focused', true);
      },
    },
    mounted() {
      window.addEventListener('keydown', this.onKeyPress);
    },
    unmounted() {
      window.removeEventListener('keydown', this.onKeyPress);
    },
  };
</script>

<style>
  .custom-radio:checked {
    border-color: #215065;
  }
  .custom-radio:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #215065;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
