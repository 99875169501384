<template>
  <div class="flex h-screen justify-between">
    <Sidebar :show="showSidebar" @toggleSidebar="toggleSidebar" @search="handleSearchSubmit" />
    <div class="main-content w-full" :class="mainContentWidth">
      <Header @toggleSidebar="toggleSidebar"></Header>
      <div class="p-[10px] overflow-y-auto" style="height: calc(100vh - 70px)">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
  import Header from './components/header/Header.vue';
  import Sidebar from './components/sidebar/Sidebar.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Layout',
    components: {
      Header,
      Sidebar,
    },
    data() {
      return {
        showSidebar: true,
      };
    },
    computed: {
      mainContentWidth() {
        return this.showSidebar ? 'lg:w-[calc(100% - 70px)]' : 'lg:w-[calc(100% - 270px)]';
      },
    },
    methods: {
      toggleSidebar() {
        this.showSidebar = !this.showSidebar;
      },
      handleSearchSubmit(query) {
        this.searchQuery = query;
        this.$router.push({ name: 'FindLoan', query: { q: query } });
      },
    },
  };
</script>

<style scoped>
  .main-content {
    background: #f9f9fb;
  }
</style>
